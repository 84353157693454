import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIURL } from "../../../../Global";
import { useAlert } from 'react-alert'
import "./deals.css"
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
function NewArrival(props) {
    const alert = useAlert()
    const [promoId1, setPromoId1] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [saved, setSaved] = useState([])
    const [previewOn1, setPreviewOn1] = useState(false);
    const [previewOn2, setPreviewOn2] = useState(false);
    const [img1, setImg1] = useState("");
    const [img2, setImg2] = useState("");
    const [invalidPic, setInvalidPic] = useState(null);
    const [searchTerm1, setSearchTerm1] = useState("")
    const [isUpdating1, setIsUpdating1] = useState(false)
    const [isUpdating4, setIsUpdating4] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [prod1, setProd1] = useState({ name: "", id: 0 })
    const [uploadShow, setUploadShow] = useState(false);
    const [validShow, setValidShow] = useState(false);
    const [sizeShow, setSizeShow] = useState(false);
    const [contentShow, setContentShow] = useState(false);
    const [display1, setDisplay1] = useState(false);
    const [newList, setNewList] = useState([])
    const reader = new FileReader();
    const initialRender = useRef(true);
    const aggr_type = sessionStorage.getItem("aggr_type") ? sessionStorage.getItem("aggr_type") : null
    useEffect(() => {

        if (initialRender.current) {
            initialRender.current = false;
        }
        if (searchTerm1 === "") {
            setNewList([]);
            setDisplay1(false);
        }



        if (searchTerm1 !== "") {

            const tokenString = sessionStorage.getItem("usertoken");

            let v2 = tokenString.replace(
                /["]+/g,
                ""
            ); /* REGEX To remove double quotes from tokenstring */

            axios
                .get(
                    `${APIURL}/api/v1/service-provider/hosp-services-promotions/?search=${searchTerm1}`,
                    {
                        headers: { Authorization: "Token " + v2 },
                    }
                )
                .then((res) => {
                    if (res.status === 200) {
                        setNewList(res.data.services);


                        setDisplay1(true)




                    } else {
                    }
                })
                .catch((error) => { });
        }
    }, [searchTerm1]);
    
    useEffect(() => {


        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '');
        axios.get(`${APIURL}/api/v1/service-provider/add-promotions/?promo_type=new_arrival`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Token ' + str
                }
            })
            .then(res => {
                if (res.data.status === "success") {


                    let data = res.data.message
                    setSaved(data)
                    if (data[0].banner !== "") {
                        setPreviewOn1(true)
                        setImg1(data[0].banner)
                    }
                    if (data[0].promo_banner_app !== "") {
                        setPreviewOn2(true)
                        setImg2(data[0].promo_banner_app)
                    }

                    setProd1({ name: data[0].prom_items[0].item_name, id: data[0].prom_items[0].item_service })
                    setPromoId1(data[0].promotion)
                    setIsUpdating1(false)
                    setIsUpdating4(false)
                    if (!data[0].is_active) {
                        setLoading1(true)
                    } else {
                        setLoading1(false)
                    }

                }

            })

    },[refresh])
    const handleActivation = (action) => {
        let data = {}
        if (action === "act") {

            data = {
                 is_active: !saved[0].is_active

            }
        } else {
            data = {
                is_deleted: true
            }
        }

        const tokenStr = sessionStorage.getItem("usertoken");


        if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
            axios.put(`${APIURL}/api/v1/service-provider/add-promotions/${promoId1}/`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
                .then((res) => {
                    if (res.data.status === "success") {
                        alert.success("Successfully Updated")
                        setRefresh(!refresh)
                    } else {
                        alert.error("Error in submission")
                    }
                })
                .catch((err) => {
                    alert.error("Error in submission");
                });
        }

    };
    const uploadImage = async (e) => {
        const key = e.target.name;
        const file = e.target.files[0];
        console.log(key)

        if (!file) {
            setUploadShow(!uploadShow);
            //setInvalidPic('Please Select an Image')
            return false;
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setValidShow(!validShow);
            //setInvalidPic('Please select valid image')
            return false;
        }
        if (file.size > 2e6) {
            setSizeShow(!sizeShow);
            return false;
        }

        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                if (key === "img1")
                    setImg1(file);
                else if (key === "img2")
                    setImg2(file);
                setInvalidPic(null);
            };

            img.onerror = () => {

                setContentShow(!contentShow);

                return false;
            };
            img.src = e.target.result;
        };

        reader.readAsDataURL(file);

        const objectURL = URL.createObjectURL(file);
        if (key === "img1") {

            setPreviewOn1(true);
        } else if (key === "img2") {

            setPreviewOn2(true);
        }
    };

    const removePicPreview = (e, img) => {
        e.preventDefault();

        if (img === "img1") {
            setPreviewOn1(false);
            setImg1("")
        }
        else if (img === "img2") {
            setPreviewOn2(false);
            setImg2("")
        }

    };
    const handleUpdate = (e) => {

        e.preventDefault();
        let data = {}
        let isimg = false
        const fd = new FormData();
        const tokenStr = sessionStorage.getItem("usertoken");
        
        if (isUpdating1 === true || isUpdating4 === true) {
            if (saved[0].banner !== img1) {
                fd.append(`promo_banner`, img1)
                fd.append(`promo_display`, "dp1")
                isimg = true
            }
            if (saved[0].promo_banner_app !== img2) {
                fd.append(`promo_banner_app`, img2)
                fd.append(`promo_display`, "dp1")
                isimg = true
            }
            if (saved[0].prom_items[0].item_service !== prod1.id) {
                data = {
                    "items": [Number(prod1.id)],

                }
                isimg = false
            }
        }
        if(img1===""){
            alert.error("Please add banner for Web")
        }
        else if(img2===""){
            alert.error("Please add banner for App")
        }
        else if(prod1.id===0){
            alert.error("Please add new arrival product")
        }
        else{
        if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
            axios.put(`${APIURL}/api/v1/service-provider/add-promotions/${promoId1}/`, isimg ? fd : data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
                .then((res) => {
                    if (res.data.status === "success") {
                        alert.success("Successfully Updated")
                        setRefresh(!refresh)
                    } else {
                        alert.error("Error in submission")
                    }
                })
                .catch((err) => {
                    alert.error("Error in submission");
                });
        }}

    };
    const handleSearchChange = (e) => {
        console.log(saved, "saved")
        if (e.target.name === "search1") {

            setSearchTerm1(e.target.value);
        }


        if (e.target.value === '') {
            setDisplay1(false);

        }

    };
    const handleSubmit = () => {
        const fd = new FormData();
        const tokenStr = sessionStorage.getItem("usertoken");

        if (prod1.id !== 0) {
            fd.append(`promotions[0]promo_type`, "new_arrival")
            fd.append(`promotions[0]promo_banner`, img1)
            fd.append(`promotions[0]promo_banner_app`, img2)
            fd.append(`promotions[0]promo_display`, "dp1")
            fd.append(`promotions[0]promotion_items`, [prod1.id])

        }
        if(img1===""){
            alert.error("Please add banner for Web")
        }
        else if(img2===""){
            alert.error("Please add banner for App")
        }
        else if(prod1.id===0){
            alert.error("Please add new arrival product")
        }
        else{

        if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
            axios.post(`${APIURL}/api/v1/service-provider/add-promotions/?${aggr_type === "aggregator" ? "aggregator=True" : ""}`, fd, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
                .then((res) => {
                    if (res.data.status === "success") {
                        alert.success("Successfully Updated")
                        setRefresh(!refresh)
                    } else {
                        alert.error(res.data.message)
                    }
                })
                .catch((err) => {
                    alert.error("Error in submission");
                });
        }}

    };
    return (
        <div className="row">
            <h6 style={{ color: "#1FAEDB", marginTop: "2%" }} className="text-center"><b>
                New Arrivals
            </b>

                {saved.length > 0 ? <div style={{ float: "right", paddingRight: "2%", display: "flex" }}>
                    <i style={{
                        marginLeft: "25px",
                        color: saved.length > 0 && saved[0].is_active ? "green" : "red",
                    }} onClick={() => { handleActivation("act") }}
                        class={saved.length > 0 && saved[0].is_active ? "fa fa-toggle-on fa-lg" : "fa fa-toggle-on fa-lg fa-rotate-180 "}
                        aria-hidden="true"></i>
                    <i style={{
                        marginLeft: "25px",
                        color: saved.length > 0 && "red",
                    }} onClick={() => { handleActivation("del") }} class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                </div> : ""}</h6>

            <div style={{ marginTop: "3%",boxShadow: "2px 0px 0px #b4b2b2", height:"350px" }} className="col-5 row">
                <div className="col-6">

                    <LoadingOverlay
                        active={loading1}
                    >

                        <div className="avatardeals">
                            <div className="insideavtr">Web</div>
                            {previewOn1 === false ? (
                                <div className="image-holder-div">
                                    <input

                                        name="img1"
                                        type="file"
                                        className="custom-file-input"
                                        id="validatedCustomFile1"
                                        onChange={uploadImage}
                                    />
                                    {invalidPic && <p className="error">{invalidPic}</p>}
                                    <label style={{ width: "100%" }} className="" htmlFor="validatedCustomFile1">


                                        <i style={{ marginLeft: "42%", marginTop: "15%", color: "#ADADAD", }} className="fa fa-picture-o fa-2x"></i><br />
                                        <span style={{ marginLeft: "35%", color: "#ADADAD", fontSize: "small" }}>Size : 570x570</span>

                                    </label>

                                </div>
                            )

                                : (
                                    <div>
                                        {isUpdating1 || saved.length === 0 ? <div onClick={(e) => { removePicPreview(e, "img1") }}>
                                            <i
                                                style={{ position: "absolute" }}
                                                className="fas fa-times-circle"
                                            ></i>
                                        </div> : ""}
                                        <img
                                            src={img1}
                                            alt="New Arrival Image1.Please Save to view"
                                            className="avatardeals"

                                        />
                                    </div>
                                )}</div><br/><br/>

                       

                    </LoadingOverlay>


                </div>
                <div className="col-6">

                    <LoadingOverlay
                        active={loading1}

                    >
                        <div className="avatardeals">
                            <div className="insideavtr">Mobile</div>
                            {previewOn2 === false ? (
                                <div className="image-holder-div">
                                    <input

                                        name="img2"
                                        type="file"
                                        className="custom-file-input"
                                        id="validatedCustomFile2"
                                        onChange={uploadImage}
                                    />
                                    {invalidPic && <p className="error">{invalidPic}</p>}
                                    <label style={{ width: "100%" }} className="" htmlFor="validatedCustomFile2">


                                        <i style={{ marginLeft: "42%", marginTop: "15%", color: "#ADADAD", }} className="fa fa-picture-o fa-2x"></i><br />
                                        <span style={{ marginLeft: "35%", color: "#ADADAD", fontSize: "small" }}></span>

                                    </label>

                                </div>
                            )

                                : (
                                    <div>
                                        {isUpdating1 || saved.length === 0 ? <div onClick={(e) => { removePicPreview(e, "img2") }}>
                                            <i
                                                style={{ position: "absolute" }}
                                                className="fas fa-times-circle"
                                            ></i>
                                        </div> : ""}
                                        <img
                                            src={img2}
                                            alt="New Arrival App Image.Please Save to view"
                                            className="avatardeals"
                                        />
                                    </div>
                                )}</div>

                       <br/><br/>
                    </LoadingOverlay>
                </div>
                {saved.length > 0 ? <button style={{width:"100px",backgroundColor: "#115269",marginLeft:"42%",marginTop:"3%"}} onClick={(e) => { !isUpdating1 ? setIsUpdating1(true) : handleUpdate(e, 1) }} className="webpromo">{!isUpdating1 ? <i class="fa fa-pencil" aria-hidden="true">{" "}Edit</i> : <i class="fa fa-floppy-o" aria-hidden="true">{" "}Update</i>}</button> : ""}
            </div>
            <div style={{ marginTop: "3%" }} className="col-7">
                <div className="prod-div">
                <LoadingOverlay
                        active={loading1}

                    >
                    <div className="search-webordering" style={{ height: "38px", marginTop: "-1px", border: "1px solid #948686", backgroundColor: "white" }}>
                        <div style={{ display: "flex", marginTop: "0.8%", marginLeft: "2%", paddingRight: "1%" }}>
                            <input
                                name="search1"
                                 disabled={!isUpdating4&&saved.length!==0}
                                type="text"
                                className=""
                                placeholder="Search here "
                                value={searchTerm1}
                                onChange={handleSearchChange}
                                style={{ width: "500px", paddingLeft: "10px", border: "none", fontSize: "15px" }}
                            />
                            <div style={{ paddingTop: "0.9%", paddingLeft: "1.5%", color: "gray", height: "30px", width: "30px" }} className="search-btn1">
                                {" "}
                                <i className="fas fa-search"></i>{" "}
                            </div>
                        </div>
                    </div > 
                    {display1 ? (
                        <div className="suggest-display adjust-dimensions" >
                            {newList.length > 0 ? (
                                newList.map((item) => {
                                    return (
                                        <p
                                            onClick={(e) => {
                                                setProd1({
                                                    name: item.item_service_name,
                                                    id: item.item_service
                                                }); setDisplay1(false);
                                                setSearchTerm1("")

                                            }}
                                            className=" text-secondary"
                                            style={{ padding: "2%" }}
                                        >
                                            {item.item_service_name}
                                        </p>
                                    );
                                })
                            ) : (
                                <h6 className="text-muted" style={{ padding: 10 }}>
                                    No Services found !!
                                </h6>
                            )}
                        </div>
                    ) : null}<br/>
                    <div className="prod-div" >  {prod1.id !== 0 && <i
                        class="fa fa-trash-o "
                        style={{ cursor:isUpdating4? "pointer":"none", color: "red" }}
                        onClick={() => {
                           if(isUpdating4 )setProd1({ name: "", id: 0 })

                        }}
                    ></i>}&nbsp;{prod1.name}
                   </div>
                   </LoadingOverlay>
                </div>
               
            </div>

            <div className="form-button" style={{ float: "right", }}>
                <button  onClick={(e) => {saved.length===0?handleSubmit():!isUpdating4 ? setIsUpdating4(true) : handleUpdate(e, 1, promoId1) }} style={{ backgroundColor: "#115269", marginLeft: "80%",marginBottom:"5%" }} className="webpromo"> <i class={saved.length===0?"":!isUpdating4 ? "fa fa-pencil" : "fa fa-floppy-o"} aria-hidden="true"></i>{" "}{saved.length===0?"Save":!isUpdating4?"Edit":"Update"}</button>
            </div>
            
        </div>
    )
}
export default NewArrival;