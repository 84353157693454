import React, { useState, createContext } from "react";

export const LoginContext = createContext();

export const LoginProvider = (props) => {
  {/*const [loginDatas, setLoginDatas] = useState({
    username: "",
    password: "",
    token: "",
    user_type: "",
    isAuthenticated: "",
  });*/}
  const [loginDatas, setLoginDatas] = useState({
   
  });
  return (
    <LoginContext.Provider value={[loginDatas, setLoginDatas]}>
      {props.children}
    </LoginContext.Provider>
  );
};
