import { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import Message from '../../common/Message';
import PhoneInput from "react-phone-input-2";
const HelpLine = (props) => {
    const [contactNumber, setContactNumber] = useState(props.contact + "")
    const [email, setEmail] = useState(props.email)
    const [isChange, setIsChange] = useState(props.contact?true:false)
    const [updated, setUpdated] = useState(false)
    const [phoneError, setPhoneError] = useState("");
    const [error, setError] = useState("")
    const [changePh,setChangePh]=useState(false)
    const [changeEmail,setChangeEmail]=useState(false)
    const[deleted,setDeleted]=useState(false)


    const callSave = () => {
        setError(null)
        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')
        let num= contactNumber.slice(2,12);
      
        const data = {
            code: "91",
            mobile_number: contactNumber,
            email: email
        }
        if (validatePhoneInput(contactNumber) !== true ) {
            setPhoneError("Invalid Number")
            setTimeout(() => {
                props.onSuccess();
                setPhoneError(null)
            }, 2000)
        }
        
        else if(validateEmailInput() === false){
           setError("Invalid EmailId")
        }
        else{
        axios.post(`${APIURL}/api/v1/staff/helpline-numbers/${props.id}`, data, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {



                if (res.data.status === "success") {
                    //setToggle(false)
                    setIsChange(true)
                    setUpdated(true)
                  
                    setTimeout(() => {
                        props.onSuccess();
                        setUpdated(false)
                    }, 2000)

                }
            })
            .catch(err => {


            })

        }
    }
    const callDelete = (item) => {
        const tokenString = sessionStorage.getItem("usertoken");
    
        let str = tokenString.replace(/["]+/g, "");
        axios
          .delete(
            `${APIURL}/api/v1/staff/helpline-update/${props.id}/`,
            {
              headers: {
                Authorization: "Token " + str,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.status === 204) {
               setDeleted(true)
              
               setTimeout(() => {
                props.onSuccess();
                setDeleted(false);
              
            }, 2000)
            } else {
              console.log("else called ");
            }
    
          
          })
          .catch((err) => {
            console.log("catch called");
          });
      };

    const callUpdate = () => {
        setError(null)
     
        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '')
        let data={}
        if (changePh&&changeEmail){
         data = {
            code: "91",
            mobile_number: contactNumber,
            email:email
            
        }}
        else if(changeEmail){
             data={
                email:email
             }
        }
        else{
            data={
                code: "91",
                mobile_number: contactNumber,
              
            }
        }
        if (validatePhoneInput(contactNumber) !== true ) {
            setPhoneError("Invalid Number")
            setTimeout(() => {
    
                setPhoneError(null)
            }, 3000)
        }
        
        else if(validateEmailInput() === false){
           setError("Invalid EmailId")
           setTimeout(() => {
    
            setError(null)
        }, 3000)
        }
        
        else {
            axios.put(`${APIURL}/api/v1/staff/helpline-update/${props.id}/`, data, {
                headers: {
    
                    "Authorization": 'Token ' + str
                }
            })
                .then(res => {
                    if (res.data.status === "success") {
                        setChangePh(false)
                        props.onSuccess();
                        setChangeEmail(false)
                        //setToggle(false)
                        setIsChange(true)
                        setUpdated(true)
                        setTimeout(() => {
    
                            setUpdated(false)
                        }, 3000)
    
                    } else  {

                        setPhoneError(res.data.message)
        
                        }
        
    
                })
        }

        


    }


    const handlePhoneInput = (value) => {
        if(value===""){
            setPhoneError("Please Enter Phone Number")
            setTimeout(() => {
    
                setPhoneError("")
            }, 3000)
        }else
        
            setContactNumber(value);
            setChangePh(true)
         
    }

    const validatePhoneInput = (value) => {
        if(value.length!==0){
        if (value.length!== 10 ) {
            return "Invalid phone number";
        }
        return true;
    }}


    const validateEmailInput = () => {

        if (typeof email !== "undefined") {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(email)) {

                return false;

            }

        }
        return true;

    }


    const handleChange = (e) => {
        const val = e.target.value;
        setEmail(val);
         if(val===""){
           
            setError("Please Enter EmailId")
            setTimeout(() => {
    
                setError(null)
            }, 3000)
        }else
            setEmail(val);
            setChangeEmail(true)
  
        
    }
    return (<>{updated && <div className='successmsg'><Message variant="success" children="Contact details updated successfully" /></div>}
    {deleted && <div className='successmsg'><Message variant="success" children="Deleted successfully" /></div>}
        {phoneError&& <div className='successmsg'><Message variant="danger" children={phoneError} /></div>}
        {error&& <div className='successmsg'><Message variant="danger" children={error} /></div>}
        <div style={{ display: "flex" }}>
            <label className=''style={{paddingTop:"1%"}}>Phone Number{props.index}:</label><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                <PhoneInput
                    // inputProps={{}}
                    country={"in"}
                    onlyCountries={['in']}
                    // countryCodeEditable={false}
                    defaultCountry={"in"}
                    international={false}
                    placeholder="Enter without code"
                    value={contactNumber}
                    onChange={handlePhoneInput}
                    disableDropdown={true}
                    isValid={validatePhoneInput}
                    disabled={isChange}
                  
                    disableCountryCode={true}
                />


                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <label className='' style={{paddingTop:"1%"}}>Email{props.index}:</label><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <input
                    className={isChange ? 'confinput1 form-control notactive' : "confinput form-control"}
                    type="email"                   
                    name="email"
                    value={email}
                    onChange={handleChange}
                   

                ></input>
                <span>&nbsp;&nbsp;&nbsp;</span>
                {isChange
                    ? <i className="fa fa-pencil fa-lg"
                        style={{ height: "30px", width: "60px", color: "#5a9569", cursor: "pointer", paddingTop:"2%" }}
                        onClick={() => { setIsChange(false) }}
                    >Edit</i> :
                    <i className="fa fa-floppy-o fa-lg"
                        style={{ height: "30px", width: "50px", color: "#007bff", cursor: "pointer" ,paddingTop:"2%"}}
                        onClick={props.contact ? callUpdate : callSave}
                    >Save</i>}<br />
                     <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    {isChange? <i className="fa fa-trash fa-lg"
                        style={{ height: "30px", width: "60px", color: "#f52753", cursor: "pointer", paddingTop:"2%" }}
                        onClick={() => { callDelete() }}
                    ></i>:""}
            </div>
            </>  
    )
}



export default HelpLine;