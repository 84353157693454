import React,{useState,useEffect} from "react";
import "./suspend.css";
import axios from 'axios';
import { Link, useNavigate,useParams } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";

function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>  Suspended Successfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
        

        <Link to="/doctorslist"><Button className='btn btn-primary btn-col' > Goto Doctors List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

function SuspendDoctor(props) {

 const [modalShow, setModalShow] = useState(false);
      const [submitMsg, setSubmitMsg]= useState("");

const {id} =useParams();
   

   const navigate =useNavigate();

   const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});
   
   useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
   
   useEffect(()=>{
   
   window.onkeydown =(e) => {
    
    keys[e.key]=true
   
    if(e.altKey && keys['d']){
      navigate('/doctorslist')
    }
    if(e.altKey && keys['a']){
      navigate('/hospital/add')
    }
    if(e.altKey && keys['b']){
      navigate('/bdo-list')
    }
    if(e.altKey && keys['o']){
      navigate('/hospital/add-bdo')
    }
    if(e.altKey && keys['n']){
      navigate('/emergency-number')
    }
    if(e.altKey && keys['s']){
      navigate('/service-request-list')
    }
    if(e.altKey && keys['l']){
      navigate('/license-details')
    }
    if(e.altKey && keys['p']){
      navigate('/license-payment')
    }
    if(e.altKey && keys['c']){
      navigate('/hosp-calllog')
    }
    if(e.altKey && keys['h']){
      navigate('/payment-history')
    }
   
   }
   window.onkeyup=(ev)=> {
    
    keys[ev.key]= false
   }
   
   return()=>{
    window.onkeydown=null
    window.onkeyup=null
   }
   },[]);


   const handleSubmit=(e)=>{
   	e.preventDefault();

   	const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); 

    
  const data = {
  	is_suspended :true
  }

    axios.put(`${APIURL}/api/v1/service-provider/doctors/${id}/`, data, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setModalShow(true);
          
          
        } else {
           setSubmitMsg("error");
        }
      })
      .catch((err) => {
         setSubmitMsg("error");
      });
  }

   
 return( 
 <>

 <div className="liner">

  </div>
    <div className="container">
       <form className="create-app-user-form col-lg-6" onSubmit={handleSubmit}>
         <div style={{textAlign: 'center'}}>

         
       <h4 className="title-of-page"> Suspend Doctor  </h4><br/>
       <h6> Please Confirm to suspend the Doctor ID {id}</h6><br/>
       </div>
       


  <div className="form-group " style={{textAlign: 'center'}}>
         <button type="button" className="btn btn-danger btn-cancel"> <Link to="/doctorslist" className="cancel-btn">Cancel </Link> </button> 
          <button type="submit" className="btn btn-primary btn-col" >Confirm </button>
  </div>







       </form>

        <SubmitPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {submitMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data submission.Please try again!
          </h5>
      :
      null}


    </div>
 </>
);
}
export default SuspendDoctor;