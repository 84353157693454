import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { APIURL } from "../../Global";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Modal,Button } from "react-bootstrap";
import { Carousel } from 'react-bootstrap';
import AddToCart from './AddToCart';
import { Badge } from 'react-bootstrap';
import AddToCartBestSell from './AddtoCartBestSell';
import dr2 from "./assets/img/dr2.jpg"

function NewArrivalsDefault({ bannerUrl, title, description, price,data,key,discount }) {

  const [selectedItem, setSelectedItem] = useState(null);
  const [cartdata, setCartdata] = useState("");
  const [cartShow, setCartShow] = useState(false)
  const [selecteddata, setSelecteddata] = useState([]);
  const [amountdata, setAmountData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [promotions, setPromotions] = React.useState([]);






return(
    <>
    

<div className="single-banner-item"  >
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="banner-content">
                  {/* <span className="sub-title">{title}</span> */}
                  <h1 style={{color:"#F0A639"}}><b>Welcome to HorizonCommerce!</b></h1>
                 <h1>{description}</h1>
                  <div className="btn-box">
                    <div className="d-flex align-items-center">
                    
                     
                                 
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="banner-image text-center">
                  <img src={dr2} className="main-image" alt="image" style={{ width: "300px",height:"300px",objectFit: "cover"}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




    </div>


    </>






)







}
export default NewArrivalsDefault