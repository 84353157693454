import React, { useContext, useState } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import { useNavigate } from "react-router-dom";
import './hospprofile.css'
import { Button, Modal } from 'react-bootstrap';
import { LoginStatusContext } from "../contexts/LoginStatusContext";

function NameUpdate(name) {
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginStatusContext);
  const [hname, sethName] = useState(name)
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [submitMsg, setSubmitMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const[flag,setFlag]=useState(false)
  const navigate = useNavigate();

  const [error, setError] = useState({
    name: ''
  });

  const handleChange = (e) => {
    setFlag(true)
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    if (key === 'name') {
      sethName(val)
    }
  }

  const validate = () => {
    //let input = email;
    let errors = {};
    let isValid = true;

    if (name === "") {
      isValid = false;
      errors["name"] = "Please enter a name";
    }

    setError(currentstate => ({
      ...currentstate, ...errors
    }))

    return isValid;
  }

  const handleContinue = async () => {

    if (validate()) {
      submitName()
    }
  }
  const callLogout = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/account/logout/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
        } else {
        }
      })
      .catch((err) => { });

    navigate("/weborderig");
  };
  const handleLogout = () => {
    callLogout();
    setIsLoggedIn(false);

    sessionStorage.clear();
  };


  const submitName = (e) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const data = {
      name: hname
    }

    axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          setFlag(false)
          setSubmitMsg("success");
          setShow(true)

        } else {
          setErrorMsg(res.data.message);
          setErrorSubmit(true);


        }

      })
      .catch(err => {
        setErrorMsg("error");

        setErrorSubmit(true);


      })

  }

  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 class="updated-message">You will be Logged Out after Updating Hospital Name. Are you sure to Continue ?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>No</Button>
          <Button variant="danger" onClick={props.onCall}>Yes</Button>

        </Modal.Footer>
      </Modal>
    )
  }


  const SuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 class="updated-message">Updated Successfully</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }


  const SubmitErrorPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 class="updated-message" style={{ color: "red" }}>{errorMsg} </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>




        </Modal.Footer>
      </Modal>
    );
  }



  return (
    <div>
      <div className="item"><input className="form-control" type="text" maxLength="150"
        name="name" value={hname.name}
        onChange={handleChange} />

        {error.name ? <div className="error-validation-msg"> {error.name}</div> : null}

      </div>
      <br />
      <div className="form-button">
        <button disabled={!flag} onClick={() => setModalShow(true)} className="btn btn-primary btn-col" >Update Name</button>
      </div>

      {
        modalShow ?
          <ConfirmPopUp
            show={modalShow}
            onCall={() => { handleContinue(); setModalShow(false) }}
            onHide={() => setModalShow(false)} /> : null
      }

      {
        submitMsg === "success" ?
          <SuccessPopUp
            show={show}
            onHide={() => {
              setShow(false)
              setSubmitMsg('');
              handleLogout();
            }} />
          : null
      }

      {errorMsg !== '' ?
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            setErrorMsg('');
          }}

        /> : ''
      }


    </div>
  )
}

export default NameUpdate
