import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import axios from "axios";
import { APIURL } from "../../Global";
function ResetPassword(props) {

  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [successSubmitMsg, setSuccessSubmitMsg] = useState(null);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [error, setError] = useState({
    password: "",
    confirm_password: ''
  });

  const location = useLocation();
  const otpValue = location.state.otp;
  const user = location.state.username;

  const SubmitSuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "green" }}> {successSubmitMsg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Link to='/webordering'> <Button className='btn btn-primary btn-col' >Goto Login </Button></Link>
        </Modal.Footer>
      </Modal>
    );
  }

  const ErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {errorMsg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Ok</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  const validate = () => {
    let errors = {};
    let isValid = true;
    setError({});
    if (typeof newPassword !== "undefined") {

      // let pattern = new RegExp(/[A-Z]/g);
      let pattern = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*&#^])([a-zA-Z0-9!@#$%^&*]{8,})$/);
      if (!pattern.test(newPassword)) {
        isValid = false;
        errors["password"] = "Password must contain at least 1 UPPERCASE, 1 lower case, 1 number, 1 special char and don't allow space";
      }


    }
    if (!newPassword || newPassword === "") {
      isValid = false;
      errors["password"] = "Please enter your password";
    }

    if (!confirmPassword || confirmPassword === "" || confirmPassword === null) {
      isValid = false;
      errors["confirm_password"] = "Please enter confirm password";
    }

    if (newPassword && typeof newPassword !== "undefined") {
      if (newPassword.length > 0 && newPassword.length < 8) {
        isValid = false;
        errors["password"] = "Must contain atleast 8 characters";
      }
    }

    if (typeof newPassword !== "undefined" && newPassword !== "" && typeof confirmPassword !== "undefined" && confirmPassword !== "" && confirmPassword !== null) {

      if (newPassword !== confirmPassword) {
        isValid = false;
        errors["confirm_password"] = "Passwords don't match";
      }
    }

    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));

    return isValid;
  }


  const callResetPw = () => {
    const dataToSend = {
      username: user,
      otp: otpValue,
      password: newPassword
    }
    axios.put(`${APIURL}/api/v1/account/forgot-password/`, dataToSend, {
      headers: {


        'Content-Type': 'application/json'
      }


    })

      .then(res => {

        if (res.data.status === "success") {


          setSuccessSubmitMsg(res.data.message ? res.data.message : "Password successfully updated!");
          setSuccessSubmit(true);


        }
        else {

          setErrorMsg(res.data.message ? res.data.message : "Error in Password reset");
          setErrorSubmit(true);

        }

      })
      .catch(err => {
        setErrorMsg("Error in Password Reset");
        setErrorSubmit(true);

      })


  }
  const handleResetPassword = (e) => {
    e.preventDefault();
    if (validate()) {

      callResetPw();



    }
  }




  return (<><div className="wrapper-content">
    <div className="forgot-password-page">









      <form className="login-form forgot-password-form" onSubmit={handleResetPassword}>
        <h4 className="form-heading title-of-tasks"> Reset Password </h4>






        {/* < div className=" " style={{color:"grey"}}> {user}</div>*/}

        <input type="password" maxLength='20' className="form-control" placeholder="New Password" value={newPassword} onChange={(e) => {
          e.preventDefault();
          setNewPassword(e.target.value)
        }} />

        {error.password ? <div className="error-validation-msg"> {error.password}</div> : null}


        <br />

        <input type="password" maxLength='20' className="form-control" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => {
          e.preventDefault();
          setConfirmPassword(e.target.value)
        }} />


        {error.confirm_password ? <div className="error-validation-msg"> {error.confirm_password}</div> : null}







        <br />  <br />
        <div style={{textAlign: 'center'}}> 
        <button className="btn btn-primary btn-col"  >Reset </button>
        </div>

      </form>


      {successSubmitMsg !== "" ?
        <SubmitSuccessPopup
          show={successSubmit}
          onHide={() => {
            setSuccessSubmit(false);
            setSuccessSubmitMsg('')
          }}

        /> : null}


      {errorMsg !== null ?
        <ErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}

        /> : null}

    </div>
  </div>

  </>

  )


}
export default ResetPassword;