import React,{useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import "./sdalist.css";
import axios from 'axios';
//import Navbar from '../common/Navbar';
import {APIURL} from "../../Global";
import Sda from '../Sda/Sda';
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';
import LoadingOverlay from 'react-loading-overlay';
import BackButton from '../common/BackButton';

function SdaList(){
  const [loading,setLoading]=useState(false);
const [sdaList,setSdaList] = useState([]); 
const [freeCount, setFreeCount]= useState();
const [activeCount, setActiveCount]= useState()
   const [errorMsg, setErrorMsg]= useState("");
  const [refresh, setRefresh]= useState(false)
	
  // const history =useHistory();

  //  const [keys,] = useState({'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});

//    useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])

// useEffect(()=>{

//   window.onkeydown =(e) => {
    
//     keys[e.key]=true

//     if(e.altKey && keys['a']){
//       history.push('/hospital/add')
//     }
//     if(e.altKey && keys['b']){
//       history.push('/bdo-list')
//     }
//     if(e.altKey && keys['o']){
//       history.push('/hospital/add-bdo')
//     }
//     if(e.altKey && keys['n']){
//       history.push('/emergency-number')
//     }
//     if(e.altKey && keys['s']){
//       history.push('/service-request-list')
//     }
//     if(e.altKey && keys['l']){
//       history.push('/license-details')
//     }
//     if(e.altKey && keys['p']){
//       history.push('/license-payment')
//     }
//     if(e.altKey && keys['c']){
//       history.push('/hosp-calllog')
//     }
//     if(e.altKey && keys['h']){
//       history.push('/payment-history')
//     }

//   }
//   window.onkeyup=(ev)=> {
    
//     keys[ev.key]= false
//   }

//   return()=>{
//     window.onkeydown=null
//     window.onkeyup=null
//   }
// },[]);
  
 useEffect(() => {
sdalist();
  }, [refresh]); 
  const sdalist =() =>
  {
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g,""); 

   
    axios
      .get(`${APIURL}/api/v1/service-provider/sda/`, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token " +v2},
      })
      .then((res) => {
        if (res.data.status === "success") {
          const datas = res.data.sdas;
          setLoading(false)
          setFreeCount(res.data.free_user_count)
          setActiveCount(res.data.active_free_users)
          setSdaList(datas);
        } else {
          setErrorMsg("error");
          setLoading(false)
        }
      })
      .catch((err) => {
        setErrorMsg("error");
        setLoading(false)
      });
  }

  const refreshHandle= ()=> {
    setRefresh(!refresh)
    sdalist();
  }

	return(
   
  <div className="drlist-page">


    <LoadingOverlay
    active={loading}
    spinner
    styles={{
      spinner: (base) => ({
        ...base,
        width: '50px',
        '& svg circle': {
          stroke: 'rgba(0, 0, 255, 0.5)'
          
        }
      }),
      overlay: {},
    }}
   
>

		<h2 className="title-of-page"> SDA List </h2>
    <div className="quick-links">
      <Link to="/add-sda"><button type="button" className="btn btn-primary btn-col dr-btn"> + Add SDA to List </button></Link>
       {/* <Link to={{pathname:'license-details', flag:"sda-page" }}><button type="button" style={{marginLeft:"10px"}} className="btn btn-info dr-btn"> View Licence Details</button></Link> */}

</div>

		{sdaList.length!== 0  ? (<div>
		<div className="drs-container">
		{sdaList.map((data,index)=>{
			return <Sda key={index} data={data} freeCount={freeCount} activeCount={activeCount} handle={refreshHandle}/>
		})}
         </div>
       
        <div className="quick-links">
		  <Link to="/add-sda"><button type="button" className="btn btn-primary btn-col dr-btn"> + Add SDA to List </button></Link>

       </div>

       </div>)
       :
       <h5 style={{color: "red" ,margin: "20px"}}>SDA List is empty </h5>
   }

    {errorMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data fetching.Please try again!
          </h5>
      :
      null}
		
    </LoadingOverlay></div>
		);
}

export default SdaList;