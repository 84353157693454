import React, {useState} from 'react'

function DatePopUp(props) {

const [check ,setCheck]= useState(false)
const [check1 ,setCheck1]= useState(false)
const [dateVal ,setDateVal]=useState('')
const [show ,setShow]= useState(true)
    
    const handleToday= ()=> {
        setShow(false)
        let date =new Date();
        date.setDate(date.getDate()-1)
        let offset = date.getTimezoneOffset();
        let updated = new Date(date.getTime() - (offset*60*1000))
        let DateExtracted = updated.toISOString().split('T')[0];
        //setNewStopDate(DateExtracted)
        let stopDate=DateExtracted
        props.date(stopDate)
      }
      
      const handleTomorrow= ()=> {
        setShow(false)
        const today =new Date()
        today.setDate(today.getDate()+1)
        let offset = today.getTimezoneOffset();
        let updated = new Date(today.getTime() - (offset*60*1000))
        let DateExtracted = updated.toISOString().split('T')[0];
        //setNewStopDate(DateExtracted)
        let stopDate=DateExtracted
        props.date(stopDate)
      }

      let date =new Date();
      let offset = date.getTimezoneOffset();
      let updated = new Date(date.getTime() - (offset*60*1000))
      let dateData = updated.toISOString().split('T')[0];
      
    return (
        <div className='modal-height'>
     <h4>Discontinue the medicine from : </h4>
      <label style={{marginRight:'20px'}} className="radio-inline"><input type="radio" checked={check1} onChange={()=> {setCheck1(!check1); setDateVal('')}} onClick={handleToday} name="optradio" />Today</label>
      <label className="radio-inline"><input type="radio" checked={check} onChange={()=> {setCheck(!check); setDateVal('')}} onClick={handleTomorrow} name="optradio" />Tomorrow</label>

      <div style={{marginTop:'20px'}} className="form-control dob item">

      <span>Custom Date :  </span>

      <input style={{cursor:'pointer'}} type='date' value={dateVal} id='start'  min={dateData}
        onChange={(e) => {
            e.preventDefault();
            setShow(false)
         setCheck(false)
         setCheck1(false)
            let dateUpdated= e.target.value 
        setDateVal(dateUpdated)
if(dateUpdated!==null){

let stopDate=dateUpdated
props.date(stopDate)
}

        }


        }
        />
        
        </div>
        <br/>
        {
          show ? <>
                        <span className='date-select'><i class="fas fa-info-circle"></i> Please select a Date</span>
                      </>
                    :
                    null
        }
        </div>
    )
}

export default DatePopUp
