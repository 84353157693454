import React, { useEffect, useState,useContext } from "react";
import axios from "axios";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { APIURL } from "../../Global";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Modal, Button } from "react-bootstrap";
import { Carousel } from 'react-bootstrap';
import AddToCartBest from './AddToCartBest';
import { Badge } from 'react-bootstrap';
import prod1 from "./assets/img/products-img1.jpg"
import masterlab from "./assets/img/labtest.png"
import procedure from "./assets/img/procedure.png"
import consumable from "./assets/img/consumable.png"
import medicine from "./assets/img/medicine.png"
import scan from "./assets/img/scan.png"
import misc from "./assets/img/misc.png"
import { useAlert } from "react-alert";
import { MdFavorite } from "react-icons/md";
import { handleFavorites } from "./handleFav";
import classes from "./favorite.module.css";
import { Fav } from "../patient/Context";
import { CartContext } from "./AddToCartContext";
import { FavDelete, FavFromQuickView } from "../patient/Context";
import AddToCartBestSell from "./AddtoCartBestSell"
import QuickView from "./QuickView";

function BestSelling({ setShowNavbarAndFooter, updateCartData }) {
  const [cartdata, setCartdata] = useState("");
  const { favAdded, setFavAdded } = useContext(Fav);
  const { favDeletedList, setFavDeletedList } = useContext(FavDelete);
  const { favFromQuickView, setFavFromQuickView } =
    useContext(FavFromQuickView);
  const [cartShow, setCartShow] = useState(false)
  const [favData, setFavData] = useState([]);
  const [favUpdated, setFavUpdated] = useState(false);
  const { handleAddToCart } = useContext(CartContext);
  const [featureList, setFeatureList] = useState([]);
  const [showCart, setShowCart] = useState(false)
  const [showWishList, setShowWishList] = useState(false)
  const [showDetails, setShowDetails] = useState(false);
  const [distance, setDistance] = useState(0.0);
  const alert = useAlert();
  const [quickViewDetails, setQuickViewDetails] = useState({})
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );

  useEffect(() => {
    if(login_datas){
    const userId = sessionStorage.getItem("userid");
    console.log("userid", userId);
    const localStorageKey = `myFavorite_${userId}`;
    try {
      const favoriteDatas = JSON.parse(localStorage.getItem(localStorageKey));
      //const parsedData = JSON.parse(favoriteDatas);
      setFavData(favoriteDatas);
    } catch (error) {
      let favoriteDatas = [];
    }

    setFavUpdated(false);
    setFavDeletedList(false);
    setFavFromQuickView(false);
  }
  }, [favUpdated, favDeletedList, favFromQuickView,login_datas]);
  useEffect(() => {

    // const tokenString = sessionStorage.getItem("usertoken");

    // let v2 = tokenString.replace(
    //   /["]+/g,
    //   ""
    // ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/patient/list-promotions/?promo_type=best_selling`,
      //  {
      //   headers: { Authorization: "Token " + v2 },
      // }
      )
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {

          const datas = res.data.items;
          console.log(datas);
          setFeatureList(datas);
          //console.log(featureList)
        } else {
          //setLoading(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  }, []);



  
const addToCart = (item) => {
  debugger;
  handleAddToCart(item);
  // Additional logic or actions
};

  const AddTohandleFavorites = (item) => {
    console.log("add handFav fun", item);
    handleFavorites(item);
    console.log("after handleFavorites fun call");
    setFavUpdated(true);
    setFavAdded(true);
  };



  // const handleAddToCart = (item) => {

  //   const today = new Date();
  //   const cartItem = {
  //     item: item,
  //     date: today.toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format
  //   };

  //   debugger;

  //   if (login_datas) {

  //     const tokenString = sessionStorage.getItem("usertoken");


  //     let v2 = tokenString.replace(
  //       /["]+/g,
  //       ""
  //     );
  //     axios.get(`${APIURL}/api/v1/patient/service-provider-detail/?hospital_id=${item.hospital_id}`,
  //      {
  //       headers: { Authorization: "Token " + v2 },
  //     }
  //     )
  //       .then((res) => {
  //         console.log(res.data);
  //         let hospital = res.data.hosp_details;
  //         if (hospital.latitude && hospital.longitude) {
  //           console.log('YES THE COORDINATES', login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
  //           calculateDistance(login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
  //         }

  //       })

  //     const dataTosend = {

  //       item_service: item.item_service,
  //       item_due_date: new Date().toISOString().split("T")[0],
  //       item_service_priority: "critical",
  //       geo_distance: distance,
  //       item_quantity:"1"
  //     };

  //     axios
  //       .post(`${APIURL}/api/v1/patient/cart/`, dataTosend, 
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Token " + v2,
  //         },
  //       }
  //       )
  //       .then((res) => {
  //         if (res.data.status === "success") {
  //           alert.success(res.data.message);
  //           updateCartData();
  //           //setTimeout(refreshPage, 1000);

  //         } else {
  //           alert.error(res.data.message);

  //         }
  //       })
  //       .catch((err) => {
  //         //setErrorMsg("Error in submission");
  //       });

  //   }


  //   else{
  //     debugger;

  //      // If the user is not logged in, store the cart item locally
  //   const existingCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
  //   const updatedCartItems = [...existingCartItems, cartItem];
  //   sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  //   alert.success("added to cart");

  //   }


  // }

  const calculateDistance = (latt1, latt2, long1, long2) => {

    var R = 6371; // Radius of the earth in km
    const lat1 = parseFloat(latt1)
    const lat2 = parseFloat(latt2)
    const lon1 = parseFloat(long1)
    const lon2 = parseFloat(long2)
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    // return d;
    setDistance(parseFloat(d).toFixed(4));
    console.log('THE DISTANCE', d)

  };
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
  }

  const HandleClose = () => {
    setCartShow(false)
  }

  const handleQuickView = (item) => {
    setQuickViewDetails({
      amount: item.amount,
      discount: item.discount,
      hospital: item.hospital_id,
      main_service: item.service_category === "master_labtest" ? "Laboratory" : item.service_category === "master_scan" ? "Scan" : item.service_category === "master_medicine" ? "Medicine" : item.service_category === "misc" ? "Miscellaneous" : item.service_category === "master_procedure" ? "Procedure" : item.service_category === "master_consumable" ? "Consumables" : "",
      name: item.hospital_name,
      service: item.item_name,
      service_category: item.service_category,
      image: item.image,
      id: item.item_service
    })

    setShowDetails(true);
  };
  const AddtoCartPopUp = (props) => {
    return (
      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
                    <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
                        <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
                    </button>
                </Modal.Header>
        <Modal.Body>
          <AddToCartBest

            datas={props.data}
            handle={HandleClose}
          // stage={displaystage}
          // onHide={handleDiseaseClose}
          // submitduration={submitDurationHandle}

          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={props.onHide}>
                  Close
                </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };

  const DetailPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
       <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ width: "96%" }}>
          <QuickView item={quickViewDetails} handleRefreshCart={HandleClose} from="wo" />
        </Modal.Body>
      </Modal>

    )
  }


  return (<>


    <div className="container">
      <div className="section-title">
        <h3>Best Selling Products</h3>
      </div>


      {featureList.length > 0 ?

        <div className="row">
          <div style={{ display: "contents", width: "200px" }} className="home-slides owl-carousel owl-theme" >
            <OwlCarousel items={4}
              className="owl-theme"
              loop
              nav
              dots={false}
              margin={0}

            >
              {featureList
                .filter(item => item.service_category === "master_consumable" || item.service_category === "master_medicine")
                .map((item) =>
  //                {  const isFavorite = favData.some(
  //                 (favItem) => item.id === favItem.id
  //               );
  // return
  (
                  <div className="single-products-box" style={{
                    width: "95%",
                    background: "white",
                    borderColor: "#e8e8e8",
                    border: "1px solid #ebfaeb",
                    borderRadius: "5px",
                    padding: "5px",
                  }}>
                    <div className="image" style={{ backgroundColor: "#f7f8fa" }} >
                      <div class="new">New</div>

                      <div className="sale">Sale</div>
                      <a href="#" className="d-block">  <Link
                        to="/search"
                        state={{ details: item.item_service }}
                      
                      > <img src={item.image} alt="image" style={{ height: "250px", width: "300px", backgroundColor: "#f7f8fa", marginLeft: "5%" }} onClick={() => ""} /></Link></a>

                      <div className="buttons-list">
                        <ul>
                          <li>
                            <div onClick={() => addToCart(item)} className="cart-btn">
                              <a data-toggle="modal" data-target="#productsQuickView">
                                <i class="fa fa-cart-plus" aria-hidden="true"></i>
                                <span className="tooltip-label">Add to Cart</span>
                              </a>
                            </div>
                          </li>
                          <li>
                            <div onClick={() => ""} className="quick-view-btn">
                            <Link
                        to="/search"
                        state={{ details: item.item_service }}
                       
                      >
                                <i  className='bx bx-search-alt'></i>
                                <span className="tooltip-label">Quick View</span>
                              </Link>
                            </div>
                          </li>
                          <li>
                            {/* {login_datas ? 
                              <div
                                onClick={() => AddTohandleFavorites(item)}
                                className="quick-view-btn"
                              >
                                <a
                                  data-toggle="modal"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: "2px",
                                  }}
                                >
                                  <MdFavorite
                                    className={
                                      isFavorite
                                        ? classes.favAdded
                                        : classes.favNotAdded
                                    }
                                  />
                                  <span className="tooltip-label">
                                    Add to Favorite
                                  </span>
                                </a>
                              </div>
                              :""} */}
                            </li>

                        </ul>
                      </div>
                    </div>

                    <div className="content">
                      <h3 style={{ fontFamily: "Arial, monospace" }}><b>{item.item_name}</b></h3>
                      <div className="price">
                        <span className="old-price"> <i class="fa fa-usd" aria-hidden="true"></i>{item.amount}</span>
                        <span>&nbsp;</span>  <span className="discounted-price">{(item.amount * (100 - item.discount) / 100).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>)

  )}
            </OwlCarousel>
          </div>
        </div>
        : ""}
    </div>

    {showDetails ? (
      <DetailPopup
        show={showDetails}
        onHide={() => {
          setShowDetails(false);

        }}
      />
    ) : (
      ""
    )}

    {cartShow ? (
      <AddtoCartPopUp
        show={cartShow}
        data={cartdata}
        onHide={() => {
          setCartShow(false);

        }}
      />
    ) : (
      ""
    )}




  </>)


}


export default BestSelling