import React from 'react'

function ViewRemark(props) {
    return (
        <div className='flex-col'>        

                <textarea 
                type="text" 
                className="form-group text-area remark-add"
                value= {props.data}
                name="remarks" 
            
            autoComplete="off"
                />
        </div>
    )
}

export default ViewRemark
