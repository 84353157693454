import React, {useState} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function AddSugar(props) {

    const [nonFasting ,setNonFasting]=useState("");
    const [fasting ,setFasting]=useState("");
    const [random ,setRandom]=useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [modalShow, setModalShow]=useState(false)
    const [errorShow, setErrorShow]= useState(false)
    const [limitShow , setLimitShow]= useState(false)



    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateTime = toISOString(Date().toLocaleString());
  function toISOString(s) {
    var months = {jan:'01',feb:'02',mar:'03',apr:'04',may:'05',jun:'06',
                  jul:'07',aug:'08',sep:'09',oct:'10',nov:'11',dec:'12'};
    var b = s.split(' ');
  
    return b[3] + '-' +
           months[b[1].toLowerCase()] + '-' +
           ('0' + b[2]).slice(-2) + 'T' +
           b[4] + b[5].substr(3);
  }

    const handleNonFasting = (e)=> {
        const val = e.target.value
        if(val.length < 6){
            setNonFasting(e.target.value)
        }
        else return false
    }

    const handleFasting = (e)=> {
        const val = e.target.value
        if(val.length < 6){
            setFasting(e.target.value)
        }
        else return false
    }
     const handleRandom = (e)=> {
        const val = e.target.value
        if(val.length < 6){
            setRandom(e.target.value)
        }
        else return false
    }
     

   const handleSubmit = () => {

    

    if((nonFasting!== "" && fasting !== "" && random !== "") || (nonFasting !== "" && fasting !== "") || (fasting !== "" && random !== "") || (nonFasting !=="" && random!== ""))
      {
        setErrorShow(true)
      }
      else {
        if((nonFasting > 19 && nonFasting < 901) || (fasting > 19 && fasting < 901) || (random > 19 && random < 901)) {

            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
            'result':"",
            'date_time':currentDateTime,
           'vital_name': ""
           }
           if(nonFasting!=""){
            dataToUpdate.result=nonFasting;
            dataToUpdate.vital_name="glucose";
             
           }
           if(fasting!=""){
            dataToUpdate.result=fasting;
            dataToUpdate.vital_name="glucose_fasting";
             
          }
          if(random!=""){
            dataToUpdate.result=random;
            dataToUpdate.vital_name="glucose_random";
          }


                   
    //    const dataToUpdate = 
    //    {
    //     'result':nonFasting,
    //     'glucose_fasting':fasting,
    //     'glucose_random': random,
       
    //  'vital_name': "glucose"
    //    }
     
     
       
         axios.post(`${APIURL}/api/v1/patient/vitals/?patient_id=${id}&timeZone=${timezoneOffset}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
                setModalShow(true)
         
          
       }
       else{
            setErrorShow(true)
        
       }
     
         })
         .catch(err=>{
       
            setErrorShow(true)
     })

        }

        else {
            setLimitShow(true)
        }


        
    }
  }

 
    const SubmitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-success'>Blood Sugar added Successfully !!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const ErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}>{
                errorMessage ? errorMessage : 'Please Enter any one Sugar level'}
              </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const LimitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}>Sugar value must be between 20 mg/dL and 900 mg/dL</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

    return (
        <div className='flex-col'><br/>
          <h5>Select Any One:</h5>
           <input class style={{width:'50%'}} type="number" min='0' max='1000' placeholder="Non Fasting Blood Sugar" value={nonFasting} onChange={handleNonFasting} className="form-control"/><br/>
            <h6><b>OR</b></h6>
           <input style={{width:'50%'}} type="number" min='0' max='1000' placeholder="Fasting Blood Sugar" value={fasting} onChange={handleFasting} className="form-control"/><br/>
           <h6><b>OR</b></h6>
           <input style={{width:'50%'}} type="number" min='0' max='1000' placeholder="Random Blood Sugar" value={random} onChange={handleRandom} className="form-control"/><br/>
           
           <button disabled={ !nonFasting && !fasting && !random } onClick={handleSubmit} className='btn btn-primary btn-lg btn-col'>Add</button> 


           {
      modalShow ? 
      <SubmitPopup
        show={modalShow}
        onHide= {() => { setModalShow(false); props.submitsugar() ; setNonFasting(""); setFasting(""); setRandom("")
        
        }}
      /> : null
    }

{
      errorShow ? 
      <ErrorPopup
        show={errorShow}
        onHide= {() => { 
          
          setErrorShow(false);
          setErrorMessage(''); 
        }}
      /> : null
    }

    
{
      limitShow ? 
      <LimitPopup
        show={limitShow}
        onHide= {() => { setLimitShow(false)     
        }}
      /> : null
    }


        </div>
    )
}

export default AddSugar;
