import React, { useState, useEffect } from "react";
import "./addcart.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import LoadingOverlay from 'react-loading-overlay';
import CartViewPage from "./CartViewPage";
function AddCart({ data2, patient_ID, patient_name, patient, render, shipID,dis1 }) {
;
  const amount1 = data2.amount;
  const dataset = data2.service_name;
  const Service_id = data2.id;
  const servicetype = data2.service_type;
  const PatientId = patient_ID;
  const PatName = patient_name;
  // const [quantityCharge,setQuantityCharge]=useState([data2.shipping_charge_id[0].charge]);
  // const [distanceCharge,setDistanceChrge]=useState([data2.shipping_charge_id[1].charge]);
  let shipid1 = 0
  let shipid2 = 0
  const details = patient;
  const [successShow, setSuccessShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [thrdistance, setThrdistane] = useState("");
  const [thrquantity, setThrquantity] = useState("");
  const [charge, setCharge] = useState(0);
  const [charge3, setCharge3] = useState(0);
  const [comparisonDist, setComparisonDist] = useState("")
  const [comparisonQty, setComparisonQty] = useState("")
  // const[thrdistance1,setThrdistane1]=useState("");
  // const[thrquantity1,setThrquantity1]=useState("");
  const [charge1, setCharge1] = useState(0);
  const [cartShow, setCartShow] = useState(false);
  const [curDate, setCurDate] = useState(new Date());
  const [dateval, setDateVal] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const viewcartpage = `/cartview`;
  let total=0
  const [carddata, setCartData] = useState({

    DueDate: "",
    quantity: "",

  });

  const [distance, setDistance] = useState(0.0);

  const calculateDistance = (latt1, latt2, long1, long2) => {
    ;
    var R = 6371; // Radius of the earth in km
    const lat1 = parseFloat(latt1)
    const lat2 = parseFloat(latt2)
    const lon1 = parseFloat(long1)
    const lon2 = parseFloat(long2)
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    // return d;
    setDistance(parseFloat(d).toFixed(4));
    console.log('THE DISTANCE', d)

  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
  }
  if (shipID.length === 1) {
    if (shipID[0].type === "distance") {
      shipid1 = shipID[0].id
    }
    else {
      shipid2 = shipID[0].id
    }
  } else if (shipID.length === 2) {
    if (shipID[0].type === "distance") {
      shipid1 = shipID[0].id
      shipid2 = shipID[1].id
    }
    else {
      shipid1 = shipID[1].id
      shipid2 = shipID[0].id
    }
  }




  const claculateCharge = () => {
   
    if (comparisonQty === "less") {
      if (thrquantity >= quantity) {
        total=total+charge1
        // setCharge3(charge3 + charge1)
      }
    } else {
      if (thrquantity <= quantity) {
        total=total+charge1
        // setCharge3(charge3 + charge1)
      }
    }
    if (comparisonDist === "less") {
      if (thrdistance >= distance) {
        total=total+charge
        // setCharge3(charge3 + charge)
      }
    } else {
      if (thrdistance <= distance) {
        total=total+charge
        // setCharge3(charge3 + charge)
      }
      setCharge3(total)
    }

    console.log("totl ship" + charge3)

  }


  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );

    axios.get(`${APIURL}/api/v1/service-provider/shipping-charge-detail/${shipid1}`, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        console.log("shipcharge details", res.data);
        if (res.data.threshold_distance !== 0.0) {
          setThrdistane(res.data.threshold_distance);
        }
        setComparisonDist(res.data.threshold_comparison)
        setCharge(res.data.charge);
        console.log("distance", "quantity", "charge", thrdistance, thrquantity, charge)
      })
      .catch((err) => {

      })
    axios.get(`${APIURL}/api/v1/service-provider/shipping-charge-detail/${shipid2}`, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        console.log("shipcharge details", res.data);
        if (res.data.threshold_quantity !== 0) {
          setThrquantity(res.data.threshold_quantity);
        }
        setComparisonQty(res.data.threshold_comparison)
        setCharge1(res.data.charge);

      })
      .catch((err) => {

      })

  }, [])

  useEffect(() => {
    if (patient && patient.latitude && patient.longitude) {
      console.log('THE CART PATIENT WITH COORDS', data2.hospital);
      const tokenString = sessionStorage.getItem("usertoken");


      let v2 = tokenString.replace(
        /["]+/g,
        ""
      );
      axios.get(`${APIURL}/api/v1/patient/service-provider-detail/?hospital_id=${data2.hospital}`, {
        headers: { Authorization: "Token " + v2 },
      })
        .then((res) => {
          console.log(res.data);
          let hospital = res.data.hosp_details;
          if (hospital.latitude && hospital.longitude) {
            console.log('YES THE COORDINATES', patient.latitude, hospital.latitude, patient.longitude, hospital.longitude);
            calculateDistance(patient.latitude, hospital.latitude, patient.longitude, hospital.longitude);
          }

        })
        .catch((err) => {

        })
    }
  })







  const SubmitPopUp1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success">Added to Cart Successfully</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const CartPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <CartViewPage sCharge={total} patient_id={PatientId} name={PatName} />

        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleChange = (e) => {
    setCharge3(0)
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === "quantity") {
      setQuantity(val);
    }
    if (val > thrquantity) {
      setFlag(!flag)
    }

  }
  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {errorMsg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const refreshPage = () => {
    window.location.reload(false);
  }

  const handledate = (date) => {

    setDueDate(date);

    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      //let datefinal = moment(DateExtracted).format('YYYY-MM-DD')  
      /*let dateString= DateExtracted.toString(); */
      // setFormattedDob(DateExtracted);

      setCartData((currentstate) => ({
        ...currentstate,
        DueDate: DateExtracted,
      }));
    }
  };
  const addCart = () => {

    if (data2.service_type === "consumables" && quantity === '') {
      setErrorMsg("Please Enter Quantity!!!");
      setErrorSubmit(true);
    } else if (data2.service_type === "consumables" && quantity <= 0) {
      setErrorMsg("Please Enter valid Quantity!!!");
      setErrorSubmit(true);
    } else if (!carddata.DueDate) {
      setErrorMsg("Please Select Delivery Date!!!");
      setErrorSubmit(true);

    } else {


      const dataTosend = {

        item_service: data2,
        item_due_date: carddata.DueDate,
        item_quantity: quantity,
        sCharge:total

      };
      if (distance) {
        dataTosend.geo_distance = distance;
      }
      console.log('THE ADD CART DATA SEND', dataTosend);
      render(dataTosend);
     
      // refreshPage();
    }

    // axios.post(`${APIURL}/api/v1/staff/add-to-cart/?patient_id=${PatientId}`, dataTosend, {
    //   headers: { Authorization: "Token " + v2 },
    // })
    //   .then((res) => {

    //     if (res.status === 200) {

    //       setSuccessShow(true);


    //       setLoading(false)
    //     }
    //     else {
    //       setLoading(false)
    //     }
    //   })
    //   .catch((err) => {
    //   setLoading(false)
    // })

  }

  // const handleViewCart = () => {
  //   setCartShow(true);
  // }


  const handleChangeRadio = (event) => {


    if (event.target.value == 1) {
      setDateVal('');
      let dateUpdated1 = curDate;
      if (curDate !== null) {
        const offset = curDate.getTimezoneOffset();

        dateUpdated1 = new Date(curDate.getTime() - offset * 60 * 1000);

        let DateExtracted1 = dateUpdated1.toISOString().split("T")[0];
        setCartData((currentstate) => ({
          ...currentstate,
          DueDate: DateExtracted1,
        }));
      }
    }
    if (event.target.value == 2) {

      setDateVal(event.target.value);

    }


  }




  return (
    <>
      <div className="doctor-card15">
        <div className="card-header-color">
          <h2 className="card-header-text">SERVICE CART</h2>
        </div>
        <div className="doct-card">
          <br />
          <br />
          <h6 className="card-row">
            <span className="content-text"> PATIENT NAME</span>
            <span className="content-text"> {PatName.toUpperCase()}</span>
          </h6>
          <br />
          <h6 className="card-row">
            <span className="content-text"> SERVICE NAME</span>
            <span className="content-text"> {dataset.toUpperCase()}</span>
          </h6>

          <br />
          <h6 className="card-row">
            <span className="content-text"> AMOUNT</span>
            <span className="content-text">  <i class="fa fa-inr" aria-hidden="true"></i> {amount1}</span>
          </h6>
          <br />
     
     

          {servicetype === "consumables" ?

            <h6 className="card-row">
              <span className="content-text"> QUANTITY</span>
              <div className="input-layout">
                <input
                  type="number"
                  className="input-item"
                  name="quantity"
                  min="0"
                  value={quantity}
                  onChange={handleChange}
                  required></input>
              </div>
            </h6> : null}
          <br />


          <h6 className="card-row">
            <span className="content-text"> DELIVERY</span>
            <div className="input-layout">


              <label class="delivery-container">
                <span className="delivery-content">
                  <input type="radio" name="status" onChange={handleChangeRadio} value="1" />
                  <span class="checkmark"></span>
                </span>
                <span style={{ color: 'black' }}> <h6>IMMEDIATE DELIVERY</h6></span>
              </label>
            </div>
          </h6>
          <h6 className="card-row">
            <span className="content-text"> </span>
            <div className="input-layout">


              <label class="delivery-container">
                <span className="delivery-content">
                  <input type="radio" name="status" onChange={handleChangeRadio} value="2" />
                  <span class="checkmark"></span>
                </span>
                {dateval ? <>

                  <DatePicker
                    onChange={date => { handledate(date) }}

                    minDate={new Date()}
                    value={dueDate}

                  /> </> :
                  <span style={{ color: 'black' }}><h6>SET DELIVERY DATE</h6></span>}

              </label>
            </div>
          </h6>


          <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
            <button className="btn btn-col" onClick={() => { claculateCharge(); addCart() }}>Add Service to Cart</button>
          </div>










        </div>


        {successShow ? (
          <SubmitPopUp1
            show={successShow}
            onHide={() => {
              setSuccessShow(false);
              
               //render();
              setCartShow(true);
              

            }}
          />
        ) : null}

        {cartShow ? (
          <CartPopUp
            show={cartShow}
            onHide={() => {
              setCartShow(false);

            }}
          />
        ) : null}

        {errorMsg !== "" ? (
          <SubmitErrorPopup
            show={errorSubmit}
            onHide={() => setErrorSubmit(false)}
          />
        ) : (
          ""
        )}


      </div>

    </>
  );
}

export default AddCart;
