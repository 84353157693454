import React, { useState, useEffect,useRef } from "react";
import "./cart.css";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import PatientNav from "./PatientNav";
import loginpage from "./assets/img/loginpage.png"
import horizonlogo from "./assets/img/horizonlogo.png"
import { APIURL } from "../../Global";

import { Modal,Form,ToggleButtonGroup,ToggleButton } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import LoadingOverlay from "react-loading-overlay";
import { Badge } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from "react-alert";
function OTPpageuser ({setShowNavbarAndFooter}){
debugger;
    const [selectedValue, setSelectedValue] = useState("email");
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    let navigate = useNavigate();
    const alert = useAlert();
    const location = useLocation();
    const phonedata = location.state.phone ? location.state.phone :"" ;
    const username = location.state.user;
    const password = location.state.password;
    const email = location.state.email ? location.state.email:"";
    const [otpDigits, setOtpDigits] = useState(['', '', '', '']);
    const [isAllDigitsEntered, setIsAllDigitsEntered] = useState(false);
    const [remainingTime, setRemainingTime] = useState(60); // Set the desired time limit in seconds
    const [timerId, setTimerId] = useState(null);
  
    const handleToggle = (value) => {
        if (selectedValue === value) {
          // Deselect the button if it is clicked again
          setSelectedValue(null);
        } else {
          // Select the button
          setSelectedValue(value);
        }
      };
      useEffect(() => { setShowNavbarAndFooter(false); return () => setShowNavbarAndFooter(true); }, [setShowNavbarAndFooter]);
     
     
      useEffect(() => {
        if (remainingTime > 0) {
          // Start the timer if time is remaining
          const id = setTimeout(() => {
            setRemainingTime((prevTime) => prevTime - 1);
          }, 1000); // Decrease the time by 1 second every second
    
          setTimerId(id);
        } else {
          // Time is up, perform necessary actions (e.g., show an error message, disable the form)
          // ...
        }
    
        return () => {
          // Clear the timer when the component is unmounted or when remainingTime reaches 0
          clearTimeout(timerId);
        };
      }, [remainingTime]);
    
      const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
      };
      const handleResend =(e) =>{
e.preventDefault();
     
 
        let data = {
            code:"91",
           
      
            mobile_number:phonedata
            
        };
    
      axios
      .post(
          `${APIURL}/api/v1/account/resend-otp/`,
    
          data,
          {
              headers: {
                  "Content-Type": "application/json",
              },
          }
      )
      .then((res) => {
          if (res.data.status === "success") {
            setRemainingTime(60)
    
            alert.success("OTP sent successfully to given mobile number")
            
           
          
    
          } else {
    
    
          }
      })
      .catch((err) => { });
   
      }
    
      // Function to handle OTP digit input
      const handleOtpDigitChange = (index, value) => {
        const newOtpDigits = [...otpDigits];
        newOtpDigits[index] = value;
        setOtpDigits(newOtpDigits);
    
        // Check if all digits have been entered
        const isAllDigitsEntered = newOtpDigits.every((digit) => digit !== '');
        setIsAllDigitsEntered(isAllDigitsEntered);
    
        // Move focus to the next input field
        if (value !== '' && index < inputRefs.length - 1) {
          inputRefs[index + 1].current.focus();
        }
      };

    // Function to handle form submission
    const handleSubmit = async (e) => {
      debugger;
      e.preventDefault();

      if (otpDigits.every((digit) => digit !== "")){
   
      clearTimeout(timerId);
      // Join the OTP digits into a single string
      let otp = otpDigits.join('');

      let data;
      if (phonedata) {
        data = {
          code: "91",
          mobile_number: phonedata,
          otp: otp
        };
      } else {
        data = {
          email: email,
          otp: otp
        };
      }

axios
  .post(
      `${APIURL}/api/v1/account/verify-otp/`,
      data,
      {
          headers: {
              "Content-Type": "application/json",
          },
      }
  )
  .then((res) => {
      if (res.data.status === "success") {
         alert.success("OTP verified Successfully")


         if (phonedata) {
          navigate("/createaccountuser", {
            state: { phone: phonedata, otp, user: username, password }
          });
        } else {
          navigate("/createaccountuser", {
            state: { email:email, otp, user: username, password }
          });
        }
      }
      else {

          alert.error(res.data.message)
      }

  }) 
  }


    }
  
  
    return(

        <>
      <section
        className="products-area ptb-70"
        style={{ marginTop: "2%"  }}
      >
     <div className="container" style={{borderRadius:"2%"}}>
            <div class="row" style={{borderRadius:"2%"}}>
              <div class="col-lg-8 col-md-6" >
              <div className="vector">
              <div className="leftpage" style={{ backgroundImage: `url(${loginpage})` ,backgroundSize: "cover" }}>
              
                </div>
          
    </div>

    
</div>
<div className="col-lg-1 col-md-1">
<div className="vertical-line"></div>
    </div>

<div class="col-lg-3 col-md-5"> 

<div className="righttop" style={{ backgroundImage: `url(${horizonlogo})`,backgroundSize: "cover" }} >
    </div>
    <h5 className="logintitle">Register your Account</h5>

   <h6 className="otphead">OTP Verification</h6>

   <h6 style={{fontFamily: 'Titillium Web',fontSize:"12px",color: "#606268",marginLeft:"10%"}}>Enter the code we send to {phonedata ? phonedata :email } </h6>
   
<br/>
   <h6 style={{color:"#6BCBB8",marginLeft:"40%",fontSize:"16px",fontFamily: 'Titillium Web'}}>{formatTime(remainingTime)}</h6>
   <form  onSubmit={handleSubmit}>
   <div className="input_field_box">
          <input
           ref={inputRefs[0]}
            type="number"
            value={otpDigits[0]}
            onChange={(e) => handleOtpDigitChange(0, e.target.value)}
          />
          <input
           ref={inputRefs[1]}
            type="number"
            value={otpDigits[1]}
            onChange={(e) => handleOtpDigitChange(1, e.target.value)}
          />
          <input
           ref={inputRefs[2]}
            type="number"
            value={otpDigits[2]}
            onChange={(e) => handleOtpDigitChange(2, e.target.value)}
          />
          <input
           ref={inputRefs[3]}
            type="number"
            value={otpDigits[3]}
            onChange={(e) => handleOtpDigitChange(3, e.target.value)}
          />
        </div>
        <h6 style={{fontFamily: 'Titillium Web',
fontStyle: "normal",
fontWeight: "400",
fontSize: "14px",marginTop:"10%",marginLeft:"10%"}}>If you didn't receive a code {""}  {remainingTime === 0 ? (
  <a href="#" onClick={handleResend}>Resend</a>
) : (
  <span>RESEND</span>
)}</h6>
        <button

  className="loginbutton"
 type="submit"
        disabled={!otpDigits.every(digit => digit !== "") || remainingTime === 0}
  style={{ color: "white", fontFamily: "Poppins", marginTop: "20%",cursor:"pointer" }}
>
  Submit
</button>

      </form>
   
   
   
  

   
   <br/>
  
<div className="or-text" style={{marginLeft:"-2%",marginTop:"5%",color:"#C2C2C2"}}>----------------------------- OR ----------------------------</div>

<button className="registerpat"  style={{color:"#1E2772",fontFamily:"Poppins"}} onClick={() => navigate("/loginpatient")}>Login Now</button>

    </div>


</div>
</div>

</section>


        </>
    )

}
export default OTPpageuser