import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function AddComo(props) {

    const[data, setData]= useState({
      
      comorbidityname : '',
      comorbiditytype  :'',
      comorbidityindex : '',
      comorbiditystage  : ''

    })

    const [error,setError]=useState({
      comorbidityname : ''
        
       });

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const[completeShow,setCompleteShow]=useState(false)
    const[errorValidate,setErrorValidate]=useState(false)



      const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>check data duplication or not</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
      const CompletePopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'> Successfully Saved</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
             <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
             
             
            </Modal.Footer>
          </Modal>
        );
      }
    
      
      const handleTitle = (e)=> {
        
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
          
            ...current , [key] : val
        }))
      }


      const handleSave=() =>
{
  setSuccessShow(true);
 
}
  
  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["comorbidityname"] || input["comorbidityname"]==="" ) {
      isValid = false;
      errors["comorbidityname"] = "Please enter Comorbidity Name";
    }
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();
 ;
 console.log(validate())
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
    comorbidity_name: data.comorbidityname.slice(0,1).toUpperCase() +data.comorbidityname.slice(1, data.comorbidityname.length),
    comorbidity_type:data.comorbiditytype,
    comorbidity_index:data.comorbidityindex,
    comorbidity_stage:data.comorbiditystage,
       
        

  }
 
     axios
       .post(`${APIURL}/api/v1/staff/master-comorbidities/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
           
       
            setCompleteShow(true);
         setData({
          comorbidityname : '',
          comorbiditytype : '',
          comorbidityindex : '',
          comorbiditystage : ''

      });
        
         } else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }

    else {
      setErrorValidate(true)
   }

}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Add comorbidities</h4>
        <div style={{maxHeight:'1000px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='col-4'>*Comorbidity Name: </label>
                    <input type='text' className='form-control col-8' name='comorbidityname' value={data.comorbidityname} onChange={handleTitle} /></div>
                    {error.comorbidityname ? <div style={{marginLeft:"15%"}} className="error-validation-msg">{error.comorbidityname}</div> : null}
                </div>

                <div className="formField" >
                <div style={{display:"flex"}}>
                    <label className='col-4 '>Comorbidity Type: </label>
                    <input type='text' className='form-control col-8' name='comorbiditytype' value={data.comorbiditytype} onChange={handleTitle} /></div>
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" >
                <div style={{display:"flex"}}>
                    <label className='col-4'>Comorbidity Index: </label>
                    <input type='text' className='form-control col-8' name='comorbidityindex' value={data.comorbidityindex} onChange={handleTitle} /></div>
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" >
                <div style={{display:"flex"}}>
                    <label className='col-4 '>Comorbidity Stage : </label>
                    <input type='text' className='form-control col-8' name='comorbiditystage' value={data.comorbiditystage} onChange={handleTitle} /></div>
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
            </div>
           <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
         

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}


{completeShow ? 

<CompletePopUp
show={completeShow}
onHide={() =>  {setCompleteShow(false)
props.modalClose()}}
/> 
: null}
            
        </div>
        </>
    )
}

export default AddComo
