import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function AddDiscease(props) {

    const[data, setData]= useState({
      allergyname:'',
      allergytype:'',
      allergystage:'',
      description:''
     
        

    })

    const [error,setError]=useState({
      allergyname:''
        
       });

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const[completeShow,setCompleteShow]=useState(false)
    const[errorValidate,setErrorValidate]=useState(false)



const CompletePopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-success'> Successfully Saved</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
       <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
       
       
      </Modal.Footer>
    </Modal>
  );
}

   

      const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Check data whether duplicated or not</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
      
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


    
  
  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["allergyname"] || input["allergyname"]==="" ) {
      isValid = false;
      errors["allergyname"] = "Please enter allergy Name";
    }
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();
 ;
 console.log(validate())
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
    allergy_name: data.allergyname.slice(0,1).toUpperCase() +data.allergyname.slice(1, data.allergyname.length),                            
    allergy_type : data.allergytype,
    allergy_stage: data.allergystage,                     
    description: data.description
        

  }
 
     axios
       .post(`${APIURL}/api/v1/staff/master-allergies/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          setCompleteShow(true);
          setData({
            allergyname : '',
            allergytype : '',
            allergystage : '',
            description : ''

         });}
          else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }

    else {
      setErrorValidate(true)
   }

}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Add Allergy</h4>
        <div style={{maxHeight:'1000px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
              <div  className="formField">
                <div style={{display:"flex"}}>
                    <label className='align-rental'>*Allergy Name: </label>
                    <input type='text' className='form-control title-flex' name='allergyname' value={data.allergyname} onChange={handleTitle} /></div>
                    {error.allergyname ? <div className="error-validation-msg error-feature-inventory"style={{float:"right"}}>{error.allergyname}</div> : null}
                </div>

                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Allergy Type: </label>
                    <input type='text' className='form-control title-flex' name='allergytype' value={data.allergytype} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Allergy Stage: </label>
                    <input type='text' className='form-control title-flex' name='allergystage' value={data.allergystage} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Description: </label>
                    <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                
            </div>
         
            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
   

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}

{completeShow ? 

<CompletePopUp
show={completeShow}
onHide={() =>  {setCompleteShow(false)
  props.modalClose();}}
/> 
: null}
            
        </div>
        </>
    )
}

export default AddDiscease
