import React, { useState } from 'react'

function ErrorNotification(){


    return(

        <div>
    <div className="hospital-dashboard-container"> 
    

       {/*<HospitalJumbotron /> */}
         <div style={{backgroundColor:"white" }} className="jumbotron jumbotron-fluid jumbo-div padding">
  <div className=" jumbo-content">

  

  
  </div>
</div>

   <div className="verify-msg padding" > 
     <h3 className="text-muted">  Your service provider adding limit is over!!!! </h3><br/>

     <h3 className="text-muted"> If you want to add more</h3>, <h3 className="text-muted"> Please Contact 
   <a href="mailto:felixacare@IntPurple.com" target="_blank" rel="noopener noreferrer">, felixacare.com </a>
     </h3> <br />    <br />

   {/* <h3 className="text-muted"> Please Contact 
   <a href="mailto:felixacare@IntPurple.com" target="_blank" rel="noopener noreferrer">felixacare.com </a>
     </h3> <br /> */}
          <br />
           <h3 className="text-muted">
         Thank you for understanding!   </h3>
          <br />

         

   </div>
</div>

  </div>
    )
}
export default ErrorNotification