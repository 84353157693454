import React, { useContext, useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  Navigate,
  NavLink,
} from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import "font-awesome/css/font-awesome.min.css";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import "./hospital.css";
//import Navbar from "../common/Navbar"
//mport Footer from "../common/Footer";
import prof from "../../assets/images/icons/doctor1.png";
import request from "../../assets/images/icons/request1.png";
import bdo from "../../assets/images/icons/bdo.png";
import sda from "../../assets/images/icons/sda1.png";
import pharmacy from "../../assets/images/icons/pharmacy.png";
import lab from "../../assets/images/icons/lab.png";
import nurse from "../../assets/images/icons/nurse1.png";
import allied from "../../assets/images/icons/allied.png";

function HomePage() {
  const [loginDatas] = useContext(LoginContext);
  const projType=sessionStorage.getItem("project_type")
  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <div>
      {/* <Navbar /> */}
      <div className="home">
        <h2 className="title-of-page"> {login_datas.name} </h2>
        <h5 className="subtitle-of-page" style={{marginBottom:"0%"}}>{login_datas.location}</h5>
 
        <div className="home-container" style={{marginTop:"1%"}}>
        {login_datas.health_care_type==="Hospital" ? <>
          <div className="home-tiles" onClick={() => navigate("/adddoctor")}>
            <img src={prof} className="home-icon" width="60px" />
            <h5 className="each-title"> Add Doctor</h5>
          </div>

          <div className="home-tiles" onClick={() => navigate("/doctorslist")}>
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Doctors List</h5>
          </div>
 </>:null}
        {projType==="home_care"? <> <div className="home-tiles" onClick={() => navigate("/add-bdo")}>
            <img src={bdo} className="home-icon" width="65px" />
            <h5 className="each-title">Add BDO</h5>
          </div>

          <div className="home-tiles" onClick={() => navigate("/bdo-list")}>
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">BDO List</h5>
          </div>
          <div className="home-tiles" onClick={() => navigate("/add-sda")}>
            <img src={sda} className="home-icon" width="65px" />
            <h5 className="each-title">Add SDA</h5>
          </div>

          <div className="home-tiles" onClick={() => navigate("/sda-list")}>
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">SDA List</h5>
          </div></>:""}
          {projType==="home_care"?<> {login_datas.health_care_type==="Pharmacy" || login_datas.health_care_type==="Hospital" ? <>
          <div
            className="home-tiles"
            onClick={() => navigate("/add-Pharm-user")}
          >
            <img src={pharmacy} className="home-icon" width="65px" />
            <h5 className="each-title">Add Pharmacy User</h5>
          </div>
          <div
            className="home-tiles"
            onClick={() => navigate("/pharmacyusers-list")}
          >
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Pharmacy Users List</h5>
          </div>
</>:null}

{login_datas.health_care_type==="Laboratory" || login_datas.health_care_type==="Hospital" ? <>
          <div className="home-tiles" onClick={() => navigate("/add-labuser")}>
            <img src={lab} className="home-icon" width="65px" />
            <h5 className="each-title">Add Lab User</h5>
          </div>
          <div
            className="home-tiles"
            onClick={() => navigate("/labusers-list")}
          >
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Lab Users List</h5>
          </div>
          </>:null}</>:""}

          {login_datas.health_care_type==="Hospital" ? <>
          {projType==="home_care"?<><div
            className="home-tiles"
            onClick={() => navigate("/add-professional")}
          >
            <img src={allied} className="home-icon" width="65px" />
            <h5 className="each-title">Add Allied Professional</h5>
          </div>
          <div
            className="home-tiles"
            onClick={() => navigate("/allied-professional-list")}
          >
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Allied Professionals List</h5>
          </div></>:""}
          <div className="home-tiles" onClick={() => navigate("/add-nurse")}>
            <img src={nurse} className="home-icon" width="65px" />
            <h5 className="each-title">Add Nurse</h5>
          </div>
          <div className="home-tiles" onClick={() => navigate("/nurse-list")}>
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Nurses List</h5>
          </div>
         </> :null}
        </div>

        {/* <div
                    // onClick={() => history.push("/doctors-list")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fa fa-address-card task-icon"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>
                    <h5 className="each-title">Add Doctor</h5> */}
        {/* </div> */}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default HomePage;
