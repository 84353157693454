
import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, Navigate, NavLink } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import 'font-awesome/css/font-awesome.min.css';
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import "./Patientdashboard.css";
import request from "../../assets/images/icons/request1.png";  
import procedure from "../../assets/images/icons/write.png";  
import patient from "../../assets/images/icons/patient.png";  


function DoctorDashboard() {
  const [loginDatas] = useContext(LoginContext);


  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <>
 
    <div >

      <div className="home">
        {/* <h1>Doctor Dashboard</h1> */}
        <h2 className="title-of-page"> {login_datas.name} </h2>
        <div className="home-container">

          <div className="home-tiles" 
           onClick={() => navigate("/followup")}
          >
            <img src={procedure} className="home-icon" width="65px" />
            <h5 className="each-title">Follow ups</h5> 

          </div> 
          <div className="home-tiles" 
           onClick={() => navigate("/webordering")}
          >
            <img src={request} className="home-icon" width="65px" />
            <h5 className="each-title">Ordering</h5> 

          </div> 
          </div>
          
          
        </div>

      </div>



    </>


  );
}

export default DoctorDashboard;