import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import AddMedicinesPat from './AddMedicinesPat';
function AddMedicinePtient(patientID,{render}) {
  ;
  const [medName, setMedname] = useState("");
  const [strength1, setStrength] = useState("");
  const [strengthunit, setStrengthUnit] = useState("");
  const [dosgetype, setDosageForm] = useState("");
  const [routinee, setRoutie] = useState("");
  const [addmedPopUp, setAddmedPopUp] = useState(false);
  
    const[data, setData]= useState({
//       med_name :
// generic_name :
// strength :
// med_type :                                  
//dosage_form :
// med_code :                      
// route :
// description: 
// manufacturer_name:  

medicinename :'',

strength:'',
medicinetype:'',
dosage_type:{ value:'', label:'' },

routine: { value:'', label:'' },

strength_unit:{ value:'', label:'' },


        

    })

    const [error,setError]=useState({
      medicinename :'',

      strength:'',
 
      dosage_type:'',
    
      routine:'',
      strength_unit:''
      
        
       });

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const[completeShow,setCompleteShow]=useState(false)
    const[errorValidate,setErrorValidate]=useState(false)
    const DosageOptions = [{ value:'tablets', label:'TABLETS' },                       
    { value:'capsules', label:'CAPSULES' },
    { value:'chewable tablets', label:'CHEWABLE TABLETS' },
    { value:'powders', label:'POWDERS' },
    { value:'solutions', label:'SOLUTIONS' },
    { value:'emulsions', label:'EMULSIONS' },
    { value:'suspensions', label:'SUSPENSIONS' },
    { value:'lotions', label:'LOTIONS' },
    { value:'creams', label:'CREAMS' },
    { value:'ointments', label:'OINTMENTS' },
    { value:'effervescent granules', label:'EFFERVESCENT GRANULES' },
    { value:'aerosols', label:'AEROSOLS' },
    { value:'gases', label:'GASES' },
    { value:'suppositories', label:'SUPPOSITORIES' },
    { value:'injections', label:'INJECTIONS' }
]

const RouteOptions = [{ value:'oral', label:'ORAL' },
{ value:'topical_skin', label:'TOPICAL_SKIN' },
{ value:'topical-eyes', label:'TOPICAL_EYE' },
{ value:'topical_ears', label:'TOPICAL_EARS' },
{ value:'inhalation', label:'INHALATION' },
{ value:'injections', label:'INJECTIONS' },
{ value:'vaginal', label:'VAGINAL' },
{ value:'anal', label:'ANAL' },
{ value:'sub_lingual', label:'SUB_LINGUAL' }

]


const Strengthoptions = [{ value:'mg', label:'mg' },                       
{ value:'g', label:'g' },
{ value:'ml', label:'mL' },
{ value:'mcg', label:'mcg' },
{ value:'mEq', label:'mEq' },
{ value:'iu', label:'IU' },
]

const CompletePopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-success'> Successfully Saved</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
       <Button className='btn btn-secondary' onClick={props.onHide}>Cancel</Button> 
       
       
      </Modal.Footer>
    </Modal>
  );
}

const handleMed=()=>{
  setAddmedPopUp(false)
}

const AddMedicinePop = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddMedicinesPat patient={patientID} closeMed={handleMed} medName={medName} />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-primary btn-col" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'> Medicine Added</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
             <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
             {/* <Button className='btn btn-primary btn-col' onClick={}>Proceed</Button>  */}
             
            </Modal.Footer>
          </Modal>
        );
      }

      const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>check data duplication or not</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
      const ErorValidatePopup=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>All fields other than description and manufacturename must required</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={handleError}>Ok</Button> 
             
             
            
            </Modal.Footer>
          </Modal>
        );
      }
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


      const handleSave=() =>
{
  if(validate()){
  ;


  const dataTosend = {

    medname: data.medicinename,
    strengthval: data.strength,
    strengthuni: data.strength_unit.value,
    dosgetypee:data.dosage_type.value,
    routee:data.routine.value

  };

  console.log('new medicinevalues', dataTosend);
  patientID.render(dataTosend);
//   setMedname(data.medicinename);
//  setStrength(data.strength);
//  setStrengthUnit(data.strength_unit.value);
//  setDosageForm(data.dosage_type.value);
//  setRoutie(data.routine.value)
 //setSuccessShow(true)
}
}
      const handleServiceChange= (data) => {

        setData( current => ({
            ...current , dosage_type: data
        }))
   }

   const handleUnitChange= (data) => {

    setData( current => ({
        ...current , routine: data
    }))
  }

  const strengthChange = (data)=>
  {
    setData(current=>({
    
        ...current , strength_unit: data
    }))
  }

  // const handleRequest = (e)=> {
  //  const val = e.target.value
  //  const key = e.target.name

  //  setData((current) => ({
  //      ...current , [key] : val==='true'? true : val==='false'? false : null
  //  }))

  // }

  // const handleYear = (date)=> {
  //   setYear(date);
      
  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();
      
  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
  //     /*let dateString= DateExtracted.toString(); */
  //     setYearData(DateExtracted);
      
  //     }
  // }

  // const handleExpiry = (date)=> {
  //   setExpiry(date);
      
  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();
      
  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
  //     /*let dateString= DateExtracted.toString(); */
  //     setExpiryData(DateExtracted);
      
  //     }
  // }

  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});

  // medicinename :'',
  // genericname:'',
  // strength:'',
  // medicinetype:'',
  // dosage_type:'',
  // medicinecode:'',
  // routine:''
    if (!input["medicinename"] || input["medicinename"]==="" ) {
      isValid = false;
      errors["medicinename"] = "Please enter medicine name";
    }

    if (!input["strength"] || input["strength"]==="" ) {
      isValid = false;
      errors["strength"] = "Please enter strength of medicine";
    }

    if (!input["dosage_type"].value || input["dosage_type"].value==="" ) {
      isValid = false;
      errors["dosage_type"] = "Please enter dosage type";
    }

    if (!input["routine"].value || input["routine"].value==="" ) {
      isValid = false;
      errors["routine"] = "Please enter route of medicine";
    }
    if (!input["strength_unit"].value || input["strength_unit"].value==="" ) {
      isValid = false;
      errors["strength_unit"] = "Please select strength unit";
    }


    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

//   const saveChanges = ()=> {
   
 
//  console.log(validate())
//     if(validate()){
//         const tokenString = sessionStorage.getItem("usertoken");
 
//      let v2 = tokenString.replace(
//        /["]+/g,
//        ""
//      ); /* REGEX To remove double quotes from tokenstring */
 
//   const dataToSend = {
//     med_name : data.medicinename,
//     generic_name :data.genericname,
//     strength : data.strength,
//     med_type :   data.medicinetype,                               
//     dosage_form : data.dosage_type.value,
//     med_code :  data.medicinecode,                    
//     route : data.routine.value,
//     description: data.description, 
//     manufacturer_name:data.manufacturername,
//     strength_unit:data.strength_unit.value,
//     composition:data.composition
        

//   }
 
//      axios
//        .post(`${APIURL}/api/v1/doctor/doctor-add-prescriptions/`, dataToSend , {
//          headers: { Authorization: "Token " + v2 },
//        })
//        .then((res) => {
//          if (res.data.status === "success") {
//           setCompleteShow(true);
            
//           setData({
//             medicinename :'',
// genericname:'',
// strength:'',
// medicinetype:'',
// dosage_type:{ value:'', label:'' },
// medicinecode:'',
// routine: { value:'', label:'' },
// description:'',
// manufacturername:'',
// strength_unit:{ value:'', label:'' },
// composition:''

//          });
//          } else {
//            setErrorShow(true)
//          }
//        })
//        .catch((err) => {
//          setErrorShow(true)
//        });
//        setSuccessShow(false)
//     }

   

// }

    return (
        <>
    <h2 style={{marginTop:'0'}} className="title-of-page">Add Medicine</h2>
        <div style={{maxHeight:'1000px'}} className='config-box fit-content'>

            
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Medicine Name : </span>
                    <input type='text' className='form-control title-flex' name='medicinename' value={data.medicinename} onChange={handleTitle} />
                    {error.medicinename ? <div className="error-validation-msg error-feature-inventory">{error.medicinename}</div> : null}
                </div>

          
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Strength: </span>
                    <input type='text' className='form-control title-flex' name='strength' value={data.strength} onChange={handleTitle} />
                    {error.strength ? <div className="error-validation-msg error-feature-inventory">{error.strength}</div> : null}
                </div>
                </div>
                <div className='feature-data pad-features'>
                <div className='flex-row name-content' style={{marginLeft:"-1%",width:"35%"}}>
                <span className='align-rental'>*Strength Unit: </span>
                <Select
                            value={data.strength_unit}
                            onChange={(data,e)=>{strengthChange(data,e)
                            }}
                            options={Strengthoptions}
                            name='strength_unit'
                            className="select-currency select-flex"
                        />
                    {error.strength_unit ? <div className="error-validation-msg error-feature-inventory">{error.strength_unit}</div> : null}
                </div>

           
                
            <br/>
             
            
                <div className='flex-row name-content'style={{marginLeft:"16%",width:"35%"}}>
                <span className='align-rental'>*Dosage: </span>
                <Select
                            value={data.dosage_type}
                            onChange={(data,e)=>{handleServiceChange(data,e)
                            }}
                            options={DosageOptions}
                            name='dosage_type'
                            className="select-currency select-flex"
                        />
                    {error.dosage_type ? <div className="error-validation-msg error-feature-inventory">{error.dosage_type}</div> : null}
                </div>
</div>  <div className='feature-data pad-features'>
                <div className='flex-row name-content'style={{marginLeft:"-1%",width:"35%"}}>
                <span className='align-rental'>*Route: </span>
                <Select
                            value={data.routine}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={RouteOptions}
                            name='routine'
                            className="select-currency select-flex"
                        />
                    {error.routine ? <div className="error-validation-msg error-feature-inventory">{error.routine}</div> : null}
                </div>
           

            </div>

            

            

           

            {/* <div className='flex-row name-content'>
                <span>Equipment Unit : </span>
                <Select
                            value={data.unit}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={unitOptions}
                            name='service_unit'
                            className="select-currency serv-width"
                        />
            </div>

            <div className='flex-row name-content'>
                <span>On Request Service : </span>

                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='request'  value= {true} checked={data.request === true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='request'  value= {false} checked={data.request === false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
            </div>

            <div className='flex-row name-content'>
                <span>Active Feature : </span>
                
                <div style={{marginBottom:'auto'}} className="form-group gender-radio-check col-lg-6 title-input">
                    <div className="form-control">
                        <input className='checkbox-class1' type='radio' id="true" name='active'  value= {true} checked={data.active === true} onChange={handleRequest} />
                        <label htmlFor='true'>Yes</label>

                        <input className='checkbox-class1' type='radio' id="false"  name='active'  value= {false} checked={data.active === false} onChange={handleRequest} />
                        <label htmlFor='false'>No</label>
                    </div>
                </div>
            </div> */}

            <div className='text-center'>
                <button onClick={handleSave} className='btn btn-primary btn-col mb-2'>ADD</button>
                
            </div>
            {successShow ? 
<SuccessPopUp
show={successShow}
// onProceed={() =>  {setSuccessShow(false); },
onHide={() =>  {setSuccessShow(false)}}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
{errorValidate ? 

<ErorValidatePopup
show={errorValidate}
onHide={() =>  {setErrorValidate(false)}}
/> 
: null}


{completeShow ? 

<CompletePopUp
show={completeShow}
onHide={() =>  {setCompleteShow(false)}}
/> 
: null}

{addmedPopUp ? 

<AddMedicinePop
show={addmedPopUp}
onHide={() =>  {setSuccessShow(false)}}
/> 
: null}


            
        </div>
        </>
    )
}

export default AddMedicinePtient
