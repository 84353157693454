import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import '../userprofile.css';

import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
import { Card, Button, Modal } from 'react-bootstrap'
import { Link, useParams, useNavigate ,useLocation } from 'react-router-dom';
function ServiceOrderReqDetails () {
    

    const { id } = useParams();
    const {state} = useLocation();
    const service_name = state.item_service;
    const consentlevelid = state.consent_level_id;
    const hospital_name =state.hospital_name;
    const request_quantity =state.request_quantity;
    const delivarydate = state.tentative_delivery_date;
   


  
}
export default ServiceOrderReqDetails