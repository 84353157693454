const AuthUtil = {
    validateAuth : () =>{
        const loggedIn = sessionStorage.getItem("usertoken");

        if (loggedIn) {
            return "/";
        }
    }
}
export default AuthUtil;
