import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { APIURL } from "../../Global";

import { Badge } from "react-bootstrap";
import flag from "./assets/img/flag/us-flag.png";
import logo1 from "../../assets/images/felixa_logo.png";
import logo2 from "../../assets/images/logo_name.png";
import prod1 from "./assets/img/products-img1.jpg";
import prod2 from "./assets/img/products-img2.jpg";
import contact from "./assets/img/contact-1.png";
import PatientNav from "./PatientNav";
import PatientFooter from "./PatientFooter";
import prod5 from "./assets/img/products-img5.jpg";
import cat1 from "./assets/img/categories-img1.png";
import cat2 from "./assets/img/categories-img2.png";
import cat3 from "./assets/img/categories-img3.png";
import cat4 from "./assets/img/categories-img4.png";
import cat5 from "./assets/img/categories-img5.png";
import cat6 from "./assets/img/categories-img6.png";
import ban1 from "./assets/img/banner-img1.png";
import ban2 from "./assets/img/banner-img2.png";
import ban3 from "./assets/img/banner-img3.png";
import ban4 from "./assets/img/banner-categories-img1.jpg";
import ban5 from "./assets/img/banner-categories-img2.jpg";
import { useAlert } from "react-alert";

import logo3 from "./assets/img/intpurple.png";

function Checklist({ setShowNavbarAndFooter, props }) {
  // const Razorpay = require('razorpay');

  const [rzp, setRzp] = useState(null);
  const [cartItems, setCatItems] = useState([]);
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const [cartdetails, setCartDetails] = useState({});
  const alert = useAlert();
  const navigate = useNavigate();
  let CartIDs = [];
  const [checkId, setCheckId] = useState("");
  const location = useLocation();
  const checkoutCartId = new URLSearchParams(location.search).get(
    "checkout_cart_id"
  );

  // const { Cartitems } = location.state;

  useEffect(() => {
    setShowNavbarAndFooter(false);
    return () => setShowNavbarAndFooter(true);
  }, [setShowNavbarAndFooter]);

  useEffect(() => {
    Cartpage();
  }, []);

  const Cartpage = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(
        `${APIURL}/api/v1/patient/cart-checkout/?checkoutcart_id=${checkoutCartId}`,
        {
          headers: { Authorization: "Token " + v2 },
        }
      )
      .then((res) => {
        console.log(res.data);
        setCatItems(res.data.cart_orders);
        setCartDetails(res.data.cart_details);
      })
      .catch((err) => {});
  };

  const Gateway = (e) => {
    e.preventDefault();
    navigate("/razor");
  };
  const PayDelivery = (e) => {
    e.preventDefault();

    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");
    const dataTosend = {
      paid_amount: subtotal,
      paid_status: "not_paid",
      payment_mode: "cod",
      checkoutcart_id: checkoutCartId,
    };

    axios
      .post(
        `${APIURL}/api/v1/patient/service-request-generation/`,
        dataTosend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          alert.success("Thank You For Your Order");

          navigate(-1);
        } else {
          alert.error("Error");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    return () => {
      if (checkoutCartId) {
        const tokenString = sessionStorage.getItem("usertoken");
        let v2 = tokenString.replace(/["]+/g, "");
        axios
          .delete(
            `${APIURL}/api/v1/patient/cart-checkout/?checkoutcart_id=${checkoutCartId}`,
            {
              headers: { Authorization: "Token " + v2 },
            }
          )
          .then((response) => {
            alert.error("Checkout cancelled");
            console.log("Checkout cancelled");
          })
          .catch((error) => {
            console.error("Error cancelling checkout", error);
          });
      }
    };
  }, [checkoutCartId]);

  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.net_total_item_amount,
    0
  );
  const shipping = cartItems.reduce(
    (acc, item) => acc + item.shipping_charge,
    0
  );
  const total = subtotal + shipping;

  const handlePaymentFailure = () => {
    // handle the payment failure here
  };

  return (
    <>
      <PatientNav />
      <br />
      <br />

      <section className="page-title-area" style={{height:"20px" ,marginTop:"-4%"}}>
     

     <h1 style={{color:"#6F6F6F",marginTop:"1%"}}>Payment</h1>
   
  
</section>

      {/* <!-- Start Categories Area --> */}

      <section class="checkout-area ptb-70">
        <div class="container">
          <form>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="billing-details">
                  <h3 class="title">Billing Details</h3>

                  <div class="row">
                    <div class="col-lg-6 col-md-12">
                      <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                          <label>
                            First Name <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={login_datas.name}
                          />
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                          <label>
                            Province <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={cartdetails.province}
                          />
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                          <label>
                            City <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={cartdetails.city}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-15">
                      <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                          <label>
                            Location <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={cartdetails.location}
                          />
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                          <label>
                            State <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={cartdetails.state}
                          />
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                          <label>
                            Country <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={cartdetails.country}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                      <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                          <label>
                            Pincode <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={cartdetails.pincode}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
            <div className="col-lg-6 col-md-12">
              <div className="order-details">
                <h3 className="title">Order Details</h3>

                <div
                  className="order-table table-responsive"
                  style={{ width: "100%" }}
                >
                  <table
                    className="table table-bordered"
                    style={{ border: "2px solid grey" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col" style={{ color: "black" }}>
                          Product Name
                        </th>
                        <th scope="col" style={{ color: "black" }}>
                          Total
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {cartItems.map((item) => (
                        <tr key={item.id}>
                          <td className="product-name">
                            <a href="#">{item.item_service_name}</a>
                          </td>
                          <td className="product-total">
                            <span className="subtotal-amount">
                              {" "}
                              <i class="fa fa-usd" aria-hidden="true"></i>
                              <b>{item.total_item_amount.toFixed(2)}</b>
                            </span>
                            <span>&nbsp;</span>{" "}
                            <Badge
                              pill
                              variant="primary"
                              style={{ fontSize: "10px" }}
                            >
                              {item.discount_percent}% OFF!
                            </Badge>{" "}
                            <span>&nbsp;</span>{" "}
                            <Badge
                              pill
                              variant="danger"
                              style={{ fontSize: "10px" }}
                            >
                              {item.vat_percent}% VAT!
                            </Badge>{" "}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td className="order-subtotal">
                          <span>Cart Subtotal</span>
                        </td>
                        <td className="order-subtotal-price">
                          <span className="order-subtotal-amount">
                            {" "}
                            <i class="fa fa-usd" aria-hidden="true"></i>
                            <b>{subtotal.toFixed(2)}</b>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="order-shipping">
                          <span>Shipping</span>
                        </td>
                        <td className="shipping-price">
                          <span>
                            {" "}
                            <i class="fa fa-usd" aria-hidden="true"></i>
                            <b>{shipping.toFixed(2)}</b>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="total-price">
                          <span>Order Total</span>
                        </td>
                        <td className="product-subtotal">
                          <span className="subtotal-amount">
                            {" "}
                            <i class="fa fa-usd" aria-hidden="true"></i>
                            <b>{total.toFixed(2)}</b>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                style={{
                  display: "block",
                  marginTop: "5%",
                  alignItems: "center",
                }}
              >
                <button className="default-btn" onClick={(e) => Gateway(e)}>
                  {" "}
                  Payment Gateway<span></span>
                </button>{" "}
                <span>&nbsp;</span>
                <button className="default-btn" onClick={(e) => PayDelivery(e)}>
                  Cash on Delivery<span></span>
                </button>
              </div>
              
            </div>
            </div>
           
          </form>
        
        </div>
      </section>
    </>
  );
}

export default Checklist;
