import React ,{useState} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import './profile.css'
import {Button ,Modal } from 'react-bootstrap';

function CouncilUpdate(props) {
    ;

    const[council ,setCouncil] =useState('')
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);
    const id = props.id1
    const [error,setError]=useState({
        council:''
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='council'){
            setCouncil(val)
          }  
        }

        const  validate = () => {
          //let input = email;
          let errors = {};
          let isValid = true;
    
          if (council==="" ) {
            isValid = false;
            errors["council"] = "Please enter Council";
          }
      
          setError(currentstate=>({
            ...currentstate,...errors
          }))
      
          return isValid;
      }

      const handleContinue =async(e) => {
        e.preventDefault();
    
        if( validate() ){
          submitAddress()
        }
      }

        const submitAddress = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '') 
          
             const data = {
                registration_council : council
             }
          
             axios.put(`${APIURL}/api/v1/doctor/medical-profile-update-view/${id}/` ,data, { headers: {
          
              "Authorization" : 'Token '+str  }})
            .then(res=>{
            
                  
              if(res.data.status==="success"){
                
                setSubmitMsg("success");
                setShow(true)
               setCouncil()
               
            
              }else{
                setErrorMsg(res.data.message);
                setErrorSubmit(true);
              
               
              }
            
            })
            .catch(err=>{
              setErrorMsg("error");
  
              setErrorSubmit(true);

            
            })
          
          }


          const ConfirmPopUp = (props) => {
            return(
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                //  backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4 class="updated-message">Updated Successfully</h4>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
          
                </Modal.Footer>
              </Modal>
            )
          }
          
          
          const SubmitErrorPopup =(props)=>{
              
          
            return (
              <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                //  backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 class="updated-message" style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>
          
                 
                 
          
                </Modal.Footer>
              </Modal>
            );
          }



    return (
        <div>
            <div className="item"><input className="form-control" type="text" maxLength="150" name="council" placeholder="Registration Council" onChange={handleChange}/>

                {error.council ? <div className="error-validation-msg"> {error.council}</div> : null}

            </div>
            <br/>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update Registration Council</button>
            </div>

            {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              props.handle();
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default CouncilUpdate
