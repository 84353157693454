import React,{useState,useEffect,useRef} from 'react';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function NewArrivals({ detailData,setDetailData }) {

    
    const [searchTerm, setSearchTerm] = useState("");
    debugger;
    const alert = useAlert();
    const [isExpanded, setIsExpanded] = useState(false);

    const handleClick = () => {
      setIsExpanded(!isExpanded);
    };
    // useEffect(() => {
    //     debugger;
    //   const tokenString = sessionStorage.getItem("usertoken");
    //   let v2 = tokenString.replace(/["]+/g, "");
  
    //   axios
    //     .get(`${APIURL}/api/v1/staff/promotion-approval/?promo_type=new_arrival&hospital_name=${searchTerm}`, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Token " + v2,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.status === 200) {
    //         const datas = res.data;
    //         setDetailData(datas);
    //       }
    //     })
    //     .catch((err) => {});
    // }, [searchTerm]);
  
    const handleApprove = (item) => {
        debugger;

        const tokenString = sessionStorage.getItem("usertoken");
    
        let str = tokenString.replace(/["]+/g, "");
       
      
        
         
        const dataToUpdate = {
          status:"approved"
        
        };
  
        axios
          .put(
            `${APIURL}/api/v1/staff/promotion-approval/${item}/`,
            dataToUpdate,
            {
              headers: {
                Authorization: "Token " + str,
              },
            }
          )
          .then((res) => {
            if (res.data.status === "success") {
                alert.success("Approved Successfully")
                axios.get(`${APIURL}/api/v1/staff/promotion-approval/?promo_type=new_arrival&status=pending`, {
                    headers: {
                      Authorization: 'Token ' + str,
                    },
                  })
                  .then((res) => {
                    setDetailData(res.data);
                  })
          
          
            } else {
              
            }
          })
          .catch((err) => {
          
          });







    };

    const handleSearchChange = (e) => {
        e.preventDefault();
       
        setSearchTerm(e.target.value);
      };
  
    const handleReject = (item) => {
        const tokenString = sessionStorage.getItem("usertoken");
    
        let str = tokenString.replace(/["]+/g, "");
       
      
        
         
        const dataToUpdate = {
          status:"rejected"
        
        };
  
        axios
          .put(
            `${APIURL}/api/v1/staff/promotion-approval/${item}/`,
            dataToUpdate,
            {
              headers: {
                Authorization: "Token " + str,
              },
            }
          )
          .then((res) => {
            if (res.data.status === "success") {
                alert.error("Rejected Successfully")
                axios.get(`${APIURL}/api/v1/staff/promotion-approval/?promo_type=new_arrival&status=pending`, {
                    headers: {
                      Authorization: 'Token ' + str,
                    },
                  })
                  .then((res) => {
                    setDetailData(res.data);
                  })
          
          
            } else {
              
            }
          })
          .catch((err) => {
          
          });



    };
  
    return (
      <>
    <div className="outerclass" style={{width : "900px",height:"1000px", backgroundColor:"#FFFFFF",marginLeft:"20%" ,marginTop:"2%",boxShadow:"0px,0px,0px",height:"auto",marginBottom:"5%"}}>
  <div className="formField">
  {/* <div className="search-section">
                  <div className="search-div">
                    <div style={{ display: "flex",marginLeft: "5%" ,marginTop:"-10%" }}>
                      <input
                        style={{}}
                        className="form-control search-input"
                        type="text"
                        placeholder="Search a Hospital"
                       value={searchTerm}
                        maxLength="150"
                        onChange={handleSearchChange}
                      />
                      <button className="btn btn-primary btn-col search-btn">
                        {" "}
                        <i className="fas fa-search"></i>{" "}
                      </button>
                      </div>
                      </div>
                      </div> */}
    <div>
    
        {detailData.length > 0  ?(
          detailData.map((data, index) => (
            <div
              key={index}
              className="list-medicines"
              style={{
                padding: "8px",
                marginTop: "20px",
                height: "auto",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                alignItems: "center", // center align items vertically
              }}
            >
              <div className="single-banner-categories-box" style={{ display: "flex", flexDirection: "column-reverse",width:"80%"  }}>
              {data.promos && data.promos.slice(0, 1).map((promo, index) => (
                <div key={index} style={{width:"50%" }}>
                 <img
        src={promo["promo_banner"]}
        alt="image"
        style={{ height: "auto", maxWidth: "150%", cursor: "pointer" }}
     
      />
   
                 
                </div>
              ))}

               </div>
       <div style={{display: 'flex', marginLeft:"-10%", gap: '10px'  }}>
                <Button variant="success"   onClick={() => handleApprove(data.id)}> <i class="fa fa-check" aria-hidden="true"></i>Approve</Button>
  {/* add a spacer between the buttons */}
  <Button variant="danger" onClick={() => handleReject(data.id)}><i class="fa fa-times" aria-hidden="true"></i>Reject</Button>
          </div> 
               
          
              <div
                className="doctor-name"
                style={{
                  color: "#000000",
                  marginLeft: "10%",
                  marginTop: "2%",
                  gridColumn: "1 / 2", // align to the left grid column
                }}
              >
                <h6> 
                  <b style={{ color: "black" }}>{data.hospital}</b>
                </h6>
                <h6 style={{fontSize:"10px"}}>Requested on: {new Date(data.modified_at).toLocaleDateString()}</h6>
                {data.start_date!==null ? <h6 style={{fontSize:"10px"}}><b>From: </b>{new Date(data.start_date).toLocaleDateString()}  </h6>:""} 
             
             {data.end_date!==null ? <h6 style={{fontSize:"10px"}}> <b>To:</b> {new Date(data.end_date).toLocaleDateString()}</h6>:""}
              </div>
              <div
                className="medicine-details"
                style={{ display: "grid", gridTemplateColumns: "1fr", marginTop: "3%",marginLeft:"-3%" }}
              >
                {data.items.map((item, i) => (
                 <div key={i} style={{ display: "flex", justifyContent: "space-between", marginLeft:"-20%",borderBottom: "2px dotted grey" ,marginTop:"5px"}}>
                    <div>
                      <h6 style={{fontSize:"12px"}}> 
                        <span> New Arrival Product:<b>{item.item_service}</b></span>
                        <span>&nbsp;&nbsp;</span><br/><br/>
                        <span>
                        
                          Price: <i className="fa fa-inr" aria-hidden="true"></i><b>{item.amount}</b>
                        </span>
                      </h6>
                      </div><div>
                      <h6 style={{fontSize:"12px"}}> 
                        <span>Product type:<b>{item.service_type==="master_labtest" ? "Lab" :item.service_type==="master_scan" ? "Scan" : item.service_type==="master_medicine" ? "Medicine" : item.service_type==="misc" ? "Misc" :item.service_type==="master_procedure" ? "Procedure" :item.service_type==="master_consumable" ? "Consumables" : "service"  }</b></span>
                        <span>&nbsp;&nbsp;</span><br/><br/>
                        <span>
                        
                          Discount:  <b>{item.discount}%</b>
                        </span>
                      </h6>
                      </div><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  

                  </div>
                ))}
              </div>
            </div>
          ))
        
        
        ) : (
            <div style={{ textAlign: "center", marginTop: "20px",color:"red" }}>
            No approval data here.
          </div>
        )}
      </div>
    </div>

    
  </div>


      </>
    );
  }
export default NewArrivals    