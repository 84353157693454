import React, {useState, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import printJS from 'print-js'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import moment from 'moment'
import { useAlert } from "react-alert";

function PaymentRegistartion(props) {

    debugger;
  debugger;

    const [invoiceData , setInvoiceData]= useState([]);
    const [method, setMethod]= useState('')
    const [cartID, setCartID]= useState("")
    //const [amount, setAmount]= useState('')
    const [errorShow,setErrorShow]=useState(false);
    const [errorMsg,setErrorMsg]=useState("");
    // const [type, setType]= useState("full")
    // const [discount, setDiscount]= useState(0)
    const alert = useAlert();
    let ItemID=[]
    // const [show, setShow]= useState(false)
    const [login_datas, setLogin_datas] = useState(
      JSON.parse(sessionStorage.getItem("logindatas"))
          ? JSON.parse(sessionStorage.getItem("logindatas"))
          : null
  );

   /* useEffect(()=> {

        setAmount(getPayable())


    },[type,apiData,discount])*/


     const handleMethod =(e) => {
        setMethod(e.target.value)
    }

    // const handleAmount = (e)=> {
    //     const val = e.target.value
    //     if(val.length < 6 ){
    //         setAmount(e.target.value)
    //     }
    //     else return false
    // }

     useEffect(()=> {
        debugger;

        const tokenString = sessionStorage.getItem("usertoken");
    
        let v2 = tokenString.replace(
          /["]+/g,
          ""
        ); /* REGEX To remove double quotes from tokenstring */
  
  
          axios
          .get(`${APIURL}/api/v1/service-provider/hospital-services/?type=misc`, {
            headers: { Authorization: "Token "+ v2 },
          })
          .then((res) => {
            if (res.status ===200) { 
                debugger;
             
              let dataList = (res.data.filter(item => {
                return item.service_name === 'Registration' || item.service_name === 'registration'
              }))
              //(res.data.orders)
              setInvoiceData(dataList[0]);
             
           console.log("invoiceData",invoiceData)
              console.log()
          }
          else{
  
          }
      }).catch((error)=>{
  
      })




 
     },[])

    const handleSave = async()=> {

        const tokenString= sessionStorage.getItem('usertoken');
      
        let str= tokenString.replace(/["]+/g, '');

       

        ItemID=invoiceData.id

        //const consult = props.consultData
        const dataToUpdate = {
          payment_mode : method,
            paid_amount: invoiceData.amount,
            patient_id: props.patientID,
            paid_for:"registration",
            paid_status:"paid",
            items :[ItemID]
      
        }
       
         axios.post(`${APIURL}/api/v1/service-provider/hospital-payments/`, dataToUpdate ,
         { headers: {
           'Content-Type': 'application/json',
           "Authorization" : 'Token '+str  }})
            .then(res=>{
       
           if(res.data.status==="success"){

         setCartID(res.data.checkoutcart_id)
       
        
          alert.show("Bill Generated")
          



              
            handlePrint()

            props.refresh();
         
           // props.dataHandle1();
       
           }else{
            setErrorMsg(res.data.message)
            setErrorShow(true)
           }
       
       
         })
         .catch(err=>{
            setErrorMsg('Error in Data Submission.Please try again')
            setErrorShow(true)
       })

    }


     const handlePrint = () => {
    
        printJS({
           printable: 'print-invoice',
           type: 'html',
           targetStyles: ['*'],
        })
        setTimeout(() => {
            document.execCommand('Stop');
          }, 1000);

      
      }


      const SubmitErrorPopup =(props)=>{
    

        return (
          <Modal
            //{...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               {/* <Button variant="secondary" onClick={props.onHide}>Ok</Button> */}
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      } 





      const procedureData = invoiceData!=="" ?

                                                      


  

                  <tr>
                  
                      <td>{invoiceData.service_name}</td>
                     
                      <td>{invoiceData.amount}</td>
                      <td>{invoiceData.vat}</td>
                      <td>{invoiceData.discount}</td>
                      <td>{invoiceData.amount}</td>
                  </tr>

  
  : null



   return (<div>
      
      <h2 style={{textAlign:'center'}} className="title-of-tasks"> Invoice  </h2><br/>

      <div className='flex-row invoice-radio'>
           <span className='text-muted font-weight-bold'>Select payment method :</span>
           <input type='radio' className='hideme' checked={method==='card'} id='card' value='card' onChange={handleMethod} />
        <label htmlFor='card' className='invoice-label'>
            Card
        </label>

        <input type='radio' className='hideme' checked={method==='cash'} id='cash' value='cash' onChange={handleMethod} />
        <label htmlFor='cash' className='invoice-label'>
            Cash 
        </label>

        <input type='radio' className='hideme' checked={method==='upi'} id='benefit_pay' value='upi' onChange={handleMethod} />
        <label htmlFor='benefit_pay' className='invoice-label'>
            UPI
        </label>
       </div>
       <br/>
       <div id='print-invoice' className="invoice-table flex-col to-print invoice-print-size ">
        <div className="invoice-row1 invoice-header">

              <div>
                <h5 className='font-weight-bold text-left'>Patient ID {props.patientID}</h5>
                <h6 className='text-left'>Patient: {props.name}</h6>
                <h6 className='text-left'>Mobile: {props.mobile}</h6>
               
              </div>

             <div>
              <h3 className="invoice-heading caps text-center">{login_datas.hospital_name}</h3>
              <h6 className="invoice-heading caps text-center">{login_datas.hospital_state},{login_datas.hospital_location},{login_datas.hospital_street}</h6>
              <br/>
              <h6 className="invoice-heading invoice-heading-normal">{login_datas.hospital_mobile}</h6>
              <h6 className="invoice-heading invoice-heading-normal">{login_datas.hospital_email}</h6>
             </div>
           {/* <h6 className="invoice-heading caps">Al Janabiyah North</h6> */}

        <div className='invoice-detail mr-2'>

            <h5>{moment().format('DD-MM-YYYY')}</h5>
        
            

        </div>
       </div>
       {/* <div className="invoice-row2">
  
       <div>
          <h5 className="grey-title caps">{invoiceData.patient_name}</h5>
    </div>
       </div> */}
       <div className="invoice-row3">
          <table style={{marginLeft:"10%"}} >
          
         
            <tr>
            
  
              <th>Test Name</th>
             
          
              <th> Amount </th>
              <th>VAT%</th>
              <th>Discount%</th>
              <th>Total</th>
            </tr>
    

{ procedureData }
          


  
          </table>
       </div>

       <div className="invoice-row4">

     <h6 className="text-right">Gross Total : <span className="caps-full"><i class="fa fa-inr" aria-hidden="true"></i></span>{invoiceData.amount}</h6>
        {/* <h6 className="text-left">VAT Amount : <span className="caps-full">INR</span>{props.vat}</h6>
        <h6 className="text-left">Discount : {props.discount}</h6>
        <h6 className="text-left">Total Amount : <span className="caps-full">INR</span>{props.total+props.vat}</h6> */}
        <h6 className="text-right">Net Total: <span className="caps-full"><i class="fa fa-inr" aria-hidden="true"></i></span>{invoiceData.amount}</h6>
        </div>
        <div className="invoice-row4">
      
       
     
         <br/>
         <h6 className="text-right">Thanks,</h6>
         <h6 className="text-right">{login_datas.name}</h6>
          

     </div>

       </div>
       <br/>

       {/**<div className="to-print">
        <a href="https://www.felixacare.com" target="_blank"><h5 className="felixa-site" > www.felixacare.com</h5></a>
  
      </div>**/}
       
       <div className="do-not-print text-center" id="pr">
                   <button className="btn btn-primary btn-col btn-square" disabled={method === ''} onClick={handleSave}> Save and Print </button>
                   <br/>
                   <br/>
                   <h6 style={{color:"grey"}}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</h6>
                    <br/>
                    <br/>
             </div> 
  

             {errorMsg!=='' ? 
    <SubmitErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);
                        setErrorMsg('');}}
       
      />: ''
    }

    </div>
       )
}

export default PaymentRegistartion
