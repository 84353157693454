

import React from "react";



function 
PageNotFound() {
  return (
    <div >
     
    <div className="home">
        
      <h1>Page not found!!</h1>
     
     
  
</div>

    </div>
   
  );
}

export default PageNotFound;