import React, { useState, createContext } from "react";

export const ProfileContext = createContext();

export const ProfileProvider = (props) => {
  
  const [render, setRender]= useState(false)

  return (
    <ProfileContext.Provider value={[render, setRender]}>
      {props.children}
    </ProfileContext.Provider>
  );
};
