import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIURL } from "../../../Global";
import printJS from "print-js";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import moment from "moment";
import national from "../../../assets/images/icons/nationalLogo.png";

function GenerateInvoiceView(props) {
  
  const [invoiceData] = useState(props.invoiceData);
  const [method, setMethod] = useState("");
  const patient = props.patient;
  const order = props.orderdetail;
  const ordertype = props.ordertype;
  const orderID = props.orderID;
  const contact = props.contactpt;
  const paystatus = props.status;
  const [amount, setAmount] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [tableData, setTableData] = useState([]);
  const [type, setType] = useState("full");
  const [discount, setDiscount] = useState(0);
  const [show, setShow] = useState(false);
  const [bdo_Data] = useState(
    JSON.parse(sessionStorage.getItem("bdodata"))
      ? JSON.parse(sessionStorage.getItem("bdodata"))
      : null
  );
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState({
    invoice_no: "",
    cpr: "",
    total_amount_paid: "",
    balance_payment: "",
    net_total_procedure_amount: "",
    total_procedure_amount: "",
    payment_status: "",
    vat: "",
    mobile_number: "",
    payment_type: "",
    discounted: "",
  });

  const [procedure, setProcedure] = useState([]);

  let initial = useRef(true);

  // useEffect(()=> {

  //   if( initial.current ){

  //     initial.current = false
  //   }
  //   else{
  //     setAmount(getPayable())
  //   }

  // },[type,apiData,discount])

  // useEffect(()=> {

  //     const tokenString= localStorage.getItem('usertoken');

  //     let str= tokenString.replace(/["]+/g, '');

  //      axios.get(`${APIURL}/api/v1/hospital/patient-hospital-payment_v1/?payment_for=procedure&after_payment=true&patient_id=${invoiceData.patient_id}`,
  //      { headers: {
  //        'Content-Type': 'application/json',
  //        "Authorization" : 'Token '+str  }})
  //         .then(res=>{

  //        if(res.data.status==="success"){

  //         setApiData(res.data)
  //         setProcedure(res.data.procedure_details)

  //        }else{

  //         setErrorMsg(res.data.message)
  //         setErrorShow(true)

  //        }

  //      })
  //      .catch(err=>{

  //         setErrorMsg('Error in Retreiving data.Please try again')
  //         setErrorShow(true)
  //    })

  //  },[])

  useEffect(() => {
    
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/service-provider/order-invoice/?order_id=${orderID}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          let dataList = [];
          dataList = res.data.invoice_details;
          setTableData(dataList);
         setData(dataList.invoice_items.map((item, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{item.item}</td>
                <td>{item.item_quantity}</td>
                <td>{item.item_amount}</td>
                <td>{item.item_quantity * item.item_amount}</td>
              </tr>
            );
          }))
        }
      })
      .catch((err) => {});
  }, []);

  // const handleSave = async()=> {
  //   initial.current= true
  //     const tokenString= localStorage.getItem('usertoken');

  //     let str= tokenString.replace(/["]+/g, '');

  //     const dataToUpdate = {
  //         payment_method : method,
  //         payment_for:'procedure',
  //         amount_paid: amount,
  //         invoice_no: apiData.invoice_no,
  //         discount: discount
  //     }

  //      axios.post(`${APIURL}/api/v1/hospital/patient-hospital-payment_v1/?payment_for=procedure&patient_id=${invoiceData.patient_id}`, dataToUpdate ,
  //      { headers: {
  //        'Content-Type': 'application/json',
  //        "Authorization" : 'Token '+str  }})
  //         .then(res=>{

  //        if(res.data.status==="success"){

  //         // async function callGet(callBack){

  //         //   if(type !== "full"){
  //         //     await callBack()
  //         //   }
  //         //   setShow(true)
  //         //   if(type === "full"){

  //         //     const procedureData = procedure
  //         //     const data = procedureData.map(item => {
  //         //       return {
  //         //         procedure_name: item.procedure_name,
  //         //         procedure_type: item.procedure_type,
  //         //         currency: item.currency,
  //         //         amount_without_vat: item.amount_without_vat,
  //         //         vat: item.vat,
  //         //       }

  //         //     })
  //         //     setProcedure(data)
  //         //     setRefresh(!refresh)

  //         //   }

  //         //   handlePrint()
  //         // }

  //         setShow(true)

  //         const procedureData = procedure
  //         const data = procedureData.map(item => {
  //           return {
  //             procedure_name: item.procedure_name,
  //             procedure_type: item.procedure_type,
  //             currency: item.currency,
  //             amount_without_vat: item.amount_without_vat,
  //             vat: item.vat,
  //           }

  //         })
  //         setProcedure(data)
  //         setRefresh(!refresh)

  //         handlePrint()

  //         // callGet(handleProcedureInvoice)

  //        }else{
  //          setShow(false)
  //         setErrorMsg(res.data.message)
  //         setErrorShow(true)
  //        }

  //      })
  //      .catch(err=>{
  //         setErrorMsg('Error in Data Submission.Please try again')
  //         setErrorShow(true)
  //    })

  // }

  const handlePrint = () => {
    printJS({
      printable: "print-invoice",
      type: "html",
      targetStyles: ["*"],
      header: "Procedure Invoice",
    });
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMsg !== ""
              ? errorMsg
              : "Error in Data Submission. Please try again!"}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handlePayment = (e) => {
    setType(e.target.value);
  };

  const handleDiscount = (e) => {
    if (parseInt(e.target.value) < parseInt(apiData.balance_payment)) {
      setDiscount(e.target.value);
    } else return false;
  };

  // const getPayable = () => {

  //   const data = apiData.balance_payment - discount

  //   return data.toFixed(3)
  // }

  return (
    <div>
      <h2 style={{ textAlign: "center" }} className="title-of-tasks">
        {" "}
        Service order Invoice{" "}
      </h2>
      <br />

      <div
        id="print-invoice"
        className="invoice-table flex-col to-print invoice-print-size "
        style={{ width: "52%" }}
      >
        <div className="invoice-row1 invoice-header">
          <div>
            <h5
              className="font-weight-bold text-left"
              style={{ color: "purple" }}
            >
              <b> National Hospital</b>
            </h5>
            <h6 className="text-left">Mavoor Rd,Near KSRTC Bus Stand,</h6>
            <h6 className="text-left">Tazhekkod,Kozhikode,</h6>
            <h6 className="text-left">Kerala 673001</h6>
            <h6 className="text-left">0495 415 2222</h6>
          </div>

          {/* <div>
                <h5 className='font-weight-bold text-left'>Invoice </h5>
                <h6 className='text-left'>Patient: {patient} </h6>
                <h6 className='text-left'>Mobile: {contact} </h6>
                <h6 className="text-left">CPR : </h6>
              </div>  */}

          <div>
            <h3 className="invoice-heading caps text-center"></h3>``
            <h6 className="invoice-heading caps text-center"></h6>
          </div>
          {/* <h6 className="invoice-heading caps">Al Janabiyah North</h6> */}

          <div className="invoice-detail mr-2">
            <img src={national} className="logoClass" width="65px" />
            <br />
            {/* <h6 style={{marginLeft:"50%"}}>{moment().format('DD-MM-YYYY')}</h6> */}
            <h6 className="invoice-heading invoice-heading-normal"></h6>
          </div>
        </div>
        <div className="invoice-row2">
          <div className="invoiceborder">
            <h5>
              <b>INVOICE</b>
            </h5>
          </div>
          <div>
            <h6 className="text-left">
              <b>BillNo:</b>
              {tableData.id} <span>&nbsp;&nbsp;&nbsp;</span>{" "}
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <b>BillDate:</b> {moment(tableData.invoice_date).format("DD-MM-YYYY")}
              <span>&nbsp;&nbsp;&nbsp;</span> <span>&nbsp;&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span>&nbsp;&nbsp;&nbsp;</span> <b>BillTime:</b>{moment(tableData.invoice_date).format("HH:mm:ss")}{" "}
            </h6>

            <h6 className="text-left">
              <b>Patient Details</b> <span>&nbsp;&nbsp;&nbsp;</span>{" "}
              <span className="lineVoice"> <span style={{marginLeft:"1%"}}></span>{patient} ,<br/> <span className="lineVoice" style={{marginLeft:"15.2%"}}> <span>&nbsp;</span> {tableData.to_addr} </span></span>{" "}
            </h6>

            {/*               
                <h6 className='text-left'> {patient} </h6>
                <h6 className='text-left'>{contact} </h6>
                <h6 className="text-left">Address </h6> */}
          </div>
        </div>
        <div className="invoice-row3">
          <table style={{ width: "100%" }}>
            <tr style={{ color: "rgba(0, 0, 0, 0.1)" }}>
              <th>
                {" "}
                <b>Sl. </b>{" "}
              </th>

              <th>
                {" "}
                <b>Item/Service</b>
              </th>
              <th>
                <b> Qty</b>
              </th>

              <th>
                {" "}
                <b>Price</b>{" "}
              </th>
              <th>
                {" "}
                <b>Amount</b>{" "}
              </th>
            </tr>
            {/* <tr>
                 <td>1</td>
                    <td>{order}</td>
                  <td>{ordertype}</td>
                  <td>250</td>
                     <td>900</td>
                  
                    {/* <td>{item.vat}</td> */}
            {/* </tr>  */}
            {data}

            {/* <tr>
            <td>
                
               <span className="caps">Patient</span><br/>
               <span className="caps">Registration </span>
               
  
             </td>
             <td>{apiData.amount_paid}</td>
             
            <td>{apiData.currency}</td>
            <td>{apiData.vat}</td>
  
          </tr> */}
          </table>
        </div>
        <div className="invoice-row4">
          <div>
            {/* <div>
             <div className='d-flex align-items-center justify-content-around mb-2'>
                {
                  show ?
                  <>
                  <h6 className="text-left">Discount </h6>
                  <h6>{parseFloat(discount).toFixed(3)}</h6>
                  </>
                  :
                  <>
                  <h6 style={{width:"40%"}} className="text-left">Discount :</h6>
                  <input type="number" className="form-control discount-input" min="0" value={discount} onChange={handleDiscount} />
                  </>
                }
                
             </div>
                {
                  show === false ?
                  <h6 className="text-left mb-3">Payable Amount : BD {getPayable()}</h6>
                  :
                  null
                }
              

              <div className='d-flex align-items-center mb-3'>

                  {
                    show ?
                    <>
                    <h6 className="text-left mb-0 mr-3">Payment Type  </h6>
                    <h6 className='mb-0'>{type}</h6>
                    </>
                    :
                    <>
                    <h6 style={{width:"40%"}} className="text-left mb-0">Payment Type : </h6>
                    <input type='radio' checked={type==="partial"} id='partial' value="partial" onChange={handlePayment} />
                    <label style={{marginBottom:'0'}} className='mr-2' for='partial'>
                    Partial
                    </label>
  
                    <input type='radio' checked={type==="full"} id='full' value="full" onChange={handlePayment} />
                    <label style={{marginBottom:'0'}} for='full'>
                    Complete 
                    </label>
                    </>
                  }

              </div>

              {
                show?
                <div className='d-flex align-items-center justify-content-around paid-highlight mt-2'>
                    <h6 className='mb-0'>Paid</h6>
                    <h6 className='mb-0'>{parseFloat(amount).toFixed(3)}</h6>
                </div>

                : null
              }

{
                show?
                <div className='d-flex align-items-center justify-content-around paid-highlight mt-2'>
                    <h6 className='mb-0'>Balance </h6>
                    <h6 className='mb-0 '>{(getPayable() - amount).toFixed(3)}</h6>
                </div>

                : null
              }

              {
               show === false ? type === "partial"?
                <>
                <div className='d-flex align-items-center'>
                  <span className='text-muted font-weight-bold'>Amount Paid : </span>
                  <input type='number' className='form-control discount-input' style={{width:'150px'}} max='100000' min='0' value={amount} onChange={handleAmount} />
                </div>
                <br/>
                <h6 style={{width:"60%"}} className="balance-highlight">Balance : {(getPayable() - amount).toFixed(3)}</h6>
                </>
                :  null : null
              }
           </div> */}

            <div className="row4-grid">
              <div>
                <h6 className="text-left">Terms and Conditions apply*</h6>
                {/* <h6 className="text-right mb-4">Total : <span className="caps-full"> </span></h6>
              <h6 className="text-left mb-4">Payment Type : {apiData.payment_type === "mixed_payment"? "Mixed" : apiData.payment_type === "benefit_pay" ? "Benefit Pay" : apiData.payment_type }</h6> */}
              </div>

              <div style={{  marginLeft:"52%" }}>
                <h6 className="text-right mb-3">
                  Total Amount :{" "}
                  <span className="caps-full">INR {tableData.items_totamount}</span>
                </h6>
                <h6 className="text-right mb-3">
                  Discount :{" "}
                  <span className="caps-full">INR {tableData.discount}</span>
                </h6>
                <h6 className="text-right mb-3">
                  GST :
                  <span className="caps-full">
                    INR {tableData.vat_applied}{" "}
                  </span>
                </h6>
                <h6 className="text-right mb-3">
                  Ship Amount:
                  <span className="caps-full">
                    INR {tableData.items_shipamount}{" "}
                  </span>
                </h6>
                <h6
                  className="text-right"
                  style={{ backgroundColor: "#9aa2a9" }}
                >
                  Net Total :INR {tableData.paid_amount}{" "}
                
                </h6>
                {/* <h6 className="text-right">Balance : BD {apiData.balance_payment}</h6> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      {/**<div className="to-print">
        <a href="https://www.felixacare.com" target="_blank"><h5 className="felixa-site" > www.felixacare.com</h5></a>
  
      </div>**/}

      <div className="do-not-print text-center" id="pr">
        <button
          className="btn btn-primary btn-col btn-square"
          onClick={handlePrint}
        >
          {" "}
          Print{" "}
        </button>
        <br />
        <br />
        <h6 style={{ color: "grey" }}>
          ** If in case 'Print' does not work, please cross check if popup
          blocker is switched 'off' in your Browser settings.
        </h6>
        <br />
        <br />
      </div>

      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorShow}
          onHide={() => {
            setErrorShow(false);
            setErrorMsg("");
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default GenerateInvoiceView;
