import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./addmedi.css";
import { Link } from "react-router-dom";
import AddRemark from "./AddRemark";
import './ViewMedPresc.css'
import Select from "react-select";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import { APIURL } from "../../Global";
import { useNavigate, Navigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import EditRemark from "./EditRemark";
import moment from "moment";
import printJS from 'print-js'

function ViewMedPresc({ patient, closeMed }) {
  const [temppushitem, setTempPushItem] = useState([]);
  const [newarray, setNewarray] = useState([]);
  const [medCounter, setmedCounter] = useState(0);
  const [render, setRender] = useState(true);
  const [reRender, setRerender] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [medId, setMedID] = useState("");
  const [price, setPrice] = useState("");
  const [addShow, setAddShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [vat, setVat] = useState("");
  const [index, setIndex] = useState();
  const [meddata, setMedData] = useState("");
  const [medErr, setMedErr] = useState("");
  const [deletesuccess, setDeleteSuccess] = useState("");
  const [deletesucc, setDeleteSucc] = useState(false);
  const [poppShow, setPopupShow] = useState(false);
  const patientid = patient;
  const [IndexToDelete, setIndexToDelete] = useState();
  const [deleteTestConfirmMsg, setDeleteTestConfirmMsg] = useState("");
  // const [item1,setItem]=useState([]);
  const [remarkData, setRemarkData] = useState("");
  const [dateShow, setDateShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const [item2, setItem2] = useState([
    // {
    //   med_name: "",
    //   strength: "",
    //   dosage_form: "",
    //   route: "",
    //   dosage: "",
    //   durationvalue:"",
    //   freequency: "",
    //   intake_count: [],
    //   intake_time: "",
    //   dosage_count: "",
    //   remarks: "",
  //  },
  ]);

  const [item1, setItem] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [display, setDisplay] = useState(false);

  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [, setOptionsDisplay] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  const [submitMsg, setSubmitMsg] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteConfirmMsg, setDeleteConfirmMsg] = useState("");
  const [deleteConfirmModalShow, setDeleteConfirmModalShow] = useState(false);
  const [remarkList, setRemarkList] = useState([]);
  const [cell, setCell] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [backup, setBackup] = useState("");
  const [procedureData, setProcedureData] = useState([]);
  const [procedureShow, setProcedureShow] = useState(false);
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);

  const [period, setPeriod] = useState("");
  const [count, setCount] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [medicine, setMedicine] = useState([]);
  const [fetchTableData, setFetchTableData] = useState(true);
  const [patientData, setPatientData] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialRender = useRef(true);


  useEffect(() => {
    " ";
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/doctor/patient-dashboard/?patient_id=${patientid}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
      
        if (res.data.status === "success") {
          setPatientData(res.data.data);
        }
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    axios
      .get(
        `${APIURL}/api/v1/doctor/online-prescription/?patient_id=${patientid}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.message.length > 0) {
            const data = res.data.message;
            const filteredData = data.filter((item) => {
              return item.id !== "";
            });
            if (filteredData.length < 1) {
              setItem([
                {
                  med_name: "",
                  strength: "",
                  dosage_form: "",
                  route: "",
                  durationvalue:"",
                  dosage: "",
                  freequency: "",
                  intake:"",
                  intake_count: [],
                  intake_time: "",
                  dosage_count: "",
                  remarks: "",
                },
              ]);
            } else {
              setItem(filteredData);
            }
          }
        }
      })
      .catch((err) => {});
  }, []);



  const data = item1.map(item => {
    return (
      <tr>
          <td>{item.medicine_name}</td>
          <td>{item.strength}</td>
          <td>{item.intake_time}</td>
          <td>{item.dosage_count}</td>
          <td>{item.dosage_form}</td>
          <td>{item.intake}</td>
          <td>{item.frequency}</td>
          <td>{item.route}</td>
          <td>{item.duration} days</td>
          <td>{item.remarks}</td>
      </tr>
    )
  });

  const handlePrint = () => {
    
    printJS({
       printable: 'print-invoice',
       type: 'html',
       targetStyles: ['*'],
      //  header: 'Prescription',
    })
  
  };

  return (
    <>
      <br />
      <div className="flex-head">
        
     

      </div>
      <br />
      <div id='print-invoice' className="invoice-table flex-col to-print invoice-print-size ">
      <h1 className="title-of-tasks title-mg"> Prescription</h1>
      <div className="invoice-row1 invoice-header">

        <div>
          <h5 className='font-weight-bold text-left'> </h5>
          <h6 className='text-left'>Patient: {patientData?.name}</h6>
          <h6 className='text-left'>Gender: {patientData?.gender}</h6>
          <h6 className='text-left'>Age: {patientData?.age}</h6>
          {/* <h6 className="text-left">CPR : </h6> */}
        </div>

        <div>
        <h3 className="invoice-heading caps text-center">{login_datas?.hospital_name}</h3>
        <h6 className="invoice-heading caps text-center">{login_datas?.location}</h6>
        </div>

          <div className='invoice-detail mr-2' style={{textAlign: 'right'}}>

          <h5>{moment().format('DD-MM-YYYY')}</h5>
          <h6>Prescribed By: <b>{login_datas?.doctor_name}</b> </h6>
          {/* <h6 className="invoice-heading invoice-heading-normal">{bdo_Data.hospital_mobile}</h6>
          <h6 className="invoice-heading invoice-heading-normal">{bdo_Data.hospital_email}</h6> */}

          </div>
        </div>
        <div className="invoice-row3">
          <table>
            <tr>
            <th> Medicine </th>
            <th>Strength</th>
            <th> Course </th>
            <th>Dosage</th>
            <th>Form</th>
            <th>Intake Period</th>
            <th>Frequency</th>
            <th>Route</th>
            <th>Duration</th>
            <th>Remarks</th>
            </tr>
            {data}
          </table>
        </div>
        <div className="invoice-row4" >
       <h6> (Intake Period : 1.Daily 2.weekly 3.monthly 4.alternative days 5. weekly twice 6. weekly thrice )</h6>
         {/* <h6 style={{float:"right"}}> Prescribed By,<br/> */}
         
        {/* <b style={{marginLeft:"80%"}}>{login_datas?.doctor_name}</b> */}
      
        </div>
        <div className="invoice-row5" ></div>
    
      </div>
      <br />
      <div className="">
        <div className="text-center">
          <Button variant="success" onClick={handlePrint}>Print</Button>
        </div>
      </div>
      <br />
    </>
  );
}

export default ViewMedPresc;
