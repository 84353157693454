import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';


function EditProcedure(props) {
  let isEdited = false;

    const[data, setData]= useState(props.data);
       

    const [error,setError]=useState({
      procedure_name:'',
      procedure_type:'',
       });
       const [selectedImage, setSelectedImage] = useState(null);
    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const [year, setYear]= useState()
    const [yearData, setYearData]= useState()
    const [expiry, setExpiry]= useState()
    const [expiryData, setExpiryData]= useState()
    const [refresh, setRefresh]= useState(false)
    const[completeShow,setCompleteShow]=useState(false)

    //const [addRentalShow, setAddRentalShow]= useState(false)
    const[errorValidate,setErrorValidate]=useState(false)

//     const serviceOptions = [{ value:'general', label:'General' },
//     { value:'service_request', label:'Service Request' },
//     { value:'rental', label:'Equipment Request' }
// ]

    const unitOptions = [{ value:'days', label:'Days' },
    { value: 'count', label: 'count' }
]



  const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
const handleSave=() =>
{
  setSuccessShow(true);
 
}

const CompletePopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-success'> Successfully Updated</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
       <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
       
       
      </Modal.Footer>
    </Modal>
  );
}



    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Procedure already present.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
             
            
            </Modal.Footer>
          </Modal>
        );
      }

     


      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


  const validate=()=>{
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["procedure_name"] || input["procedure_name"]==="" ) {
      isValid = false;
      errors["procedure_name"] = "Please enter procedure Name";
    }
    if (!input["procedure_type"] || input["procedure_type"].value==="" ) {
      isValid = false;
      errors["procedure_type"] = "Please enter procedure Type";
    }
    

     setError(currentstate=>({
          ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();

    
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
     const formData = new FormData();

     if (selectedImage) {
       formData.append('image', selectedImage);
       isEdited=true;
     }
     
     if (data.procedure_name !==props.data.procedure_name) {
       formData.append('procedure_name', data.procedure_name);
       isEdited=true;
     }
     
     if (data.procedure_type!==props.data.procedure_type) {
       formData.append('procedure_type', data.procedure_type);
       isEdited=true;
     }
 
     if(isEdited){
     axios
       .put(`${APIURL}/api/v1/staff/master-procedure-detail/${data.id}/`, formData , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          setCompleteShow(true);
          // setRefresh(true)
           
           //setSuccessShow(false)
         } else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
   

      }
    

}

const handleImageUpload = (event) => {
  const image = event.target.files[0];
  setData(prevData => ({...prevData, image: URL.createObjectURL(image)}));
  setSelectedImage(image);
  
};
    return (
        <>
        
    <h2 style={{marginTop:'0'}} className="title-of-page">Edit procedure</h2>
    <div style={{display:"flex" ,marginLeft:"180px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {data.image && (
         <div style={{ display: 'flex' }}>
          <p>Selected Image:</p>
          <img src={data.image} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
        <div style={{maxHeight:'1000px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Procedure Name: </label>
                    <input type='text' className='form-control title-flex' name='procedure_name' value={data.procedure_name} onChange={handleTitle} /></div>
                  
                </div>

                <div className="formField">
                  <div  style={{display:"flex"}}>
                    <label className='align-rental'>*Procedure Type: </label>
                    <input type='text' className='form-control title-flex' name='procedure_type' value={data.procedure_type} onChange={handleTitle} /></div>
                   
                </div>

            </div>

            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
        
{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}

{completeShow ? 

<CompletePopUp
show={completeShow}
onHide={() =>  {setCompleteShow(false)
props.modalClose()}}
/> 
: null}


            
        </div>
        </>
    )
}

export default EditProcedure;
