import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../Global";
import PhoneInput from "react-phone-input-2";
import { useAlert } from "react-alert";
function Addresspage() {
    const alert = useAlert();
   const[ change,setChange]=useState(false)
    const [id, setId] = useState()
    const [refresh, setRefresh] = useState(false)
    const [addressEdit, setAddressEdit] = useState(false);
    const [addressList, setAddressList] = useState([])
    const [addres, setAddress] = useState([])
    const [addressadd, setAddressAdd] = useState(false);
    const [isDefault, setDefault] = useState(false);
    const [contactNumber, setContactNumber] = useState("")
    const [code, setCode] = useState()
    const [countryCode, setCountryCode] = useState("us")
    const [addresNew, setAddressNew] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        landmark: "",
        pincode: "",
        state: "",
        house: "",
        city: "",

    })
    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        setAddressNew(current => ({
            ...current, [key]: val
        }))
    }
    const handleUpdate = () => {


        const tokenStr = sessionStorage.getItem("usertoken");

        let v2 = tokenStr.replace(/["]+/g, "");
        let dataTosend={}
        if(change){
            dataTosend = {
                apt_no: addresNew.house,
                code: code,
                first_name: addresNew.firstName,
                is_default: isDefault,
                landmark: addresNew.landmark,
                last_name: addresNew.lastName,
                mobile_number: contactNumber.slice((code+"").length),
                pincode: addresNew.pincode,
                city: addresNew.city,
                state: addresNew.state,
                province: addresNew.city,
    
            };
        } else{
         dataTosend = {
            apt_no: addresNew.house,
            is_default: isDefault,
            first_name: addresNew.firstName,           
            landmark: addresNew.landmark,
            last_name: addresNew.lastName,          
            pincode: addresNew.pincode,
            city: addresNew.city,
            state: addresNew.state,
            province: addresNew.city,

        };
    }
        axios
            .put(`${APIURL}/api/v1/patient/shipping-address-detail/${id}/ `, dataTosend, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    setRefresh(!refresh);
                    setAddressAdd(false);
                    setAddressNew({
                        firstName: "",
                        lastName: "",
                        phoneNumber: "",
                        landmark: "",
                        pincode: "",
                        state: "",
                        house: "",
                        city: "",
                    })
                    setChange(false)
                    setCountryCode("")
                    setContactNumber("")
                    setCode('')


                } else {
                    alert.error(res.data.message);
                }
            })
            .catch((err) => {
                // setErrorMsg("Error in submission");
            });

    };
    const handleActions = (item, key) => {


        const tokenStr = sessionStorage.getItem("usertoken");

        let v2 = tokenStr.replace(/["]+/g, "");
        let dataTosend = {}
        if (key === "def") {
            dataTosend = {
                is_default: true

            };
        }
        else {
            dataTosend = {
                is_delete: true

            }
        }
        axios
            .put(`${APIURL}/api/v1/patient/shipping-address-detail/${id}/ `, dataTosend, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success(res.data.message);
                    setRefresh(!refresh);
                    setAddressAdd(false);
                    setAddressNew({
                        firstName: "",
                        lastName: "",
                        phoneNumber: "",
                        landmark: "",
                        pincode: "",
                        state: "",
                        house: "",
                        city: "",
                    })
                    setCountryCode("")
                    setContactNumber("")
                    setCode('')


                } else {
                    alert.error(res.data.message);
                }
            })
            .catch((err) => {
                // setErrorMsg("Error in submission");
            });

    };

    const handleProceed = () => {


        const tokenStr = sessionStorage.getItem("usertoken");

        let v2 = tokenStr.replace(/["]+/g, "");

        const dataTosend = {
            apt_no: addresNew.house,
            code: code,
            first_name: addresNew.firstName,
            is_default: isDefault,
            landmark: addresNew.landmark,
            last_name: addresNew.lastName,
            mobile_number: contactNumber,
            pincode: addresNew.pincode,
            city: addresNew.city,
            state: addresNew.state,
            province: addresNew.city,

        };

        axios
            .post(`${APIURL}/api/v1/patient/shipping-address/ `, dataTosend, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    setRefresh(!refresh);
                    setAddressAdd(false);
                    setAddressNew({
                        firstName: "",
                        lastName: "",
                        phoneNumber: "",
                        landmark: "",
                        pincode: "",
                        state: "",
                        house: "",
                        city: "",
                    })
                    setCountryCode("")
                    setContactNumber("")
                    setCode('')


                } else {
                    alert.error(res.data.message);
                }
            })
            .catch((err) => {
                // setErrorMsg("Error in submission");
            });

    };

    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(/["]+/g, "");
        axios
            .get(`${APIURL}/api/v1/patient/shipping-address/ `, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {

                setAddressList(res.data.message);
                const filteredData = res.data.message.filter(x => {
                    return x.is_default === true
                })
                setAddress(filteredData)
            })
            .catch((err) => { });
    }, [refresh]);

    const handleChecked = () => {
        setDefault(!isDefault);
    };
    const handleEdit = (item) => {
        setAddressNew({
            firstName: item.first_name,
            lastName: item.last_name,
            landmark: item.landmark,
            pincode: item.pincode,
            state: item.state,
            house: item.apt_no,
            city: item.city
        })
        setDefault(item.is_default)
        setId(item.id)
        setContactNumber(item.code + "" + item.mobile_number + "");
        setAddressEdit(true)
        setAddressAdd(true)
    };
    const handlePhoneInput = (value, data) => {
        setChange(true)
        setCode(value.slice(0, data.dialCode.length));
        setContactNumber(value);
        setCountryCode(data.countryCode)
        // setChangePh(true)

    }


    return (
        <>
            <div className="form-button productsQuickView" id="productsQuickView" style={{ marginTop: "-58px", }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content ">
                        <div className="row ">
                            <div className="addresspart " >

                                <h5> Select Address </h5>


                                <div
                                    className="shipping "
                                    style={{ marginLeft: "22%", border: "2px solid #6BCBB8" }}
                                >

                                    <h6
                                        style={{
                                            fontSize: "15px",

                                            color: "#6F6F6F",

                                        }}
                                    > {" "}
                                        {addres.length > 0 ? <><span> {addres[0].first_name}{" "}{addres[0].last_name}</span><br />
                                            {addres[0].apt_no !== null && <span>{addres[0].apt_no},</span>}
                                            {addres[0].landmark !== null && <span>{" "}{addres[0].landmark},</span>}
                                            {addres[0].city !== null && <span>{" "}{addres[0].city},</span>}
                                            {addres[0].province !== null && <span>{" "}{addres[0].province},</span>}
                                            {addres[0].pincode !== null && <span>{" "}{addres[0].pincode},</span>}
                                            {addres[0].state !== null && <span>{" "}{addres[0].state}</span>}<br />
                                            {addres[0].mobile_number !== null && <span>Mobile:{addres[0].mobile_number}</span>}
                                        </>
                                            : ""}<br />
                                        
                                        <i
                                            class="fa fa-pencil-square"
                                            onClick={() => {
                                                handleEdit(addres[0])
                                            }}
                                            
                                            style={{ color: "#F0A639", marginLeft: "78%" }}
                                            aria-hidden="true"
                                        ></i>
                                        <i
                                            class="fa fa-trash"
                                            onClick={() => {
                                                setId(addres[0].id)
                                                handleActions(addres[0], "del")
                                            }}
                                            style={{ color: "red", marginLeft: "2%" }}
                                            aria-hidden="true"
                                        ></i>

                                    </h6>


                                </div><br />
                                {addressList.map((item) => {
                                    return (<>
                                        {!item.is_default && <div
                                            className="shipping"
                                            style={{ flex: "2 2 70%", marginLeft: "22%" }}
                                        >

                                            <h6
                                                style={{
                                                    fontSize: "15px",

                                                    color: "#6F6F6F",
                                                }}
                                            >
                                                <><span> {item.first_name}{" "}{item.last_name}</span><br />
                                                    {item.apt_no !== null && <span>{item.apt_no},</span>}
                                                    {item.landmark !== null && <span>{" "}{item.landmark},</span>}
                                                    {item.city !== null && <span>{" "}{item.city},</span>}
                                                    {item.province !== null && <span>{" "}{item.province},</span>}
                                                    {item.pincode !== null && <span>{" "}{item.pincode},</span>}
                                                    {item.state !== null && <span>{" "}{item.state}</span>}<br />
                                                    {item.mobile_number !== null && <span>Mobile:{item.mobile_number}</span>}
                                                </><br />
                                                <buttin
                                                 className="def-button"
                                                    onClick={() => {
                                                        setId(item.id)
                                                        handleActions(item, "def")
                                                    }}
                                                    style={{ color: "#F0A639", marginLeft: "50%" }}>
                                                    Set as Default
                                                </buttin>
                                                <i
                                                
                                                    class="fa fa-pencil-square"
                                                    onClick={() => {
                                                        handleEdit(item)
                                                    }}
                                                    style={{ color: "#F0A639", marginLeft: "2%" }}
                                                    aria-hidden="true"
                                                ></i>

                                                <i
                                                    class="fa fa-trash"
                                                    onClick={() => {
                                                        setId(item.id)
                                                        handleActions(item, "del")
                                                    }}
                                                    style={{ color: "red", marginLeft: "2%" }}
                                                    aria-hidden="true"
                                                ></i>
                                            </h6>

                                        </div>}<br /></>

                                    )
                                })}





                            </div>
                            {!addressadd && <div><button className='cons-button' onClick={() => setAddressAdd(true)}>Add New </button><br /></div>}

                            {addressadd ? <div className="addAddress" style={{ marginLeft: '17%' }}>
                                <button
                                    style={{ backgroundColor: "white", marginLeft: "95%" }}
                                    variant="secondary"
                                    onClick={() => {setAddressAdd(false)}}
                                >
                                    <i
                                        class="fa fa-times fa-lg"
                                        style={{ color: "#1269B9" }}
                                        aria-hidden="true"
                                    ></i>
                                </button>
                                <div style={{marginTop:"2%"}}> <input placeholder="First Name" type='text' className='gridinputprop col-5' name='firstName' value={addresNew.firstName} onChange={handleChange} />
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <input placeholder="Last Name" type='text' className='gridinputprop col-5' name='lastName' value={addresNew.lastName} onChange={handleChange} /></div><br />
                                <div>
                                    <PhoneInput
                                        inputProps={{}}
                                        country={countryCode}
                                        onChange={handlePhoneInput}
                                        value={contactNumber}
                                        dropdownStyle={{ display: "none" }}

                                    // onBlur={handleCheckExistingPhone}
                                    />

                                    {/* <input type='text' placeholder="MobileNumber" className='gridinputprop' name='phoneNumber' value={addresNew.phoneNumber} onChange={handleChange} /> */}
                                </div><br />
                                <div><input type='text' placeholder="Landmark" className='gridinputprop' name='landmark' value={addresNew.landmark} onChange={handleChange} /></div><br />
                                <div> <input placeholder="Pincode" type='text' className='gridinputprop col-5' name='pincode' value={addresNew.pincode} onChange={handleChange} />
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <input type='text' placeholder="State" className='gridinputprop col-5' name='state' value={addresNew.state} onChange={handleChange} /></div><br />
                                <div><input placeholder="House no/House Name" type='text' className='gridinputprop' name='house' value={addresNew.house} onChange={handleChange} /></div><br />
                                <div><input placeholder="City" type='text' className='gridinputprop' name='city' value={addresNew.city} onChange={handleChange} /></div><br />
                                <div className="gridinputprop" style={{ border: "none" }}><input type="checkbox"
                                    id="returnable"
                                    name="returnable"
                                    value={isDefault}
                                    onChange={handleChecked}

                                />
                                    <label style={{ fontSize: "small" }}>&nbsp;Keep as default</label>
                                    <button style={{ marginLeft: "42%" }} onClick={addressEdit ? handleUpdate : handleProceed} className='cons-button'>{addressEdit ? "Update" : " Save"}</button></div>


                            </div>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
           
        </>

    )
}
export default Addresspage