import React, { useState,useEffect } from "react";
import { createContext } from "react";
import { APIURL } from "../../Global";
import axios from "axios";
import { useAlert } from "react-alert";
export const Cart = createContext();
export const CartContext = createContext();
export const CartDelete = createContext();
//export const FavFromQuickView = createContext();
function AddToCartContext({ children }) {
  const [cartAdd, setCartAdd] = useState(false);
  const [distance, setDistance] = useState(0.0);
  const [cartData, setCartData] = useState([]);
  const [cartItems1, setCartItems1] = useState([]);
  const [cartCount, setCartCount] = useState(0);

  const alert = useAlert();
//   const [cartDelete, setCartDelete] = useState(false);
 // const [favFromQuickView, setFavFromQuickView] = useState(false);
 const [cartItems, setCartItems] = useState(() => {
  const existingCartItems = sessionStorage.getItem("cartItems");
  return existingCartItems ? JSON.parse(existingCartItems) : [];
});
 const[refresh,setRefresh]=useState(false)
 const [login_datas, setLogin_datas] = useState(
  JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null
);



useEffect(() => {
  // ...

  if (login_datas) {
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");
    // Fetch cart items for logged-in user
    axios
      .get(`${APIURL}/api/v1/patient/cart/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        console.log(res.data);
        setCartItems1(res.data.cart_items);
      })
      .catch((err) => {
        // Handle error if necessary
      });
  } else {
    // Fetch cart items from sessionStorage for non-logged-in user
    const existingCartItems = JSON.parse(sessionStorage.getItem("cartItems")) || [];
    setCartItems1(existingCartItems);
  }

  // ...
}, [login_datas,]);

const refreshPage = () => {
       
  window.location.reload(false);
  window.scrollTo(0,0);
};

const updateCartCount = () => {
  const tokenString = sessionStorage.getItem("usertoken");
  let v2 = tokenString.replace(/["]+/g, "");

  axios
    .get(`${APIURL}/api/v1/patient/cart/`, {
      headers: { Authorization: "Token " + v2 },
    })
    .then((res) => {
      console.log(res.data);
      if (login_datas) {
        setCartItems1(res.data.cart_items);
      } else {
        setCartItems(res.data.cart_items);
      }
    })
    .catch((err) => {
      // Handle error if necessary
    });
};




 useEffect(() => {
  const existingCartItems = JSON.parse(sessionStorage.getItem("cartItems")) || [];
  setCartItems(existingCartItems);
}, []);

const calculateDistance = (latt1, latt2, long1, long2) => {

  var R = 6371; // Radius of the earth in km
  const lat1 = parseFloat(latt1)
  const lat2 = parseFloat(latt2)
  const lon1 = parseFloat(long1)
  const lon2 = parseFloat(long2)
  var dLat = deg2rad(lat2 - lat1);  // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  // return d;
  setDistance(parseFloat(d).toFixed(4));
  console.log('THE DISTANCE', d)

};
const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}
// const updateCartData = () => {
//   const tokenString = sessionStorage.getItem("usertoken");


//   let v2 = tokenString.replace(
//       /["]+/g,
//       ""
//   );
//   // Make API call to retrieve updated cart data
//   axios.get(`${APIURL}/api/v1/patient/cart/`, {
//     headers: { Authorization: "Token " + v2 },
//   })
//     .then((res) => {
//       setCartData(res.data.cart_items);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }

const handleDelete = (item) => {
  const tokenString = sessionStorage.getItem("usertoken");
  let str = tokenString.replace(/["]+/g, "");

  axios
    .delete(`${APIURL}/api/v1/patient/cart-detail/${item}/`, {
      headers: {
        Authorization: "Token " + str,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 204) {
        alert.success("Deleted Successfully");
        // Fetch updated cart items and update cart count
        updateCartCount();
        window.location.reload();
      }
    })
    .catch((err) => {
      // Handle error if necessary
    });
};

const handleAddToCart = (item) => {
  debugger;
  const today = new Date();
  const cartItem = {
    item: item,
    date: today.toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format
  };

  if (login_datas) {
    // Handle the logic for adding item to cart when the user is logged in
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");

    axios.get(`${APIURL}/api/v1/patient/service-provider-detail/?hospital_id=${item.hospital_id ? item.hospital_id : item.hospital}`, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        console.log(res.data);
        let hospital = res.data.hosp_details;
        if (hospital.latitude && hospital.longitude) {
          console.log('YES THE COORDINATES', login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
          calculateDistance(login_datas.latitude, hospital.latitude, login_datas.longitude, hospital.longitude);
        }
      });

    const dataTosend = {
      item_service: item.item_service ?item.item_service : item.id,
      item_due_date: item.date ? item.date : new Date().toISOString().split("T")[0],
      item_service_priority: "critical",
      geo_distance: distance,
      item_quantity: item.item_quantity ? item.item_quantity :"1"
    };

    axios.post(`${APIURL}/api/v1/patient/cart/`, dataTosend, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + v2,
      },
    })
      .then((res) => {
        if (res.data.status === "success") {
          alert.success("added to cart");
          setCartItems1((prevItems) => [...prevItems, cartItem]);
          setCartCount((prevCount) => prevCount + 1);
          //updateCartData();
       
     
          // setTimeout(refreshPage, 1000);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => {
        // setErrorMsg("Error in submission");
      });
  } else {
    // Handle the logic for adding item to cart when the user is not logged in
    const updatedCartItems = [...cartItems, cartItem];
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    setCartItems(updatedCartItems);
    setCartCount((prevCount) => prevCount + 1);
    // Show alert message
    alert.success("Added to cart");
  }
};
  return (
    <CartContext.Provider value={{ cartAdd, setCartAdd, cartItems,cartItems1 , handleAddToCart,handleDelete, cartCount, setCartCount }}>
    
     
          {children}
      
          </CartContext.Provider>
  );
}

export default AddToCartContext;