import { JitsiMeeting } from '@jitsi/react-sdk';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal,Button } from "react-bootstrap";
const VideoCall = () => {
    const { state } = useLocation()
    const otherUser = state.patient;
    const id=state.id;
    const apiRef = useRef();
    const [logItems, updateLog] = useState([]);
    const [showNew, toggleShowNew] = useState(false);
    // const [sharePopup, setSharePopup] = useState(true);
    const [knockingParticipants, updateKnockingParticipants] = useState([]);
    let room=""
    const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
    const user = 
        JSON.parse(sessionStorage.getItem("logindatas"))
            ? JSON.parse(sessionStorage.getItem("logindatas"))
            : null
  if(user.user_type==="hospital_doctor"){
     room =
     'B^#'+otherUser+"A%$"+day+"$%#"+month+"RSW"+year+"#$#"+user.user_id}
   else if(user.user_type==="patient"){
   room = 'B^#'+user.user_id+"A%$"+day+"$%#"+month+"RSW"+year+"#$#"+otherUser.id}
console.log("uuuuuu",otherUser)

    const printEventOutput = payload => {
        updateLog(items => [...items, JSON.stringify(payload)]);
    };

    const handleAudioStatusChange = (payload, feature) => {
        if (payload.muted) {
            updateLog(items => [...items, `${feature} off`])
        } else {
            updateLog(items => [...items, `${feature} on`])
        }
    };

    const handleChatUpdates = payload => {
        if (payload.isOpen || !payload.unreadCount) {
            return;
        }
        apiRef.current.executeCommand('toggleChat');
        updateLog(items => [...items, `you have ${payload.unreadCount} unread messages`])
    };

    const handleKnockingParticipant = payload => {
        updateLog(items => [...items, JSON.stringify(payload)]);
        updateKnockingParticipants(participants => [...participants, payload?.participant])
    };



    const handleJitsiIFrameRef1 = iframeRef => {
        // iframeRef.style.border = '10px solid #3d3d3d';
        iframeRef.style.background = '#3d3d3d';
        iframeRef.style.height = '450px';
        iframeRef.style.marginBottom = '20px';
    };

   

    const handleApiReady = apiObj => {
        apiRef.current = apiObj;
        apiRef.current.on('knockingParticipant', handleKnockingParticipant);
        apiRef.current.on('audioMuteStatusChanged', payload => handleAudioStatusChange(payload, 'audio'));
        apiRef.current.on('videoMuteStatusChanged', payload => handleAudioStatusChange(payload, 'video'));
        apiRef.current.on('raiseHandUpdated', printEventOutput);
        apiRef.current.on('titleViewChanged', printEventOutput);
        apiRef.current.on('chatUpdated', handleChatUpdates);
        apiRef.current.on('knockingParticipant', handleKnockingParticipant);
    };

    // const handleReadyToClose = () => {
    //     /* eslint-disable-next-line no-alert */
    //     alert('Ready to close...');
    // };

    // const generateRoomName= () => `FelixaCareRoomNo${Math.random() * 100}-${Date.now()}`


    // const hostname = window && window.location && window.location.hostname;
    const renderSpinner = () => (
        <div style={{
            fontFamily: 'sans-serif',
            textAlign: 'center'
        }}>
            Loading..
        </div>
    );
    const handleChange = (e) => {
        const val = e.target.value;

        console.log(val)
    }
    const ShareLinkPopup = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
               
                // style={{ opacity: "0.4"}}
            >
                <Modal.Body >

<h6 style={{display:"flex"}}> meetingLink:<span style={{color:"blue"}}>&nbsp;https://meet.jit.si/ {room}</span></h6>
                    <div style={{ display: "flex",  }}><h6 style={{ marginTop: "1px" ,marginLeft:"90px"}} className="">Share via: <span>&nbsp;&nbsp;</span></h6>

                        <div className="radio-options">
                            <input
                                style={{ marginTop: "1%" }}
                                type="radio"
                                id="Hospital"
                                name="health"
                                value="med_name"
                                //   checked={search === "med_name"}
                                onChange={handleChange}
                            /><span>&nbsp;</span>
                            <i style={{ color: "blue" }} class="fa fa-commenting-o fa-lg" aria-hidden="true"></i>

                        </div>

                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <div className="radio-options">
                            <input
                                style={{ marginTop: "1%" }}
                                type="radio"
                                id="Hospital"
                                name="health"
                                value="generic_name"
                                //   checked={search === "generic_name"}
                                onChange={handleChange}
                            /><span>&nbsp;</span>
                            <i style={{ color: "green" }} class="fa fa-whatsapp fa-lg" aria-hidden="true"></i>


                        </div>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <div className="radio-options">
                            <input
                                style={{ marginTop: "1%" }}
                                type="radio"
                                id="Pharmacy"
                                name="health"
                                value="composition"
                                //   checked={search === "composition"}
                                onChange={handleChange}
                            /><span>&nbsp;</span>
                            <i style={{ color: "red" }} class="fa fa-envelope-o fa-lg" aria-hidden="true"></i>


                        </div>
                    </div>

<br/>

                    <button
                        style={{ marginLeft: "40%", fontSize: "15px", backgroundColor: "var(--theme-blue)" }}
                        type="button"
                        className="btn-primary btn-square"
                        onClick={props.onHide}
                    >
                        {" "}
                        Share Link{" "}
                    </button>


                </Modal.Body>
            </Modal>
        );
    };

    return (
        <div style={{backgroundColor:"black"}}>
{/* <button style={{float:"right", marginRight:"1%",marginTop:"0.5%",paddingLeft:"1.5%",paddingRight:"1%", color:"white", backgroundColor:"blue"}} onClick={() => setSharePopup(true)}className="edit-button">
								<i

									variant="contained"
									style={{ marginLeft: "-8px"}}
									class="fa fa-share-alt"
									tabIndex="0"
								>
									</i>Share</button> */}
          
            {/* <CopyToClipboard text={"https://meet.jit.si/" + room}
                onCopy={() => console.log("copied")}>
                <button>Share link</button>
            </CopyToClipboard> */}

            <JitsiMeeting
                enableClosePage={true}
                //   domain={"https://uni.cianlogic.com/videocall"}
                roomName={room}
                spinner={renderSpinner}
                userInfo={{
                     displayName:user.name.charAt(0).toUpperCase() + user.name.slice(1) 
                }}

                configOverwrite={{
                    subject: 'Video Consultation',
                    hideConferenceSubject: false
                }}
                onApiReady={externalApi => handleApiReady(externalApi)}
                // onReadyToClose = { handleReadyToClose }
                getIFrameRef={handleJitsiIFrameRef1} />
            {/* {sharePopup ? (
                <ShareLinkPopup
                    show={sharePopup}
                    onHide={() => {
                        setSharePopup(false);
                    }}
                />
            ) : (
                ""
            )} */}
        </div>

    );
};

export default VideoCall;