import React,{useState,useEffect} from 'react';
import axios from "axios";
import "./Diagnosis.css";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

import {APIURL} from "../../Global";

function Diagnosis(props){
 // const [id] = useState(props.match.params.id);
  const [comorbidities,setComorbidities]=useState([]);
 const [comorbValue,setComorbValue]=useState('');
 const [reRender,setReRender]=useState(false);
 const[successShow, setSuccessShow]= useState(false)
  const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);
    const [modalShow, setModalShow]= useState(false)
    const [refresh, setRefresh]= useState(false)
           const [,setSubmitMsg] = useState("");
           const id = props.id;
           const [,setGetError]=useState('');
           const [deleteIndex, setDeleteIndex]= useState('');
  
  const [deleteShow, setDeleteShow]= useState(false)

useEffect(()=> {

  const tokenString= sessionStorage.getItem('usertoken');
   
    let str= tokenString.replace(/["]+/g, '')  
           
     
     
   
     axios.get(`${APIURL}/api/v1/doctor/diagnosis-list/?patient_id=${id}`, { headers: {
       
       "Authorization" : 'Token '+str  }})
     .then(res=>{
      
       if(res.status==200){
        
          setComorbidities(res.data.message)
 
       }

       else {
        setErrorSubmit(true);     
      }
       setDeleteIndex('');
       setDeleteShow(false);
 
    
     })
  
     .catch(err=>{
 
 })

},[refresh])

const handleChange=(e)=>{
	e.preventDefault();
	setComorbValue(e.target.value);

}

const DeleteConfirmPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Are you sure you want to delete?</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

        <Button variant="danger" onClick={()=>{handleDelete(deleteIndex)}}> Confirm</Button>

       

      </Modal.Footer>
    </Modal>
  );
}

const validate = ()=> {
  let isValid= true
  let comorbArray= comorbidities
  let value = comorbValue
  if(comorbValue!=='' && comorbValue!== null){

    for(let i=0; i<comorbArray.length; i++){
      if(comorbArray[i].diagnosis.toLowerCase() !== value.toLowerCase()){
        isValid = true
      }
      else {
        isValid = false
        setModalShow(true)
        break;
      }
    }
  }

  return isValid
}

const handleAdd=(e)=>{
  ;

	e.preventDefault();

  
  if(comorbValue!=='' && comorbValue!== null && validate()){
	const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
     let updatedArray = comorbidities;
     const id = props.id;

    // updatedArray.push(comorbValue);    
  
  const dataToSend= {
  
    diagnosis: comorbValue,                                  
    patient: id


  }
 

    axios.post(`${APIURL}/api/v1/doctor/diagnosis-list/`, dataToSend, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
      
        
      if(res.status==200){
        setComorbValue('');
        setSuccessShow(true)
      
   
      }
else{
    //setComorbValue('');
   setErrorMsg(res.data.message);
              setErrorSubmit(true);
              setRefresh(!refresh);
}

    })
    .catch(err=>{
 
              setComorbValue('');
              setSubmitMsg("error");
              setErrorSubmit(true);
})
 }
 else {
   setErrorMsg("please type any findings");
   setErrorSubmit(true)
 }
}

const handleDelete= (item)=> {
  // ;

  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '')  ;

  //  const dataToSend = {
  //   "id" : item
  // }

    axios.delete(`${APIURL}/api/v1/doctor/diagnosis-details/${item}/`, { headers: {
      
      "Authorization" : 'Token '+str  }})
    .then(res=>{
      
        
      if(res.status==204){

      setRefresh(!refresh)
      
      
   
      }
else{

   setErrorMsg(res.data.message);
              setErrorSubmit(true);
}


setDeleteIndex('');
setDeleteShow(false);

    })
    .catch(err=>{

              setSubmitMsg("error");
              setErrorSubmit(true);
})

}

const SubmitPopUp = (props) => {
  return(
  <Modal
  {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <h5 className='text-success'>Findings added Successfully!!</h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>          

      </Modal.Footer>
    </Modal>
  )
}

const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg!=='' ? "" + ''+ errorMsg : "Error in Data Submission. Please try again!" } </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}

const ErrorPopUp = (props) => {
  return(
  <Modal
  {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <h5 style={{color: "red"}}>This diagnosis is already Present </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>          

      </Modal.Footer>
    </Modal>
  )
}

const comorbData= comorbidities.length>0? comorbidities.map((item,index)=>{
  return (<div key={item.id}className="comorbidity-row comorb-item">
         <h6>{item.diagnosis} </h6>
        <i onClick={()=>{
          
setDeleteIndex(item.id);
setDeleteShow(true);
        }} type="button"  className="fas fa-times close-button" >  </i> 
         </div>
  	)
}) 
: "No Content" ;

return (<>

<>
 <div className="comorb-holder">
 
  {comorbData}        
    
    <br/>
    </div>
<div className="findings-body-content" style={{padding:"5%"}}>
                      {/* <input type="text" maxLength="150" placeholder="Add Comorbidity" value={comorbValue} onChange={handleChange} className="form-control"/>  */}

                      <textarea rows="4" columns='50' className="textbox-style" maxLength="150" value={comorbValue} placeholder="Add Findings..." onChange={handleChange} />   </div><br/>
                     <div className='form-button'> <button style={{alignSelf:'center'}} type="submit" onClick={handleAdd} className="btn btn-smallest btn-square btn-primary btn-col btn-col btn-add-comorbidity "> Add </button></div>                     
                      {/* <i style={{alignSelf:'center'}} type="submit" onClick={handleAdd} className="fas fa-plus-circle fa-2x findings-add-button"></i>  */}
           


      {errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  setErrorSubmit(false)}
       
      />: ''
    }

    {
    deleteIndex!=='' ?
    <DeleteConfirmPopup
        show={deleteShow}
        onHide={() =>  {setDeleteShow(false);
                       setDeleteIndex('');}}
       
      />: ''
    }

{
         modalShow ?
        <ErrorPopUp
            show={modalShow}
            onHide= { ()=> setModalShow(false)}  />
            : null
      }

{
         successShow ?
        <SubmitPopUp
            show={successShow}
            onHide= {()=> {setSuccessShow(false); setRefresh(!refresh); } } />
            : null
      }
 </>

             </>

)
}
export default Diagnosis;