import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, Navigate } from "react-router-dom";
import "./login.css";
import "./log.css";
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import { LoginContext } from "../contexts/LoginContext";
import { APIURL } from "../../Global";
import axios from "axios";
import background from "../../assets/images/bg.jpg";
import { HospLoginContext } from "../contexts/HospitalLoginContext";
import AuthUtil from "./AuthUtil";
import home from "../../assets/images/newww.png";
// import orgData from "../../felixaconfig.json"
function LoginPage() {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [, setHospData] = useContext(HospLoginContext);
  //const [, setHospDocData] = useContext(HospDrLoginContext);
  const location = useLocation();
  const [, setLoginDatas] = useContext(LoginContext);
  const [, setIsLoggedIn] = useContext(LoginStatusContext);
  const [error, setError] = useState(false);
  const [serveCount, setServeCount] = useState("");
  const [aggregator, setAggregator] = useState('');
  const [orgImg, setOrgImg] = useState("")
  const [orgFlag, setOrgFlag] = useState(false)

  const [regcount, setRegCount] = useState('');
  useEffect(() => {
    setLoginDatas(null);
    setIsLoggedIn(false);
  }, []);


  useEffect(() => {
    ;
    axios.get(`${APIURL}/api/v1/account/customer-info/`,

    )
      .then(res => {
        if (res.data.status === "success") {
          setServeCount(Number(res.data['service provider count']))
          sessionStorage.setItem("project_type", res.data.project_type);
          setAggregator(res.data.aggregation_type);
          setOrgImg(res.data.organization_logo)
          if (res.data.organization_logo !== APIURL + "/media/null" && res.data.organization_logo !== "") {
            setOrgFlag(true)
          }
          setRegCount(res.data.registered_sp_count)

        }


      })
  }, []);

  const handleLogin = async (e) => {
    console.log(serveCount, regcount)
    e.preventDefault();
    await apiCall();
  };

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === "loginUsername") {
      setLoginUsername(val);
    }
    if (key === "loginPassword") {
      setLoginPassword(val);
    }
  };
  const togglePasswordVisiblity = (e) => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const apiCall = () => {
    axios
      .post(
        `${APIURL}/api/v1/account/login/`,
        {
          username: loginUsername,
          password: loginPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          sessionStorage.setItem("logindatas", JSON.stringify(res.data));

          setLoginDatas(res.data);
          /* Store Token */
          sessionStorage.setItem("usertoken", JSON.stringify(res.data.token));

          sessionStorage.setItem("usertype", res.data.user_type);
          sessionStorage.setItem("userid", res.data.user_id);
          sessionStorage.setItem("hospId", res.data.hospital_id);
          sessionStorage.setItem("role", res.data.role);
          sessionStorage.setItem("aggr_type", aggregator);
          if (res.data.verification_status === "pending") {
            sessionStorage.setItem("hosp_data", JSON.stringify(res.data));
            sessionStorage.setItem("hospital_pending", "true");
            setHospData(res.data);
          }
          // }
        } else {
          setLoginUsername("");
          setLoginPassword("");
          setError(res.data.message);
        }
      })
      .catch(() => {
        setLoginUsername("");
        setLoginPassword("");
        setError("There was some error in login");
      });
  };

  const authUrl = AuthUtil.validateAuth();
  //When authentication is valid.
  if (authUrl) {
    return <Navigate to={authUrl} state={{ from: location }} />;
  }

  return (
    <>
      <div className="App">
        <div className="appAside">
          <article class="card1">
            <img src={orgFlag ? orgImg : home} class="card__img" />
            <div class="card__data">
              <h2 class="card__title"><b>3 DOC</b></h2>
              <p class="card__description">Remote Care Platform, It Is Easy And Quick...<br />
                Convenient Home Care Technology Solution.</p>
              <a href=" " class="card__btn">Learn more</a>
            </div>
          </article>

        </div>
        <div className="appForm">
          <div className="formData">
            <div className="formdata1">
              <h2 className="name">Login</h2>
            </div>
            <div className="formFields">
              <div className="formField">
                <input
                  type="text"
                  className="formFieldInput"
                  name="loginUsername"
                  placeholder={"Username/E-mail Address"}
                  value={loginUsername}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="formField">
                <div
                  className=""
                  style={{ backgroundColor: "#e8f0fe", position: 'relative', borderRadius: 20 }}
                >
                  <input
                    style={{ backgroundColor: "#e8f0fe" }}
                    type={passwordType}
                    className="form-control box1"
                    value={loginPassword}
                    name="loginPassword"
                    placeholder={"Password"}
                    onChange={handleChange}
                    required
                  />
                  <span style={{ float: "right", top: 10, right: 10, position: 'absolute', color: 'black' }}>
                    {/* <button className="btn btn-outline-primary1" onClick={togglePasswordVisiblity}> */}
                    {passwordType === "password" ? (
                      <i
                        className="fa fa-eye-slash"
                        onClick={togglePasswordVisiblity}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye"
                        onClick={togglePasswordVisiblity}
                      ></i>
                    )}
                    {/* </button> */}
                  </span>
                </div>
              </div>

              <div className="forgot-password">
                <Link to="/forgot-password">Forgot password?</Link>
              </div>

              <div className="formField formButtonLayout">
                <button className="formFieldButton" onClick={handleLogin} style={{ cursor: "pointer !important" }}>
                  Sign In
                </button>


                {regcount < serveCount ?
                  <Link
                    to="/signup"
                    className="formFieldButton"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    Create an account{" "}
                  </Link> :
                  <Link
                    to="/ErrorNotification"
                    className="formFieldButton"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    Create an account{" "}
                  </Link>}
              </div>
              <div className="formField">
                {error ? (
                  <div>
                    <p className="login-error" style={{ color: "red" }}>
                      {"Error"}:{" "}
                      {error === "Invalid credentials"
                        ? "Invalid credentials"
                        : error === "There was some error in login"
                          ? "There was some error in login"
                          : error}{" "}
                      <br />
                      {"Please try again!"}
                    </p>{" "}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="headerdata1" />
    </>
  );
}

export default LoginPage;
