import React, {useState,useRef,useEffect} from 'react'
 import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function AddSymptoms(props) {
    // const [obsData, setObsData]= useState('')
  // const [observations, setObservations] = useState([]);

  const [symptoms, setSymptoms] = useState([]);
  const[sympData,setSympData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [display, setDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorShow, setErrorShow] = useState(false);
    const [modalShow, setModalShow]=useState(false)
    // const [errorShow, setErrorShow]= useState(false)


    const handleClick = (item) => {
      initialRender.current = true;
      setSearchTerm(item.symptom_name);
      setSympData(item.symptom_name);
      // setPatientDetails(item)
  
      setDisplay(false);
      // setAllData([])
      // setRefresh(!refresh)
    };
  
    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };

    const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setSymptoms([]);
      setSympData("");
      setDisplay(false);
    } else {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      axios
        .get(`${APIURL}/api/v1/doctor/symptoms/?search=${searchTerm}`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          console.log(res);
          if (res && res.data) {
            setSymptoms(res.data);

            setDisplay(true);
            // setTotalPages(res.data.total_pages)
            // setCurrentPage(res.data.page)
            // setTotalRecords(res.data.total_records)
          } else {
          }
        })
        .catch((error) => {});
    }
  }, [searchTerm]);


    const handleSave = ()=> {
    if (searchTerm !=="" && searchTerm !== null) {

        const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               "symptoms": searchTerm,
               "patient_id": id
           }
     
     
       
         axios.post(`${APIURL}/api/v1/patient/symptoms-list/${id}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
         
            setModalShow(true)
       }
       else{
        setErrorMessage(res.data.message);

        setErrorShow(true)
        
       }
     
         })
         .catch(err=>{
       
          setErrorShow(true)
     })

    }else {
      setErrorShow(true);
    }
  }

    const SubmitPopup =(props)=>{
    

      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className='text-success'>Symptoms added  Successfully !!</h5>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>
    
           
           
    
          </Modal.Footer>
        </Modal>
      );
    }

    const ErrorPopup =(props)=>{
  

      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 style={{color: "red"}}> {
          errorMessage === "" ? "Please Select a Valid symptom!" : errorMessage
  }</h5>
        </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>
    
           
           
    
          </Modal.Footer>
        </Modal>
      );
    }

    return (
        <div className='flex-col'>        
    
      <div className="comorbidities-body-content"  style={{display:"flex"}}>
        <input
          type="text"
          maxLength="100"
          placeholder="Add Symptoms"
          value={searchTerm}
          onChange={handleSearchChange}
          // value={comorbValue}

          // onChange={handleChange}

          className="form-control"
        />
<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <button
          type="submit"
          className="btn btn-smallest btn-square btn-primary btn-col btn-col btn-add-comorbidity"
          onClick={handleSave}
        >
          {" "}
          Save{" "}
        </button>
      </div>
                {/* <textarea 
                type="text" 
                className="form-group text-area remark-add"
                value= {obsData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setObsData(e.target.value)
          } }
            autoComplete="off"
                />
            <button disabled={obsData===''} onClick={handleSave} className='btn btn-primary btn-col'>Save</button>
 */}

{display ? (
        <div className="suggest-display adjust-dimensions comor-auto-suggest ">
          {symptoms.length > 0 ? (
            symptoms.map((item) => {
              return (
                <p
                  onClick={() => handleClick(item)}
                  className="suggest-item text-secondary"
                >
                  {item.symptom_name}
                </p>
              );
            })
          ) : (
            <h6 className="text-muted">No symptoms found !!</h6>
          )}
        </div>
      ) : null}


            {
      modalShow ? 
      <SubmitPopup
        show={modalShow}
        onHide= {() => { setModalShow(false); props.handleAdd();    
        }}
      /> : null
    }

{
      errorShow ? 
      <ErrorPopup
        show={errorShow}
        onHide= {() => { setErrorShow(false)     
        }}
      /> : null
    }
        </div>
    )
}

export default AddSymptoms
