

import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, Navigate, NavLink } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import 'font-awesome/css/font-awesome.min.css';
//import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import "./labsp.css";
//import Navbar from "../common/Navbar"
//mport Footer from "../common/Footer";



function LabDashboard() {
  const [loginDatas] = useContext(LoginContext);


  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <div >

      <div className="home">

        <h2 className="title-of-page"> {login_datas.name} </h2>
        <h4 className="subtitle-of-page">{login_datas.location}</h4>

        <div className="home-container">
       
        

          <div className="home-tiles"

            onClick={() => navigate("/add-bdo")}>
            <i className="fa fa-user home-icon">
            </i>
            <h5 className="each-title">Add BDO</h5>

          </div>

          <div className="home-tiles"

            onClick={() => navigate("/bdo-list")}>
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">BDO List</h5>

          </div>
          <div className="home-tiles"

            onClick={() => navigate("/add-sda")}>
            <i className="fa fa-user home-icon">
            </i>
            <h5 className="each-title">Add SDA</h5>

          </div>

          <div className="home-tiles"

            onClick={() => navigate("/sda-list")}>
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">SDA List</h5>

          </div>
        

          <div className="home-tiles"

            onClick={() => navigate("/add-labuser")}>
            <i className="fa fa-user home-icon">
            </i>
            <h5 className="each-title">Add Lab User</h5>

          </div>
          <div className="home-tiles"

            onClick={() => navigate("/labusers-list")}>
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">Lab Users List</h5>

          </div> 




        </div>


      

      </div>

    </div>

  );
}

export default LabDashboard;