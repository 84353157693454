import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, NavLink } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import { Modal } from "react-bootstrap";
import flag from "./assets/img/flag/us-flag.png";
import logo1 from "../../assets/images/felixa_logo.png";
import logo2 from "../../assets/images/logo_name.png";
import prod1 from "./assets/img/products-img1.jpg"
import prod2 from "./assets/img/products-img2.jpg"
import contact from "./assets/img/contact-1.png"
import PatientNav from "./PatientNav";
import PatientFooter from "./PatientFooter";
import { APIURL } from "../../Global";
import { Badge } from 'react-bootstrap';
import prod5 from "./assets/img/products-img5.jpg"
import cat1 from "./assets/img/categories-img1.png"
import cat2 from "./assets/img/categories-img2.png"
import cat3 from "./assets/img/categories-img3.png"
import cat4 from "./assets/img/categories-img4.png"
import cat5 from "./assets/img/categories-img5.png"
import cat6 from "./assets/img/categories-img6.png"
import ban1 from "./assets/img/banner-img1.png"
import ban2 from "./assets/img/banner-img2.png"
import AddToCartBestSell from "./AddtoCartBestSell"
import ban3 from "./assets/img/banner-img3.png"
import ban4 from "./assets/img/banner-categories-img1.jpg"
import ban5 from "./assets/img/banner-categories-img2.jpg"

import masterlab from "./assets/img/labtest.png"
import procedure from "./assets/img/procedure.png"
import consumable from "./assets/img/consumable.png"
import medicine from "./assets/img/medicine.png"
import scan from "./assets/img/scan.png"
import misc from "./assets/img/misc.png"
import logo3 from "./assets/img/intpurple.png"
function NewArrivalDeals({ setShowNavbarAndFooter }) {
  const [promotions, setPromotions] = React.useState([]);
  const [cartdata, setCartdata] = useState("");
  const [cartShow, setCartShow] = useState(false)
  const [showDetails, setShowDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => { setShowNavbarAndFooter(false); return () => setShowNavbarAndFooter(true); }, [setShowNavbarAndFooter]);


  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/patient/list-promotions/?promo_type=new_arrival`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {

          const datas = res.data.items;
          console.log(datas);
          setPromotions(datas);
          //console.log(featureList)
        } else {
          //setLoading(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  }, []);

  const handleAddToCart = (item) => {

    let id = item.id ? item.id : item.item_service;



    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/patient/hospital-services-detail/${id}/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {

          const datas = res.data.data;
          console.log(datas);
          setCartdata(datas);
          setShowDetails(false);
          setCartShow(true);

          //console.log(featureList)
        } else {
          //setLoading(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  }

  const HandleClose = () => {
    setCartShow(false)
  }


  const handleQuickView = (item) => {


    let id = item.item_service;



    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/patient/hospital-services-detail/${id}/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {

          const datas = res.data.data;
          console.log(datas);
          setSelectedItem(datas);
          setShowDetails(true);
          //console.log(featureList)
        } else {
          //setLoading(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  }


  const AddtoCartPopUp = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddToCartBestSell

            datas={props.data}
            handle={HandleClose}
          // stage={displaystage}
          // onHide={handleDiseaseClose}
          // submitduration={submitDurationHandle}

          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={props.onHide}>
                        Close
                      </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };


  const DetailPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body style={{ width: "96%" }}>
          <div className=" productsQuickView" id="productsQuickView" >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">


                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="products-image">
                      {props.data.main_service_name === 'laboratory' ? (
                        <img src={masterlab} alt="image" />
                      ) : props.data.main_service_name === 'Scan' ? (
                        <img src={scan} alt="image" />
                      ) : props.data.main_service_name === 'Medical Equipments' ? (
                        <img src={consumable} alt="image" />
                      ) :
                        props.data.main_service_name === 'Medicines' ? (
                          <img src={medicine} alt="image" />
                        ) :
                          props.data.main_service_name === 'Misc' ? (
                            <img src={misc} alt="image" />
                          ) :
                            props.data.main_service_name === 'Procedure' ? (
                              <img src={procedure} alt="image" />
                            ) :



                              (
                                <img src={prod1} alt="image" style={{ height: '300px', width: '300px' }} />
                              )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="products-content">
                      <h6><a href="#"><b>{props.data.service_name}</b></a></h6><br />

                      <div className="price">
                        <span className="old-price">{props.data.amount}</span>
                        <span className="discounted-price">{(props.data.amount * (100 - props.data.discount) / 100).toFixed(2)}</span>
                        <span>&nbsp;&nbsp;</span>
                        <Badge pill variant="primary" style={{ fontSize: '16px' }}>
                          {props.data.discount}% Off!
                        </Badge>
                      </div>

                      <div style={{ display: "flex" }} className="products-review">
                        <div className="rating">
                          <i className='bx bxs-star'></i>
                          <i className='bx bxs-star'></i>
                          <i className='bx bxs-star'></i>
                          <i className='bx bxs-star'></i>

                        </div>
                        <a href="#" className="rating-count">4 stars</a>
                      </div>

                      <ul className="products-info">
                        <li><span>Vendor:</span> <a href="#">{props.data.hospital_name}</a></li>
                        <li><span>Availability:</span> <a href="#">In stock</a></li>
                        <li><span>Products Type:</span> <a href="#">{props.data.main_service_name} </a></li>
                      </ul>





                      <div className="products-add-to-cart">
                        {props.data.service_category === "master_consumables" ?
                          <div className="input-counter">
                            <span className="minus-btn"><i className='bx bx-minus'></i></span>
                            <input type="text" min="1" value="1" />
                            <span className="plus-btn"><i className='bx bx-plus'></i></span>
                          </div>
                          : ""}
                        <button type="submit" className="default-btn" onClick={() => handleAddToCart(props.data)}><i className="flaticon-trolley" ></i> Add to Cart</button>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div></div>
        </Modal.Body>
      </Modal>

    )
  }

  return (
    <>

      <PatientNav />
      <br />
      <br />

      <section className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h1>New Arrivals</h1>
            <ul>
              <li><a href="/webordering">Home</a></li>
              <li>New Arrivals</li>
            </ul>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="section-title">
          <h2>New Arrivals</h2>
        </div>




        <div className="row">
          <div style={{ display: "contents", width: "200px" }} className="products-slides  owl-theme" >
            {/* <OwlCarousel items={5}
                            className="owl-theme"
                            loop
                            nav
                            margin={0}
                     
                        > */}
            {promotions.map((item) => (

              <div className="single-products-box" style={{ marginRight: "30px" }}>
                <div className="image" >
                  <a href="#" className="d-block">  {item.service_category === 'master_labtest' ? (
                    <Link
                    to="/search"
                    state={{ details: item.item_service }}
                   
                  ><img src={masterlab} alt="image" style={{ height: '200px', width: '200px' }} onClick={() => ""} /></Link>
                  ) : item.service_category === 'master_scan' ? (
                    <Link
                    to="/search"
                    state={{ details: item.item_service }}
                   
                  ><img src={scan} alt="image" style={{ height: '200px', width: '200px' }} onClick={() => ""} /></Link>
                  ) : item.service_category === 'master_consumable' ? (
                      <Link
                    to="/search"
                    state={{ details: item.item_service }}
                   
                  ><img src={consumable} alt="image" style={{ height: '200px', width: '200px' }}onClick={() => ""} /></Link>
                  ) :
                    item.service_category === 'master_medicine' ? (
                      <Link
                    to="/search"
                    state={{ details: item.item_service }}
                   
                  > <img src={medicine} alt="image" style={{ height: '200px', width: '200px' }} onClick={() => ""} /></Link>
                    ) :
                      item.service_category === 'misc' ? (
                        <Link
                        to="/search"
                        state={{ details: item.item_service }}
                       
                      > <img src={misc} alt="image" style={{ height: '200px', width: '200px' }} onClick={() => ""} /></Link>
                      ) :
                        item.service_category === 'master_procedure' ? (
                          <Link
                          to="/search"
                          state={{ details: item.item_service }}
                         
                        > <img src={procedure} alt="image" style={{ height: '200px', width: '200px' }} onClick={() => ""} /></Link>
                        ) :



                          (
                            <Link
                            to="/search"
                            state={{ details: item.item_service }}
                           
                          > <img src={prod1} alt="image" style={{ height: '300px', width: '300px' }} onClick={() => ""} /></Link>
                          )}</a>

                  <div className="buttons-list">
                    <ul>
                      <li>
                        <div onClick={() => handleAddToCart(item)} className="cart-btn">
                          <a data-toggle="modal" data-target="#productsQuickView">
                            <i class="fa fa-cart-plus" aria-hidden="true"></i>
                            <span className="tooltip-label">Add to Cart</span>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div onClick={() => ""} className="quick-view-btn">
                        <Link
                    to="/search"
                    state={{ details: item.item_service }}
                   
                  > 
                            <i className='bx bx-search-alt'></i>
                            <span className="tooltip-label">Quick View</span>
                          </Link>
                        </div>
                      </li>


                    </ul>
                  </div>
                </div>

                <div className="content">
                  <h3><b>{item.item_name}</b></h3>
                  <div className="price">
                    <span className="new-price"><i class="fa fa-inr" aria-hidden="true"></i>{item.amount}</span>
                  </div>
                </div>
              </div>

            ))}
            {/* </OwlCarousel> */}
          </div>
        </div>



        {showDetails ? (
          <DetailPopup
            show={showDetails}
            data={selectedItem}
            onHide={() => {
              setShowDetails(false);

            }}
          />
        ) : (
          ""
        )}

        {cartShow ? (
          <AddtoCartPopUp
            show={cartShow}
            data={cartdata}
            onHide={() => {
              setCartShow(false);

            }}
          />
        ) : (
          ""
        )}

      </div>


    </>




  )

}
export default NewArrivalDeals 