

let backendHost;
let socketHost;


const hostname = window && window.location && window.location.hostname;

if(hostname === 'apps.3docs.in') {
  backendHost = 'https://api.3docs.in';
  socketHost = 'wss://api.3docs.in';
} else if(hostname === 'unistage.cianlogic.com') {
  backendHost = 'https://unistageapi.cianlogic.com';
  socketHost = 'wss://unistageapi.cianlogic.com';
} else if(hostname === 'uni.cianlogic.com') {
  backendHost = 'https://uniapi.cianlogic.com';
  socketHost = 'wss://uniapi.cianlogic.com';
}else if(hostname === 'localhost') {
  backendHost = 'https://uniapi.cianlogic.com';
  socketHost = 'wss://uniapi.cianlogic.com';
}

export const APIURL = `${backendHost}`;
export const SOCKETURL = `${socketHost}`;




