import React from 'react'

function BookAppointment(props) {

    const data = props.data
    return (
        <div className="booking-detail container">
            <h4 className='title-of-tasks'>Booking Details</h4>
            <br/>
            <div className='booking-details-grid'>
                <div className="text-left">
                    <span className="text-muted">Doctor</span>
                    <h5>{data.doctor_name}</h5>
                </div>
                <div className="detail-icon">
                    <i className="fas fa-user-md"></i>
                </div>

                <div className="text-left">
                    <span className="text-muted">Date</span>
                    <h5>{data.date}</h5>
                </div>
                <div className="detail-icon">
                    <i className="far fa-calendar"></i>
                </div>

                <div className="text-left">
                    <span className="text-muted">Token</span>
                    <h5>{data.token}</h5>
                </div>
                <div className="detail-icon">
                    <i className="fas fa-address-card"></i>
                </div>

                <div className="text-left">
                    <span className="text-muted">Slot</span>
                    <h5>{data.slot}</h5>
                </div>
                <div className="detail-icon">
                    <i className="fas fa-user-clock"></i>
                </div>

                <div className="text-left">
                    <span className="text-muted">Arrival Time</span>
                    <h5>{data.arrival_time}</h5>
                </div>
                <div className="detail-icon">
                    <i className="far fa-clock"></i>
                </div>
            </div>

        </div>
    )
}

export default BookAppointment
