import React,{useState} from "react";

import "./hospital.css";
import "./hospitalpending.css";


function HospitalPending() {

  const [hospData,] =useState(JSON.parse(sessionStorage.getItem('hosp_data'))? JSON.parse(sessionStorage.getItem('hosp_data')) : null);
   const Hospital_name= hospData ? hospData.name : null;
   // const Hospital_specializations = hospData ? hospData.specializations : null;
   
   const location = hospData ? hospData.location : null;
 
   
 
   
  return (
    <div>
    <div className="hospital-dashboard-container"> 
    

       {/*<HospitalJumbotron /> */}
         <div style={{backgroundColor:"white" }} className="jumbotron jumbotron-fluid jumbo-div padding">
  <div className=" jumbo-content">
    <div className="captions">

        <h1 style={{color:"rgb(51, 181, 229)"}} className="title-of-hosp"> {Hospital_name}</h1>
        
       {/*  <h3 className="additional-text">{Hospital_specializations} Hospital</h3> */}
        <h3 className="additional-text">{location} </h3>
    </div>
  

  
  </div>
</div>

   <div className="verify-msg padding" > 
     <h3 className="text-muted">  We are in process of reviewing your registration details and shall approve your account at the earliest. </h3><br/>

     <h3 className="text-muted"> Meanwhile, kindly verify your account from the email we have sent you (if not done yet). </h3>  <br />

   <h3 className="text-muted"> In case of any queries, kindly contact us at  
   <a href="mailto:felixacare@IntPurple.com" target="_blank" rel="noopener noreferrer"> IntPurple.com </a>
     </h3> <br />
          <br />
           <h3 className="text-muted">
         Thank you for understanding!   </h3>
          <br />

         

   </div>
</div>

  </div>
)

}
export default HospitalPending;