import React, { useState, createContext } from "react";

export const RegContext = createContext();

export const RegProvider = (props) => {
  const [userDatas, setUserDatas] = useState({
    firstname: "",
    lastname:'',
    email: "",
    username:"",
    address:"",
    password: "",
    confirmpassword: "",
    gender: "",
    otp: "",
    qualification: "",
    hospital: "",
    dob: "",
    usertype: "",
    isDoctor: "",
    country: "",
    region: "",
    zipcode:"",
    phone: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
  });
  return (
    <RegContext.Provider value={[userDatas, setUserDatas]}>
      {props.children}
    </RegContext.Provider>
  );
};
