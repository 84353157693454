import React, {useState,useEffect} from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
function AddRemarkReport(props) {
    const [remarkData, setRemarkData]= useState('')
    const [comments, setComments] = useState()
    const[refresh,setRefresh]=useState(false)
  const[flag,setFlag]=useState(false)
  const rptImg=props.rptImg
    const handleSave = ()=> {
        props.save()
    }
    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");
    
        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
    
        axios.get(`${APIURL}/api/v1/doctor/patient-documents-view/?patient_id=${props.id}`                                                                          
         , {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    const data=res.data.results
                    let filteredList = data.filter((item, index) => {
                      return (item.id=== props.reportId);
                    })
                 setComments(filteredList[0].comment)
                    
                   
                    //setConsent(datas.consent_level)
                }
                else {
    
                }
            })
            .catch((err) => {
    
            })
    
    }, [refresh])
    const callSave = (rowToUpdate) => {
       
          const tokenString = sessionStorage.getItem("usertoken");
    
          let str = tokenString.replace(/["]+/g, "");
         
          let comment=comments
          
           
          const dataToUpdate = {
            comment,
          
          };
    
          axios
            .put(
              `${APIURL}/api/v1/doctor/patient-documents-view/?patient_id=${props.id}&id=${props.reportId}`,
              dataToUpdate,
              {
                headers: {
                  Authorization: "Token " + str,
                },
              }
            )
            .then((res) => {
              if (res.data.status === "success") {
               setFlag(false)
              setRefresh(!refresh)
               setRemarkData("")
              } else {
                
              }
            })
            .catch((err) => {
            
            });
        
      };
      const divStyle={
        overflowY: 'scroll',
        width:'500px',
       marginLeft:"2%",
        height:'600px',
        position:'relative'
      };
    return (<>
    {rptImg.split(".")[rptImg.split(".").length-1]!=="pdf"?
<div className="form-button" style={{}}>
       <i className="fa fa-search-plus"    onClick={() =>{ var GFG = document.getElementById("geeks");
         var currWidth = GFG.clientWidth;
         var currHeight=GFG.height;
         GFG.style.width = (currWidth + 100) + "px";
         GFG.style.height=(currHeight+100)+"px"
         } } >
   
 </i>
   <span>&nbsp;&nbsp;</span>
 <i className="fa fa-search-minus" onClick={() =>{  var GFG = document.getElementById("geeks");
         var currWidth = GFG.clientWidth;
         var currHeight=GFG.height;
         if(currWidth>=500)
         GFG.style.width = (currWidth - 100) + "px";     
         if(currHeight>=600) 
         GFG.style.height=(currHeight-100)+"px"} } >
    
 </i>
 
 </div>:"" }
      <div className="form-button" style={divStyle}>
      
      {rptImg.split(".")[rptImg.split(".").length-1]!=="pdf"?
       <>
         
         <img className='update-class banner'  src={rptImg}  width="400" height="600" id="geeks" GFG="250" alt='Scanned Image'/>
       
       
       </>
       : <object data={rptImg}  type="application/pdf" width="100%" height="100%">
       <p>Alternative text - include a link <a href={rptImg}>to the PDF!</a></p>
     </object>}

</div>

        <div className=''>  
        <small>Add Your Comments Here..</small>
                <textarea 
                type="text" 
                className="form-group text-area "
                value= {comments}
               
                 onChange={(e)=>{
                 setFlag(true)
                  e.preventDefault();
                  setComments(e.target.value)
          } }
            autoComplete="off"
                /><br/>
         { flag?  <button  onClick={callSave} className='btn btn-primary btn-col'>Save</button>:""}
          
           
        </div>
        </>
    )
}

export default AddRemarkReport
