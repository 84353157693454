import React, { useState } from 'react';
import "./pharmacyuser.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
function PharmacyUser({ data, freeCount, activeCount, handle }) {
  // const [userId, setUserId] = useState('')
 
  const [successShow, setSuccessShow] = useState(false)
 

  const create_link = `/create-pharmacy-user/${data.id}`;

  const update_link = `/updatePharmacyUser/${data.id}`;
  const change_password_link = `/changepassword-pharm/${data.id}`;
  const [deleteShow, setDeleteShow] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState('');
  const [suspendShow, setSuspendShow] = useState(false);
  const [isSuspend, setIsSuspend] = useState(data.is_suspended);
  const [modalShow, setModalShow] = useState(false);




  const handleDeletePopUp = (id) => {
    //  e.preventDefault();
    setDeleteIndex(id);
    setDeleteShow(true);
  }

  const DeleteConfirmPopup = (props) => {


    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >

        <Modal.Body >

          <h4 className="title-of-page"> Delete User  </h4>
          <h5 className="title-of-page" > Please Confirm to Delete the User ID {deleteIndex}</h5><br />

          <div className="form-group " style={{ marginLeft: "120px" }}>
            <button type="button" className="btn btn-danger btn-cancel" onClick={props.onHide}> Cancel </button>
            <button type="submit" className="btn btn-primary btn-col" onClick={() => { handleDelete(deleteIndex) }} >Confirm </button>
          </div>
        </Modal.Body>

      </Modal>
    );
  }
  const handleDelete = (item) => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');

    //  const dataToSend = {
    //   "id" : item
    // }

    axios.post(`${APIURL}/api/v1/service-provider/hospital-user-delete/?user_id=${deleteIndex}&role=pharmacyuser`, {},
      {
        headers: {

          "Authorization": 'Token ' + str,
          'Content-Type': 'application/json'
        }
      })
      .then(res => {


        if (res.data.status === "success") {
          setSuccessShow(true);
          // handle();
          // setRefresh(!refresh)



        }
        else {
          console.log('else called ');

          //  setErrorMsg(res.data.message);
          //             setErrorSubmit(true);
        }


        setDeleteIndex('');
        setDeleteShow(false);

      })
      .catch(err => {
        console.log("catch called");
        // setSubmitMsg("error");
        // setErrorSubmit(true);
      })

  }

  const SubmitPopUp1 = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="" >Deleted Successfully</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  function SubmitPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4> {isSuspend?"Suspended":"Authorised"} Successfully!!</h4>
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          
  
         
  
         
        </Modal.Footer>
      </Modal>
    );
  }

  const handleSuspendPopUp = (id) => {
    setDeleteIndex(id);
    setSuspendShow(true);
    setIsSuspend(!isSuspend)
  };
  const SuspendConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 className="title-of-page"> Suspend User </h4>
          <h5 className="title-of-page">
            {" "}
            Please Confirm to{data.is_suspended?"Authorise":"Suspend"} the User ID {deleteIndex}
          </h5>
          <br />

          <div className="form-group " style={{ marginLeft: "120px" }}>
            <button
              type="button"
              className="btn btn-danger btn-cancel"
              onClick={props.onHide}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={() => {
                handleSuspend(deleteIndex);
              }}
            >
              Confirm{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleSuspend = (e) => {
  //	e.preventDefault();

   	const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); 

    
  const data = {
    is_suspended :isSuspend
  }

    axios.put(`${APIURL}/api/v1/service-provider/pharmacy-user-alter/${deleteIndex}/`, data, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setModalShow(true);
          
          
        } else {
          // setSubmitMsg("error");
        }
        setDeleteIndex("");
        setSuspendShow(false);
      })
      .catch((err) => {
        // setSubmitMsg("error");
      });

  };

  return (

    <>
      <div className="doctor-card">

        <div className="card-header12" >
          <h6 >{data.id} </h6>

          <h6> {data.name} </h6>
        </div><br/>


        <div className="card-content26">
         
          <h6><span className="key-labels">Username </span><br/> <span>{data.username}</span></h6>

          <h6><span className="key-labels">Qualification</span> <br/><span> {data.qualification}</span></h6>
          <h6> <span className="key-labels"> Status </span>: {data.is_suspended ?

            <span style={{ color: "var(--red)" }}> Suspended </span>


            :

            <span style={{ color: "var(--green)" }}> Authorized </span>


          } </h6>

          <br />

          <div className="contact-div">
            <i className="fa fa-phone key-labels" aria-hidden="true"></i>{data.code + " " + data.phone}<br />
            <i className="fa fa-envelope key-labels" aria-hidden="true"></i> {data.email}


          </div>
          <br />







          <div className="card-content-divider"></div>
          <div className="bottom-links">
          {data.is_suspended ? (
              <Link to="#"  
              onClick={() => {
                handleSuspendPopUp(data.id);
              }}
              
              > Authorize |</Link>
            ) : (
              <Link to =""
              onClick={() => {
                handleSuspendPopUp(data.id);
              }}
              className="delete-warning"> Suspend |</Link>
            )}


          {data.user ? (
              <Link to={change_password_link}>Change Password |</Link>
            ) : (
              <span className="temp-disabled"> Change Password| </span>
            )}


            {data.user ?
              <span className="temp-disabled"> Generate User Credentials|</span>
              :

              <Link to={create_link} state={{name: data.name}}> Generate User Credentials |</Link>}
            <Link to={update_link}> Edit Details |</Link>
            <Link to="" onClick={() => { handleDeletePopUp(data.id) }} className="delete-warning" >Delete User </Link>
          </div>
        </div>
      </div>

      {
        deleteShow ?
          <DeleteConfirmPopup
            show={deleteShow}
            onHide={() => {
              setDeleteShow(false);
              setDeleteIndex('');
              handle();
            }}

          /> : ''
      }
      {successShow ? (
        <SubmitPopUp1
          show={successShow}
          onHide={() => {
            setSuccessShow(false);
            handle();
          }}
        />
      ) : null}
      {suspendShow ? (
        <SuspendConfirmPopup
          show={suspendShow}
          onHide={() => {
            setSuspendShow(false);
            setDeleteIndex("");
            handle();
          }}
        />
      ) : (
        ""
      )}
   
   {modalShow ? (
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            handle();
          }}
        />
      ) : null}


    </>
  )


}

export default PharmacyUser;