import React, { useState, useEffect } from "react";
import "./changepasswordhospital.css";
import axios from 'axios';
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";

import { Link, useParams } from "react-router-dom";

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { APIURL } from "../../Global";
import BackButton from "../common/BackButton";

function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4> Password updated successfully!!</h4>

      </Modal.Body>
      <Modal.Footer>



        <Link to="/labusers-list"><Button className='btn btn-primary btn-col' > Goto Lab List </Button></Link>


      </Modal.Footer>
    </Modal>
  );
}

function ChangePasswordHospitalLab(props) {

  const [modalShow, setModalShow] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [submitMsg, setSubmitMsg] = useState("");
  const [error, setError] = useState({

    password: '',
    confirm_password: "",

  });
  const { id } = useParams();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");

  const togglePasswordVisiblity = (e) => {

    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const togglePasswordVisiblity1 = (e) => {

    if (passwordType1 === "password") {
      setPasswordType1("text")
      return;
    }
    setPasswordType1("password")
  }

  const handlePasswordChange = (e) => {


    setPassword(e.target.value);
  }
  const handleConfirmPassword = (e) => {

    setConfirmPassword(e.target.value);
  }

  const validate = async () => {

    let errors = {};
    let isValid = true;
    setError({});




    if (password !== "undefined") {
      let pattern = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/);
      if (!pattern.test(password)) {
        isValid = false;
        errors["password"] = "Password must contain at least 1 UPPERCASE, 1 lower case, 1 number, 1 special character(@$!#^%*?&) and don't use space";
      }
    }
    if (!password || password === "" || password === null) {
      isValid = false;
      errors["password"] = "Please enter password";
    }

    if (!confirmPassword || confirmPassword === "" || confirmPassword === null || typeof confirmPassword === "undefined") {
      isValid = false;
      errors["confirm_password"] = "Please enter confirm password";
    }

    if (password && typeof password !== "undefined") {
      if (password.length > 0 && password.length < 8) {
        isValid = false;
        errors["password"] = "Must contain atleast 8 characters";
      }
    }

    if (typeof password !== "undefined" && password !== "" && password !== null && typeof confirmPassword !== "undefined" && confirmPassword !== "" && confirmPassword !== null) {
      if (password !== confirmPassword) {
        isValid = false;
        errors["confirm_password"] = "Passwords don't match";
      }
    }





    await setError(currentstate => ({
      ...currentstate,
      ...errors
    }));


    return isValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await validate()) {
      callSubmit();

    }
  }
  const callSubmit = () => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );


    const data = {
      password: password
    }

    axios
      .put(`${APIURL}/api/v1/service-provider/lab-user-alter/${id}/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Token " + v2
        },
      })
      .then((res) => {
        if (res.data.status === "success") {

          setModalShow(true);

        } else {

          setSubmitMsg("error");
        }
      })
      .catch((err) => {

        setSubmitMsg("error");
      });
  }


  return (
    <>

      <div className="liner">

      </div>
      <div className="container">

        <form className="change-pw-form col-lg-6" onSubmit={handleSubmit}>
          <h4 className="title-of-page"> Change Password </h4>
          <h5 className="title-of-tasks" > Lab User ID : {id} </h5>
          <div className="form-group ">
            <div className="form-control " style={{ backgroundColor: "rgb(232, 240, 254)" }}>
              <input className="box1 " type={passwordType} maxLength='30' placeholder="*Enter new Password" value={password} onChange={handlePasswordChange} />
              <span style={{ float: "right" }}>
                {passwordType === "password" ? <i onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity} className="fa fa-eye"></i>}
              </span>
            </div>
            {error.password ? <div className="error-validation-msg"> {error.password}</div> : null}
          </div>
          <div className="form-group ">
            <div className="form-control " style={{ backgroundColor: "rgb(232, 240, 254)" }}>
              <input className="box1 " type={passwordType1} maxLength='30' placeholder="*Confirm Password" value={confirmPassword} onChange={handleConfirmPassword} />
              <span style={{ float: "right" }}>
                {passwordType1 === "password" ? <i onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity1} className="fa fa-eye"></i>}
              </span>
            </div>
            {error.confirm_password ? <div className="error-validation-msg"> {error.confirm_password}</div> : null}

          </div>

          <div className="form-button ">
            <Link to="/labusers-list"><button className="btn btn-primary btn-col" > Cancel</button></Link>
            <span>&nbsp;&nbsp;</span>
            <button type="submit" className="btn btn-primary btn-col">Update password </button>
          </div>







        </form>

        <SubmitPopUp
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

        {submitMsg === "error" ?
          <h5 style={{ color: "red" }}>

            Error in data submission.Please try again!
          </h5>
          :
          null}

      </div>
    </>
  );
}
export default ChangePasswordHospitalLab;