import React, { useState } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';


function EditMisc(props) {
  const [error, setError] = useState({
    misc_name: ''

  });
  const [data, setData] = useState(props.data)
  const [completeShow, setCompleteShow] = useState(false)
  const [successShow, setSuccessShow] = useState(false)
  const [errorShow, setErrorShow] = useState(false)




  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>Error in data submission.Please try again!</h5>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }
  const CompletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'> Successfully Saved</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setData(current => ({
      ...current, [key]: val
    }))
  }




  const saveChanges = (e) => {
    e.preventDefault();
    ;
    if(validate()){
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */
    let dataToSend = {}
    if (props.data.misc_name === data.misc_name) {
      dataToSend = {

        description: data.description

      }
    } else if (props.data.description === data.description) {
      dataToSend = {
        misc_name: data.misc_name,
      }
    } else {
      dataToSend = {
        misc_name: data.misc_name,
        description: data.description

      }
    }

    axios
      .put(`${APIURL}/api/v1/staff/miscellaneous-detail/${data.id}/`, dataToSend, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setCompleteShow(true);
          setData({
            misc_name: '',
            description: ''
          });
        }
        else {
          setErrorShow(true)
        }
      })
      .catch((err) => {
        setErrorShow(true)
      });
    //  setSuccessShow(false)
    } 
  }

  const validate = () => {

    let input = data;
    let errors = {};
    let isValid = true;
    setError({});


    if (!input["misc_name"] || input["misc_name"] === "") {
      isValid = false;
      errors["misc_name"] = "Please enter miscellaneous Name";
    }



    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));


    return isValid;
  }



  return (
    <>
      <h4 style={{ marginTop: '0' }} className="title-of-page">Edit Miscellaneous</h4>
      <div style={{ maxHeight: '1000px' }} className='config-box fit-content'>

        <div className='feature-data pad-features'>
          <div className="formField">
            <div  style={{ display: "flex" }}>
            <label className='align-rental'>*Miscellaneous Name: </label>
            <input type='text' className='form-control title-flex' name='misc_name' value={data.misc_name} onChange={handleTitle} /> </div>
            {error.misc_name ? <div className="error-validation-msg error-feature-inventory">{error.misc_name}</div> : null}

          </div>


          <div className="formField" style={{ display: "flex" }}>
            <label className='align-rental'>Description: </label>
            <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
            {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
          </div>
        </div>

        <div className='text-center'>
          <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>

        </div>

        {errorShow ?

          <ErrorPopUp
            show={errorShow}
            onHide={() => { setErrorShow(false) }}
          />
          : null}
        {completeShow ?

          <CompletePopUp
            show={completeShow}
            onHide={() => {
              setCompleteShow(false)
              props.modalClose()
            }}
          />
          : null}

      </div>
    </>
  )
}

export default EditMisc
