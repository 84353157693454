

import React from "react";

import "./home.css";


function HomePage() {
  return (
    <div >
     
    <div className="home">
        
      <h1>Home Page</h1>
     
     
  
</div>

    </div>
   
  );
}

export default HomePage;