import React, {useState} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import {Button ,Modal } from 'react-bootstrap';
function OtpUpdate(props) {

    const [otp, setOtp]= useState('')
    const [otpmsg,setotpmsg]= useState(false);
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);
    const [errorOTPMsg,setErrorOTPMsg]=useState(null);
    const [errorOTPSubmit,setErrorOTPSubmit]=useState(false);
    //const [otpStatus, setOtpStatus]= useState(false)


    const VerifyOTPErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorOTPMsg} </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const SuccessPopUp = (props) => {
        return(
        <Modal
          {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            //  backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 class="updated-message">Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }
      
      
      const SubmitErrorPopup =(props)=>{
          
      
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            //  backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 class="updated-message" style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

    const handleChange=e=>{
        e.preventDefault();
        setOtp(e.target.value)
    }

    const handleReSendOTP=(e)=> {
        e.preventDefault();

        const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') 

        const data = {
            mobile_number: props.num,
            code: props.code,
                      
        }
       
        axios.post(`${APIURL}/api/v1/account/change-number/`, {
          

           mobile_number: data.mobile_number,
          code: data.code,
             

    },{ headers: {
      "Authorization" : 'Token '+str,
     'Content-Type': 'application/json'}})
    .then(res =>{
            
            if(res.data.status === "success"){
                    
                    setotpmsg(true);
                     
                }
                
            })
     .catch(err =>{

  })
    }

    async function handleVerifyOTP() {

        if(otp!=="" && otp!==null){
          const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') 

        let status = null;
           // e.preventDefault();
            const data = {
                 code : props.code,
                mobile_number: props.num,
                otp: otp
              
            }
           
    
    
            axios.put(`${APIURL}/api/v1/account/change-number/`, {
              
    
               mobile_number: data.mobile_number,
              code: data.code,
              otp: data.otp
          
    
        }, { headers: {
          "Authorization" : 'Token '+str,
         'Content-Type': 'application/json'
          }})
        .then(res =>{
                
                if(res.data.status === "success"){
                       status = true;
                           //localStorage.setItem("usertoken", JSON.stringify(res.data.token));      
           
                  //submitPhone();
                  setSubmitMsg("success");
                  setShow(true)
                    }
                    else{
                      
                      status= false;
                      setErrorOTPMsg(res.data.message);
                  setErrorOTPSubmit(true);
                    }
                    
                })
         .catch(err =>{
       setErrorOTPMsg("Error in OTP verification");
                  setErrorOTPSubmit(true);
        
       })
    
        }
        else{
          setErrorOTPMsg("Please enter OTP");
                  setErrorOTPSubmit(true);
        }
  }
    

          const handleRegister=(e)=>{
            e.preventDefault();    
           handleVerifyOTP();      
          
      }

    return (
        <>
        <div className='flex-col'>
        <p className="msg-otp">We have sent an OTP to the mobile number you have entered.<br/></p>
        <div className="click-resendotp-div">
                <span className="click-resendotp" onClick={handleReSendOTP}>Click here</span> to Resend OTP
                <br/>
                {otpmsg === true ? <span style={{color:"green"}}> Successfully resend OTP </span> : ""}
        </div>
            <input className="form-control otp-input" type="text" maxLength='30' name="otp" placeholder="Enter OTP here" value={otp} onChange={handleChange}
                required/>
        <br/>
        <button className='btn btn-primary btn-col' type="submit" onClick={handleRegister} >Submit</button>
      
        </div>
        

                            {
         submitMsg==="success" ?
        <SuccessPopUp
            show={show}
            onHide= { ()=>{
              setShow(false) 
              setSubmitMsg('');
              props.handler();
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }

{errorOTPMsg!==null ?
    <VerifyOTPErrorPopup
        show={errorOTPSubmit}
        onHide={() =>  setErrorOTPSubmit(false)}
       
      />: null}
                        
        </>
    )
}

export default OtpUpdate
