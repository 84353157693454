import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIURL } from "../../../../Global";
import { useAlert } from 'react-alert'
import "./deals.css"
import BestSelling from "./BestSelling";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import NewArrival from "./NewArrival";
import DealPromo from "./DealPromo";

function Promotions(props) {
    const [na, setna] = useState(true)
    const [dp1, setdp1] = useState(false)
    const [dp2, setdp2] = useState(false)
    const [dp3, setdp3] = useState(false)
    const [bs, setbs] = useState(false)
    const [dealsList1, setDealsList1] = useState([])
    const [dealsList2, setDealsList2] = useState([])
    const [dealsList3, setDealsList3] = useState([])
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {


        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '');
        axios.get(`${APIURL}/api/v1/service-provider/add-promotions/?promo_type=deal_promotion`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Token ' + str
                }
            })
            .then(res => {
                if (res.data.status === "success") {
                    //   setBestSelling(res.data.message)
                    let data = res.data.message
                   
                  
                    const filteredData = data.filter(item => {
                        return item.promo_display === 'dp1'
                    })
                    setDealsList1(filteredData)
                    const filteredData2 = data.filter(item => {
                        return item.promo_display === 'dp2'
                    })
                    setDealsList2(filteredData2)
                    const filteredData3 = data.filter(item => {
                        return item.promo_display === 'dp3'
                    })
                    setDealsList3(filteredData3)

                }
                else {

                }
            })
            .catch(err => {



            })
    }, [refresh])
    const onSuccess = () => {

        setRefresh(!refresh)
      };
    return (
        <div className="row">
            <div className="col-2 lef-side" style={{ paddingTop: "3%" }}>
                <div className="main-section">
                    <h6>New Arrivals</h6>
                </div>
                <div style={{ backgroundColor: na ? "#8FD9C0" : "#B4B2B2" }}
                    onClick={() => {
                        setna(true);
                        setdp1(false);
                        setdp2(false);
                        setdp3(false);
                        setbs(false)
                    }} className="sub-section">
                    <p >New Arrivals</p>
                </div>
              
              
                <br />
                <div className="main-section">
                    <h6>Deal Promotions</h6>
                </div>
                <div style={{ backgroundColor: dp1 ? "#8FD9C0" : "#B4B2B2" }}
                    onClick={() => {
                        setna(false);
                        setdp1(true);
                        setdp2(false);
                        setdp3(false);
                        setbs(false);
                      
                    }} className="sub-section">
                    <p >Deal Promotions1</p>
                </div>
                <div style={{ backgroundColor: dp2 ? "#8FD9C0" : "#B4B2B2" }}
                    onClick={() => {
                        setna(false);
                        setdp1(false);
                        setdp2(true);
                        setdp3(false);
                        setbs(false);
                       
                    }} className="sub-section">
                    <p >Deal Promotions2</p>
                </div>
                <div style={{ backgroundColor: dp3 ? "#8FD9C0" : "#B4B2B2" }}
                    onClick={() => {
                       
                        setna(false);
                        setdp1(false);
                        setdp2(false);
                        setdp3(true);
                        setbs(false);
                       
                    }} className="sub-section">
                    <p >Deal Promotions3</p>
                </div>
                <br />
                <div className="main-section">
                    <h6>Best Selling</h6>
                </div>
                <div style={{ backgroundColor: bs ? "#8FD9C0" : "#B4B2B2" }}
                    onClick={() => {
                        setna(false);
                        setdp1(false);
                        setdp2(false);
                        setdp3(false);
                        setbs(true)
                    }} className="sub-section">
                    <p>Best Selling Products</p>
                </div>
                <br /><br />
            </div>


            <div className="col-10">
{na?<NewArrival/>:
bs?<BestSelling/>:
dp1?<DealPromo dataList={dealsList1} onSuccess={onSuccess} display="dp1"/>:
dp2?<DealPromo dataList={dealsList2} onSuccess={onSuccess} display="dp2"/>:
<DealPromo dataList={dealsList3} onSuccess={onSuccess} display="dp3"/>}
            </div>

        </div>
    )
}
export default Promotions