import React, {useState} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function ShipQuantity (props) {
    

    const [quantity, setQuantity]=useState(props.setquantitydata !== " " ? props.setquantitydata : " ")
    const [modalShow, setModalShow]=useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [errorSubmit, setErrorSubmit] = useState(false);
    const id = props.id;
    const handleChange = (e)=> {
        const val = e.target.value
     
            setQuantity(e.target.value)
         }

  const handleShipEdit = () => {
    if(quantity<0){
      setErrorMsg("Negative Values not allowed!!!");
      setErrorSubmit(true);
     }
     if(quantity=="0"){
      setErrorMsg("Zero Values not allowed!!!");
      setErrorSubmit(true);
     }
           
    if(quantity!=0){
                const id =props.id
            const tokenString= sessionStorage.getItem('usertoken');
       
            let str= tokenString.replace(/["]+/g, '');
             
             
           const dataToUpdate = 
               {
                   "threshold_quantity": quantity
               }
         
         
           
             axios.put(`${APIURL}/api/v1/service-provider/shipping-charge-detail/${id}/`,dataToUpdate, { headers: {
               
               "Authorization" : 'Token '+str,
               'Content-Type': 'application/json'  }
         
         
             })
         
         
         
             .then(res=>{
            
               if(res.data.status==="success"){
                    setModalShow(true)
                    //setCharge(" ")
               }
          
             })
             .catch(err=>{
           
               
         })
        }
            }
      const SubmitPopup =(props)=>{
    

                return (
                  <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                     backdrop="static"
                      keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                       
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className='text-success'>Quantity Edited Successfully !!</h5>
                     
                    </Modal.Body>
                    <Modal.Footer>
                     
                       <Button variant="secondary" onClick={props.onHide}>Ok</Button>
              
                     
                     
              
                    </Modal.Footer>
                  </Modal>
                );
              } 
              const SubmitErrorPopup = (props) => {
                return (
                  <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 style={{ color: "red" }}>
                      {errorMsg}
                      </h5>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={props.onHide}>
                        Ok
                      </Button>
                    </Modal.Footer>
                  </Modal>
                );
              };
              const ErrorPopup =(props)=>{
    

                return (
                  <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                     backdrop="static"
                      keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                       
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 style={{color: "red"}}> Error in data submission.Please check again!</h5>
                     
                    </Modal.Body>
                    <Modal.Footer>
                     
                       <Button variant="secondary" onClick={props.onHide}>Ok</Button>
              
                     
                     
              
                    </Modal.Footer>
                  </Modal>
                );
              }
        
        return (
          <>
          <label>Edit Quantity:</label>
            <div className='flex-col'>

<input
                    type="number"
                    name="quantity"
                    min="0"
                    value={quantity}
                    onChange={handleChange}
                    required
                ></input>  
  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
<button  onClick={handleShipEdit} className='btn btn-primary btn-col'>Add</button> 


{
        modalShow ? 
        <SubmitPopup
          show={modalShow}
          onHide= {() => { setModalShow(false); props.setQuantityHandle(); 
          }}
        /> : null
      }
      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}
        />
      ) : (
        ""
      )}
                </div>
      
</>
        )

}

export default ShipQuantity;
