import React, { useState } from "react";
import { createContext } from "react";
export const Fav = createContext();
export const FavDelete = createContext();
export const FavFromQuickView = createContext();
function Context({ children }) {
  const [favAdded, setFavAdded] = useState(false);
  const [favDeletedList, setFavDeletedList] = useState(false);
  const [favFromQuickView, setFavFromQuickView] = useState(false);
  return (
    <Fav.Provider value={{ favAdded, setFavAdded }}>
      <FavDelete.Provider value={{ favDeletedList, setFavDeletedList }}>
        <FavFromQuickView.Provider
          value={{ favFromQuickView, setFavFromQuickView }}
        >
          {children}
        </FavFromQuickView.Provider>
      </FavDelete.Provider>
    </Fav.Provider>
  );
}

export default Context;
