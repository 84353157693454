import React, { useState, useRef, useEffect, Fragment } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./hosppatientrecord.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import DatePicker from "react-date-picker";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import "./reacttable.css";
import moment from "moment";
import AddBp from "../vitals/AddBp";
import AddSp from "../vitals/AddSp";
import AddPulse from "../vitals/AddPulse";
import AddTemp from "../vitals/AddTemp";
import AddSugar from "../vitals/AddSugar";
import GlucoseVitals from "../vitals/GlucoseVitals";
import VitalsBP from "../vitals/VitalsBP";
import VitalsChol from "../vitals/VitalsChol";
import VitalsSp from "../vitals/VitalsSp";
import VitalsPulse from "../vitals/VitalsPulse";
import VitalsTemp from "../vitals/VitalsTemp";
import Inference from "./Inference/Inference";
import ProcedureHistory from "./ProcedureHistory"
import Chronic from "../HospSearchPatient/Chronic/Chronic";
import AddObs from "../Observations/AddObs";
import ViewObs from "../Observations/ViewObs";
import AddSymptoms from "../Symptoms/AddSymptoms";
import ViewSymptoms from "../Symptoms/ViewSymptoms";
import AddAller from "../Allergy/AddAller";
import Comorbidities from "../HospSearchPatient/Como/Comorbidities";
import Diagnosis from "../Findings/Diagnosis";
import ViewAller from "../Allergy/ViewAller";
import ImageUpdate from "./ImageUpdate";
import AddRemark from "./AddRemark";
import EditRemark from "./EditRemark";
import CurrentMedications from "./CurrentMedications";
import AddMedicinesPat from "./AddMedicinesPat";
import PastMedications from "./PastMedications";
import AddDetails from "./AddDetails";

import WeightAdd from "./WeightAdd"
import HeightAdd from "./HeightAdd";

import { APIURL } from "../../Global";
import { Suspense } from "react";
import AddCholestrol from "../vitals/AddCholestrol";
import AddBmi from "../vitals/AddBmi";
import VitalsBmi from "../vitals/VitalsBmi";
import ViewMedPresc from "./ViewMedPresc";
import AddRemarkReport from "./AddRemarkReport";

function PatientRecord(props) {

  const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
  const [imgErrorShow, setImgErrorShow] = useState(false)
  const [height, setHeight] = useState();
  const [maritalStatus, setMaritalStatus] = useState();
  const [weight, setWeight] = useState();
  const [editDetailSuccess, setEditDetailSuccess] = useState(false);
  const [age, setAge] = useState();
  const [summary, setSummary] = useState("");
  const [addChol, setAddCholestrol] = useState(false);
  const [historyEditShow, setHistoryEditShow] = useState(false)
  const [medEditShow, setMedEditShow] = useState(false)
  const [flag, setFlag] = useState(0)
  const [flag2, setFlag2] = useState(0)
  const [complaintEditShow, setComplaintEditShow] = useState(false)
  const [editProcedureShow, setEditProcedureShow] = useState(false);
  const [historyAddShow, setHistoryAddShow] = useState(false)
  const [medAddShow, setMedAddShow] = useState(false)
  const [complaintAddShow, setComplaintAddShow] = useState(false)
  const [addBmi, setAddBmi] = useState(false);
  const [bmiShow, setBmiShow] = useState(false);
  const [familyData, setFamilyDatas] = useState("");
  const [famHistory, setFamHistory] = useState()
  const [medHistory, setMedHistory] = useState()
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [permission, setPermission] = useState(false);
  const [permissionScan, setPermissionScan] = useState(false);
  const [submsg2, setSubMsg2] = useState(false);
  const [submsg1, setSubMsg1] = useState();
  const [observationsShow, setObservationsShow] = useState(false);
  const [userDetails, setuserDetails] = useState();
  const [name, setName] = useState();
  const [gender, setGender] = useState();
  const [imgUrl, setImgUrl] = useState();
  const [weightAddShow, setWeightAddShow] = useState(false);
  const [heightAddShow, setHeightAddShow] = useState(false);
  const [procedureDelete, setProcedureDelete] = useState(false);
  const [procedureUpdate, setProcedureUpdate] = useState(false);
  const [logindatas] = useState(JSON.parse(sessionStorage.getItem('logindatas')))
  const [testData1, setTestData1] = useState([{
    id: "",
    test_name: "",
    date: "",
    lab_test_details: "",
  },]);
  const [isOnline, setIsOnline] = useState(false)
  const [next_visit, setNext_visit] = useState(null);
  const [visit, setVisit] = useState("")
  const [next, setNext] = useState(null);
  const [addPulse, setAddPulse] = useState(false);
  const [addTemp, setAddTemp] = useState(false);
  const [addSp, setAddSp] = useState(false);
  const [addBp, setAddBp] = useState(false);
  const [addSugar, setAddSugar] = useState(false);
  const [scanDate, setScanDate] = useState()
  const [testDate, setTestDate] = useState()
  const [testsList, setTestsList] = useState([
    {
      id: "",
      test_name: "",
      date: "",
      lab_test_details: "",
      created_by :"",
    },
  ]);
  const [scanList, setScanList] = useState([
    {
      id: "",
      scan_name: "",
      date: "",
      scan_details: "",
      created_by :"",
    },
  ]);
  const [rptImg, setRptImg] = useState()
  const [cellid, setcellId] = useState()
  const [rptModalShow, setRptModalShow] = useState(false)
  const [item1, setItem] = useState([]);
  const [popupShow, setPopupShow] = useState(false);
  const [imgEditShow, setImgEditShow] = useState(false);
  const [reasonAddShow, setReasonAddShow] = useState(false);
  const [addtest, setAddtest] = useState(true)
  const [addscan, setAddscan] = useState(true)
  const [reasonEditShow, setReasonEditShow] = useState(false);
  const [index, setIndex] = useState();
  const [editShow, setEditShow] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [remarkViewShow, setRemarkViewShow] = useState(false);
  const [detailData, setDetailData] = useState("");
  const [cell, setCell] = useState("");
  const [editSuccess, setEditSuccess] = useState(false);
  const [sizeShow, setSizeShow] = useState(false);
  const [validShow, setValidShow] = useState(false);
  const [contentShow, setContentShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(false);
  const [searchAssign, setSearchAssign] = useState("");
  const [displayDoctor, setDisplayDoctor] = useState(false);
  const [doctorId, setDoctorId] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [assignList, setAssignList] = useState([]);

  const [submitMsg, setSubmitMsg] = useState("");
  const [submitMsg1, setSubmitMsg1] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [deleteNextMsg, setDeleteNextMsg] = useState("");
  const [deleteNextModalShow, setDeleteNextModalShow] = useState(false);

  const [options, setOptions] = useState([]);
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [search, setSearch] = useState("");
  const [procedureMsg, setProcedureUpdateMsg] = useState("");
  const [procedureShow, setProcedureShow] = useState(false);
  const [searchScan, setSearchScan] = useState("");
  const [scanSuggest, setScanSuggest] = useState();
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [testListCounter, setTestListCounter] = useState(0);
  const [scanListCounter, setScanListCounter] = useState(0);
  const wrapperRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [, setDateVal] = useState("");
  const [deleteScanConfirmMsg, setDeleteScanConfirmMsg] = useState("");
  const [deleteScanConfirmModalShow, setDeleteScanConfirmModalShow] =
    useState(false);
  const [deleteNextVisitConfirmMsg, setDeleteNextVisitConfirmMsg] =
    useState("");
  const [deleteNextVisitConfirmModalShow, setDeleteNextVisitConfirmModalShow] =
    useState(false);

  const [scanIndexToDelete, setScanIndexToDelete] = useState();
  const [deleteTestConfirmMsg, setDeleteTestConfirmMsg] = useState("");
  const [deleteTestConfirmModalShow, setDeleteTestConfirmModalShow] =
    useState(false);
  const [testIndexToDelete, setTestIndexToDelete] = useState();
  const [testErr, setTestErr] = useState("");
  const [scanErr, setScanErr] = useState("");
  const [updatedTestList, setUpdatedTestList] = useState([]);
  const [updatedScanList, setUpdatedScanList] = useState([]);
  const [submitTrigger, setSubmitTrigger] = useState(true);
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorScanShow, setErrorScanShow] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [errorSubmit1, setErrorSubmit1] = useState(false);
  const [glucoseShow, setGlucoseShow] = useState(false);
  const [bpShow, setBpShow] = useState(false);
  const [pulseShow, setPulseShow] = useState(false);
  const [tempshow, setTempShow] = useState(false);
  const [spshow, setSpShow] = useState(false);
  const [cholesterolShow, setCholesterolShow] = useState(false);
  const [addPrescShow, setAddPrescShow] = useState(false);
  const [addPrescShow1, setAddPrescShow1] = useState(false);
  const [viewPrescShow, setViewPrescShow] = useState(false);
  const [inferenceShow, setInferenceShow] = useState(false);
  const [observationsArray, setObservationsArray] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [symptomsShow, setSymptomsShow] = useState(false);
  const [timing] = useState(200);
  const [isBooped, setIsBooped] = useState(false);
  const [allergyArray, setAllergyArray] = useState([]);
  const [allergyShow, setAllergyShow] = useState(false);
  const [errorNextModalShow, setErrorNextModalShow] = useState(false);
  const [nextErr, setNextErr] = useState("nil");
  const [messagesShow, setMessagesShow] = useState(false);
  const [procedureRefresh, setProcedureRefresh] = useState(false)
  const [scanRefresh, setScanRefresh] = useState(false)
  const [testRefresh, setTestRefresh] = useState(false)
  const [searchProcedure, setSearchProcedure] = useState("");
  const [procedureSuggest, setProcedureSuggest] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAreaChane, setIsAreaChange] = useState(false)
  let [n, setN] = useState(0);
  let [s, setS] = useState(0);
  const navigate = useNavigate();
  // let per = [];
  // let per2 = [] ;

  const [procedureList, setProcedureList] = useState([{
    procedure_details: {
      procedure_name: "",
      procedure_id: "",
      procedure_type: "",
      amount: "",
    },
    date_of_procedure: '',
    area: "",
    session: "",
    quantity: "",
    details: "",
    procedure_reason: "",
    proc_image: "",
    assign_name: "",
    status: "",
  }]);

  const [errorShow, setErrorShow] = useState(false)
  const [procedureErr, setProcedureErr] = useState("");
  const [displayAssign, setDisplayAssign] = useState(false)
  const [procedureListCounter, setProcedureListCounter] = useState(0);
  const [imageEditShow, setImageEditShow] = useState(false)
  const [imageRptEditShow, setRptImageEditShow] = useState(false)
  const [imageViewShow, setImageViewShow] = useState(false)
  const [deleteProcedureConfirmMsg, setDeleteProcedureConfirmMsg] = useState("");
  const [deleteProcedureConfirmModalShow, setDeleteProcedureConfirmModalShow] = useState(false);
  const [procedureIndexToDelete, setProcedureIndexToDelete] = useState();
  const [procedureReasonShow, setProcedureReasonShow] = useState(false)
  const [procedureToggle, setProcedureToggle] = useState(false)
  const hostname = window && window.location && window.location.hostname;
  const [medRefresh, setMedRefresh] = useState(false);
  const [load, setLoad] = useState(false)

  const ref1 = useRef();
  const ref3 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();
  const ref11 = useRef();
  const ref12 = useRef();
  const ref10 = useRef();
  const renderTooltip2 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Includes patient's personal informations.
    </Tooltip>
  );

  const renderTooltip3 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Includes Vitals details, Chronic medical condition, Comorbidities , and
      add findings.
    </Tooltip>
  );

  const renderTooltip9 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Observations noted is displayed here. Can set new Observations.
    </Tooltip>
  );
  const renderTooltip10 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Symptoms noted is displayed here. can set Symptoms.
    </Tooltip>
  );

  const renderTooltip12 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      All past medical records of the patient.
    </Tooltip>
  );
  const renderTooltip14 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>

      View patients prescriptions. Can add new prescription.
    </Tooltip>
  );
  const renderTooltip11 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Set Test Schedules,Scan schedules of patient.

    </Tooltip>
  );

  const renderTooltip15 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Set next visit
    </Tooltip>
  );
  const renderTooltip20 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Add Allergy details of Patient.
    </Tooltip>
  );
  const renderTooltip26 = (props) => (
    <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
      Doctor can set and view the procedures assigned to patients.
    </Tooltip>
  );
  const refreshPage = () => {
    window.location.reload(false);
  }

  const [historyShow, setHistoryShow] = useState(false);

  const handleDisplay = () => {
    setDisplay(false);
    setDisplay1(false);
    setDisplay2(false);

  }
  const [linkDisabled, setLinkDisabled] = useState(false)
  const [todayDate, setTodayDate] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  useEffect(() => {
    document.addEventListener('click', handleDisplay)

    return () => {
      document.removeEventListener('click', handleDisplay)
    }
  }, [])
  useEffect(() => {
    if (visit === todayDate) {
      if (isOnline === true) {
        setLinkDisabled(true)
      }
      else {
        setLinkDisabled(false)
      }
    } else { setLinkDisabled(false) }
  }, [visit, isOnline])
  useEffect(() => {

    setProcedureList([{
      procedure_details: {
        procedure_name: "",
        procedure_id: "",
        procedure_type: "",
        amount: "",
      },
      date_of_procedure: '',
      area: "",
      session: "",
      quantity: "",
      details: "",
      procedure_reason: "",
      proc_image: "",
      assign_name: "",
      status: "",
    }])

    setProcedureListCounter(0);

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    axios.get(`${APIURL}/api/v1/doctor/procedure-list/?patient_id=${id}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          console.log('THE PROCEDURE DATA', res.data.message);

          if (res.data.message.length > 0) {
            const data = res.data.message;
            const filteredData = data.filter(item => {
              return item.id !== ''
            })
            filteredData.forEach(item => {
              if (item.procedure_name || item.procedure_type) {
                item.procedure_details = {
                  procedure_name: item.procedure_name,
                  procedure_id: item.procedure,
                  procedure_type: item.procedure_type,
                  amount: item.procedure_amount,
                }
                // item.assign_name = item.assign_to
              }
            });

            if (filteredData.length < 1) {
              setProcedureList([{
                procedure_details: {
                  procedure_name: "",
                  procedure_id: "",
                  procedure_type: "",

                },
                date_of_procedure: '',
                area: "",
                session: "",

                details: "",
                procedure_reason: "",
                proc_image: "",


              }])

            }
            else {
              setProcedureList(filteredData)
            }
            setProcedureListCounter(filteredData.length);
          }

        }
      })

  }, [procedureRefresh])

  const reader = new FileReader();

  const setDefaultDoctor = () => {
    const hosp_doc_data = sessionStorage.getItem('hosp_doc_data');
    if (hosp_doc_data) {
      const { doctor_name, user_id } = JSON.parse(hosp_doc_data);
      setDoctorId(user_id);
      setDoctorName(doctor_name);
    }
  }

  let track = null;

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/allergies-list/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log("Allergies", res.data.message)
          setAllergyArray(res.data.message);
        }
      })
  }, [refresh]);


  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/patient-history-detail/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {

          setFamilyDatas(res.data.message);
          setFamHistory(res.data.message[0].family_history)
          setMedHistory(res.data.message[0].past_medical_history)
        }
      })
  }, [refresh]);


  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/patient/observations-list/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setObservationsArray(res.data);
        }
      })
  }, [refresh]);

  const callSaveDetails = () => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const data = {
      patient_id: id,
      present_complaint: familyData.present_complaint,
      past_medical_history: familyData.past_medical_history,
      family_history: familyData.family_history
    }
    axios.put(`${APIURL}/api/v1/service-provider/patient-history-detail/?patient_id=${id}`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {

          setEditDetailSuccess(true)
        }
      })
  }

  useEffect(() => {
    if (permission === true) {
      const tokenString = sessionStorage.getItem("usertoken");
      const userid = sessionStorage.getItem("userid");
      let str = tokenString.replace(/["]+/g, "");
      if (testErr === "nil") {
        let values = [];
        let labtests = {};
        const tests = updatedTestList;
        for (let i = n; i < tests.length; i++) {

          values.push({
            patient: id,
            doctor: userid,
            lab_test_details: tests[i].lab_test_details,
            date: tests[i].date,
            test: tests[i].id
          })
        }
        labtests = {

          "labtests": values
        }
        axios
          .post(`${APIURL}/api/v1/doctor/tests-list/`, labtests, {
            headers: {
              Authorization: "Token " + str,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              setSubmitMsg("success");
              setPermission(false)
              setModalShow(true);
            } else {
              setSubmitMsg("error");
              setErrorSubmit(true);
              setAddtest(true)
              setPermission(false)
            }
          })
          .catch((err) => {
            setSubmitMsg("error");
            setErrorSubmit(true);
            setAddtest(true)
            setPermission(false)
          });
      }
    }
  }, [testErr !== "nil", submitTrigger]);


  useEffect(() => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')




    axios.get(`${APIURL}/api/v1/doctor/procedures/?search=${searchProcedure}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          setProcedureSuggest(res.data.message);
          console.log(res.data)
        }


      })
  }, [searchProcedure]);

  useEffect(() => {

    if (permissionScan === true) {
      const tokenString = sessionStorage.getItem("usertoken");
      const userid = sessionStorage.getItem("userid");
      let str = tokenString.replace(/["]+/g, "");

      if (scanErr === "nil") {
        const scans1 = updatedScanList;
        let values1 = []

        for (let i = s; i < scans1.length; i++) {

          values1.push({
            patient: id,
            doctor: userid,
            scan_details: scans1[i].scan_details,
            date: scans1[i].date,
            scan: scans1[i].id
          })



        }
        const scans = {
          "scans": values1
        }
        axios
          .post(`${APIURL}/api/v1/doctor/scans-list/`, scans, {
            headers: {
              Authorization: "Token " + str,
              "Content-Type": "application/json",
            },
          })

          .then((res) => {
            if (res.data.status === "success") {
              setPermissionScan(false)
              setSubmitMsg("success");
              setModalShow(true);
            } else {
              setSubmitMsg("error");
              setErrorSubmit(true);
              setAddscan(true)
              setPermissionScan(false)
            }
          })
          .catch((err) => {
            setSubmitMsg("error");
            setErrorSubmit(true);
            setAddscan(true)
            setPermissionScan(false)
          });
      }
    }
  }, [scanErr !== "nil", submitTrigger]);


  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/laboratory-tests/?patient_id=${id}&search=${search}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {


          setOptions(res.data.message);
        }
      })
  }, [search]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/scans/?patient_id=${id}&search=${searchScan}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setScanSuggest(res.data.message);
        }
      })
  }, [searchScan]);


  const handleObservationsOverlay = (e) => {
    e.preventDefault();
    setObservationsShow(true);
  };

  const handleAddObs = () => {
    setObservationsShow(false);
    setRefresh(!refresh);
  };
  const handleAddAllergy = () => {
    setAllergyShow(false);
    setRefresh(!refresh);
  };
  const handleAllergyOverlay = (e) => {
    e.preventDefault();
    setAllergyShow(true);
  };
  const handleCholOverlay = (e) => {
    e.preventDefault();
    setCholesterolShow(true);
  };
  const submitCholHandle = () => {
    setAddCholestrol(false);
  };
  const ComplaintAddPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddDetails update={handleSend} />

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Add</Button>

        </Modal.Footer>
      </Modal>
    );
  }
  const MedHistoryAddPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddDetails update={handleSend} />

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Add</Button>

        </Modal.Footer>
      </Modal>
    );
  }

  const ComplaintPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={detailData} update={handleSend} />

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

        </Modal.Footer>
      </Modal>
    );
  }
  const HistoryAddPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddDetails update={handleSend} />

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Add</Button>

        </Modal.Footer>
      </Modal>
    );
  }

  const handleHistoryAdd = () => {

    setHistoryAddShow(true)
  }

  const handleFamAdd = () => {
    setMedAddShow(true)
  }
  const handleMedHistory = (data) => {
    setMedEditShow(true)
    setDetailData(data)
  }

  const handleHistory = (data) => {
    setHistoryEditShow(true)
    setDetailData(data)
  }
  const MedHistoryPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={medHistory} update={handleSend} />

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

        </Modal.Footer>
      </Modal>
    );
  }
  const HistoryPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={famHistory} update={handleSend} />

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

        </Modal.Footer>
      </Modal>
    );
  }

  const BmiPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsBmi {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleBmiOverlay = (e) => {
    e.preventDefault();
    setBmiShow(true);
  };

  const handleBmi = () => {
    setAddBmi(true);
  };

  const AddBmiPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddBmi id={id} submitbmi={submitBmiHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleMed = () => {
    setAddPrescShow(false);
    setMedRefresh(!medRefresh);
  };
  const handleChol = () => {
    setAddCholestrol(true);
  };

  const ViewPrescPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewMedPresc patient={id} closeMed={handleMed} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const ProcedureUpdatePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "green" }}>
            {procedureMsg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddPrescPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center", marginLeft: "45%" }} id="contained-modal-title-vcenter"> <b> Medicines</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddMedicinesPat patient={id} closeMed={handleMed} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const DeleteSuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success">Deleted Successfully</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const EditProcedurePopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>


        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            {" "}
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }


  const AllergyPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="title-of-tasks header">Allergy</h3>

          <AddAller id={id} handleAdd={handleAddAllergy} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            {" "}
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/procedure-assignee/?q=${searchAssign}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {

        if (res.data.status === "success") {

          setAssignList(res.data.details);
          setDefaultDoctor();
        }
      })
  }, [searchAssign]);

  const handleTrack = () => {
    setMessagesShow(false);
    if (track !== null) {
      track.stop();
    }
  };

  const TestData = async () => {

    setTestsList([
      {
        id: "",
        test_name: "",
        date: "",
        lab_test_details: ""
      },
    ]);
    setTestListCounter(0);
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    await axios
      .get(`${APIURL}/api/v1/doctor/tests-list/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setN(res.data.message.length)
          if (res.data.message.length > 0) {

            setTestListCounter(res.data.message.length);
            setTestsList(res.data.message);
            const today = new Date();
            const offset = today.getTimezoneOffset();
            let dateUpdated = new Date(today.getTime() - offset);
            const m = moment(dateUpdated, "YYYY-MM-DD");

            for (let index = 0; index < res.data.message.length; index++) {
              let ot = moment.utc(res.data.message[index].date).toDate()
              var ms = moment(ot, "YYYY-MM-DD");
              let timediff = moment.duration(moment(ms, "YYYY-MM-DD").diff(moment(m, "YYYY-MM-DD"))).asDays();
              if (timediff >= 0) {
                setTestDate(res.data.message[index].date);
                break;
              }
              else {
                setTestDate("");
              }


            }
          }

        }
      })
  }
  const GetReport = async () => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    await axios
      .get(`${APIURL}/api/v1/doctor/patient-documents-view/?patient_id=${id}&per_page=100&sort_by=desc`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.message.length > 0) {
            const data = res.data.results;
            const filteredData = data.filter((item) => {
              return item.id !== "";
            });


            if (filteredData.length < 1) {
              setItem([
                {
                  id: "",
                  title: "",
                  image: "",
                  comment: "",
                  date: ""
                },
              ]);
            } else {
              setItem(filteredData);
            }
          }
        }
      })
  }
  const ScanData = async () => {


    setScanList([
      {
        id: "",
        scan_name: "",
        date: "",
        scan_details: "",
      },
    ]);
    setScanListCounter(0)
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    await axios
      .get(`${APIURL}/api/v1/doctor/scans-list/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {

        if (res.data.status === "success") {
          setS(res.data.message.length);
          if (res.data.message.length > 0) {
            setScanListCounter(res.data.message.length);

            setScanList(res.data.message);
            const today = new Date();
            const offset = today.getTimezoneOffset();
            let dateUpdated = new Date(today.getTime() - offset);
            const m = moment(dateUpdated, "YYYY-MM-DD");

            for (let index = 0; index < res.data.message.length; index++) {
              let ot = moment.utc(res.data.message[index].date).toDate()
              var ms = moment(ot, "YYYY-MM-DD");
              let timediff = moment.duration(moment(ms, "YYYY-MM-DD").diff(moment(m, "YYYY-MM-DD"))).asDays();
              if (timediff >= 0) {
                setScanDate(res.data.message[index].date);
                break;
              }
              else {
                setScanDate("");
              }


            }

          }
        }
      })
  }

  const allDatas = async () => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    await axios
      .get(`${APIURL}/api/v1/doctor/patient-dashboard/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {

        if (res.data.status === "success") {
          setLoading(false)
          setuserDetails(res.data.data);
          setName(res.data.data.name);
          setAge(res.data.data.age);
          setGender(res.data.data.gender);
          setVisit(res.data.data.next_visit)
          setIsOnline(res.data.data.is_online)
          
          if (res.data.pemission_levels[0] === "Level 1") {
            setFlag(1)
            console.log("permission", flag)
          }
          if (res.data.pemission_levels[1] === "Level 2") {
            setFlag2(2)
            console.log("permission", flag2)

          }

          console.log("permission", flag)
          console.log("permission", flag2)
          const today = new Date();
          const offset = today.getTimezoneOffset();
          let dateUpdated = new Date(today.getTime() - offset);
          let ot = moment.utc(res.data.data.next_visit).toDate()
          const m = moment(dateUpdated, "YYYY-MM-DD");
          var ms = moment(ot, "YYYY-MM-DD");
          let timediff = moment.duration(moment(ms, "YYYY-MM-DD").diff(moment(m, "YYYY-MM-DD"))).asDays();;
          if (timediff >= 0) {
            setNext_visit(res.data.data.next_visit);

          }
          else {
            setNext_visit(null)
          }
          setDoctorName(logindatas.doctor_name);
          setDoctorId(logindatas.user_id);
          if (res.data.data.next_visit !== null) {
            setNext(new Date(res.data.data.next_visit));
          } else {
            setNext(null);
          }
          setImgUrl(res.data.data.photo);
        }
      })
    setLoading(false)
  };
  const saveNextVisit = () => {


    const fd1 = new FormData();

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    if (doctorName === logindatas.doctor_name) {

      setDoctorName(logindatas.doctor_name)
    }

    if (doctorId === '' || doctorId === null) {
      setSubmitMsg("error");
      setErrorSubmit(true);
      setErrorMessage('Please select a Doctor for next visit');
    } else if (next === null) {
      setSubmitMsg("error");
      setErrorSubmit(true);
      setErrorMessage('Please select a Valid Date for next visit');

    } else if (next !== null && doctorId !== null) {
      const offset = next.getTimezoneOffset();
      let dateUpdated = new Date(next.getTime() - offset * 60 * 1000);
      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      fd1.append("patient_id", id);
      fd1.append("next_visit", DateExtracted);
      fd1.append("doctor_id", doctorId);
      fd1.append("is_online", isOnline);
      axios
        .post(`${APIURL}/api/v1/doctor/patient-record/`, fd1, {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        })

        .then((res) => {
          if (res.data.status === "success") {
            setSubmitMsg("success");
            setModalShow(true);
          } else {
            setSubmitMsg1("error");
            setErrorSubmit1(true);
          }
        })
        .catch((err) => {
          setSubmitMsg1("error");
          setErrorSubmit1(true);
        });
    }
  };

  useEffect(() => {
    setLoading(true)
    allDatas();
    TestData();
    ScanData();
  }, [scanRefresh, testRefresh]);

  useEffect(() => {
    setTestsList(testsList);
  }, [testsList]);
  useEffect(() => {
    setScanList(scanList);
  }, [scanList]);

  const handleAddPrescOverlay = (e) => {
    e.preventDefault();
    setAddPrescShow(true);
  };
  const handleViewPresc = (e) => {
    e.preventDefault();
    setViewPrescShow(true);
  };
  useEffect(() => {
    GetReport();
  }, []);
  useEffect(() => {
    PatientDetails();
  }, []);

  const PatientDetails = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data.health_details;
          setWeight(data.weight);
          setHeight(data.height);
          setMaritalStatus(data.married);
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
        setErrorSubmit(true);
      });
    setWeightAddShow(false);
    setHeightAddShow(false);
  };

  const handleClick = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const SymptomsPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="title-of-tasks header">Symptom</h3>
          <AddSymptoms id={id} handleAdd={handleAddSymptoms} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            {" "}
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ProcedureHistoryPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <ProcedureHistory id={id} />

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const InferencePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="title-of-tasks"> Inference & Impact Analysis </h2>
          <Inference patientId={id} summary={displaySummaryTable} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleWeightAdd = () => {
    setWeightAddShow(true);
  };

  const handleHeightAdd = () => {
    setHeightAddShow(true);
  };

  const submitweightHandle = () => {
  };

  const submitheightHandle = () => {
  };

  const handleSubmitTest = () => {

    n = testsList.length;

    setTestErr("nil");
    const updatedTests = testsList.filter((row, i) => {
      return row.test !== "" && row.date !== "";
    });
    const testData = testsList.filter((row, i) => {
      return (
        row.test !== "" || row.date !== ""
      );
    });
    testData.map((item) => {
      console.log('THE TEST ITEM', item);
      if (item.test_name === "") {
        setTestErr("*Test name in Test Schedules Table is mandatory!");
        setErrorModalShow(true);
      } else if (item.id === "") {
        setTestErr("*Please select Test name from the dropdown!");
        setErrorModalShow(true);
      }

      else if (item.date === "") {
        setTestErr("*Date in Test Schedules Table is mandatory!");
        setErrorModalShow(true);
      }
      else if (item.test !== "" && item.date !== "") {
        setUpdatedTestList(updatedTests);
        setSubmitTrigger(!submitTrigger);
        setPermission(true)
      }
    });
    // setUpdatedTestList(updatedTests);
    // setSubmitTrigger(!submitTrigger);

  }
  const handleSubmitScan = () => {

    //  setSubmitTrigger(!submitTrigger);
    s = scanList.length;
    setScanErr("nil");
    const updatedScans = scanList.filter((row, i) => {
      return (
        row.scan !== "" && row.date !== ""
      );
    });

    const scanData = scanList.filter((row, i) => {
      return (
        row.scan !== "" || row.date !== ""
      );
    });

    scanData.map((item) => {

      if (item.scan_name === "") {
        setScanErr("*Scan name in Scan Schedules table is mandatory!");
        setErrorScanShow(true);
      }
      else if (item.id === "") {
        setScanErr("*Please select Scan name from the dropdown!");
        setErrorScanShow(true);
      }
      else if (item.date === "") {
        setScanErr("*Date in Scan Schedules Table is mandatory!");
        setErrorScanShow(true);
      }
      else if (item.scan_name !== "" && item.date !== "") {
        setUpdatedScanList(updatedScans);
        setSubmitTrigger(!submitTrigger);
        setPermissionScan(true)
      }
    });
    // setUpdatedScanList(updatedScans);
    // setSubmitTrigger(!submitTrigger);
  };
  const handleNextVisit = (date) => {
    if (date !== null) {
      setNext(date);
    }
  };

  const renderAssigned = (cellInfo) => {
    if (cellInfo.index > procedureListCounter - 1 || procedureList[cellInfo.index].isEdit) {

      return (<div  >
        <input
          style={{ backgroundColor: "#fafafa" }}
          value={procedureList[cellInfo.index].assign_name}
          className="form-control"
          name="assignee"

          onChange={(e) => {
            e.preventDefault();
            const list = procedureList;
            list[cellInfo.index].assign_name = e.target.value;
            setProcedureList(list);
            setCurrentIndex(cellInfo.index);
            setCurrentColumn(cellInfo.column.id);
            setDisplayAssign(true);
            setSearchAssign(e.target.value);
          }}
        />
        {currentIndex === cellInfo.index && cellInfo.column.id === "assign_name" && displayAssign && (<div className="suggestions-box-holder">
          <button className="btn-suggestion btn btn-secondary btn-smallest" title="close" onClick={() => setDisplayAssign(false)}> X </button>
          <div className="suggestions-box suggestions-assignee-box">
            {assignList.map((item, i) => {

              return (
                <div
                  className=""
                  key={item.id}
                  onClick={(e) => {
                    e.preventDefault();
                    setRender(!render);
                    setDoctorId(item.id);
                    setDoctorName(item.full_name);
                    setDisplayDoctor(false);
                    const List = procedureList;
                    List[cellInfo.index].assign_name = item.full_name;
                    List[cellInfo.index].assign_id = item.id;
                    setProcedureList(List);
                    setDisplayAssign(false);
                  }}
                >
                  <div className="search-item " style={{ height: "30px" }}>

                    <h6 className=""> {item.full_name}</h6>
                  </div>

                </div>
              )
            })
            }

          </div>
        </div>
        )
        }
      </div>
      )
    } else {

      return (
        <input
          style={{ backgroundColor: "#fafafa" }}
          value={procedureList[cellInfo.index].assign_name}
          className="form-control"
          name="assignee"
          disabled={true}
          onChange={(e) => {
            e.preventDefault();
            const list = procedureList;
            list[cellInfo.index].assign_name = e.target.value;
            setProcedureList(list);
            setCurrentIndex(cellInfo.index);
            setCurrentColumn(cellInfo.column.id);
            setDisplayAssign(true);
            setSearchAssign(e.target.value);
          }}
        />
      )

    }
  }

  const handleAssignee = () => {
    const data = procedureList
    const sessionData = JSON.parse(sessionStorage.getItem('logindatas'))

    data.forEach(item => {
      if (item.assign_id === "" || item.assign_name === "") {
        item.assign_id = sessionData.user_id
        item.assign_name = sessionData.name
      }
    })

    setProcedureList(data)
  }

  const UpdateSuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success">Procedure Updated Successfully</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const AddCholestrolPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCholestrol id={id} submitcholestrol={submitCholHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const handleDeleteRowProcedure = (index) => {

    setDeleteProcedureConfirmMsg('success');
    setDeleteProcedureConfirmModalShow('true');
    setProcedureIndexToDelete(index);
  }
  const handleProcedureReasonEdit = (data, index) => {
    setDetailData(data)
    setCell(index)
    setProcedureReasonShow(true)
  }
  const handleReasonAdd = (index, data) => {
    if (data === 'toggle') {
      setProcedureToggle(true)
    }
    else {
      setProcedureToggle(false)
    }
    setCell(index)
    setReasonAddShow(true)
  }

  const procedureEdit = (data, e) => {



    const procedures = data

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');

    const fd = new FormData();

    if (procedures.procedure_details.procedure_id) {
      fd.append(`procedure_id`, procedures.procedure_details.procedure_id)
    }
    if (procedures.date_of_procedure) {
      fd.append(`date_of_procedure`, procedures.date_of_procedure.split('T').join(" ").slice(0, 16))
    }
    if (procedures.assign_id) {
      fd.append(`assign_id`, procedures.assign_id)
    }
    if (procedures.details) {
      fd.append(`details`, procedures.details)
    }

    axios.put(`${APIURL}/api/v1/hospital/patient-hospital-procedures/`, fd, {
      headers: {

        "Authorization": 'Token ' + str,
        'Content-Type': 'application/json'
      }

    })

      .then(res => {

        if (res.data.status === "success") {

          setProcedureUpdateMsg("successfully updated");
          setProcedureShow(true);
          // setRefresh(!refresh);
          setSubmitMsg("success");
          setModalShow(true);

        }
        else {
          setSubmitMsg("error");
          setErrorSubmit(true);

        }

      })
      .catch(err => {

        setSubmitMsg("error");
        setErrorSubmit(true);
      })
  }


  const handleSubmitProcedure = () => {

    let isValid = true

    handleAssignee()
    const updatedProcedure = procedureList.filter((row, i) => {
      return (row.procedure_details.procedure_name !== "" && row.date !== "")
    })

    const procedureData = procedureList.filter((row, i) => {
      return (row.procedure_details.procedure_name !== "" || row.details !== "")
    })

    procedureData.map((item) => {
      console.log('The item in prodcedure', item)
      if (item.procedure_details.procedure_name === "") {
        setProcedureErr("*Procedure name is mandatory!")
        setErrorShow(true)
        isValid = false
      } else if (item.procedure_details.procedure_id === '') {
        setProcedureErr("*Please select procedure name from the Dropdown!")
        setErrorShow(true)
        isValid = false
      } else if (item.date === "") {
        setProcedureErr("*Date in Procedure Table is mandatory!")
        setErrorShow(true)
        isValid = false
      }
      let list = procedureList;
      list.forEach(procedure => {
        if (procedure.id && !item.id && procedure.patient_procedure && procedure.patient_procedure === item.procedure_details.procedure_name &&
          procedure.procedure_type && procedure.procedure_type === item.procedure_details.procedure_type &&
          procedure.quantity === item.quantity &&
          procedure.area === item.area &&
          procedure.session === item.session &&
          procedure.details === item.details &&
          moment(procedure.date_of_procedure).format('dd-MM-yyyy') === moment(item.date_of_procedure).format('dd-MM-yyyy')) {
          setProcedureErr(`Procedure ${procedure.patient_procedure} Already exists!`)
          setErrorShow(true)
          isValid = false
        }
      });
    })

    if (procedureList[procedureList.length - 1].procedure_details.procedure_name === "") {
      setProcedureErr("*Procedure name is mandatory!")
      setErrorShow(true)
      isValid = false
    }
    if (procedureList[procedureList.length - 1].date_of_procedure === "") {
      setProcedureErr("*Procedure date is mandatory!")
      setErrorShow(true)
      isValid = false
    }

    if (isValid) {
      procedureSubmit(updatedProcedure)
    }

  }


  // const handleSubmitProcedure = () => {

  //   let isValid = true

  //   handleAssignee()
  //   const updatedProcedure = procedureList.filter((row, i) => {
  //     return (row.procedure_details.procedure_name !== "" && row.date_of_procedure !== "")
  //   })

  //   const procedureData = procedureList.filter((row, i) => {
  //     return (row.procedure_details.procedure_name !== "" || row.details !== "")
  //   })

  //   procedureData.map((item) => {
  //     console.log('The item in prodcedure', item)
  //     if (item.procedure_details.procedure_name === "") {
  //       setProcedureErr("*Procedure name is mandatory!")
  //       setErrorShow(true)
  //       isValid = false
  //     } else if (item.procedure_details.procedure_id === '') {
  //       setProcedureErr("*Please select procedure name from the Dropdown!")
  //       setErrorShow(true)
  //       isValid = false
  //     } else if (item.date === "") {
  //       setProcedureErr("*Date in Procedure Table is mandatory!")
  //       setErrorShow(true)
  //       isValid = false
  //     }
  //     let list = procedureList;
  //     list.forEach(procedure => {
  //       if (procedure.id && !item.id && procedure.patient_procedure && procedure.patient_procedure === item.procedure_details.procedure_name &&
  //         procedure.procedure_type && procedure.procedure_type === item.procedure_details.procedure_type &&
  //         procedure.quantity === item.quantity &&
  //         procedure.area === item.area &&
  //         procedure.session === item.session &&
  //         procedure.details === item.details &&
  //         moment(procedure.date_of_procedure).format('dd-MM-yyyy') === moment(item.date).format('dd-MM-yyyy') &&
  //         procedure.assign_name === item.assign_name) {
  //         setProcedureErr(`Procedure ${procedure.patient_procedure} Already exists!`)
  //         setErrorShow(true)
  //         isValid = false
  //       }
  //     });
  //   })


  //   if (isValid) {
  //     procedureSubmit(procedureData)
  //   }

  // }



  const renderProcedureImage = (cellInfo) => {
    if (cellInfo.index > procedureListCounter - 1 || procedureList[cellInfo.index].isEdit) {

      if (procedureList[cellInfo.index].proc_image === null || procedureList[cellInfo.index].proc_image === '') {
        return (
          <div>
            <input id='procedureImage' hidden='hidden' type="file" onChange={uploadProcedureImage} />

            <i onClick={() => { editProcedureImage(cellInfo.index) }} class="fas fa-image select-img"></i>
          </div>
        )
      }
      else {
        return (
          <button onClick={() => { setImageEditShow(true); setIndex(cellInfo.index) }} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
        )
      }
    }

    else {
      if (procedureList[cellInfo.index].proc_image === null || procedureList[cellInfo.index].proc_image === '') {

        return ""

      }
      else {
        return (
          <button onClick={() => { setImageViewShow(true); setIndex(cellInfo.index); }} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
        )
      }

    }

  }

  const openEditTest = (index) => {

    console.log('THE ITEM', testsList[index]);
    handleUpdateTest(index)
  }
  const openEditScan = (index) => {

    console.log('THE ITEM', scanList[index]);
    handleUpdateScan(index)
  }



  const openEditProcedure = (index) => {
    console.log('THE ITEM', procedureList[index]);
    handleUpdateProcedure(index)
  }

  const AddRowProcedure = () => {

    console.log('called add');
    const list = procedureList;
    list.push({
      procedure_details: {
        procedure_name: "",
        procedure_id: "",
        procedure_type: "",
        amount: "",
      },
      date_of_procedure: '',
      area: "",
      session: "",
      quantity: "",
      details: "",
      reason: "",
      proc_image: "",
      assign_name: "",
      status: "",
    });

    setProcedureList(list);
    console.log('THE LIST', list)
    setRender(!render);

  }
  const deleteProcedureRow = (index) => {
    let newlist = [];
    if (index > procedureListCounter - 1) {
      newlist = procedureList.filter((test, i) => {
        return i !== index
      })

      setProcedureList(newlist);
      if (newlist.length <= 0) {

        setProcedureList([{
          procedure_details: {
            procedure_name: "",
            procedure_id: "",
            procedure_type: "",
            amount: "",
          },
          date_of_procedure: '',
          area: "",
          session: "",
          quantity: "",
          details: "",
          reason: "",
          proc_image: "",
          assign_name: "",
          status: "",
        }])

      }
    }
    else {
      handleDeleteRowProcedure(index);
    }

  }

  const renderSaveProcedure = (cellInfo) => {
    if (cellInfo.index > procedureListCounter - 1 || procedureListCounter === 0) {
      return (
        <button className="btn btn-primary btn-col btn-square btn-smallest"
          disabled={load} onClick={handleSubmitProcedure}>Save</button>
      )
    } else if (procedureList[cellInfo.index].isEdit) {
      return (
        <button className="btn btn-primary btn-col btn-square btn-smallest"
          onClick={() => { openEditProcedure(cellInfo.index) }}>Save</button>
      )
    } else {
      return (
        <i
          style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
          onClick={() => {
            let procedures = procedureList;
            procedures.forEach(procedure => {
              procedure.isEdit = false;
            });
            procedures[cellInfo.index].isEdit = true;
            setProcedureList(procedures);
            setRender(!render);
          }}
          className="fas fa-edit"
          aria-hidden="true"
        ></i>
      )
    }
  };

  const renderEditableProcedure = (cellInfo) => {

    if (cellInfo.index > procedureListCounter - 1 || procedureListCounter === 0) {

      return (<div ref={wrapperRef}>

        <input
          style={{ backgroundColor: "#fafafa" }}
          value={procedureList[cellInfo.index][cellInfo.column.id].procedure_name}
          className="form-control"
          name="procedure"

          onChange={(e) => {

            e.preventDefault();

            setCurrentIndex(cellInfo.index);
            setCurrentColumn(cellInfo.column.id);
            setDisplay(true);
            setSearchProcedure(e.target.value);
            const procedures = procedureList;
            procedures[cellInfo.index][cellInfo.column.id].procedure_name = e.target.value;
            setProcedureList(procedures)
            if (e.target.value === "") {
              setDisplay(false);
            }
          }}
          autoComplete="off"
        />
        {currentIndex === cellInfo.index && cellInfo.column.id === "procedure_details" && display && (<div className="suggestions-box">
          {procedureSuggest.map((item, i) => {

            return (
              <div className="scan-item"
                key={item.id}
                onClick={(e) => {
                  e.preventDefault();
                  setRender(!render);
                  const procedure = procedureList;
                  procedure[cellInfo.index][cellInfo.column.id].procedure_id = item.id;
                  procedure[cellInfo.index][cellInfo.column.id].procedure_name = item.procedure_name;
                  procedure[cellInfo.index][cellInfo.column.id].procedure_type = item.procedure_type;
                  procedure[cellInfo.index][cellInfo.column.id].amount = item.amount
                  setProcedureList(procedure);
                  setDisplay(false);
                }}
              >
                <div className='search-procedure'>
                  <p className="search-item">{item.procedure_name}</p>
                </div>
              </div>
            )
          })
          }

        </div>)
        }
      </div>
      )
    }
    else {
      return (<div>
        <div
          style={{ backgroundColor: "#fafafa" }}
          className="form-control test-name-col"
          name="date"
          value={procedureList[cellInfo.index][cellInfo.column.id].procedure_name}
          onBlur={(e) => {
            const procedure = procedureList;
            procedureList[cellInfo.index][cellInfo.column.id].procedure_name = e.target.innerHTML;
            setProcedureList(procedure);
          }}
          autoComplete="off"
          dangerouslySetInnerHTML={{
            __html: procedureList[cellInfo.index][cellInfo.column.id].procedure_name
          }}
        />
      </div>)

    }
  }
  const handleChange = (e) => {
    const val = e.target.value;
    if (e.target.value === "true")
      setIsOnline(true)
    else
      setIsOnline(false)

  }
  const renderEditableProcedureDate = (cellInfo) => {

    if (cellInfo.index > procedureListCounter - 1 || procedureList[cellInfo.index].isEdit) {
      const today = new Date();
      const offset = today.getTimezoneOffset();
      let dateUpdated = new Date(today.getTime() - (offset * 60 * 1000));
      let DateExtracted = dateUpdated.toISOString().split('T')[0];
      let showDate = '';
      if (procedureList[cellInfo.index] && procedureList[cellInfo.index].date_of_procedure) {
        showDate = procedureList[cellInfo.index].date_of_procedure
      }
      return (<div>

        <input type="date" data-date={showDate} className="scan-date-col date-custom-format" min={DateExtracted} onChange={e => {

          e.preventDefault();
          let date = e.target.value
          // let extracted = date.toISOString()
          const procedure = procedureList;
          procedure[cellInfo.index].date_of_procedure = date;

          setProcedureList(procedure);
          setTableRefresh(!tableRefresh)
          const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

          setDateVal(m);
        }
        }
        />

      </div>

      )

    }

    else {

      return (<div>

        <div
          style={{ backgroundColor: "#fafafa" }}

          className="form-control test-date-col"
          name="date"
          value={procedureList[cellInfo.index].date}

          onBlur={(e) => {
            const procedure = procedureList;
            procedure[cellInfo.index].date = e.target.innerHTML;

            setProcedureList(procedure);
          }}
          autoComplete="off"
          dangerouslySetInnerHTML={{
            __html: procedureList[cellInfo.index].date

          }}
        />
      </div>)

    }

  }

  const uploadProcedureImage = (e) => {
    const file = e.target.files[0];
    const procedure = procedureList
    if (!file) {
      setUploadShow(!uploadShow)
      return false
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow)
      return false;
    }
    if (file.size > 2e6) {
      setSizeShow(!sizeShow)
      return false
    }
    reader.onload = (e) => {
      const img1 = new Image();
      img1.onload = () => {
        procedure[cell].proc_image = file
        setProcedureList(procedure)
        setIndex(cell)
        setTableRefresh(!tableRefresh)
      };
      img1.onerror = () => {
        setContentShow(!contentShow)

        return false;
      };
      img1.src = e.target.result;
    };
    reader.readAsDataURL(file);
  }


  const renderProcedureArea = (cellInfo) => {

    if (cellInfo.index > procedureListCounter - 1 || procedureList[cellInfo.index].isEdit) {

      return (<div>
        <input type='text' className="form-control" value={procedureList[cellInfo.index].area} placeholder="Enter Area" onChange={e => {
          setIsAreaChange(true)
          e.preventDefault();
          let data = e.target.value;

          const procedure = procedureList;
          procedure[cellInfo.index].area = data;
          setTableRefresh(!tableRefresh)
          setProcedureList(procedure)

        }
        }
        />
      </div>

      )
    }

    else {
      return (<div>
        <div
          style={{ backgroundColor: "#fafafa" }}

          className="form-control test-name-col"
          name="date"
          value={procedureList[cellInfo.index].area}

          onBlur={(e) => {
            const procedure = procedureList;
            procedureList[cellInfo.index].area = e.target.innerHTML;

            setProcedureList(procedure);
          }}
          autoComplete="off"
          dangerouslySetInnerHTML={{
            __html: procedureList[cellInfo.index].area
          }}
        />
      </div>)
    }

  }

  const renderProcedureType = (cellInfo) => {

    if (procedureList[cellInfo.index][cellInfo.column.id].amount === "" && procedureList[cellInfo.index][cellInfo.column.id].procedure_name === "") {
      return (
        <div style={{ backgroundColor: "#fafafa" }} className="form-control test-name-col">

        </div>
      )
    }

    else {
      return (<div>
        <div
          style={{ backgroundColor: "#fafafa" }}

          className="form-control test-name-col"
          name="date"
          value={procedureList[cellInfo.index].session}

          onBlur={(e) => {
            const procedure = procedureList;
            procedureList[cellInfo.index][cellInfo.column.id].procedure_type = e.target.innerHTML;

            setProcedureList(procedure);
          }}
          autoComplete="off"
          dangerouslySetInnerHTML={{
            __html: procedureList[cellInfo.index][cellInfo.column.id].procedure_type
          }}
        />
      </div>)
    }

  }
  const renderProcedureSession = (cellInfo) => {

    if (cellInfo.index > procedureListCounter - 1 || procedureList[cellInfo.index].isEdit) {

      return (<div>
        <input type='text' className="form-control" value={procedureList[cellInfo.index].session} placeholder="Enter Session" onChange={e => {
          e.preventDefault();
          let data = e.target.value;

          const procedure = procedureList;
          procedure[cellInfo.index].session = data;
          setTableRefresh(!tableRefresh)
          setProcedureList(procedure)

        }
        }
        />
      </div>

      )
    }

    else {
      return (<div>
        <div
          style={{ backgroundColor: "#fafafa" }}

          className="form-control test-name-col"
          name="date"
          value={procedureList[cellInfo.index].session}

          onBlur={(e) => {
            const procedure = procedureList;
            procedureList[cellInfo.index].session = e.target.innerHTML;

            setProcedureList(procedure);
          }}
          autoComplete="off"
          dangerouslySetInnerHTML={{
            __html: procedureList[cellInfo.index].session
          }}
        />
      </div>)
    }

  }

  const renderProcedureDetails = (cellInfo) => {

    if (cellInfo.index > procedureListCounter - 1 || procedureList[cellInfo.index].isEdit) {
      let data = '';
      if (procedureList[cellInfo.index] && procedureList[cellInfo.index].details) {
        data = procedureList[cellInfo.index].details;
      }
      return (<div>
        <textarea className="text-area" placeholder="Add Procedure Details" cols="100" rows="2"
          defaultValue={data}
          onChange={e => {
            e.preventDefault();
            data = e.target.value;

            const procedure = procedureList;
            procedure[cellInfo.index].details = data;

            setProcedureList(procedure)

          }
          }
        />
      </div>

      )

    }
    else {
      return (

        <textarea className="scan-text" value={procedureList[cellInfo.index].details} cols="100" rows="2" />
      )
    }
  }

  const renderProcedureReason = (cellInfo) => {

    if (cellInfo.index > procedureListCounter - 1 || procedureList[cellInfo.index].isEdit) {
      if (procedureList[cellInfo.index].procedure_reason === "" || procedureList[cellInfo.index].procedure_reason === null) {
        return (
          <button onClick={() => handleReasonAdd(cellInfo.index, 'toggle')} className='btn btn-smallest add-remark-btn'><i className="fas fa-plus"></i></button>
        )
      }
      else {
        return (
          <button onClick={() => handleProcedureReasonEdit(procedureList[cellInfo.index].procedure_reason, cellInfo.index)} className='btn btn-primary btn-col btn-square btn-smallest'>View/Edit</button>
        )
      }

    }
    else {
      if (procedureList[cellInfo.index].procedure_reason === "" || procedureList[cellInfo.index].procedure_reason === null) {
        return ''

      }
      else {
        return <button onClick={() => handleView(procedureList[cellInfo.index].procedure_reason)} className='btn btn-primary btn-col btn-square btn-smallest'>View</button>
      }
    }
  }

  const editProcedureImage = (pos) => {
    setCell(pos)
    const fileInput = document.getElementById('procedureImage')
    fileInput.click();
  }

  const procedureSubmit = async (procedures) => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');

    const fd = new FormData();

    if (procedures.length < 1) {
      return false
    }

    let params = [];
    console.log('THE PROCEDURES', procedures)

    // /for (let i = procedures.length; i < procedures.length; i++) {


    if (procedures[procedures.length - 1].proc_image === "") {
      fd.append(`procedures[${procedures.length - 1}]procedure`, procedures[procedures.length - 1].procedure_details.procedure_id)
      fd.append(`procedures[${procedures.length - 1}]date`, procedures[procedures.length - 1].date_of_procedure.split('T').join(" ").slice(0, 10))

      fd.append(`procedures[${procedures.length - 1}]area`, procedures[procedures.length - 1].area)
      fd.append(`procedures[${procedures.length - 1}]session`, procedures[procedures.length - 1].session)
      fd.append(`procedures[${procedures.length - 1}]quantity`, procedures[procedures.length - 1].quantity)

      fd.append(`procedures[${procedures.length - 1}]details`, procedures[procedures.length - 1].details)
      if (procedures[procedures.length - 1].procedure_reason) {
        fd.append(`procedures[${procedures.length - 1}]procedure_reason`, procedures[procedures.length - 1].procedure_reason)
      }
      else { fd.append(`procedures[${procedures.length - 1}]procedure_reason`, "") }

      fd.append(`procedures[${procedures.length - 1}]patient`, id)
    }
    else {
      fd.append(`procedures[${procedures.length - 1}]procedure`, procedures[procedures.length - 1].procedure_details.procedure_id)
      fd.append(`procedures[${procedures.length - 1}]date`, procedures[procedures.length - 1].date_of_procedure.split('T').join(" ").slice(0, 10))

      fd.append(`procedures[${procedures.length - 1}]area`, procedures[procedures.length - 1].area)
      fd.append(`procedures[${procedures.length - 1}]session`, procedures[procedures.length - 1].session)
      fd.append(`procedures[${procedures.length - 1}]quantity`, procedures[procedures.length - 1].quantity)
      fd.append(`procedures[${procedures.length - 1}]proc_image`, procedures[procedures.length - 1].proc_image)
      fd.append(`procedures[${procedures.length - 1}]details`, procedures[procedures.length - 1].details)
      if (procedures[procedures.length - 1].procedure_reason) {
        fd.append(`procedures[${procedures.length - 1}]procedure_reason`, procedures[procedures.length - 1].procedure_reason)
      }
      else { fd.append(`procedures[${procedures.length - 1}]procedure_reason`, "") }
      fd.append(`procedures[${procedures.length - 1}]patient`, id)
    }
    // else {
    //   if (i < procedures.length - 1) {

    //   }
    //   else {
    //     return false
    //   }
    // }

    // }
    setLoad(true)
    axios.post(`${APIURL}/api/v1/doctor/procedure-list/`, fd, {
      headers: {
        "Authorization": 'Token ' + str,
        "Content-Type": "multipart/form-data",
      }

    })

      .then(res => {

        if (res.data.status === "success") {

          setLoad(false)
          setSubmitMsg("success");
          setModalShow(true);

        }
        else {
          setLoad(false)
          setSubmitMsg("error");
          setErrorSubmit(true);

        }

      })
      .catch(err => {
        setLoad(false)
        setSubmitMsg("error");
        setErrorSubmit(true);
      })
  }

  //   const procedureSubmit = async (procedures1) => {
  //     const tokenString = sessionStorage.getItem('usertoken');

  //     let str = tokenString.replace(/["]+/g, '');


  //     const fd = new FormData();

  //     fd.append(`procedure_id`, procedureList[procedures1.length-1].procedure_details.procedure_id)
  //     fd.append(`date`, procedureList[procedures1.length-1].date.split('T').join(" ").slice(0, 10))
  //     isAreaChane&& fd.append(`area`, procedureList[procedures1.length-1].area)
  //     fd.append(`session`, procedureList[procedures1.length-1].session)
  //     fd.append(`quantity`, procedureList[procedures1.length-1].quantity)
  //     fd.append(`details`, procedureList[procedures1.length-1].details)
  //     fd.append(`procedure_reason`, procedureList[procedures1.length-1].procedure_reason)
  //     fd.append(`proc_image`, procedureList[procedures1.length-1].proc_image)

  //     if (procedures1.length < 1) {
  //       return false
  //     }

  //     let procedures2 = [];
  //     let procedures={}
  //     console.log('THE PROCEDURES', procedures1)

  //     // for (let i = 0; i < procedures1.length; i++) {

  //        procedures2.push(fd)

  //       // procedures2.push({
  //       //   patient: id,
  //       //   procedure:procedures1[ procedures1.length-1].procedure_details.procedure_id,
  //       //   date:procedures1[ procedures1.length-1].date,
  //       //   area:procedures1[ procedures1.length-1].area,
  //       //   session:procedures1[ procedures1.length-1].session,
  //       //   details:procedures1[ procedures1.length-1].details,
  //       //   procedure_reason:procedures1[ procedures1.length-1].procedure_reason

  //       // })

  //       // if (procedures1[i].image instanceof File === false ) {
  //       //   procedureEdit(procedures1[i]);
  //       //   continue
  //       // }
  //       if (procedures[i].image instanceof File === true || procedures[i].image === "") {
  //         fd.append(`procedures[${i}]procedure`, procedures[i].procedure_details.procedure_id)
  //         fd.append(`procedures[${i}]date`, procedures[i].date_of_procedure.split('T').join(" ").slice(0, 10))
  //         // fd.append(`procedure[${i}]assign`, procedures[i].assign_id)
  //         fd.append(`procedures[${i}]area`, procedures[i].area)
  //         fd.append(`procedures[${i}]session`, procedures[i].session)

  //         fd.append(`procedures[${i}]proc_image`, procedures[i].image)
  //         fd.append(`procedures[${i}]details`, procedures[i].details)
  //         fd.append(`procedures[${i}]procedure_reason`, procedures[i].reason)
  //       // }
  //       // else {
  //       //   if (i < procedures.length - 1) {
  //       //     continue
  //       //   }
  //       //   else {
  //       //     return false
  //       //   }
  //     //   }

  //     // }

  // procedures ={
  //   "procedures":procedures2
  // }

  //     setLoad(true)
  //     axios.post(`${APIURL}/api/v1/doctor/procedure-list/`,fd, {
  //       headers: {
  //         "Authorization": 'Token ' + str,

  //       }

  //     })

  //       .then(res => {

  //         if (res.data.status === "success") {
  //           setLoad(false)
  //           setSubmitMsg("success");
  //           setModalShow(true);

  //         }
  //         else {
  //           setLoad(false)
  //           setSubmitMsg("error");
  //           setErrorSubmit(true);

  //         }

  //       })
  //       .catch(err => {
  //         setLoad(false)
  //         setSubmitMsg("error");
  //         setErrorSubmit(true);
  //       })
  //   }
  const handleAddPrescOverlay1 = (e) => {
    e.preventDefault();
    setAddPrescShow1(true);
  };
  const handleDeleteNextVisit1 = () => {
    setDeleteNextVisitConfirmMsg("success");
    setDeleteNextVisitConfirmModalShow("true");
  };
  const handleDeleteNext = () => {
    setNext_visit(null);
    setNext(null);
    callDeleteNext();
  };

  const handleDeleteTest = (index, props) => {



    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");


    const ts_id = testsList[index].id



    axios.delete(`${APIURL}/api/v1/doctor/tests-details/${ts_id}`, {
      headers: {
        Authorization: "Token " + str,
        "Content-Type": "application/json",
      },

    })

      .then((res) => {

        if (res.status === 204) {

          setTestRefresh(!testRefresh)
          setAddtest(true)
        } else {
          setSubmitMsg("error");
          setAddtest(true)
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
        setAddtest(true)
      });
  };
  const handleUpdateScan = (i) => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
    const fd = new FormData();

    fd.append(`scan`, scanList[i].scan)
    // fd.append(`doctor`, testsList[i].procedure_details.procedure_id)
    fd.append(`date`, scanList[i].date)

    fd.append(`scan_details    `, scanList[i].scan_details)




    axios.put(`${APIURL}/api/v1/doctor/scans-details/${scanList[i].id}/`, fd, {
      headers: {

        "Authorization": 'Token ' + str,
      }
    })
      .then(res => {

        if (res.data.status === "success") {

          setSubMsg1("Successfully Updated");
          setSubMsg2(true);

        }
        else {
          setSubmitMsg("error");
        }

      })
      .catch(err => {

        setSubmitMsg("error");

      })

  };

  const handleUpdateTest = (i) => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
    const fd = new FormData();

    fd.append(`test`, testsList[i].test)
    // fd.append(`doctor`, testsList[i].procedure_details.procedure_id)
    fd.append(`date`, testsList[i].date)

    fd.append(`lab_test_details  `, testsList[i].lab_test_details)




    axios.put(`${APIURL}/api/v1/doctor/tests-details/${testsList[i].id}/`, fd, {
      headers: {

        "Authorization": 'Token ' + str,
      }
    })
      .then(res => {

        if (res.data.status === "success") {

          setSubMsg1("Successfully Updated");
          setSubMsg2(true);

        }
        else {
          setSubmitMsg("error");
        }

      })
      .catch(err => {

        setSubmitMsg("error");

      })

  };

  const handleUpdateProcedure = (i) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
    const fd = new FormData();

    fd.append(`procedure_id`, procedureList[i].procedure_details.procedure_id)
    fd.append(`date`, procedureList[i].date.split('T').join(" ").slice(0, 10))
    isAreaChane && fd.append(`area`, procedureList[i].area)
    fd.append(`session`, procedureList[i].session)
    fd.append(`quantity`, procedureList[i].quantity)
    fd.append(`details`, procedureList[i].details)
    fd.append(`procedure_reason`, procedureList[i].procedure_reason)


    if (procedureList[i].assign_id) {
      fd.append(`assign`, procedureList[i].assign_id)
    }

    if (procedureList[i].proc_image instanceof File) {
      fd.append(`proc_image`, procedureList[i].proc_image)
    }

    axios.put(`${APIURL}/api/v1/doctor/procedure-list-details/${procedureList[i].id}/`, fd, {
      headers: {

        "Authorization": 'Token ' + str,
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          setProcedureUpdateMsg("successfully updated");
          setProcedureShow(true);

          // setProcedureRefresh(!procedureRefresh);
          // if (!procedureUpdate) {
          //   setProcedureUpdate(true);
          //}
        }
        else {
          setSubmitMsg("error");
        }

      })
      .catch(err => {

        setSubmitMsg("error");

      })

  };

  const handleDeleteProcedure = (index) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');

    axios.delete(`${APIURL}/api/v1/doctor/procedure-list-details/${procedureList[index].id}`, {
      headers: {

        "Authorization": 'Token ' + str,
      }
    })
      .then(res => {

        if (res.data.status === "success") {


          setProcedureDelete(true);
        }
        else {
          setSubmitMsg("error");


        }

      })
      .catch(err => {

        setSubmitMsg("error");

      })
  }
  const callDeleteNext = (index) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const dataToUpdate = {
      patient_id: id,
      next_visit: next_visit,

    };

    axios
      .put(`${APIURL}/api/v1/doctor/patient-record/`, dataToUpdate, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
          setDeleteNextMsg("Successfully deleted next visit data");
          setDeleteNextModalShow(true);
          setDoctorName("")
          setDisplayDoctor(false)
        } else if (res.data.status === "error") {
          setNextErr(
            res.data.message ? res.data.message : "Error in submission!"
          );
        } else {
          setNextErr("Error in submission!");
        }
      })
      .catch((err) => {
        setNextErr("Error in submission!");
      });
  };

  const ErrorProcedurePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {procedureErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Ok</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const PatientDetailsPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Updated successfully.</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const DeleteNextVisitConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Next Visit!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              setDeleteNextVisitConfirmModalShow(false);
              handleDeleteNext();
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DeleteTestConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Scheduled Test</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              setDeleteTestConfirmModalShow(false);
              handleDeleteTest(props.index, props.newList);
              setTestIndexToDelete(null);
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/symptoms-list/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setSymptoms(res.data);
        }
      })
  }, [refresh]);

  const WeightAddPopup = (props) => {
    ;
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WeightAdd
            id={id}
            submitweight={submitweightHandle}
            seteditweight={weight}
            seteditheight={height}
            onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={PatientDetails}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const HeightAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HeightAdd
            id={id}
            submitheight={submitheightHandle}
            seteditheight={height}
            seteditweight={weight}
            onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={PatientDetails}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const AddPrescPopup1 = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="title-of-tasks"> Past Medications </h2>

          <Suspense fallback={<div>Loading...</div>}>
            <PastMedications id={id} />
          </Suspense>



          {/* <AddPrescription {...props} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const DeleteProcedureConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Scheduled Procedure</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Cancel</Button>

          <Button variant="danger" onClick={() => {

            setDeleteProcedureConfirmModalShow(false);
            handleDeleteProcedure(props.index);;
            setProcedureIndexToDelete(null);
          }}> Confirm</Button>

        </Modal.Footer>
      </Modal>
    );
  }

  const PulsePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsPulse {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const TempPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsTemp {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsSp {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitUpdated = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "green" }}>
            {submsg1}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const GlucosePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GlucoseVitals {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleObs = () => {
    setRefresh(!refresh);
  };

  const handleSymptomsOverlay = (e) => {
    e.preventDefault();
    setSymptomsShow(true);
  };

  const handleAddSymptoms = () => {
    setSymptomsShow(false);
    setRefresh(!refresh);
  };


  const BpPopup = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsBP {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const CholesterolPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsChol {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const MessagesPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Suspense fallback={<div>Loading...</div>}>
          </Suspense>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Data submitted successfully!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const DeletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{deleteNextMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ErrorNextPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {nextErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {testErr !== "" ? testErr : scanErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMessage ? errorMessage : 'Check Data already exist or not!!'}{" "}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitErrorPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            Please Select doctor name{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleGlucoseOverlay = (e) => {
    e.preventDefault();
    setGlucoseShow(true);
  };
  const handlePulseOverlay = (e) => {
    e.preventDefault();
    setPulseShow(true);
  };

  const handleTempOverlay = (e) => {
    e.preventDefault();
    setTempShow(true);
  }
  const handleSpOverlay = (e) => {
    e.preventDefault();
    setSpShow(true);
  }
  const handleBpOverlay = (e) => {
    e.preventDefault();
    setBpShow(true);
  };

  const ObservationsPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="title-of-tasks header">Observation</h3>

          <AddObs id={id} handleAdd={handleAddObs} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            {" "}
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const ErrorScanPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {scanErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ImgEditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  let imgFile = null;

  function handleFile(childData) {
    imgFile = childData;
  }

  const handleDeleteRowTest = (index) => {
    setDeleteTestConfirmMsg("success");
    // const newarray = []
    // newarray.push(row)
    // setTestData1(newarray)
    setDeleteTestConfirmModalShow("true");
    setTestIndexToDelete(index);
  };

  const deleteRow = (index, props) => {

    let newlist = [];
    if (index > testListCounter - 1) {

      newlist = testsList.filter((test, i) => {
        return i !== index;
      });

      setTestsList(newlist);
      setAddtest(true)
      setAddscan(true)
      if (newlist.length <= 0) {
        setTestsList([
          {
            id: "",
            test_name: "",
            date: "",
            lab_test_details: "",
            created_by :""
          },

        ]);
      }
    } else {
      handleDeleteRowTest(index);
    }

  };
  const renderEditableTest = (cellInfo) => {

    if (cellInfo.index > testListCounter - 1) {
      return (
        <div ref={wrapperRef}>
          <input
            style={{ backgroundColor: "#fafafa" }}
            value={testsList[cellInfo.index][cellInfo.column.id]}
            className="form-control"
            name="test"

            onChange={(e) => {
              setAddtest(false)
              e.preventDefault();

              setCurrentIndex(cellInfo.index);
              setCurrentColumn(cellInfo.column.id);
              setDisplay1(true);

              setSearch(e.target.value);

              const tests = testsList;
              tests[cellInfo.index].test_name = e.target.value;

              setTestsList(tests);
              if (e.target.value === "") {
                setDisplay1(false);
              }
            }}
            autoComplete="off"
          />

          {currentIndex === cellInfo.index &&
            cellInfo.column.id === "test_name" &&
            display1 && (
              <div className="suggestions-box">
                {options.map((item, i) => {


                  return (
                    <div
                      className="scan-item"
                      key={item.id}
                      onClick={(e) => {

                        e.preventDefault();

                        setRender(!render);

                        const tests = testsList;
                        tests[cellInfo.index].test_name = item.test_name;
                        tests[cellInfo.index].id = item.id
                        setTestsList(tests);
                        setDisplay1(false);
                      }}
                    >
                      <p className="search-item">{item.test_name}</p>
                    </div>

                  );
                })}
              </div>
            )}
        </div>
      );
    }

    else {

      return (

        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control scan-name-col"
            name="test"
            value={testsList[cellInfo.index].test_name}
            onBlur={(e) => {
              const tests = testsList;
              tests[cellInfo.index].test = e.target.innerHTML;

              setTestsList(tests);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: testsList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };

  const handlePulse = () => {
    setAddPulse(true);
  };
  const handleTemp = () => {
    setAddTemp(true);
  };
  const handleSp = () => {
    setAddSp(true);
  };

  const handleSugar = () => {
    setAddSugar(true);
  };
  const handleBp = () => {
    setAddBp(true);
  };

  const renderEditableTestDate = (cellInfo) => {

    if ((cellInfo.index > testListCounter - 1) || (testsList[cellInfo.index].isEdit)) {
      const today = new Date();

      const offset = today.getTimezoneOffset();

      let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      return (
        <div>
          <input
            type="date"
            data-date={testsList[cellInfo.index].date}
            className="scan-date-col date-custom-format"
            min={DateExtracted}
            onChange={(e) => {
              e.preventDefault();
              let date = e.target.value;

              const tests = testsList;
              tests[cellInfo.index].date = date;

              setTestsList(tests);
              const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

              setDateVal(m);
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-date-col"
            name="date"
            value={testsList[cellInfo.index].date}
            onBlur={(e) => {
              const tests = testsList;
              tests[cellInfo.index].date = e.target.innerHTML;

              setTestsList(tests);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: testsList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };

  const renderTestDetails = (cellInfo) => {

    if ((cellInfo.index > testListCounter - 1) || (testsList[cellInfo.index].isEdit)) {
      let data = '';
      if (testsList[cellInfo.index] && testsList[cellInfo.index].lab_test_details) {
        data = testsList[cellInfo.index].lab_test_details;
      }
      return (
        <div>
          <textarea
            className="text-area"
            placeholder="Add Test Details"
            cols="100"
            rows="2"
            defaultValue={data}
            onChange={(e) => {

              e.preventDefault();
              data = e.target.value;

              const tests = testsList;
              console.log("testlist", tests)
              testsList[cellInfo.index].lab_test_details = data;

              setTestsList(tests);
            }}
          />
        </div>
      );
    }
    else {

      return (

        <textarea
        className="text-area"
          value={testsList[cellInfo.index].lab_test_details}
          cols="100"
          rows="2"
        />
      );
    }
  };

  const renderAddedBy = (cellInfo) => {
    const createdBy = testsList[cellInfo.index].created_by;
    const hospitalName =  testsList[cellInfo.index].hospital_name;
    const value = `${createdBy}-${hospitalName}`;


      return (

        <input
        style={{ backgroundColor: "#fafafa" }}
        className="form-control scan-name-col"
        readOnly
          value={value}
          cols="100"
          rows="2"
        />
      );
    
      }



  const renderEditScan = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1 || scanListCounter === 0) {
      return (
        <button className="btn btn-primary btn-col btn-square btn-smallest"
          onClick={handleSubmitScan}>Save</button>
      )
    } else if (scanList[cellInfo.index].isEdit) {
      return (
        <button className="btn btn-primary btn-col btn-square btn-smallest"
          onClick={() => { openEditScan(cellInfo.index) }}>Save</button>
      )
    } else {
      return (
        <i
          style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
          onClick={() => {

            let procedures = scanList;
            procedures.forEach(procedure => {
              procedure.isEdit = false;
            });
            scanList[cellInfo.index].isEdit = true;
            setScanList(procedures);
            setRender(!render);
          }}
          className="fas fa-edit"
          aria-hidden="true"
        ></i>
      )
    }
  };

  const renderUpdateTest = (cellInfo) => {
    if (cellInfo.index > testListCounter - 1 || testListCounter === 0) {
      return (
        <button className="btn btn-primary btn-col btn-square btn-smallest"
          onClick={handleSubmitTest}>Save</button>
      )
    } else if (testsList[cellInfo.index].isEdit) {
      return (
        <button className="btn btn-primary btn-col btn-square btn-smallest"
          onClick={() => { openEditTest(cellInfo.index) }}>Save</button>
      )
    } else {
      return (
        <i
          style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
          onClick={() => {

            let procedures = testsList;
            procedures.forEach(procedure => {
              procedure.isEdit = false;
            });
            testsList[cellInfo.index].isEdit = true;
            setTestsList(procedures);
            setRender(!render);
          }}
          className="fas fa-edit"
          aria-hidden="true"
        ></i>
      )
    }
  };

  const AddRowTest = () => {
    setAddtest(false)
    const list = testsList;
    list.push({
      date: "",
      id: "",
      lab_test_details: "",
      test_name: ""
    });
    setTestsList(list);
    setRender(true);
  };

  const columns = [
    {
      Header: <i className="fa fa-trash"></i>,
      Cell: (props) => {
        return (
          <i style={{ marginLeft: "30%" }}
            className="fa fa-trash trash-btn"
            onClick={() => {

              deleteRow(props.index, props);
            }}
          ></i>
        );
      },
      width: 50,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Test Name",
      accessor: "test_name",
      Cell: renderEditableTest,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,

      sortable: false,
    },

    {
      Header: "Date (YYYY-MM-DD)",
      accessor: "date",
      Cell: renderEditableTestDate,
      sortable: false,

    },
    {
      Header: "Test Details",
      accessor: "lab_test_details",
      Cell: renderTestDetails,
      sortable: false,
      filterable: false,

    },
    {
      Header: "Save/Edit",
      Cell: renderUpdateTest,


    },

  
    {
      Header: "Added By",
      Cell: renderAddedBy,
      width:200


    },
 

    {
      Header: "Add New",

      Cell: props => {

        return (props.index === testsList.length - 1 ? testsList[props.index].id ?
          addtest && <button
            className="btn btn-primary btn-col"
            onClick={AddRowTest}
          >
            +
          </button>
          : null : null)
      },
      sortable: false,
      filterable: false,
    },

  ];
  const AddRptPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemarkReport update={handleSend} id={id} reportId={cellid} rptImg={rptImg} />
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    );
  };

  const renderReport = (cellInfo) => {
    const date4 = item1;
    const date5 = [...date4].reverse();
    return <h6>{date4[cellInfo.index][cellInfo.column.id]}</h6>;
  };
  const renderReportId = (cellInfo) => {
    const date4 = item1;
    const date5 = [...date4].reverse();
    return <><h6 style={{ marginLeft: "35%" }}>{date4[cellInfo.index][cellInfo.column.id]}</h6></>;
  };
  // const renderReportDate = (cellInfo) => {
  //   const date4 = item1;
  //   const date5 = [...date4].reverse();

  //   return <h6>{date5[cellInfo.index].uploaded_at.split("T")[0]}</h6>;

  // };

  const handleAdd1 = (index, id, img) => {
    setcellId(id);
    setRptModalShow(true);
    setRptImg(img)
  };

  const renderRemarks3 = (cellInfo) => {

    return (
      <i
        style={{ color: "rgb(91 138 218)", marginLeft: "40%" }}

        onClick={() => {
          const date4 = item1;
          const date5 = [...date4].reverse();
          handleAdd1(cellInfo.index, date4[cellInfo.index].id, date4[cellInfo.index].images[0])
        }}
        className="fa fa-file-image-o fa-lg"
      ></i>
    );

  };

  const columnsReports = [
    {
      Header: "Id",
      accessor: "id",
      Cell: renderReportId,
      width: 70,
      sortable: false,
      filterable: false,
    },

    {
      Header: "Title",
      accessor: "title",
      Cell: renderReport,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      sortable: false,
    },

    // {
    //   Header: "Date",
    //   accessor: "date",
    //   Cell: renderReportDate,
    //   sortable: false,
    // },
    {
      Header: "Reports",
      accessor: "comment",
      Cell: renderRemarks3,
      sortable: false,
      filterable: false,
      width: 120,
    }


  ];
  const AddRowScan = () => {
    setAddscan(false)
    const list = scanList;
    list.push({
      id: "",
      scan_name: "",
      date: "",
      scan_details: " "

    });
    setScanList(list);
    setRender(true);

  };
  const handleDeleteRowScan = (index) => {
    setDeleteScanConfirmMsg("success");
    setDeleteScanConfirmModalShow("true");
    setScanIndexToDelete(index);
  };

  const DeleteScanConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Scheduled Scan</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              setDeleteScanConfirmModalShow(false);
              handleDeleteScan(props.index);
              setScanIndexToDelete(null);
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SizePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">You have Exceeded the size limit 2MB</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const LoadPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please select an Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ValidPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please select a valid Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ContentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Invalid Image Content</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddBpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddBp id={id} submitbp={submitBpHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };



  const AddSugarPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddSugar id={id} submitsugar={submitSugarHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const AddPulsePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddPulse id={id} submitpulse={submitPulseHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddTempPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddTemp id={id} submittemp={submitTempHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const AddSpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddSp id={id} submitsp={submitSpHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const submitBpHandle = () => {
    setAddBp(false);
  };


  const submitSugarHandle = () => {
    setAddSugar(false);
  };
  const submitPulseHandle = () => {
    setAddPulse(false);
  };
  const submitTempHandle = () => {
    setAddTemp(false);
  };
  const submitSpHandle = () => {
    setAddSp(false);
  };

  const handleDeleteScan = (index) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    const ts_id = scanList[index].id
    axios
      .delete(`${APIURL}/api/v1/doctor/scans-details/${ts_id}`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.status == 204) {
          setScanRefresh(!scanRefresh)
          setAddscan(true)
          setScanDate("")
        } else {
          setAddscan(true)
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
        setAddscan(true)
      });
  };
  const deleteRowScan = (index) => {
    let newlist = [];
    if (index > scanListCounter - 1) {
      newlist = scanList.filter((scan, i) => {
        return i !== index;
      });

      setScanList(newlist);
      setAddscan(true)
      if (newlist.length <= 0) {
        setScanList([
          {
            id: "",
            scan_name: "",
            date: "",
            scan_details: ""
          },

          setScanDate("")
        ]);
      }
    } else {
      handleDeleteRowScan(index);
    }
  };

  const handleMessagesOverlay = (e) => {
    e.preventDefault();
    setMessagesShow(true);
  };

  const renderEditableScan = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1 || scanListCounter === 0) {
      return (
        <div>
          <input
            style={{ backgroundColor: "#fafafa" }}
            value={scanList[cellInfo.index][cellInfo.column.id]}
            className="form-control"
            name="scan_name"
            onChange={(e) => {
              setAddscan(false)
              e.preventDefault();
              setCurrentIndex(cellInfo.index);
              setCurrentColumn(cellInfo.column.id);
              setDisplay2(true);
              setSearchScan(e.target.value);
              const scans = scanList;
              scans[cellInfo.index].scan_name = e.target.value;
              setScanList(scans);
              if (e.target.value === "") {
                setDisplay2(false);
              }
            }}
            autoComplete="off"
          />

          {currentIndex === cellInfo.index &&
            cellInfo.column.id === "scan_name" &&
            display2 && (
              <div className="suggestions-box">
                {scanSuggest.map((item, i) => {

                  return (
                    <div
                      className="scan-item"
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();
                        setRender(!render);
                        const scans = scanList;
                        scans[cellInfo.index].scan_name = item.scan_name;
                        scans[cellInfo.index].id = item.id
                        setScanList(scans);
                        setDisplay2(false);
                      }}
                    >
                      <p className="search-item">{item.scan_name}</p>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      );
    }

    else {

      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control scan-name-col"
            name="scan"
            value={scanList[cellInfo.index].scan}
            onBlur={(e) => {
              const scans = scanList;
              scans[cellInfo.index].scan = e.target.innerHTML;

              setScanList(scans);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };

  const renderEditableScanDate = (cellInfo) => {
    if ((cellInfo.index > scanListCounter - 1) || (scanList[cellInfo.index].isEdit)) {
      const today = new Date();

      const offset = today.getTimezoneOffset();

      let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      return (
        <div>
          <input
            type="date"
            data-date={scanList[cellInfo.index].date}
            className="scan-date-col date-custom-format"
            min={DateExtracted}
            onChange={(e) => {
              e.preventDefault();
              let date = e.target.value;

              const scans = scanList;
              scans[cellInfo.index].date = date;

              setScanList(scans);
              const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

              setDateVal(m);
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control scan-date-col"
            name="date"
            value={scanList[cellInfo.index].date}
            onBlur={(e) => {
              const scans = scanList;
              scans[cellInfo.index].date = e.target.innerHTML;

              setScanList(scans);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };

  let sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }

  const AddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const RemarkViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {detailData}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DetailPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DetailsUpdateSuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Updated successfully.</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleView = (data) => {
    console.log('THE REASON', data)
    setDetailData(data);
    setRemarkViewShow(true);
  };

  const renderDetails = (cellInfo) => {
    if ((cellInfo.index > scanListCounter - 1) || (scanList[cellInfo.index].isEdit)) {
      let data = '';
      if (scanList[cellInfo.index] && scanList[cellInfo.index].scan_details) {
        data = scanList[cellInfo.index].scan_details;
      }
      return (
        <div>
          <textarea
            className="text-area"
            placeholder="Add Scan Details"
            cols="100"
            rows="2"
            defaultValue={data}
            onChange={(e) => {
              e.preventDefault();
              data = e.target.value;

              const scans = scanList;
              scanList[cellInfo.index].scan_details = data;

              setScanList(scans);
            }}
          />
        </div>
      );
    } else {
      return (
        <textarea
          className="scan-text"
          value={scanList[cellInfo.index].scan_details}
          cols="100"
          rows="2"
        />
      );
    }
  };

  const UpdateImgPopup = (props) => {

    return (
      <Modal
        {...props}

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Please upload an Image</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>

        </Modal.Footer>
      </Modal>
    );
  }

  const ProcedureImgPopup = (props) => {

    return (
      <Modal
        {...props}

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUpdate img={procedureList[index].proc_image} imgUpdate={handleFile} />


        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  // const ReportImgPopup = (props) => {

  //   return (
  //     <Modal
  //       {...props}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //       backdrop="static"
  //       keyboard={false}
  //       dialogClassName="remark-width"
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title id="contained-modal-title-vcenter">

  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <div className="form-button" style={{ height: "700px" }}>

  //           {rptImg.split(".")[rptImg.split(".").length - 1] !== "pdf" ?
  //             <>

  //               <img className='update-class banner' src={rptImg} width="500" height="600" id="geeks" GFG="250" alt='Scanned Image' />


  //             </>
  //             : <object data={rptImg} type="application/pdf" width="100%" height="100%">
  //               <p>Alternative text - include a link <a href={rptImg}>to the PDF!</a></p>
  //             </object>}

  //         </div>
  //         {rptImg.split(".")[rptImg.split(".").length - 1] !== "pdf" ?
  //           <div className="form-button">
  //             <i className="fa fa-search-plus" onClick={() => {
  //               var GFG = document.getElementById("geeks");
  //               var currWidth = GFG.clientWidth;
  //               var currHeight = GFG.height;
  //               GFG.style.width = (currWidth + 100) + "px";
  //               GFG.style.height = (currHeight + 100) + "px"
  //             }} >

  //             </i>
  //             <span>&nbsp;&nbsp;</span>
  //             <i className="fa fa-search-minus" onClick={() => {
  //               var GFG = document.getElementById("geeks");
  //               var currWidth = GFG.clientWidth;
  //               var currHeight = GFG.height;
  //               GFG.style.width = (currWidth - 100) + "px";
  //               GFG.style.height = (currHeight - 100) + "px"
  //             }} >

  //             </i>

  //           </div> : ""}
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // }
  const ProcedureImgViewPopup = (props) => {

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {procedureList && procedureList[index] && procedureList[index].proc_image ?
            <>
              <p className='text-right'>
              </p> <div className="form-button" style={{}}>
                <i className="fa fa-search-plus" onClick={() => {
                  var GFG = document.getElementById("geeks");
                  var currWidth = GFG.clientWidth;
                  var currHeight = GFG.height;
                  if (currWidth <= 600) {
                    GFG.style.width = (currWidth + 10) + "px";
                  }
                  if (currHeight <= 700) {
                    GFG.style.height = (currHeight + 10) + "px"
                  }
                }} >

                </i>
                <span>&nbsp;&nbsp;</span>
                <i className="fa fa-search-minus" onClick={() => {
                  var GFG = document.getElementById("geeks");
                  var currWidth = GFG.clientWidth;
                  var currHeight = GFG.height;
                  if (currWidth >= 400)
                    GFG.style.width = (currWidth - 10) + "px";
                  if (currHeight >= 500)
                    GFG.style.height = (currHeight - 10) + "px"
                }} >

                </i>

              </div>
              <p className='text-center'>
                <img className='update-class banner' src={APIURL + procedureList[index].proc_image} width={500} height={600} id="geeks" GFG="250" alt='Scanned Image' />
              </p>
            </>
            : null}


        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const reasonAddHandle = () => {
    if (procedureToggle) {
      setReasonAddShow(false)
      const procedure = procedureList
      procedure[cell].procedure_reason = sendData
      setProcedureList(procedure)
      setEditSuccess(true)
    }
    else {
      setReasonAddShow(false)
      const tests = testsList
      tests[cell].labtest_reason.reason = sendData
      setTestsList(tests)
      setEditSuccess(true)
    }

  }

  const ReasonEditPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={detailData} update={handleSend} />

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

        </Modal.Footer>
      </Modal>
    );
  }

  const ProcedureReasonPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={detailData} update={handleSend} />

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>

        </Modal.Footer>
      </Modal>
    );
  }
  const ReasonAddPopup = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} save={reasonAddHandle} />

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>


        </Modal.Footer>
      </Modal>
    );
  }
  const renderAddedByScan = (cellInfo) => {
    const createdBy = scanList[cellInfo.index].created_by;
    const hospitalName =  scanList[cellInfo.index].hospital_name;
    
    const value = `${createdBy}-${hospitalName}`;
  
    return (
      <input
        style={{ backgroundColor: "#fafafa", whiteSpace: "pre-line" }}
        className="form-control scan-name-col"
        readOnly
        value={value}
        cols="100"
        rows="2"
      />
    );
  };
  

  const columnsScans = [
    {
      Header: <i className="fa fa-trash"></i>,
      Cell: (props) => {
        return (

          <i style={{ marginLeft: "30%" }}
            className="fa fa-trash trash-btn"
            onClick={() => {

              deleteRowScan(props.index, props);
            }}
          ></i>
        );
      },
      width: 50,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Scan Name",
      accessor: "scan_name",
      Cell: renderEditableScan,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,

      sortable: false,
    },
    {
      Header: "Date (YYYY-MM-DD)",
      accessor: "date",
      Cell: renderEditableScanDate,
      sortable: false,

    },

    {
      Header: "Scan Details",
      accessor: "scan_details",
      Cell: renderDetails,
      sortable: false,
      filterable: false,

    },

    {
      Header: "Save/Edit",
      Cell: renderEditScan,
      sortable: false,
      filterable: false,

    },
    {
      Header: "Added By",
      //accessor: "scan_details",
      Cell: renderAddedByScan,
      width: 200,
      sortable: false,
      filterable: false,

    },

    {
      Header: "Add New",
      Cell: (props) => {
        return (props.index === scanList.length - 1 ? scanList[props.index].id ?
          addscan && <button
            className="btn btn-primary btn-col"
            onClick={AddRowScan}
          >
            {" "}
            +
          </button>
          : null : null)
      },

      sortable: false,
      filterable: false,
    },
  ];

  let summaryArray = summary.split(/[\r\n]+/);
  let displaySummary =
    summaryArray.length > 1
      ? summaryArray.map((line, i) => {
        if (line) {
          return (
            <>
              <h6 className="text-left ">
                {" "}
                {i + 1}.{line}{" "}
              </h6>
            </>
          );
        }
      })
      : "-No data to show-";

  let displaySummaryTable = (
    <div className="sub-section inferences-subsection">
      <div className="sub-section-header box-theme">
        <h6 className="sub-section-title">
          {" "}
          Inferences <span className="ref-text">(Ref: last 60 days) </span>
        </h6>
      </div>
      <div className="sub-section-body">{displaySummary}</div>
    </div>
  );
  useEffect(() => {
    if (!isBooped) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);


  const renderAddedByPro = (cellInfo) => {

 
    const createdByPro = procedureList[cellInfo.index].created_by;
    const hospitalNamePro =  procedureList[cellInfo.index].hospital_name;
     
  const value1 = `${createdByPro}-${hospitalNamePro}`;

    return (

      <input
      style={{ backgroundColor: "#fafafa", whiteSpace: "pre-line" }}
      className="form-control scan-name-col"
      readOnly
      value={value1}
        cols="100"
        rows="2"
      />
    );
  
};



  const columnsProcedure = [

    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: props => {
        return (
          <i style={{ marginLeft: "30%" }} className="fas fa-trash-alt trash-btn" onClick={() => { deleteProcedureRow(props.index) }} ></i>
        )
      },
      width: 60,
      sortable: false,
      filterable: false
    },

    {
      Header: <span>Procedure Name
        <h6>(Select from the list only) </h6>
      </span>,
      accessor: "procedure_details",
      Cell: renderEditableProcedure,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      width: 200,
      minWidth: 100,
      sortable: false

    },

    {
      Header: <span>Date
        <h6>(YYYY-MM-DD) </h6>
      </span>,
      accessor: "date",
      Cell: renderEditableProcedureDate,
      sortable: false,
      width: 150


    },
    {
      Header: "Area",
      accessor: "area",
      Cell: renderProcedureArea,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      width: 140,
      minWidth: 100,
      sortable: false

    },
    {
      Header: "Type",
      accessor: "procedure_details",
      Cell: renderProcedureType,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      width: 140,
      minWidth: 100,
      sortable: false

    },
    {
      Header: "Sessions",
      accessor: "session",
      Cell: renderProcedureSession,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },
      width: 140,
      minWidth: 100,
      sortable: false

    },
    {
      Header: "Procedure Details",
      accessor: "details",
      Cell: renderProcedureDetails,
      sortable: false,
      filterable: false,
      width: 200

    },
    {
      Header: "Reason",
      accessor: "procedure_reason",
      Cell: renderProcedureReason,
      sortable: false,
      filterable: false,
      width: 100

    },
    // {
    //   Header: " Assignee",
    //   accessor: "assign_name",
    //   Cell: renderAssigned,
    //   style: {
    //     backgroundColor: "rgba(0,0,0,0.1)"
    //   },
    //   sortable: false,
    //   filterable: false,
    //   width: 200

    // },
    {
      Header: <i class="fas fa-image"></i>,
      accessor: "proc_image",
      Cell: renderProcedureImage,
      sortable: false,
      filterable: false,
      width: 100
    },
    {
      Header: "",

      Cell: renderSaveProcedure,
      width: 80,
      sortable: false,
      filterable: false
    },
    {
      Header: "Added By",
      Cell: renderAddedByPro,
      width: 200,


    },

    {
      Header: "Add",

      Cell: props => {
        return (
          props.index === procedureList.length - 1 ? procedureList[props.index].id ?
            <button className="btn btn-primary btn-col btn-col btn-col btn-small" onClick={AddRowProcedure}>+Add</button>
            : null : null
        )
      },
      width: 80,
      sortable: false,
      filterable: false
    },

  ];

  const submitBmiHandle = () => {
    setAddBmi(false);
    PatientDetails();

  };

  const onVideoCall = () => {
    navigate("/call/1234");
    //TODO - Should be unique room id, accessible to doctor and patient
  }

  return (
    <>

      <br />
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: '50px',
            '& svg circle': {
              stroke: 'rgba(0, 0, 255, 0.5)',

            }
          }),
          overlay: (base) => ({
            ...base,
            background: '',
            position: "initial"

          })
        }}
      >
        <div className=" flex-row-end">
        </div>

        <div className="patient-record-holder">
          <div className="sticky-nav">

            <div className="left-nav">
              <h6  className="form-button">

                <span 
                 
                  id={gender === "female" ? "patient-female" : "patient-male"}
                >
                 <b> {name}</b>
                </span>

              </h6>
              <ul className="stickynav-ul">
                <div className="checkBoxWebmenu">
                  {flag === 1 || flag2 === 2 ?
                    <li
                      onClick={() => {
                        handleClick(ref1);
                      }}
                    >
                      {" "}
                      Personal Data{" "}
                      <OverlayTrigger
                        trigger="hover"
                        rootClose
                        placement="top"
                        overlay={renderTooltip2}
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>{" "}
                    </li>
                    : null}

                  {flag === 1 && flag2 === 2 && login_datas && login_datas.user_type && login_datas.user_type === 'hospital_doctor' ?
                    <li
                      onClick={() => {
                        handleClick(ref1);
                      }}
                    >
                      {" "}
                      Set Next Visit{" "}
                      <OverlayTrigger
                        trigger="hover"
                        rootClose
                        placement="top"
                        overlay={renderTooltip15}
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>{" "}
                    </li>
                    : null}
                  {flag === 1 && flag2 === 2 ?
                    <li
                      onClick={() => {
                        handleClick(ref3);
                      }}
                    >
                      {" "}
                      Diagnostics Info{" "}
                      <OverlayTrigger
                        trigger="hover"
                        rootClose
                        placement="top"
                        overlay={renderTooltip3}
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>{" "}
                    </li>
                    : null}
                  {flag === 1 && flag2 === 2 ?
                    <li
                      onClick={() => {
                        handleClick(ref5);
                      }}
                    >
                      {" "}
                      Observations{" "}
                      <OverlayTrigger
                        trigger="hover"
                        rootClose
                        placement="top"
                        overlay={renderTooltip9}
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>
                    </li>
                    : null}
                  {flag === 1 && flag2 === 2 ?
                    <li
                      onClick={() => {
                        handleClick(ref6);
                      }}
                    >
                      {" "}
                      Symptoms{" "}
                      <OverlayTrigger
                        trigger="hover"
                        rootClose
                        placement="top"
                        overlay={renderTooltip10}
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>
                    </li>
                    : null}
                  {flag === 1 && flag2 === 2 ?
                    <li
                      onClick={() => {
                        handleClick(ref7);
                      }}
                    >
                      {" "}
                      Allergy{" "}
                      <OverlayTrigger
                        trigger="hover"
                        rootClose
                        placement="top"
                        overlay={renderTooltip20}
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>
                    </li>
                    : null}
                  {flag === 1 && flag2 === 2 ?
                    <li
                      onClick={() => {
                        handleClick(ref11);
                      }}
                    >
                      {" "}
                      Procedures{" "}
                      <OverlayTrigger
                        trigger="hover"
                        rootClose
                        placement="top"
                        overlay={renderTooltip26}
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>
                    </li>
                    : null}
                  {flag === 1 && flag2 === 2 ?
                    <li
                      onClick={() => {
                        handleClick(ref12);
                      }}
                    >
                      {" "}
                      Current medication & Online prescription{" "}
                      <OverlayTrigger
                        trigger="hover"
                        rootClose
                        placement="top"
                        overlay={renderTooltip14}
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>{" "}
                    </li>
                    : null}
                  {flag === 1 && flag2 === 2 ?
                    <li
                      onClick={() => {
                        handleClick(ref9);
                      }}
                    >
                      {" "}
                      Treatment Planner{" "}
                      <OverlayTrigger
                        trigger="hover"
                        rootClose
                        placement="top"
                        overlay={renderTooltip11}
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>{" "}
                    </li>
                    : null}
                  {flag === 1 && flag2 === 2 ?
                    <li
                      onClick={() => {
                        handleClick(ref8);
                      }}
                    >
                      Medical Reports{" "}
                      <OverlayTrigger
                        trigger="hover"
                        rootClose
                        placement="top"
                        overlay={renderTooltip12}
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>
                    </li>
                    : null}</div>
                <div className="checkBoxWebpatient" style={{ marginTop: "3%" }}>
                  {flag === 1 && flag2 === 2 && login_datas && login_datas.user_type && login_datas.user_type === 'hospital_doctor' ?
                    <li style={{}}
                    >
                      {" "}
                      Next visit:  <span>&nbsp;</span>  <span>&nbsp;</span><span>&nbsp;</span>{next_visit ? <span style={{ color: "black" }}>  {moment(next_visit).format("DD MMM YYYY")}</span> : <span style={{ color: "black" }}> ---</span>}<span>&nbsp;&nbsp;</span>
                      {isOnline ? <i  class="fa fa-video-camera " aria-hidden="true"></i> : ""}


                    </li>
                    : null}
                  {flag === 1 && flag2 === 2 ?
                    <li

                    >
                      {" "}
                      Upcoming Test:<span>&nbsp;</span><span style={{ fontSize: "15px", color: "black" }}> {testsList.length > 0 && testsList[0].id !== '' ? (
                        <>
                          {testDate ? moment(testDate).format("DD MMM YYYY") : <span> ---</span>}
                        </>
                      ) : <span> ---</span>}</span>



                    </li>
                    : null}
                  {flag === 1 && flag2 === 2 ?
                    <li

                    >
                      {" "}
                      Upcoming Scan:<span>&nbsp;</span><span style={{ fontSize: "15px", color: "black" }}>
                        {scanList.length > 0 && scanList[0].id !== '' ? (
                          <>
                            {scanDate ? moment(scanDate).format("DD MMM YYYY") : <span> ---</span>}
                          </>
                        ) : <span> ---</span>}</span>



                    </li>
                    : null}
                </div>
              </ul>
            </div>
          </div>

          <div className="right-section" >


            {flag2 !== 2 ? <h4> <b>Level-1 Patient</b> </h4> : null}


            <div style={{ justifyContent: 'space-evenly' }}
              className="info section-1 each-section first-contentbox1 " ref={ref1}

            >
              <div style={{ width: '35%' }} className="second-tab sub-section1 ">
                <div className="sub-section-header box-theme">
                  <h6 className="sub-section-title"> {name} </h6>
                </div>
                <div className="sub-section-img ">
                  <img
                    className="profile-picture"
                    src={imgUrl}
                    alt="Profile Photo"
                    width="100px"
                    height="auto"
                  />
                </div>
                <div className="personal-details-grid">
                  <h6 className='text-left'>
                    Gender :  </h6><span className="user-details text-left">{gender}</span>
                  <h6 className='text-left'>
                    Age: </h6><span className="user-details text-left"> {age}</span>{" "}
                  <h6 className="text-left">
                    <b>  Weight:{" "}</b>
                    {weight} kg<span>&nbsp;&nbsp;&nbsp;</span>
                    <span>
                      {weight ? (
                        <i
                          style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                          onClick={handleWeightAdd}
                          className="fa fa-pencil"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          onClick={() => handleWeightAdd()}
                          style={{ justifySelf: "start", color: "#3468c2" }}
                          className="fas fa-plus"
                          aria-hidden="true"
                        ></i>
                      )}
                    </span>
                  </h6>

                  <h6 className='text-left'>
                    <b> Height:{"  "}</b>
                    {height} cm <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>
                      {height ? (
                        <i
                          style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                          onClick={handleHeightAdd}
                          className="fa fa-pencil iconStyle"
                        ></i>
                      ) : (
                        <i
                          onClick={() => handleHeightAdd()}
                          style={{ justifySelf: "start", color: "#3468c2" }}
                          className="fas fa-plus"
                        ></i>
                      )}
                    </span>
                  </h6>

                  <h6 className='text-left'>
                    <b> Family History: {"  "}</b>

                    {famHistory ? <i
                      style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                      onClick={() => handleHistory(familyData?.family_history)}
                      className="fas fa-edit iconStyle"
                    ></i> : <i
                      onClick={() => handleHistoryAdd()}
                      style={{ justifySelf: "start", color: "#3468c2" }}
                      className="fas fa-plus"
                    ></i>}
                  </h6>

                  <h6 className='text-left'><b> Medical History:{"  "}</b>
                    {medHistory ? <i
                      style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                      onClick={() => handleMedHistory(familyData?.past_medical_history)}
                      className="fas fa-edit iconStyle"
                    ></i> : <i
                      onClick={() => handleFamAdd()}
                      style={{ justifySelf: "start", color: "#3468c2" }}
                      className="fas fa-plus"
                    ></i>}</h6>
                  <h6 style={{ width: "100%" }} className=''>
                    <b>  Marital Status:</b>
                    {maritalStatus ? "Married" : "Not Married"} </h6>


                </div>

              </div>

              {login_datas && login_datas.user_type && login_datas.user_type === 'hospital_doctor' && flag2 === 2 ?



                <div className="second-tab sub-section imp-info-subsection">

                  <div className="sub-section-header box-theme">
                    <h6 className="sub-section-title"> Set Next Visit</h6></div>

                  <div className="second-contentbox1">
                    <div className="next-visit-box">
                      <div className="" style={{ display: "flex", fontSize: "13px", }}>
                        <h6 style={{ fontSize: "13px", marginLeft: "2%" }}>consult via: </h6>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <div className="radio-options">
                          <input
                            style={{}}
                            type="radio"
                            id="in_person"
                            name="nextvisit"
                            value="false"
                            checked={isOnline === false}
                            onChange={handleChange}
                          /><span>&nbsp;</span>
                          In-person

                        </div>

                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <div className="radio-options">
                          <input
                            style={{}}
                            type="radio"
                            id="video"
                            name="nextvisit"
                            value="true"
                            checked={isOnline === true}
                            onChange={handleChange}
                          /><span>&nbsp;</span>
                          Online

                        </div>


                        <Link style={{ pointerEvents: linkDisabled ? '' : "none" }} to={{
                          pathname: `/videocall`,
                        }} state={{ patient: id }}
                        ><div style={{ color: linkDisabled ? "var(--teal)" : "silver" }} className="video-button" ><i class="fa fa-video-camera" aria-hidden="true"></i>{"  "}Consultation</div></Link>
                      </div>
                      <div className="d-flex flex-row justify-content-around appFornextvisit" >
                        <div className="d-flex flex-column align-self-center" style={{ width: "40%" }}>
                          <h6 style={{ fontSize: "13px" }}>Select Doctor from list</h6>
                          <input
                            style={{ backgroundColor: "#fafafa" }}
                            onClick={() => {
                              setSearchAssign("");
                              setDisplayDoctor(true);
                            }}
                            value={doctorName}
                            className="form-control"
                            name="assignee"
                            onChange={(e) => {
                              e.preventDefault();

                              setDoctorName(e.target.value);
                              setDisplayDoctor(true);

                              setSearchAssign(e.target.value);
                            }}
                          />

                          {displayDoctor && (
                            <div className="suggestions-box-holder doctor-suggest">
                              <button
                                className="btn-suggestion btn btn-secondary btn-smallest"
                                title="close"
                                onClick={() => setDisplayDoctor(false)}
                              >
                                {" "}
                                X{" "}
                              </button>
                              <div className="suggestions-box suggestions-assignee-box">
                                {assignList.map((item, i) => {
                                  return (
                                    <div
                                      className="scan-item"
                                      key={item.id}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setRender(!render);
                                        setDoctorId(item.id);
                                        setDoctorName(item.full_name);
                                        setDisplayDoctor(false);
                                      }}
                                    >
                                      <div className="search-item each-option-suggestion">
                                        <h6 className=""> {item.full_name}</h6>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          style={{ alignSelf: "flex-start",color:"black" }}
                          className="each-list-cal each-tile box-4   schedule-box "
                        >
                          <div>
                            <DatePicker
                              popperClassName="some-custom-class"
                              popperPlacement="top-end"
                              format="dd-MM-yyyy"
                              value={next}
                              onChange={(date) => {
                                handleNextVisit(date);

                              }}
                              minDate={new Date()}
                              clearIcon={null}
                            />
                            {" "}
                            <i onClick={() => {
                              handleDeleteNextVisit1();
                            }} style={{ color: "red", fontSize: "medium" }} class="fa fa-trash-o" aria-hidden="true"></i>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                  <div className="form-button">
                    <button
                      style={{ alignSelf: "center" }}
                      onClick={saveNextVisit}
                      className="btn btn-primary btn-col btn-square btn-small"
                    >
                      Save Next Visit
                    </button>
                  </div>
                </div>

                : null}

            </div>
            {flag2 === 2 ?
              <div className="each-section summary  section-diagnostic" ref={ref3}>
                <h5 className="sub-title head-strip">
                  {" "}
                  Diagnostics Info{" "}
                </h5>
                <div className="second-contentbox">
                  <div className="sub-section vitals-subsection diagnostics">
                    <div className="sub-section-header box-theme">
                      <h6 className="sub-section-title">
                        {" "}
                        Vitals{" "}
                      </h6>
                    </div>
                    <div className="sub-section-body">
                      <div className="vitals-colv">
                        <div className="vitals-row">
                          <div className="">
                            <br />
                            <button
                              className="btn btn-primary btn-col"
                              onClick={handleSugar}
                            >
                              +
                            </button>
                          </div>
                          <div
                            className="each-list-title each-tile box-4 colored-box colored-box-small"
                            id="vitals-tile-small"
                            onClick={handleGlucoseOverlay}
                          >
                            <span className="history-link">
                              <h6>Blood Sugar </h6>
                            </span>
                          </div>

                          <div
                            className="each-list-title each-tile box-4 colored-box colored-box-small"
                            id="vitals-tile-small"
                            onClick={handlePulseOverlay}
                          >
                            <span className="history-link">
                              <h6> Pulse </h6>
                            </span>
                          </div>
                          <div>
                            <br />
                            <button
                              onClick={handlePulse}
                              className="btn btn-primary btn-col"
                            >
                              +
                            </button>
                          </div>
                        </div>

                        <div className="vitals-row">
                          <div>
                            <br />
                            <button
                              className="btn btn-primary btn-col"
                              onClick={handleBp}
                            >
                              +
                            </button>
                          </div>
                          <div
                            className="each-list-title each-tile box-4 colored-box colored-box-small"
                            id="vitals-tile-small"
                            onClick={handleBpOverlay}
                          >
                            <span className="history-link">
                              <h6>Blood Pressure </h6>
                            </span>
                          </div>

                          <div
                            className="each-list-title each-tile box-4 colored-box colored-box-small"
                            id="vitals-tile-small"
                            onClick={handleBmiOverlay}
                          >
                            <span className="history-link">
                              <h6> BMI </h6>
                            </span>
                          </div>
                          <div>
                            <br />
                            <button
                              onClick={handleBmi}
                              className="btn btn-primary btn-col"
                            >
                              +
                            </button>
                          </div>

                        </div>
                        <div className="vitals-row">
                          <div className="">
                            <br />
                            <button
                              className="btn btn-primary btn-col"
                              onClick={handleChol}
                            >
                              +
                            </button>
                          </div>
                          <div
                            className="each-list-title each-tile box-4 colored-box colored-box-small"
                            id="vitals-tile-small"
                            onClick={handleCholOverlay}
                          >
                            <span className="history-link">
                              <h6>Cholesterol </h6>
                            </span>
                          </div>
                          <div
                            className="each-list-title each-tile box-4 colored-box colored-box-small"
                            id="vitals-tile-small"
                            onClick={handleTempOverlay}
                          >
                            <span className="history-link">
                              <h6> Temperature </h6>
                            </span>
                          </div>
                          <div>
                            <br />
                            <button
                              onClick={handleTemp}
                              className="btn btn-primary btn-col"
                            >
                              +
                            </button>
                          </div>


                        </div>
                        <div className="vitals-row">
                          <div className="">
                            <br />
                            <button
                              className="btn btn-primary btn-col"
                              onClick={handleSp}
                            >
                              +
                            </button>
                          </div>
                          <div
                            className="each-list-title each-tile box-4 colored-box colored-box-small"
                            id="vitals-tile-small"
                            onClick={handleSpOverlay}
                          >
                            <span className="history-link">
                              <h6>SPO2 </h6>
                            </span>
                          </div>
                          <div> <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sub-section primarymed-subsection diagnostics">
                    <div className="sub-section-header box-theme">
                      <h6 className="sub-section-title">
                        {" "}
                        Primary/Chronic Medical Condition{" "}
                      </h6>
                    </div>
                    <div className="sub-section-body comorb-body-content">

                      <Chronic id={id} />

                    </div>
                  </div>
                </div>
                <div className="second-contentbox">
                  <div className="sub-section vitals-subsection diagnostics">
                    <div className="sub-section-header box-theme">
                      <h6 className="sub-section-title">
                        {" "}
                        Comorbidities{" "}
                      </h6>
                    </div>
                    <div className="sub-section-body comorb-body-content">

                      <Comorbidities id={id} />

                    </div>
                  </div>
                  <div

                    className="sub-section vitals-subsection diagnostics"
                  >
                    <div className="sub-section-header box-theme">
                      <h6 className="sub-section-title">
                        {" "}
                        Findings{" "}
                      </h6>
                    </div>
                    <div className="sub-section-body comorb-body-content">

                      <Diagnosis id={id} />

                    </div>
                  </div>
                </div>
              </div>
              : null}
            {/*******************************OBSERVATIONS************/}
            {flag2 === 2 ?
              <div className="each-section summary  section-diagnostic" ref={ref5}>

                <h5 className="sub-title head-strip">
                  {" "}
                  Observations{" "}
                </h5>

                <br />

                {/****placeholder only****/}

                <ViewObs
                  patientId={id}
                  handleObs={handleObs}
                  obs={observationsArray}
                />

                <div className="form-button"
                  onClick={handleObservationsOverlay}
                >
                  <button className="btn btn-primary btn-col btn-col btn-smallest btn-square mb-3">
                    {" "}
                    Add Observations{" "}
                  </button>
                </div>

                {/******placeholder*** ENd*/}
              </div>
              : null}
            {/*****************************OBS END ********************/}
            {flag2 === 2 ?
              <div
                className="each-section summary  section-diagnostic" ref={ref6}

              >
                <h4 className="sub-title head-strip">
                  {" "}
                  Symptoms{" "}

                </h4>

                <br />

                <ViewSymptoms
                  patientId={id}
                  handleObs={handleObs}
                  obs={symptoms}
                />


                <div className="form-button" onClick={handleSymptomsOverlay}>
                  <button className="btn btn-primary btn-col btn-col btn-smallest btn-square">
                    {" "}
                    Add Symptoms{" "}
                  </button>
                </div>

                {/******placeholder*** ENd*/}
              </div>
              : null}
            {flag2 === 2 ?
              <div
                className="each-section summary  section-diagnostic" ref={ref7}

              >
                <h4 className="sub-title head-strip">
                  {" "}
                  Allergy
                </h4>

                <br />

                <ViewAller
                  patientId={id}
                  handleObs={handleObs}
                  obs={allergyArray}
                />
                <div className="form-button" onClick={handleAllergyOverlay}>
                  <button className="btn btn-primary btn-col btn-col btn-smallest btn-square">
                    {" "}
                    Add Allergy{" "}
                  </button>
                </div>

                {/******placeholder*** ENd*/}
              </div>
              : null}
            {flag2 === 2 ?
              <div className="each-section summary  section-diagnostic" ref={ref11} >
                <h5 className="sub-title head-strip">
                  {" "}
                  Procedure Table{" "}</h5>
                <br />
                <ReactTable
                  columns={columnsProcedure}
                  data={procedureList}
                  defaultPageSize={5}
                  style={{ color: "black" }}
                  resizable
                  className="observation-table"
                >

                </ReactTable>
                <br />
                <div style={{ textAlign: 'center' }}>
                </div>

              </div>
              : null}
            {flag2 === 2 ?
              <div
                className="each-section summary  section-diagnostic" ref={ref12}>

                <h5 className="sub-title head-strip">
                  {" "}
                  Current medication & Online prescription{" "}</h5>

                <div className="second-contentbox">
                  {/******Prescription*******/}

                  <CurrentMedications id={id} refresh={medRefresh} />


                  <span className="history-link" onClick={handleAddPrescOverlay}>
                    <button className="btn btn-primary btn-col btn-col btn-small btn-square">
                      {" "}
                      Add Medicines{" "}
                    </button>
                  </span>
                  &nbsp;&nbsp;
                  {/* <span className="history-link" onClick={handleViewPresc}>
                    <button className="btn btn-primary btn-print btn-small btn-square">
                      {" "}
                      View and Print{" "}
                    </button>
                  </span> */}
                  <span>&nbsp;</span>
                  <span className="history-link" onClick={handleAddPrescOverlay1}>
                    <button className="btn btn-primary btn-col btn-col btn-small btn-square">
                      {" "}
                      Past Medications{" "}
                    </button>
                  </span>
                </div>
              </div>
              : null}
            {flag2 === 2 ?

              <div className="each-section summary  section-diagnostic" >
                <h5 className="sub-title head-strip">
                  {" "}
                  Treatment Planner{" "}

                </h5>

                <br />
                <div className="each-section summary  section-diagnostic" ref={ref9}>
                  <h4 className="sub-title">
                    Test Schedules
                  </h4>

                  <br />

                  <ReactTable
                    columns={columns}
                    data={testsList}
                    defaultPageSize={5}
                    style={{ color: "black" }}
                    className="observation-table"
                  ></ReactTable>
                  <br />

                  {deleteTestConfirmMsg === "success" ? (
                    <DeleteTestConfirmPopup
                      show={deleteTestConfirmModalShow}
                      onHide={() => setDeleteTestConfirmModalShow(false)}
                      index={testIndexToDelete}
                      newList={testData1}
                    />
                  ) : (
                    ""
                  )}

                </div>
                <br />

                <div className="each-section summary  section-diagnostic" ref={ref10} >
                  <h4 className="sub-title">
                    Scan Schedules
                  </h4>

                  <br />

                  <ReactTable
                    columns={columnsScans}
                    data={scanList}
                    defaultPageSize={5}
                    style={{ color: "black" }}
                    className="observation-table"
                  ></ReactTable>
                  <br />

                  {deleteScanConfirmMsg === "success" ? (
                    <DeleteScanConfirmPopup
                      show={deleteScanConfirmModalShow}
                      onHide={() => setDeleteScanConfirmModalShow(false)
                      }
                      index={scanIndexToDelete}
                    />
                  ) : (
                    ""
                  )}
                </div>

                {deleteNextVisitConfirmMsg === "success" ? (
                  <DeleteNextVisitConfirmPopup
                    show={deleteNextVisitConfirmModalShow}
                    onHide={() => setDeleteNextVisitConfirmModalShow(false)}
                  />
                ) : (
                  ""
                )}
              </div>
              : null}

            {flag2 === 2 ?
              <div className="each-section summary  section-diagnostic" ref={ref8}>
                <h5 className="sub-title head-strip">
                  Medical Reports

                </h5>

                <br />

                <ReactTable
                  columns={columnsReports}
                  data={item1}
                  defaultPageSize={5}
                  style={{ color: "black" }}
                  className="observation-table"
                ></ReactTable>
                <br />
              </div>
              : null}
            <br />
          </div>

        </div>

        {/********* Set Reminders******/}

        {errorModalShow ? (
          <ErrorPopup
            show={errorModalShow}
            onHide={() => { setErrorModalShow(false); setTestRefresh(!testRefresh); setAddtest(true); }}
          />
        ) : null}

        {scanErr !== "nil" && scanErr !== "false" ? (
          <ErrorScanPopup
            show={errorScanShow}
            onHide={() => { setErrorScanShow(false); setScanRefresh(!scanRefresh); setAddscan(true) }}
          />
        ) : null}



        {nextErr !== "nil" ? (
          <ErrorNextPopup
            show={errorNextModalShow}
            onHide={() => setErrorNextModalShow(false)}
          />
        ) : null}


        {
          procedureReasonShow ?
            <ProcedureReasonPopup
              show={procedureReasonShow}
              onHide={() => {
                setProcedureReasonShow(false)
              }}
              onSave={() => {
                if (sendData !== procedureList[cell].procedure_reason) {
                  setProcedureReasonShow(false)
                  setEditSuccess(true)
                  const procedure = procedureList
                  procedure[cell].procedure_reason = sendData
                  setProcedureList(procedure)

                }
              }} /> : null
        }

        {glucoseShow ? (
          <GlucosePopup
            show={glucoseShow}
            onHide={() => setGlucoseShow(false)}
            id={id}
          />
        ) : null}

        {bpShow ? (
          <BpPopup show={bpShow} onHide={() => setBpShow(false)} id={id} />
        ) : null}


        {inferenceShow ? (
          <InferencePopup
            show={inferenceShow}
            onHide={() => setInferenceShow(false)}
          />
        ) : null}
        {symptomsShow ? (
          <SymptomsPopup
            show={symptomsShow}
            onHide={() => setSymptomsShow(false)}
          />
        ) : null}
        {submsg2 !== "" ? (
          <SubmitUpdated
            show={submsg2}
            onHide={() => {
              setSubMsg2(false);
              setTestRefresh(!testRefresh)
            }}
          />
        ) : (
          ""
        )}

        {pulseShow ? (
          <PulsePopup
            show={pulseShow}
            onHide={() => setPulseShow(false)}
            id={id}
          />
        ) : null}
        {tempshow ? (
          <TempPopup
            show={tempshow}
            onHide={() => setTempShow(false)}
            id={id}
          />
        ) : null}
        {spshow ? (
          <SpPopup
            show={spshow}
            onHide={() => setSpShow(false)}
            id={id}
          />
        ) : null}

        {addBmi ? (
          <AddBmiPopup
            show={addBmi}
            onHide={() => {
              setAddBmi(false);
            }}
          />
        ) : null}

        {bmiShow ? (
          <BmiPopup
            show={bmiShow}
            onHide={() => setBmiShow(false)}
            id={id}
          />
        ) : null}

        {cholesterolShow ? (
          <CholesterolPopup
            show={cholesterolShow}
            onHide={() => setCholesterolShow(false)}
            id={id}
          />
        ) : null}

        {inferenceShow ? (
          <InferencePopup
            show={inferenceShow}
            onHide={() => setInferenceShow(false)}
          />
        ) : null}

        {
          submitMsg1 === "error" ? (
            <SubmitErrorPopup1
              show={errorSubmit1}
              onHide={() => {
                setErrorSubmit1(false);
                setSubmitMsg1("");
              }}
            />
          ) : null
        }
        {deleteNextMsg !== "" ? (
          <DeletePopUp
            show={deleteNextModalShow}
            onHide={() => {
              setDeleteNextModalShow(false);
              setReRender(!reRender);

              setDeleteNextMsg("");
            }}
          />
        ) : (
          ""
        )}
        {submitMsg === "success" ? (
          <SubmitPopUp
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setAddtest(true)
              setAddscan(true)
              // setReRender(!reRender);
              //setRefresh(!refresh)
              setErrorMessage("");
              setDoctorName(logindatas.doctor_name);
              setSearchAssign("");
              setSubmitMsg("");
              // refreshPage();
              setProcedureRefresh(!procedureRefresh);
              setScanRefresh(!scanRefresh);
              setTestRefresh(!testRefresh)


            }}
          />
        ) : submitMsg === "error" ? (
          <SubmitErrorPopup
            show={errorSubmit}
            onHide={() => {
              setErrorSubmit(false);
              setSubmitMsg("");
              setTestRefresh(!testRefresh)
              // refreshPage();
            }}
          />
        ) : (
          ""
        )}


        {sizeShow ? (
          <SizePopUp
            show={sizeShow}
            onHide={() => {
              setSizeShow(false);
            }}
          />
        ) : null}

        {uploadShow ? (
          <LoadPopUp
            show={uploadShow}
            onHide={() => {
              setUploadShow(false);
            }}
          />
        ) : null}

        {validShow ? (
          <ValidPopUp
            show={validShow}
            onHide={() => {
              setValidShow(false);
            }}
          />
        ) : null}

        {contentShow ? (
          <ContentPopUp
            show={contentShow}
            onHide={() => {
              setContentShow(false);
            }}
          />
        ) : null}

        {popupShow ? (
          <EditPopup
            show={popupShow}
            onHide={() => {
              setPopupShow(false);
            }}
            onSave={() => {
              if (imgFile !== null) {
                setPopupShow(false);
                const scans = scanList;
                scans[index].scan_details.image = imgFile;
                setScanList(scans);
                setEditSuccess(true);
              }
            }}
          />
        ) : null}

        {imgEditShow ? (
          <ImgEditPopup
            show={imgEditShow}
            onHide={() => {
              setImgEditShow(false);
            }}
            onSave={() => {
              if (imgFile !== null) {
                setImgEditShow(false);
                const tests = testsList;
                tests[index].labtest_reason.image = imgFile;
                setTestsList(tests);
                setEditSuccess(true);
              }
            }}
          />
        ) : null}


        {
          imageEditShow ?
            <ProcedureImgPopup
              show={imageEditShow}
              onHide={() => {
                setImageEditShow(false)
              }}
              onSave={() => {
                if (imgFile !== null) {
                  setImageEditShow(false)
                  const procedure = procedureList
                  procedure[index].proc_image = imgFile
                  setProcedureList(procedure)
                  setEditSuccess(true)

                } else {
                  setImgErrorShow(true)
                }

              }}
            /> : null
        }
        {
          imgErrorShow ?
            <UpdateImgPopup
              show={imgErrorShow}
              onHide={() => {
                setImgErrorShow(false)
              }}

            /> : null
        }
        {/* {
          imageRptEditShow ?
            <ReportImgPopup
              show={imageRptEditShow}
              onHide={() => {
                setRptImageEditShow(false)
              }}

            /> : null
        } */}

        {
          imageViewShow ?
            <ProcedureImgViewPopup
              show={imageViewShow}
              onHide={() => {
                setImageViewShow(false)
              }}

            /> : null
        }


        {remarkViewShow ? (
          <RemarkViewPopup
            show={remarkViewShow}
            onHide={() => {
              setRemarkViewShow(false);
            }}
          />
        ) : null}
        {procedureMsg !== "" ? (
          <ProcedureUpdatePopUp
            show={procedureShow}
            onHide={() => { setProcedureShow(false); setProcedureRefresh(!procedureRefresh) }}
          />
        ) : (
          ""
        )}

        {editSuccess ? (
          <DetailsUpdateSuccessPopUp
            show={editSuccess}
            onHide={() => {
              setEditSuccess(false);
            }}
          />
        ) : null}

        {addShow ? (
          <AddPopup
            show={addShow}
            onHide={() => {
              setAddShow(false);
            }}
          />
        ) : null}
        {
          historyEditShow ?
            <HistoryPopup
              show={historyEditShow}
              onHide={() => {
                setHistoryEditShow(false)
              }}
              onSave={() => {
                if (sendData !== familyData.family_history) {
                  setHistoryEditShow(false);
                  const data = familyData
                  data.family_history = sendData
                  callSaveDetails()
                }
              }} /> : null
        }

        {editShow ? (
          <DetailPopup
            show={editShow}
            onHide={() => {
              setEditShow(false);
            }}
            onSave={() => {
              if (sendData !== scanList[cell].scan_details) {
                setEditShow(false);
                setEditSuccess(true);
                const scans = scanList;
                scans[cell].scan_details = sendData;
                setScanList(scans);

              }
            }}
          />
        ) : null}

        {viewShow ? (
          <ViewPopup
            show={viewShow}
            onHide={() => {
              setViewShow(false);
            }}
          />
        ) : null}
        {
          reasonAddShow ?
            <ReasonAddPopup
              show={reasonAddShow}
              onHide={() => {
                setReasonAddShow(false)
              }}
            /> : null
        }
        {reasonEditShow ? (
          <ReasonEditPopup
            show={reasonEditShow}
            onHide={() => {
              setReasonEditShow(false);
            }}
            onSave={() => {
              if (sendData !== testsList[cell].labtest_reason.reason) {
                setReasonEditShow(false);
                setEditSuccess(true);
                const tests = testsList;
                tests[cell].labtest_reason.reason = sendData;
                setTestsList(tests);
              }
            }}
          />
        ) : null}

        {procedureErr !== "" ?
          <ErrorProcedurePopup
            show={errorShow}
            onHide={() => setErrorShow(false)}

          />

          : null}

        {procedureDelete ?
          <DeleteSuccessPopup
            show={procedureDelete}
            onHide={() => { setProcedureDelete(false); setProcedureRefresh(!procedureRefresh); }}

          />

          : null}

        {procedureUpdate &&
          <UpdateSuccessPopup
            show={procedureUpdate}
            onHide={() => setProcedureUpdate(false)}

          />}

        {messagesShow === false ? (
          <div
            className="dr-msgs-basic-sticky "
            onClick={handleMessagesOverlay}
            title="Message via Felixa Messenger"
          >
          </div>
        ) : null}


        {observationsShow ? (
          <ObservationsPopup
            show={observationsShow}
            onHide={() => setObservationsShow(false)}
            id={id}
          />
        ) : null}

        {messagesShow ? (
          <MessagesPopup show={messagesShow} onHide={handleTrack} id={id} />
        ) : null}

        {weightAddShow ? (
          <WeightAddPopup
            show={weightAddShow}
            seteditweight={weight}
            onHide={() => {
              setWeightAddShow(false);
            }}
          />
        ) : null}

        {heightAddShow ? (
          <HeightAddPopup
            show={heightAddShow}
            seteditheight={height}
            onHide={() => {
              setHeightAddShow(false);
            }}
          />
        ) : null}
        {allergyShow ? (
          <AllergyPopup
            show={allergyShow}
            onHide={() => setAllergyShow(false)}
          />
        ) : null}
        {editProcedureShow ? (
          <EditProcedurePopUp
            show={editProcedureShow}
            onHide={() => setEditProcedureShow(false)}
          />
        ) : null}

        {addPulse ? (
          <AddPulsePopup
            show={addPulse}
            onHide={() => {
              setAddPulse(false);
            }}
          />
        ) : null}
        {addTemp ? (
          <AddTempPopup
            show={addTemp}
            onHide={() => {
              setAddTemp(false);
            }}
          />
        ) : null}
        {addSp ? (
          <AddSpPopup
            show={addSp}
            onHide={() => {
              setAddSp(false);
            }}
          />
        ) : null}

        {addBp ? (
          <AddBpPopup
            show={addBp}
            onHide={() => {
              setAddBp(false);
            }}
          />
        ) : null}
        {addChol ? (
          <AddCholestrolPopUp
            show={addChol}
            onHide={() => {
              setAddCholestrol(false);
            }}
          />
        ) : null}


        {addSugar ? (
          <AddSugarPopup
            show={addSugar}
            onHide={() => {
              setAddSugar(false);
            }}
          />
        ) : null}

        {deleteProcedureConfirmMsg === "success" ?

          <DeleteProcedureConfirmPopup
            show={deleteProcedureConfirmModalShow}
            onHide={() => setDeleteProcedureConfirmModalShow(false)}
            index={procedureIndexToDelete}
          />

          :
          ''}

        {
          historyShow ?
            <ProcedureHistoryPopup
              show={historyShow}
              onHide={() => {
                setHistoryShow(false)
              }}
            /> : null
        }
        {addPrescShow ? (
          <AddPrescPopup
            show={addPrescShow}
            onHide={() => setAddPrescShow(false)}
            id={id}
          />
        ) : null}

        {addPrescShow1 ? (
          <AddPrescPopup1
            show={addPrescShow1}
            onHide={() => setAddPrescShow1(false)}
            id={id}
          />
        ) : null}
        {viewPrescShow ? (
          <ViewPrescPopup
            show={viewPrescShow}
            onHide={() => setViewPrescShow(false)}
            id={id}
          />
        ) : null}
        {
          medEditShow ?
            <MedHistoryPopup
              show={medEditShow}
              onHide={() => {
                setMedEditShow(false)
              }}
              onSave={() => {
                if (sendData !== familyData.past_medical_history) {
                  setMedEditShow(false);
                  const data = familyData
                  data.past_medical_history = sendData
                  setFamilyDatas(data)
                  callSaveDetails()

                }
              }} /> : null
        }
        {
          complaintEditShow ?
            <ComplaintPopup
              show={complaintEditShow}
              onHide={() => {
                setComplaintEditShow(false)
              }}
              onSave={() => {
                if (sendData !== familyData.present_complaint) {
                  setComplaintEditShow(false);
                  const data = familyData
                  data.present_complaint = sendData
                  setFamilyDatas(data)
                  callSaveDetails()

                }
              }} /> : null
        }
        {
          editDetailSuccess ?
            <PatientDetailsPopUp
              show={editDetailSuccess}
              onHide={() => {
                setEditDetailSuccess(false);
                setRefresh(!refresh)
              }}

            /> : null
        }
        {
          complaintAddShow ?
            <ComplaintAddPopup
              show={complaintAddShow}
              onHide={() => {
                setComplaintAddShow(false)
              }}
              onSave={() => {
                if (sendData !== familyData.present_complaint) {
                  setComplaintAddShow(false);
                  const data = familyData
                  data.present_complaint = sendData
                  setFamilyDatas(data)
                  callSaveDetails()

                }
              }} /> : null
        }
        {
          medAddShow ?
            <MedHistoryAddPopup
              show={medAddShow}
              onHide={() => {
                setMedAddShow(false)
              }}
              onSave={() => {
                if (sendData !== familyData.past_medical_history) {
                  setMedAddShow(false);
                  const data = familyData
                  data.past_medical_history = sendData
                  setFamilyDatas(data)
                  callSaveDetails()

                }
              }} /> : null
        }
        {
          historyAddShow ?
            <HistoryAddPopup
              show={historyAddShow}
              onHide={() => {
                setHistoryAddShow(false)
              }}
              onSave={() => {
                if (sendData !== familyData.family_history) {
                  setHistoryAddShow(false);
                  const data = familyData
                  data.family_history = sendData
                  setFamilyDatas(data)
                  callSaveDetails()
                }
              }} /> : null
        }
        {rptModalShow ? (
          <AddRptPopup
            show={rptModalShow}
            onHide={() => {
              setRptModalShow(false);
            }}
          />
        ) : null}
      </LoadingOverlay>
    </>

  );
}
export default PatientRecord;
