
import React, { useState, useEffect, useRef } from "react";

import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


import "./hospitalnavbar.css";

function HospitalNavbar() {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const webSocket = useRef(null);




  
  // useEffect(() => {
  //   const tokenString = sessionStorage.getItem("usertoken");
  //   // const hospId = localStorage.getItem("hospId");
  //   let str = tokenString.replace(/["]+/g, "");
  //   webSocket.current = new WebSocket(
  //     "wss://pcpapi.cianlogic.com/ws/service_request/staff/?token=" +
  //       str
  //   );
  //   webSocket.current.onopen = () => {
  //     // on connecting, do nothing but log it to the console
  //     console.log("WebSocket connected");
  //   };

  //   webSocket.current.onmessage = (evt) => {
  //     // listen to data sent from the websocket server
  //     const message = JSON.parse(evt.data);
  //     console.log(message);
  //     console.log(message.message.unread_count);
  //     setCount(message.message.unread_count);
  //   };

  //   webSocket.current.onclose = () => {
  //     console.log("disconnected");
  //     // automatically try to reconnect on connection loss
  //   };
  // }, []);

  return (
    <nav className="hosp-navbar">
      {/*<img
        src="http://intpurple.com/wp-content/uploads/2020/04/logo_small.png"
        className="logo"
        alt="logo"
      /> */}

      <div className="btns">
        <button className="btn nav-btn">
          <NavLink
            exact
            className="inactive"
            activeClassName="active"
            to="/hospital"
          >
            {" "}
            <i className="fas fa-home"></i> {t("HOME")}
          </NavLink>
        </button>
        <button className="btn nav-btn">
          <NavLink
            className="inactive"
            activeClassName="active"
            to="/doctorslist"
          >
            {t("DOCTORS LIST")}{" "}
          </NavLink>
        </button>
        <button className="btn nav-btn">
          <NavLink className="inactive" activeClassName="active" to="/bdo-list">
            {" "}
            {t("BDO LIST")}{" "}
          </NavLink>
        </button>
        <button className="btn nav-btn">
          <NavLink className="inactive" activeClassName="active" to="/sda-list">
            {" "}
            {t("SDA LIST")}{" "}
          </NavLink>
        </button>
        
        <button className="btn nav-btn">
          <NavLink className="inactive" activeClassName="active"        // to="/servicelist"
            to="/notifications"
            
          >
             <div 
          style={{ pointerEvents: "none"}}
          className="notification"
        >
           <i
            className="fas fa-bell fa-1x"
            aria-hidden="true"
          ></i>
          <span
            style={{ position: "absolute", top: "-6px", right: "-6px", fontSize: 10 }}
            className="badge"
          >
            {count}
          </span> </div>
          &nbsp; {t("Notifications")}{" "}
          </NavLink>
        </button>
        {/* <button className="btn login-btn">
          <Link to="/login">Login </Link>
        </button>
        <button className="btn">
          <Link to="/register"> Register </Link>
        </button> */}
      </div>
    </nav>
  );
}

export default HospitalNavbar;
