import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import Map from "./Map";
// import GoogleMapReact from 'google-map-react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Autocomplete from 'react-google-autocomplete';

import "../css/bootstrap.min.css";
import "../css/iofrm-style.css";
import "../css/iofrm-theme4.css";
import "./register.css";
import axios from "axios";

import "./country_region_selector.css";
import "./phoneinput.css";
import "./hospregister.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { APIURL } from "../../Global";

import AuthUtil from "../login/AuthUtil";


function ServiceProviderRegister(props) {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [img, setImg] = useState();
  const [emailshow, setemailshow] = useState();
  const [latitude, setLatitude] = useState(13.0334);
  const [longitude, setLongitude] = useState(77.5640);

  const [hospitalDatas, setHospitalDatas] = useState({
    name: "",
    phone: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
    email: "",
    country: "India",
    state: "",
    location: "",
    street: "",
    password: "",
    confirmpassword: "",
    latitude: "",
    longitude: "",
    pincode: "",
    accreditation: null,
    regn_number: "",
    // //contact_phone: {
    //   dialcode: "",
    //   rawphone: "",
    //   mobile_number: "",
    // },
    primary_email: "",
    website_url: "",
    established_year: null,
    healthcare_type: "",
    delivery_radius:"",
    img_url: "",
    img: "",
    gst: "",
    description: "",
    emergency: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
  });
  const [error, setError] = useState({
    name: "",
    phone: "",
    email: "",
    country: "",
    state: "",
    location: "",
    address: "",
    pincode: "",
    street: "",
    password: "",
    confirmpassword: "",
    delivery_radius:"",
    regn_number: "",
    healthcare_type: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidRegis, setIsValidRegis] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [invalidPic, setInvalidPic] = useState(null);
  const [previewOn, setPreviewOn] = useState(false);
  const [formDataStatus, setFormDataStatus] = useState(false);
  const [sizeShow, setSizeShow] = useState(false);
  const [validShow, setValidShow] = useState(false);
  const [contentShow, setContentShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [isMapError, setIsMapError] = useState(false);
  const [isMapSucess, setIsMapSucess] = useState(false);

  const [errorSubmitMsg, setErrorSubmitMsg] = useState(null);
  const [errorSubmit, setErrorSubmit] = useState(false);

  const [successSubmitMsg, setSuccessSubmitMsg] = useState(null);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [confirmDelete, setDeleteShow] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  let formData = new FormData();

  const name = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const confirmpassword = useRef(null);
  const mobile_number = useRef(null);
  const emergency = useRef(null);
  const country = useRef(null);
  const region = useRef(null);
  const location = useRef(null);
  const address = useRef(null);
  const pincode = useRef(null);
  const street = useRef(null);
  const website_url = useRef(null);
  const primary_email = useRef(null);
  const established_year = useRef(null);
  const delivery_radius = useRef(null);
  const regn_number = useRef(null);
  const togglePasswordVisiblity = (e) =>
  {
  
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const togglePasswordVisiblity1 = (e) =>
  {
  
    if(passwordType1==="password")
    {
     setPasswordType1("text")
     return;
    }
    setPasswordType1("password")
  }
  useEffect(() => {
    let input = hospitalDatas;
    if (
      typeof input["password"] !== "undefined" &&
      input["password"] !== "" &&
      typeof input["confirmpassword"] !== "undefined" &&
      input["confirmpassword"] !== "" &&
      input["confirmpassword"] !== null
    ) {
      if (input["password"] !== input["confirmpassword"]) {
        setError((currentstate) => ({
          ...currentstate,
          confirmpassword: "Passwords don't match",
        }));
      } else {
        setError((currentstate) => ({
          ...currentstate,
          confirmpassword: "",
        }));
      }
    } else if (input["confirmpassword"] === "") {
      setError((currentstate) => ({
        ...currentstate,
        confirmpassword: "",
      }));
    }
  }, [hospitalDatas]);

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setHospitalDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
  };

  const reader = new FileReader();
  const uploadImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      setUploadShow(!uploadShow);
      //setInvalidPic('Please Select an Image')
      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow);
      //setInvalidPic('Please select valid image')
      return false;
    }
    if (file.size > 2e6) {
      setSizeShow(!sizeShow);
      return false;
    }

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        setImg(file);
        setInvalidPic(null);
      };

      img.onerror = () => {
        setPreviewOn(false);
        setContentShow(!contentShow);
        //setInvalidPic('Invalid Image Content')
        return false;
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);

    const objectURL = URL.createObjectURL(file);

    setHospitalDatas((currentstate) => ({
      ...currentstate,
      img_url: objectURL,
    }));
    setPreviewOn(true);

    setFormDataStatus(true);
  };

  const setMapError = (lat, lng) => {
    setIsMapError(true);
    setLatitude(lat);
    setLongitude(lng);
  }

  const newCoordinates = (lat, lng, address, city, state, pincode) => {
    // console.log('THE LAT LONG', lat, lng, address, city, state);
    // console.log('THE ADDRESS', address);
    // console.log('THE CITY', city);
    // console.log('THE STATE', state);
    let data = {...hospitalDatas}
    data.state = state;
    data.location = city;
    data.street = address;
    data.latitude = parseFloat(lat).toFixed(4);
    data.longitude = parseFloat(lng).toFixed(4);
    data.pincode = pincode;
    setError((currentstate) => ({
      ...currentstate,
      address: "",
      street: "",
      location: "",
      pincode: "",
      state: "",
    }));
    setHospitalDatas(data);
    setLatitude(lat);
    setLongitude(lng);
    setTimeout(() => {
      setIsMapSucess(true);
    }, 300);
  }

  const SizePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 id="confirm-reject">You have exceeded image size limit 2MB</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const MapError = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>Please Select Address within India</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const MapSuccess = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ }}>You have selected the following Address</h5>
          <br/>
            <label className="">Latitude: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.latitude}</span><br/>
            <label className="">Longitude: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.longitude}</span><br/>
            <label className="">Address: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.street}</span><br/>
            <label className="">Location/City: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.location}</span><br/>
            <label className="">State: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.state}</span><br/>
            <label className="">Pincode: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.pincode}</span>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const LoadPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please select an Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ValidPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please select a valid Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ConfirmPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          <h4 id="confirm-reject">Are you sure you want to delete the uploaded image ?</h4>
           
          </Modal.Body>
          <Modal.Footer>
  
             <Button variant="secondary" onClick={props.onHide}>No</Button>
             <Button variant="danger" onClick={() => {
               removePicPreview();
               setDeleteShow(false);
             }}>Yes</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const PrivacyPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Privacy Policy
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="privacy-modal">
            
           <p>
           Introduction
3DOC is a website and mobile application owned and managed by IntPurple Technologies LLP.

This Privacy Policy (“Privacy Policy” and “Policy”) is applicable to the use of 3DOC available in the form of a website at 3DOCand mobile application, and any of our websites, products and/or services that explicitly indicate the applicability of this Policy (collectively, “Service(s)”), by you (“You”, “Your”, “User” and/or “Customer”).  Please read this document carefully and ensure that You understand its contents before using Our Service(s).

The purpose of this Privacy Policy is to explain the manner in which Company Replace may collect, receive, use, store, share, disclose, transfer, and process personal information (collectively, “Process”, “Processed”, “Processing”). This Privacy Policy applies to personal information collected by Company Replace or explicitly provided by the User.

The use of the Service is subject to the Terms and Conditions available at 3DOC/terms.html (“Terms”).

This Privacy Policy is subject to change. Please see the date provided on this document/page and the pages linked above to know when this document was last modified.

Types of Personal Information that is Processed
We believe in the principle of data minimization. The Company collects and processes only information that it believes is reasonably required for providing its services and carrying out its functions. In particular, the Company may Process the following types of information:

Information provided by You:Your identity and contact information such as your name, gender, date of birth, address, email address, phone number and password;Photos, videos, and medical data (such as Lab reports), uploaded by you;Decisions made by Users regarding accuracy of outputs provided by the ServiceDirect communications with Us, and Your public or private feedback; andCommunications between You and other users of the Service, including but not limited to text, photos, videos, and documents, sent or received by You through the Service. Direct communications between users are not end-to-end encrypted.
Information collected automatically:
Technical details such as information regarding Your Internet Protocol address (IP address), app version, operating system, device related information, information related to Your web browser and network performance metrics;
Details about Your use of the Service such the manner in which the Service is used, which of Our Services are used, time when our Services are used, and duration of use;
Details regarding Services provided to You, such as which Service was provided, date and time of the Service provided, and reports/analysis/outputs generated;
Details of payments between end users
In addition to the above, a unique identifier may be associated with each account and/or app install.
Purposes for which Personal Information is Processed
Use of the Service. Your information may be Processed to provide:
The ability for users to communicate with each other;
Automated analysis of medical data, such as lab report data, through the use of technologies that are popularly known as artificial intelligence;
The ability to store and retrieve information;
Confirming receipt of payments, and processing refunds where relevant under the Terms
Any other feature of the Service.
To send important information. We may contact You in case we need to send important information to You, such as transaction details, important notifications, or changes to the Terms, this Policy or other relevant documentation. You cannot stop these communications as long as You have an account with Us.
To de-identify information. We may de-identify any information in Our possession (“De-identified Information”). De-identified Information shall be owned by Company Replace. De-identified Information shall not be subject to the Terms or this Privacy Policy.
Improvement of Services. Your device and usage information, performance metrics, details of Your interactions with the Services, and feedback provided by You, may be used to improve the Services.
Marketing.
We may send direct marketing communications to You, such as emails to inform You about new features or new services. If You do not wish to continue receiving such communications from Us, please follow the instructions for unsubscribing as provided in each communication.
We may publish your comments, reviews, feedback, or testimonials (collectively, “published feedback”). Such published feedback may contain certain personal information such as the name of the person that provided the feedback. Please contact us if you feel that a published feedback has been published with your personal information without your consent, or if you wish to remove a published feedback that you previously consented to.
Your activities on the Service may be used to offer relevant advertising to You on the Service and/or on other websites or apps.
Security. We may Process Your personal information for the purpose of ensuring that the security of the Service is not compromised, to enhance the security of the Service, to inform You in case of a security breach, and to take legal action against security breaches.
To take legal actions. We may use Your information to prosecute or otherwise take legal action in case of security incidents, overdue payments, breach of Terms, fraud, misrepresentation, identity theft, or any other violation of law.
Users may choose to upload images or videos of clinical conditions for the purposes of discussion with peers, or for consultation with the AI based platform.
On peer-to-peer sharing of information, the platform will simply function as a medium for dissemination of information and does not assume any liability for the information shared.
Company Replace will take all reasonable precautions to protect / deidentify any such information shared on the app. Images are uploaded on the app under the understanding that the user has the permission to do so, and the user and the party whose information is shared indemnifies Company Replace against any involuntary leakage or breach of privacy.
Disclosures of Personal Information
Service Providers. We may disclose Your information to Our service providers, such as our data storage provider, payment processor, and other service providers.
Marketing. We may disclose certain information to our advertising partners to provide interest-based advertisements to You, and to show advertisements regarding Our products and services on other websites, apps, and services.
Legal Disclosures. We may disclose Your information as required by applicable laws, in reply to legal requests by public authorities, including meeting national security or law enforcement requirements, and when We believe that disclosure is necessary to protect Our rights and/or to comply with a judicial proceeding, court order, or other legal process served on Us.
Our group companies. Your personal data may be shared between Our group companies for the activities permitted under this Policy.
Your Rights
Compliance with Your Rights. Please allow reasonable time for Us to review Your request. We may request additional information or require verification of Your identity before taking any action on Your request. We reserve the right to, at Our sole discretion, charge a reasonable fee for compliance with Your request. If You believe that We have failed to comply with Your rights, please contact Our Grievance Officer as provided at the bottom of this Policy.
Access to information. You may request a copy of information that is associated with Your account. It is not possible for Us to provide access to any De-identified Information, as such information cannot be associated with any identifiable individual or account.
Correction of information. Certain information may be corrected by You through the options available to You in Your account. If You require correction in any other personal information, please contact Us. We may refuse to correct certain information if We believe that the incorrect information would not affect Your rights under the Terms, this Policy, and applicable laws.
Objection to direct marketing. You may opt-out of direct marketing communications such as marketing email sent by Us, by following the instructions provided in such communication to opt out of (or unsubscribe from) such communications.
Miscellaneous
Links to Third Party Websites. The Service may contain links to third-party websites or services. Please be cautious while sharing or viewing information using such links. We are not responsible for the privacy practices of third-party websites or services. We advise you to carefully read and understand the privacy policy and terms of service of any such third-party website or service.
Modifications of this Privacy Policy. We may modify this Policy from time to time. If We do this, We will post the modified Policy on this page and will indicate the date this Policy was last updated. Any such modifications will become effective immediately for all new accounts created on the Service and all orders placed on the Service after the date of such update. For existing accounts and existing orders, the new Policy will become effective fourteen days after the date on which they are posted (unless a longer period is provided by Us for such new terms to go into effect).
Contact Information. If you have any queries about this privacy policy or your dealings with us, you may contact us at privacy@intpurple.com
In case You wish to contact Our Grievance Officer, You may do so at:
Name: Faslurahman

Contact information: faslu@intpurple.com
           </p>
          </Modal.Body>
          <Modal.Footer>
  
             <Button variant="danger" onClick={() => {
               setPrivacy(false);
               setShowPrivacy(false);
             }}>Decline</Button>
             <Button className="formFieldButton" onClick={() => {
               setPrivacy(true);
               setShowPrivacy(false);
             }}>Accept Policy</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const ContentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Invalid Image Content</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {errorSubmitMsg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitSuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h4>Registered successfully !!</h4>
          <h6>An email verification has been send to your email id {hospitalDatas.email}. Please verify the email</h6>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>

          <Link to="/webordering">
            <Button className="btn btn-primary btn-col">Goto Login</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    );
  };

  const removePicPreview = () => {
    // e.preventDefault();
    setPreviewOn(false);
  };

  const handleContinue = async (e) => {
    e.preventDefault();

    const res = await validate();

    if (res === true && isValidEmail && isValidPhone) {
      handleSignup();
    }
  };
  const validate = async () => {
    let focussed = false;
    let input = hospitalDatas;
    let errors = {};
    let isValid = true;
    setError({});

    if (input["name"].trim() === "" || input["name"].trim() === null) {
      isValid = false;
      errors["name"] = "Please enter service provider name";
      if (!focussed) {
        name.current.focus();
        focussed = true;
      }
    }

    if (typeof input["name"] !== "undefined") {
      var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern3.test(input["name"])) {
        isValid = false;
        errors["name"] = "Special Characters are not allowed";
        if (!focussed) {
          name.current.focus();
          focussed = true;
        }
      }
    }

    if (typeof input["email"] !== "undefined") {
      const pattern2 = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern2.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
        if (!focussed) {
          email.current.focus();
          focussed = true;
        }
      }
    }

    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
      if (!focussed) {
        email.current.focus();
        focussed = true;
      }
    }
    if (
      typeof input["email"] === "undefined" ||
      input["email"].trim() === null ||
      input["email"].trim() === ""
    ) {
      isValid = false;

      errors["email"] = "Please enter email Address.";
      if (!focussed) {
        email.current.focus();
        focussed = true;
      }
    }


    if (typeof input["password"] !== "undefined") {
      let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,20}/);
      if (!pattern.test(input["password"])) {
        isValid = false;
        errors["password"] = "Password must contain at least 1 UPPERCASE, 1 lower case, 1 number, 1 special char(@$!#^%*?&) and don't allow space";
        if (!focussed) {
          password.current.focus();
          focussed = true;
        }
      }
    }

    if (input["password"].trim() && typeof input["password"] !== "undefined") {
      if (input["password"].length > 0 && input["password"].length < 8) {
        isValid = false;

        errors["password"] = "Must contain atleast 8 characters";
        if (!focussed) {
          password.current.focus();
          focussed = true;
        }
      }
    }
    if (
      input["password"].trim() === null ||
      typeof input["password"] === "undefined" ||
      input["password"].trim() === "" ||
      input["password"].trim() === null
    ) {
      isValid = false;
      errors["password"] = "Please enter your password";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }

    if (
      typeof input["password"] !== "undefined" &&
      input["password"].trim() !== "" &&
      typeof input["confirmpassword"] !== "undefined" &&
      input["confirmpassword"].trim() !== "" &&
      input["confirmpassword"].trim() !== null
    ) {
      if (input["password"] !== input["confirmpassword"]) {
        isValid = false;
        errors["confirmpassword"] = "Passwords don't match";
        if (!focussed) {
          confirmpassword.current.focus();
          focussed = true;
        }
      }
    }

    if (
      input["confirmpassword"].trim() === null ||
      typeof input["confirmpassword"] === "undefined" ||
      input["confirmpassword"].trim() === ""
    ) {
      isValid = false;
      errors["confirmpassword"] = "Please enter confirm password";
      if (!focussed) {
        confirmpassword.current.focus();
        focussed = true;
      }
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }
    if (
      hospitalDatas.phone.dialcode === null ||
      hospitalDatas.phone.dialcode === "" ||
      !hospitalDatas.phone.rawphone.trim() ||
      hospitalDatas.phone.rawphone.trim() === ""
    ) {
      isValid = false;
      errors["phone"] = "Please enter phone number";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }
    if (hospitalDatas.phone.rawphone.length > 0 && hospitalDatas.phone.rawphone.length < 10) {
      isValid = false;
      errors["phone"] = "Please enter mobile number with atlleast 10 digits ";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }

    if ((hospitalDatas.phone.dialcode.length > 0 && hospitalDatas.phone.dialcode.length < 3 ) &&
    (hospitalDatas.phone.rawphone.length > 0 && hospitalDatas.phone.rawphone.length < 10))
    {
      isValid = false;
      errors["phone"] = "Please enter a valid phone number";
      if (!focussed) {
        password.current.focus();
        focussed = true;
      }
    }
    // if (
    //   hospitalDatas.emergency.dialcode === null ||
    //   hospitalDatas.emergency.dialcode === "" ||
    //   !hospitalDatas.emergency.rawphone.trim() ||
    //   hospitalDatas.emergency.rawphone.trim() === ""
    // ) {
    //   isValid = false;
    //   errors["emergency"] = "Please enter emergency number";
    //   if (!focussed) {
    //     confirmpassword.current.focus();
    //     focussed = true;
    //   }
    // }
    if (hospitalDatas.emergency.rawphone.length > 0 && hospitalDatas.emergency.rawphone.length < 10) {
      isValid = false;
      errors["emergency"] = "Please enter mobile number with 10 digits ";
      if (!focussed) {
        confirmpassword.current.focus();
        focussed = true;
      }
    }
    if ((hospitalDatas.emergency.dialcode.length > 0 && hospitalDatas.emergency.dialcode.length < 3 ) &&
     (hospitalDatas.emergency.rawphone.length > 0 && hospitalDatas.emergency.rawphone.length < 10))
    {
      isValid = false;
      errors["emergency"] = "Please enter a valid emergency number";
      if (!focussed) {
        confirmpassword.current.focus();
        focussed = true;
      }
    }

    if (
      typeof hospitalDatas.state === "undefined" ||
      hospitalDatas.state.trim() === "" ||
      hospitalDatas.state.trim() === null ||
      typeof input["location"] === "undefined" ||
      input["location"].trim() === "" ||
      input["location"].trim() === null ||
      typeof input["street"] === "undefined" ||
      input["street"].trim() === "" ||
      input["street"].trim() === null ||
      typeof input["pincode"] === "undefined" ||
      input["pincode"].trim() === "" ||
      input["pincode"].trim() === null
    ) {
      isValid = false;
      errors["address"] = "Please select address";
      if (!focussed) {
        location.current.focus();
        focussed = true;
      }
    }

    if (hospitalDatas.country.trim() === "" || hospitalDatas.country.trim() === null) {
      isValid = false;
      errors["country"] = "Please enter Country";
      if (!focussed) {
        location.current.focus();
        focussed = true;
      }
    }
    if (
      typeof hospitalDatas.state === "undefined" ||
      hospitalDatas.state.trim() === "" ||
      hospitalDatas.state.trim() === null
    ) {
      isValid = false;
      errors["state"] = "Please enter Region";
      if (!focussed) {
        street.current.focus();
        focussed = true;
      }
    }

    if (
      typeof input["location"] === "undefined" ||
      input["location"].trim() === "" ||
      input["location"].trim() === null
    ) {
      isValid = false;
      errors["location"] = "Please enter Location";
      if (!focussed) {
        location.current.focus();
        focussed = true;
      }
    }

    if (
      typeof input["street"] === "undefined" ||
      input["street"].trim() === "" ||
      input["street"].trim() === null
    ) {
      isValid = false;
      errors["street"] = "Please enter Street address";
      if (!focussed) {
        street.current.focus();
        focussed = true;
      }
    }

    if (
      typeof input["pincode"] === "undefined" ||
      input["pincode"].trim() === "" ||
      input["pincode"].trim() === null
    ) {
      isValid = false;
      errors["pincode"] = "Please enter Pincode";
      if (!focussed) {
        pincode.current.focus();
        focussed = true;
      }
    }

    


    if (
      typeof input["regn_number"] === "undefined" ||
      input["regn_number"].trim() === "" ||
      input["regn_number"].trim() === null
    ) {
      isValid = false;
      errors["regn_number"] = "Please enter registration number";
      if (!focussed) {
        regn_number.current.focus();
        focussed = true;
      }
    }

    if (
      typeof input["delivery_radius"] === "undefined" ||
      input["delivery_radius"].trim() === "" ||
      input["delivery_radius"].trim() === null
    ) {
      isValid = false;
      errors["delivery_radius"] = "Please enter delivery radius";
      if (!focussed) {
        delivery_radius.current.focus();
        focussed = true;
      }
    }

    if (hospitalDatas.delivery_radius < 1) {
      isValid = false;
      errors["delivery_radius"] = "Please ensure delivery radius is greater than or equal to 1.0 ";
      if (!focussed) {
        delivery_radius.current.focus();
        focussed = true;
      }
    }

    // if (website !== "" && website!== null
    // ) { 
    //   var regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
    //   var pattern = new RegExp(regex);
    //   if (!pattern.test(website)) {
    //     isValid = false;
    //     errors["website"] = "Please enter a valid url.";
       
    //     }

    if (
      hospitalDatas.website_url !== "" &&
      hospitalDatas.website_url !== null
    ) {
      var regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
      var pattern = new RegExp(regex);
      if (!pattern.test(hospitalDatas.website_url)) {
        isValid = false;
        errors["website_url"] = "Please enter a valid url.";
        if (!focussed) {
          website_url.current.focus();
          focussed = true;
        }
      }
    }

    // if (
    //   typeof input["primary_email"] === "undefined" ||
    //   input["primary_email"] === "" ||
    //   input["primary_email"] === null
    // ) {
    //   isValid = false;
    //   errors["primary_email"] = "Please enter Primary Email Id";
    //   if (!focussed) {
    //     primary_email.current.focus();
    //     focussed = true;
    //   }
    // }


    if (
      typeof input["primary_email"] !== "undefined" &&
      input["primary_email"].trim() !== "" &&
      input["primary_email"].trim() !== null
    ) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["primary_email"])) {
        isValid = false;
        errors["primary_email"] = "Please enter a valid email address.";
        if (!focussed) {
          primary_email.current.focus();
          focussed = true;
        }
      }
    }

  
    // if (specialtiesArray.length === 0) {
    //   isValid = false;
    //   errors["specialtiesArray"] = "Please mark Specialties";
    // }

    // if (facilitiesArray.length === 0) {
    //   isValid = false;
    //   errors["facilitiesArray"] = "Please mark Specialties";
    // }



    let text = /^[0-9]+$/;
    if (
      hospitalDatas &&
      hospitalDatas.established_year &&
      hospitalDatas.established_year !== null &&
      hospitalDatas.established_year !== ""
    ) {
      if (hospitalDatas.established_year.length === 4) {
        if (hospitalDatas.established_year !== 0) {
          if (
            hospitalDatas.established_year !== "" &&
            !text.test(hospitalDatas.established_year)
          ) {
            isValid = false;
            errors["established_year"] = "Please Enter Numeric Values Only";
            if (!focussed) {
              established_year.current.focus();
              focussed = true;
            }
          }

          if (hospitalDatas.established_year.length !== 4) {
            isValid = false;
            errors["established_year"] = "Please Enter year in proper format";
            if (!focussed) {
              established_year.current.focus();
              focussed = true;
            }
          }
          var current_year = new Date().getFullYear();
          if (
            hospitalDatas.established_year < 1600 ||
            hospitalDatas.established_year > current_year
          ) {
            isValid = false;
            errors["established_year"] =
              "Year should be in range 1600 to current year";
              if (!focussed) {
                established_year.current.focus();
                focussed = true;
              }
          }
        } else {
          isValid = false;
          errors["established_year"] = "Year should be in proper format";
          if (!focussed) {
            established_year.current.focus();
            focussed = true;
          }
        }
      } else {
        isValid = false;
        errors["established_year"] = "Year should be in proper format";
        if (!focussed) {
          established_year.current.focus();
          focussed = true;
        }
      }
    }

    if (
      typeof input["healthcare_type"] === "undefined" ||
      input["healthcare_type"].trim() === "" ||
      input["healthcare_type"].trim() === null
    ) {
      isValid = false;
      errors["healthcare_type"] = "Please Select Health Care type";
    }

    if (!privacy) {
      isValid = false;
      errors["privacy_policy"] = "You must agree to the Privacy Policy!";
    }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };
  /*Handle Signup */
  const callSignUpWithoutFormData = () => {
    const dataToSend = {
      name: hospitalDatas.name,
      mobile_number: hospitalDatas.phone.mobile_number,
      email: hospitalDatas.email,
      country: hospitalDatas.country,
      state: hospitalDatas.state,
      location: hospitalDatas.location,
      street: hospitalDatas.street,
      latitude: hospitalDatas.latitude,
      longitude: hospitalDatas.longitude,
      pincode: hospitalDatas.pincode,
     // contact_phone: hospitalDatas.contact_phone.mobile_number,
       
   accreditation: hospitalDatas.accreditation,
      reg_number: hospitalDatas.regn_number,
      //primary_email: hospitalDatas.primary_email,
      established_year: hospitalDatas.established_year,
      delivery_radius : hospitalDatas.delivery_radius,
    
      website: hospitalDatas.website_url,

      health_care_type: hospitalDatas.healthcare_type,
     // practise_type: hospitalDatas.practise_type,

     // facilities: facilitiesArray,
     // specializations: specialtiesArray,
      password: hospitalDatas.password,
      gst: hospitalDatas.gst,
      description: hospitalDatas.description,
      emergency_number: hospitalDatas.emergency.mobile_number
       
    };

   

    axios
      .post(`${APIURL}/api/v1/service-provider/register/`, dataToSend, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setSuccessSubmitMsg("success");
          setSuccessSubmit(true);

          //history.push('/success')
        } else {
          setErrorSubmitMsg(res.data.message);
          setErrorSubmit(true);

        
        }
      })
      .catch((err) => {
        setErrorSubmitMsg(`Error in ${hospitalDatas.healthcare_type} Registration. Please try again!`);
        setErrorSubmit(true);
      });
  };

  const callSignUpWithFormData = () => {
    const user = {
      name: hospitalDatas.name,
      mobile_number: hospitalDatas.phone.mobile_number,
      email: hospitalDatas.email,
      country: hospitalDatas.country,
      state: hospitalDatas.state,
      location: hospitalDatas.location,
      street: hospitalDatas.street,
      latitude: hospitalDatas.latitude,
      longitude: hospitalDatas.longitude,
      pincode: hospitalDatas.pincode,
      // contact_phone: hospitalDatas.contact_phone.mobile_number
      //   ? hospitalDatas.contact_phone.mobile_number
      //   : null,
      accreditation: hospitalDatas.accreditation,
      reg_number: hospitalDatas.regn_number,
      //primary_email: hospitalDatas.primary_email,
      established_year: hospitalDatas.established_year,
      delivery_radius:hospitalDatas.delivery_radius,
      website: hospitalDatas.website_url,

      health_care_type: hospitalDatas.healthcare_type,
     // practise_type: hospitalDatas.practise_type,

     // facilities: facilitiesArray,
      //specializations: specialtiesArray,
      password: hospitalDatas.password,
      gst: hospitalDatas.gst,
      description: hospitalDatas.description,
      emergency_number: hospitalDatas.emergency.mobile_number
        
    };

    if (user.emergency_number === null) {
      formData.append("emergency_number", "");
    } else {
      formData.append("emergency_number", user.emergency_number);
    }

    if (user.established_year === null) {
      formData.append("established_year", "");
    } else {
      formData.append("established_year", user.established_year);
    }

    // if (user.contact_phone === null) {
    //   formData.append("contact_phone", "");
    // } else {
    //   formData.append("contact_phone", user.contact_phone);
    // }

    if (user.gst === "") {
      formData.append("gst", "");
    } else {
      formData.append("gst", user.gst);
    }

    if (user.description === "") {
      formData.append("description", "");
    } else {
      formData.append("description", user.description);
    }
    // if (user.primary_email === "") {
    //   formData.append("primary_email", "");
    // } else {
    //   formData.append("primary_email", user.primary_email);
    // }
    if (user.accreditation === "") {
      formData.append("accreditation", "");
    } else {
      formData.append("accreditation", user.accreditation);
    }  if (user.website === "") {
      formData.append("website", "");
    } else {
      formData.append("website", user.website);
    }  if (user.photo === "") {
      formData.append("photo", "");
    } else {
      formData.append("photo", img);
    }
    //formData.append("photo", img);
    formData.append("name", user.name);
    formData.append("mobile_number", user.mobile_number);
   // formData.append("accreditation", user.accreditation);
    formData.append("reg_number", user.reg_number);
    formData.append("email", user.email);
    //formData.append("primary_email", user.primary_email);
    formData.append("country", user.country);
    formData.append("state", user.state);
    formData.append("location", user.location);
    formData.append("street", user.street);
    //formData.append("website", user.website);
    formData.append("delivery_radius",user.delivery_radius);
    formData.append("health_care_type", user.health_care_type);
    //formData.append("practise_type", user.practise_type);
    //formData.append("facilities", user.facilities);
    //formData.append("specializations", user.specializations);
    formData.append("password", user.password);
    formData.append("latitude", user.latitude);
    formData.append(" longitude", user.longitude);
    formData.append("pincode", user.pincode);
    axios
      .post(`${APIURL}/api/v1/service-provider/register/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setSuccessSubmitMsg("success");
          setSuccessSubmit(true);

          //history.push('/success')
        } else {
          setErrorSubmitMsg(res.data.message);
          setErrorSubmit(true);

          //history.push('/error')
        }
      })
      .catch((err) => {
        setErrorSubmitMsg(`Error in ${hospitalDatas.healthcare_type} Registration. Please try again!`);
        setErrorSubmit(true);
      });
  };

  const handleSignup = () => {
    if (formDataStatus) {
      callSignUpWithFormData();
    } else {
      callSignUpWithoutFormData();
    }
  };

  const selectCountry = (val) => {
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      country: val,
    }));
  };

  const selectRegion = (val) => {
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      state: val,
    }));
  };

  const handlePhoneInput = (value, data, event, formattedValue) => {
    const rawPhone = value.slice(data.dialCode.length);

    const dialcode = value.slice(0, data.dialCode.length);
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      phone: {
        dialcode: dialcode,
        rawphone: rawPhone,
        mobile_number: value,
      },
    }));
  };
  const handleContactPhoneInput = (value, data, event, formattedValue) => {
    const rawPhone = value.slice(data.dialCode.length);

    const dialcode = value.slice(0, data.dialCode.length);
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      contact_phone: {
        dialcode: dialcode,
        rawphone: rawPhone,
        mobile_number: value,
      },
    }));
  };

  const handleEmergencyInput = (value, data, event, formattedValue) => {
    const rawPhone = value.slice(data.dialCode.length);

    const dialcode = value.slice(0, data.dialCode.length);
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      emergency: {
        dialcode: dialcode,
        rawphone: rawPhone,
        mobile_number: value,
      },
    }));
  };

  // const handleSpecialties = (e) => {
  //   //const val=e.target.value;
  //   const key = e.target.name;

  //   setSpecialties((currentstate) => ({
  //     ...currentstate,
  //     [key]: !specialties[key],
  //   }));
  // };

  const handleCheckExistingRegis = (e) => {
    setIsValidRegis(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        regn_number: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        [e.target.name]: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                regn_number: `This Registration number already Exists`,
              }));
              setIsValidRegis(false);
            } else {
              setIsValidRegis(true);
            }
          } else {
            setIsValidRegis(true);
          }
        })
        .catch((err) => {});
    }
  };
  const handleCheckExisting = (e) => {
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        email: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        [e.target.name]: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                [key]: `This ${key} is already registered!`,
              }));
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          } else {
            setIsValidEmail(true);
          }
        })
        .catch((err) => {});
    }
  };
  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {
      const rawPhone = hospitalDatas.phone.rawphone;

      const dialcode = hospitalDatas.phone.dialcode;
      setError((currentstate) => ({
        ...currentstate,
        phone: "",
      }));

      const data = {
        code: dialcode,
        mobile_number: rawPhone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                phone: "Phone number already registered!",
              }));
              setIsValidPhone(false);
            } else {
              setIsValidPhone(true);
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
  };

  // const handleFacilities = (e) => {
  //   //const val=e.target.value;
  //   const key = e.target.name;

  //   setFacilities((currentstate) => ({
  //     ...currentstate,
  //     [key]: !facilities[key],
  //   }));
  // };

  // const handlePractiseType = (e) => {
  //   const val = e.target.value;
  //   setHospitalDatas((currentstate) => ({
  //     ...currentstate,
  //     practise_type: val,
  //   }));
  // };
  const handleHealthCareType = (e) => {
    const val = e.target.value;
    setHospitalDatas((currentstate) => ({
      ...currentstate,
      healthcare_type: val,
    }));
  };  

  const onPlaceSelected = ( place ) => {
		console.log( 'plc', place );
		// const address = place.formatted_address,
		//       addressArray =  place.address_components,
		//       city = this.getCity( addressArray ),
		//       area = this.getArea( addressArray ),
		//       state = this.getState( addressArray ),
		     const latValue = place.geometry.location.lat();
		      const lngValue = place.geometry.location.lng();
		// Set these values in the state.
		// this.setState({
		// 	address: ( address ) ? address : '',
		// 	area: ( area ) ? area : '',
		// 	city: ( city ) ? city : '',
		// 	state: ( state ) ? state : '',
		// 	markerPosition: {
		// 		lat: latValue,
		// 		lng: lngValue
		// 	},
		// 	mapPosition: {
		// 		lat: latValue,
		// 		lng: lngValue
		// 	},
		// })
    setLatitude(latValue);
    setLongitude(lngValue);
	};

  
  const authUrl = AuthUtil.validateAuth();
  //When authentication is valid.
  if (authUrl) {
    return <Navigate to={authUrl} state={{ from: location }} />;
  }

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  return (
    <div>
    
      <br/>
    <div className="">
      <div className="">        
          <div className="form-content">
            <div className="form-reg-items">
              
     
              <form className="reg-form hosp-form" onSubmit={handleContinue}>
                <div className="reg-form-div">
                  <br/>
                  <br/>
                <h2 className="form-margin"> Service Provider Registration </h2>
                <br/>
                  <div className="first-row">
                    <div className="custom-file">
                      {previewOn === false ? (
                        <div className="pic-container">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="validatedCustomFile"
                            onChange={uploadImage}
                          />
                          {invalidPic && <p className="error">{invalidPic}</p>}
                          <label className="" htmlFor="validatedCustomFile">
                            <p>Upload Profile Picture</p>
                            <div className="camera-icon">
                              <i className="fa fa-camera camera-icon"></i>
                            </div>
                          </label>
                          {/*
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Photo</label> */}
                        </div>
                      ) : (
                        <div>
                          <div onClick={() => {
                              setDeleteShow(true);
                            }}>
                            <i
                              className="fa fa-window-close circular-button"
                            ></i>
                          </div>
                          <img
                            src={hospitalDatas.img_url}
                            alt="Hospital Picture"
                            className="avatar"
                          />
                        </div>
                      )}
                    </div>

                    {/*
 <div className="custom-file">
                <input type="file" className="custom-file-input" id="validatedCustomFile" onChange={preview_image}/>
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Photo</label>
 
   <img id="output_image" className="avatar"/>
 </div>  */}
                  </div>

                  <h6 className="sub-headings"> Registration Details * </h6>
                  <br/>
                  <div className="item item-left-row">
                    <label className="">* Service Provider Name: </label>{" "}
                    <input
                      ref={name}
                      className="form-control"
                      type="text"
                      name="name"
                      value={hospitalDatas.name}
                      placeholder=""
                      onChange={handleChange}
                      maxLength="30"
                    />
                    {error.name ? (
                      <div className="error-validation-msg">
                        {error.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="item item-left-row">
                    <label className="">* Email ID: </label>{" "}
                    <input
                      ref={email}
                      className="form-control"
                      type="email"
                      name="email"
                      value={hospitalDatas.email}
                      placeholder=""
                      onChange={handleChange}
                      maxLength="30"
                      onBlur={handleCheckExisting}
                    />
                    {error.email ? (
                      <div className="error-validation-msg">
                        {error.email}
                      </div>
                    ) : null}
                  </div>
                  
                  <div className="item item-left-row">
                    <label className="">* Password: </label>{" "}
                    <div style={{display:"flex", backgroundColor:"#F7F7F7", height:"44px"}}>
                    <input
                      ref={password}
                      className="pswd"
                      type={passwordType}
                      name="password"
                      placeholder=""
                      onChange={handleChange}
                      maxLength="20"
                    />
                    <span style={{float:"right"}}>
         
         { passwordType==="password"? <i style={{paddingTop:"60%"}} onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> :<i style={{paddingTop:"60%"}} onClick={togglePasswordVisiblity} className="fa fa-eye"></i> }
         </span></div>
                    {error.password ? (
                      <div className="error-validation-msg">
                        {error.password}
                      </div>
                    ) : null}
                  </div>
                  <div className="item item-left-row">
                    <label className="">* Confirm Password: </label>{" "}
                    <div style={{display:"flex", backgroundColor:"#F7F7F7", height:"44px"}}>
                    <input
                      ref={confirmpassword}
                      className="pswd"
                      type={passwordType1}
                      name="confirmpassword"
                      placeholder=""
                      onChange={handleChange}
                      maxLength="20"
                    />
                     <span style={{float:"right"}}>
         
         { passwordType1==="password"? <i style={{paddingTop:"60%"}} onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> :<i style={{paddingTop:"60%"}} onClick={togglePasswordVisiblity1} className="fa fa-eye"></i> }
         </span></div>
                    {error.confirmpassword ? (
                      <div className="error-validation-msg">
                        {error.confirmpassword}
                      </div>
                    ) : null}
                  </div>

                  <div className=" phone-input item item-left-row">
                    <label className="">* Phone Number: </label>
                    
                      <PhoneInput
                        inputProps={{}}
                        country={"in"}
                        onlyCountries={['in']}
                        countryCodeEditable={false}
                        value={hospitalDatas.phone.mobile_number}
                        onChange={handlePhoneInput}
                        onBlur={handleCheckExistingPhone}
                        // disableDropdown={true}
                      />
                    

                    {error.phone ? (
                      <div className="error-validation-msg">{error.phone}</div>
                    ) : null}
                  </div>

                  <div className="phone-input item item-left-row">
                    <label className="">Emergency Number: </label>
                   
                      <PhoneInput
                        ref={emergency}
                        inputProps={{}}
                        country={"in"}
                        onlyCountries={['in']}
                        countryCodeEditable={false}
                        value={hospitalDatas.emergency_number}
                        onChange={handleEmergencyInput}
                         // disableDropdown={true}
                      />
                    {error.emergency ? (
                      <div className="error-validation-msg">
                        {error.emergency}
                      </div>
                    ) : null}
                  </div>

                

                  <div className="divider"></div>
                  <h6 className="sub-headings"> * Address </h6>

                  <Map
                    google={props.google}
                    ref={address}
                    center={{lat: latitude, lng: longitude}}
                    height={300}
                    sendBack={newCoordinates}
                    setError={setMapError}
                    zoom={15}
                  />

                    {error.address ? (
                      <div className="error-validation-msg" style={{marginLeft: 36, marginRight: 36}}>
                        {error.address}
                      </div>
                    ) : null}

<div className="item item-left-row">
                    <label className="">* Street Name: </label>{" "}
                    <input
                      ref={street}
                      className="form-control disabled-input"
                      type="text"
                      name="street"
                      placeholder=""
                      value={hospitalDatas.street}
                      onChange={handleChange}
                      maxLength="150"
                      disabled={true}
                    />
                    {error.street ? (
                      <div className="error-validation-msg">{error.street}</div>
                    ) : null}
                  </div>
                  <div className="item item-left-row">


                    <label className="">* City: </label>{" "}
                    <input
                      ref={location}
                      className="form-control disabled-input"
                      type="text"
                      name="location"
                      placeholder=""
                      value={hospitalDatas.location}
                      onChange={handleChange}
                      maxLength="150"
                      disabled={true}
                    />
                    {error.location ? (
                      <div className="error-validation-msg">
                        {error.location}
                      </div>
                    ) : null}
                  </div>
                  
                  <div className="region-selector item item-left-row">
                    <label className=""> * State: </label>
                    <input
                      ref={region}
                      className="form-control disabled-input"
                      type="text"
                      name="state"
                      placeholder=""
                      value={hospitalDatas.state}
                      onChange={handleChange}
                      maxLength="150"
                      disabled={true}
                    />
                    {/* <RegionDropdown
                      ref={region}
                      country={hospitalDatas.country}
                      value={hospitalDatas.state}
                      name="state"
                      onChange={(val) => selectRegion(val)}
                      disabled={true}
                    /> */}
                    {error.state ? (
                      <div className="error-validation-msg">{error.state}</div>
                    ) : null}
                    {/* <small id="emailHelp" className="form-text text-muted">
                      *Select State- (Enabled once you select Country)
                    </small> */}
                    
                  </div>

                
                  <div className="country-selector item item-left-row">
                    <label className="">Country: </label>
                    <input
                      ref={country}
                      className="form-control disabled-input"
                      type="text"
                      name="country"
                      placeholder=""
                      value={hospitalDatas.country}
                      onChange={handleChange}
                      maxLength="150"
                      disabled={true}
                    />
                    {/* <CountryDropdown
                      ref={country}
                      value={hospitalDatas.country}
                      name="country"
                      onChange={(val) => selectCountry(val)}
                      disabled={true}
                    /> */}
                    {error.country ? (
                      <div className="error-validation-msg">
                        {error.country}
                      </div>
                    ) : null}
                  </div>


                 
                  <div className="item item-left-row">
                    <label className="">* Zipcode: </label>{" "}
                    <input
                      ref={pincode}
                      className="form-control disabled-input"
                      type="text"
                      name="pincode"
                      placeholder=""
                      value={hospitalDatas.pincode}
                      onChange={handleChange}
                      maxLength="150"
                      disabled={true}
                    />
                    {error.pincode ? (
                      <div className="error-validation-msg">
                        {error.pincode}
                      </div>
                    ) : null}
                  </div>

                  <div className="divider"></div>

                  <h6 className="sub-headings"> Service Provider Details </h6>

                  <div className="item item-left-row">
                    {" "}
                    <input
                      className="form-control"
                      type="text"
                      value={hospitalDatas.accreditation}
                      name="accreditation"
                      placeholder="Accreditation"
                      onChange={handleChange}
                      maxLength="30"
                    />
                    {/* {error.accreditation ? (
                      <div className="error-validation-msg">
                        {error.accreditation}
                      </div>
                    ) : null} */}
                  </div>

                  <div className="item item-left-row">
                    {" "}
                    <input  
                    ref={regn_number}
                      className="form-control"
                      type="text"
                      value={hospitalDatas.regn_number}
                      name="regn_number"
                      placeholder="* Registration Number"
                      onChange={handleChange}
                      maxLength="30"
                      onBlur={handleCheckExistingRegis}

                    />
                    {error.regn_number ? (
                      <div className="error-validation-msg">
                        {error.regn_number}
                      </div>
                    ) : null}
                  </div>
                  <div className="item item-left-row">
                    {" "}
                    <small id="emailHelp" className="form-text text-muted">
                     delivery radius km in number
                    </small>
                    <input
                    ref={delivery_radius}
                      className="form-control"
                      type="number"
                      value={hospitalDatas.delivery_radius}
                      name="delivery_radius"
                      placeholder="* Delivery Radius"
                      onChange={handleChange}
                      maxLength="30"
                    />
                    {error.delivery_radius ? (
                      <div className="error-validation-msg">
                        {error.delivery_radius}
                      </div>
                    ) : null}
                  
                    
                  </div>


                  <div className="item item-left-row">
                    {" "}
                    <input
                      ref={website_url}
                      className="form-control"
                      type="text"
                      value={hospitalDatas.website_url}
                      name="website_url"
                      placeholder="https://www.example.com"
                      onChange={handleChange}
                      maxLength="150"
                    />
                     {error.website_url ? (
                      <div className="error-validation-msg">
                        {error.website_url}
                      </div>
                   ) : null}
                  </div>

                  <div className="item item-left-row">
                    <input
                      ref={established_year}
                      className="form-control"
                      type="number"
                      value={hospitalDatas.established_year}
                      name="established_year"
                      placeholder="Established Year"
                      onChange={handleChange}
                      maxLength="30"
                    />
                  {error.established_year ? (
                      <div className="error-validation-msg">
                        {error.established_year}
                      </div>
                   ) : null}
                  </div>

                  <div className="item item-left-row">
                    {" "}
                    <input
                      className="form-control"
                      type="text"
                      value={hospitalDatas.gst}
                      name="gst"
                      placeholder="GST"
                      onChange={handleChange}
                      maxLength="30"
                    />
                  </div>

                  <div className="item item-left-row">
                    {" "}
                    <textarea
                      rows="3"
                      className="text-area-about"
                      placeholder="Description"
                      name="description"
                      onChange={handleChange}
                      maxLength="150"
                    />
                  </div>

                  <div className="divider"></div>
                  <h6 className="sub-headings">*Health Care Type </h6>
                  <div className="healthcare-width row">
                    <div className="radio-options">
                      <input
                        type="radio"
                        id="Hospital"
                        name="healthcare_type"
                        value="Hospital"
                        checked={hospitalDatas.healthcare_type === "Hospital"}
                        onChange={handleHealthCareType}
                      />
                      <label htmlFor="Hospital">Hospital</label>
                    </div>

                    {/* <div className="radio-options">
                      <input
                        type="radio"
                        id="Clinic"
                        name="healthcare_type"
                        value="Clinic"
                        checked={hospitalDatas.healthcare_type === "Clinic"}
                        onChange={handleHealthCareType}
                      />
                      <label htmlFor="Clinic">Clinic</label>
                    </div> */}

                    <div className="radio-options">
                      <input
                        type="radio"
                        id="Pharmacy"
                        name="healthcare_type"
                        value="Pharmacy"
                        checked={
                          hospitalDatas.healthcare_type == "Pharmacy"
                        }
                        onChange={handleHealthCareType}
                      />
                      <label htmlFor="Pharmacy">Pharmacy</label>
                    </div>
                    <div className="radio-options">
                      <input
                        type="radio"
                        id="Laboratory"
                        name="healthcare_type"
                        value="Laboratory"
                        checked={
                          hospitalDatas.healthcare_type == "Laboratory"
                        }
                        onChange={handleHealthCareType}
                      />
                      <label htmlFor="Laboratory">Laboratory</label>
                    </div>


                    {error.healthcare_type ? (
                      <div className="error-validation-msg">
                        {error.healthcare_type}
                      </div>
                    ) : null}
                  </div>


                  <div className="divider"></div>

                  <div className="healthcare-width">
                    <input type="checkbox" id="privacy" name="privacy" checked={privacy} onClick={() => {
                      setShowPrivacy(true);
                    }}/>
                    <label for="privacy">Please Accept Privacy Policy</label>
                    {error.privacy_policy ? (
                      <div className="error-validation-msg">
                        {error.privacy_policy}
                      </div>
                    ) : null}
                  </div>

                  <div className="divider"></div>
                 
                </div>
                <div className="form-button">
                  <button
                    id="submit"
                    type="submit"
                    className="btn btn-primary formFieldButton"
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {errorSubmitMsg !== null ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}
        />
      ) : null}

      {successSubmitMsg === "success" ? (
        <SubmitSuccessPopup
          show={successSubmit}
          onHide={() => setSuccessSubmit(false)}
          setemailshow={email}
        />
      ) : null}

      {sizeShow ? (
        <SizePopUp
          show={sizeShow}
          onHide={() => {
            setSizeShow(false);
          }}
        />
      ) : null}

      {isMapError ? (
        <MapError
          show={isMapError}
          onHide={() => {
            setIsMapError(false);
          }}
          />
      ) : null}

      {isMapSucess ? (
        <MapSuccess
          show={isMapSucess}
          onHide={() => {
            setIsMapSucess(false);
          }}
          />
      ) : null}

      {uploadShow ? (
        <LoadPopUp
          show={uploadShow}
          onHide={() => {
            setUploadShow(false);
          }}
        />
      ) : null}

      {validShow ? (
        <ValidPopUp
          show={validShow}
          onHide={() => {
            setValidShow(false);
          }}
        />
      ) : null}

      {contentShow ? (
        <ContentPopUp
          show={contentShow}
          onHide={() => {
            setContentShow(false);
          }}
        />
      ) : null}

      {confirmDelete ? (
        <ConfirmPopUp
          show={confirmDelete}
          onHide={() => {
            setDeleteShow(false);
          }}
        />
      ) : null}

      {showPrivacy ? (
        <PrivacyPopUp
          show={showPrivacy}
          onHide={() => {
            setShowPrivacy(false);
          }}
        />
      ) : null}
     <br/>
   
    </div>
  );
}

export default  ServiceProviderRegister;
