import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../Global";
import Pagination from "react-js-pagination";
import "./regdhospital.css"
import "./pagination.css";
import BackofficeNavBar from "../BackoffcieNav/BackofficeNavBar";
import LoadingOverlay from 'react-loading-overlay';
import { Button,ToggleButton } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import moment from "moment";
import BackButton from "../common/BackButton";
function PatientsMessage ({patient_id}) {
    const [loading, setLoading] = useState(false);
    //const [pending, setPending] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage,] = useState(5);
    const [expand, setExpand] = useState()
    const id=patient_id;
   
    const [alldata, setAllData] = useState("");
    
    useEffect(() => {
    
        setLoading(true)
        const tokenString = sessionStorage.getItem("usertoken");
    
        let v2 = tokenString.replace(
          /["]+/g,
          ""
        );
        axios.get(`${APIURL}/api/v1/staff/patient-feedback-view/` , {
          headers: { Authorization: "Token " + v2 },
        })
          .then((res) => {
            if (res.status === 200) {
              const data = res.data
             
              setAllData(data)


             
           
            }
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
    
      }, [])

   
      const handleSearchChange = (e) => {
        e.preventDefault();
        setCurrentPage(1)
        setSearchTerm(e.target.value)
    }
      let data = alldata ? alldata.sort((a, b) => {
        if (a.patient_name.toLowerCase() < b.patient_name.toLowerCase()) return -1;
        if (a.patient_name.toLowerCase() > b.patient_name.toLowerCase()) return 1;
        return 0;
    })
        .filter((value) => {
            if (searchTerm === '') {
                return value
            } else if (value.patient_name.toLowerCase().includes(searchTerm.toLowerCase())) {
                return value
            }
        }) : [];

      const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

      const handleDetail = (index) => {
        if (expand !== index) {
            setExpand(index)
        }
        else {
            setExpand()
        }
    }

    const indexLast = currentPage * perPage;
    const indexFirst = indexLast - perPage;
    const current = data.slice(indexFirst, indexLast);
    const total = data.length;

    const pageNumbers = []
    for (let i = 0; i <= Math.ceil(total / perPage); i++) {
        pageNumbers.push(i)
    }
    const totalPages = pageNumbers.length;

  let display = current.length > 0 ? current.map((item, index) => {

return(

 
        <>
  
        
     
  
        <div className='list-item1'>
  
           
  
          {" "}
          <h4 className="each-list-link" >
  
              
         
          <Link to="#" style={{ textDecoration: 'none' }} className={expand === index ? 'feature-data mt-3' : 'feature-data'} onClick={() => handleDetail(index)}>
                        <h5><i className={expand === index ? "fa fa-angle-up fa-fw" : "fa fa-angle-down fa-fw"} style={{ color: "#6788bc " }} ></i> {item.patient_name}</h5><span>&nbsp;&nbsp;</span>||{"  "}<span>&nbsp;&nbsp;</span><span><h6>{item.mobile_number}</h6></span><span>&nbsp;&nbsp;</span>{expand!==index ? <i class="fa fa-toggle-off" style={{color:"red"}} aria-hidden="true"></i>:null}
                    </Link>
                </h4>
                {
                    expand === index ? <> 
                
                    {item.message}  <h4><i class="fa fa-toggle-on" style={{color:"green"}} aria-hidden="true"></i></h4>
                      

                    </>
                        : null
                }

</div>
</>

)
  }) :null

  return (
      <>
 <div className="container12">
<div className="serviceshow">
<br />
                            <br />
                            <div className="search-section1">
  
       
                             <div className="search-div">
                    
                            <div style={{ display: "flex" }}>
                                
                                    <input
                                    style={{}}
                                        className="form-control search-input"
                                        type="text"
                                        placeholder="Search Patient"
                                        value={searchTerm}
                                        maxLength="150"
                                        onChange={handleSearchChange}
                                    />
                                      <button  className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>
                              
                            </div>
                            
                            </div>
                            </div>
<div className="title-of-tasks" >
<div className="failed-list-section">
                <div className="container12">
                  <br/>
                 
                {display ? (
                                            display
                                        ) : (
                                            <div style={{ color: "red", fontSize: "15px", height: "100px", padding: "20px" }}>
                                               {!loading? <h4>No Messages to show!!</h4>:""}
                                            </div>
                                        )}
                    
                     <br/><br/>
                            {totalPages > 1 ?
                                <div className="pagn pagn-small">
                                    <Pagination
                                     
                                        activePage={currentPage}
                                        itemsCountPerPage={perPage}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={totalPages}
                                        onChange={handlePageChange}
                                        disabledClass="disabled-class"
                                    />
                                </div>
                                : null}
            

                </div>
              </div>



</div>


</div>

    </div>


</>
  )

}
export default PatientsMessage