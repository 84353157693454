import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditSymptoms(props) {

    const[data, setData]= useState(props.data);
     
        

    

    const [error,setError]=useState({
      observation_name:'',
        
       });
       let navigate=useNavigate()
    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const[completeShow,setCompleteShow]=useState(false)
    const[errorValidate,setErrorValidate]=useState(false)





   
      const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>check data duplication or not</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const CompletePopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'> Successfully Saved</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
             <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
             
             
            </Modal.Footer>
          </Modal>
        );
      }
     
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


   
  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


   
  if (!input["symptom_name"] || input["symptom_name"]==="" ) {
    isValid = false;
    errors["symptom_name"] = "Please enter symptom Name";
  }
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();
 ;
 console.log(validate())
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  let dataToSend = {}
  if(props.data.symptom_name===data.symptom_name&&props.data.symptom_type===data.symptom_type){
    dataToSend={
      symptom_code : data.symptom_code
    }
  }else if(props.data.symptom_name===data.symptom_name){
    dataToSend={
      symptom_code : data.symptom_code,
      symptom_type : data.symptom_type
    }
  }else{
    dataToSend={
      symptom_name: data.symptom_name,                               
      symptom_type : data.symptom_type,
      symptom_code : data.symptom_code
    }
  }
   
   

  
 
     axios
       .put(`${APIURL}/api/v1/staff/master-symptom-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          setCompleteShow(true); 
          setData({
            symptom_name: "",                               
            symptom_type : "",
            symptom_code : ""
         });
         
         } else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }

    else {
      setErrorValidate(true)
   }

}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Edit Symptoms</h4>
        <div style={{maxHeight:'1000px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Symptom Name: </label>
                    <textarea type='text' className='form-control title-flex' name='symptom_name' value={data.symptom_name} onChange={handleTitle} /></div>
                    {error.symptom_name ? <div className="error-validation-msg error-feature-inventory">{error.symptom_name}</div> : null}
                </div>

                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Symptom Type: </label>
                    <input type='text' className='form-control title-flex' name='symptom_type' value={data.symptom_type} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Symptom Code: </label>
                    <input type='text' className='form-control title-flex' name='symptom_code' value={data.symptom_code} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
               
               
                
            </div>

            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
          
           
{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}

{completeShow ? 

<CompletePopUp
show={completeShow}
onHide={() =>  {setCompleteShow(false)
props.modalClose()}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditSymptoms;
