import React, { useState, useEffect, useRef } from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./service.css";
import { useAlert } from 'react-alert' 
import { Tooltip } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from "axios";
import { APIURL } from "../../Global";
import { SOCKETURL } from "../../Global";
import { useNavigate, Navigate, Link } from "react-router-dom";
import EditRemark from "./EditRemark";
import AddRemark from "./AddRemark";
import LoadingOverlay from "react-loading-overlay";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

function ServiceRequests_doc() {
  const alert = useAlert()
  var Typeahead = require("react-bootstrap-typeahead").Typeahead;
  const [reRender, setRerender] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(true);
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [display, setDisplay] = useState(false);
  const [infoShow, setinfoShow] = useState(false);
  const [infoData, setInfoData] = useState("");
  const [type, setType] = useState("");
  const [, setGetError] = useState('');
  const [tableData, setTableData] = useState([]);

  const [, setOptionsDisplay] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [remarkData, setRemarkData] = useState('')
  const [errorMsg, setErrorMsg] = useState("");
  const [remarkList, setRemarkList] = useState([])
  const [backup, setBackup] = useState('')

  const [submitMsg, setSubmitMsg] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const[page,setPage]=useState(1)
  const [editShow, setEditShow] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [cell, setCell] = useState('')
  const [count, setCount] = useState(0);
  const webSocket = useRef(null);
  const [id, setId] = useState("");
  const [patient, setpatient] = useState("");
  const [order, setOrder] = useState("")
  const [trackId, setTrackingId] = useState()
  let navigate = useNavigate();
  const [changed, setChanged] = useState()
  const [keys,] = useState({ 'p': false, 'a': false, 'n': false, 'm': false, 'l': false });

  let tableData2=[]
  const handleInfoPopUp = (item,type) => {
    setInfoData(item);
    setinfoShow(true);
    console.log(item)
    if(type==="master_medicine"){
      setType("Medicines")
    }else{
      setType("Lab Tests")
    }
  };
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><h5>{type}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
           
            
        {infoData.map((item,index) => {
             return (            
             <div className="row-of-features">
               <div className="col-of-features"><br/>
                 <h6> {index+1}&nbsp;{item.item_service_name}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Quantity:&nbsp;{item.request_quantity}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Requested Due Date:&nbsp;{item.request_due_date}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Priority:&nbsp;{item.request_priority}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Status:&nbsp;{item.request_status}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Amount:&nbsp;{item.order_amount}</h6>
             

              
               </div>
             </div>
             );
          })}
              
          
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => { window.scrollTo(0, 0) }, [])


  useEffect(() => {

    getServices(page,perPage);
  },[perPage, reRender])
  const getServices =  async  (page, per_page) => {
    setLoading(true);
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');



    axios.get(`${APIURL}/api/v1/patient/assigned-sr-view/?page=${page}&per_page=${per_page}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {
        if (res.data.status === "success") {
          setLoading(false);
          let data=res.data.orders
          let dataList =data&&data.filter((item, index) => {
            return (item.su_orders.length>0)
          });


          let updatedList = [];
          let filteredList = dataList.filter((item, index) => {
            return !(item.order_delivery_status.toLowerCase() === "delivered" || item.order_delivery_status.toLowerCase() === "rejected");
          }).sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
          })
          updatedList = filteredList.map((item, index) => {

            let status;
            let statusObj;
            let newDate=  new Date(Math.min.apply(null, item.su_orders.map(function(e) {
              return new Date(e.tentative_delivery_date===null?e.request_due_date:e.tentative_delivery_date);
             
            })));
            if (item.order_delivery_status.toLowerCase() === "assigned") {
              status = "assigned";
              statusObj = { value: "assigned", label: "Assigned" }
            } if (item.order_delivery_status.toLowerCase() === "ongoing") {
              status = "ongoing";
              statusObj = { value: "ongoing", label: "Ongoing" }
            }
            else if (item.order_delivery_status.toLowerCase() === "accepted") {
              status = "accepted";
              statusObj = { value: "accepted", label: "Accepted" }
            }
            else if (item.order_delivery_status.toLowerCase() === "delivered") {
              status = "delivered";
              statusObj = { value: "delivered", label: "Delivered" }
            }
            else if (item.order_delivery_status.toLowerCase() === "rejected") {
              status = "rejected";
              statusObj = { value: "rejected", label: "Rejected" }
            }
            else if (item.order_delivery_status.toLowerCase() === "out_for_delivery") {
              status = "out_for_delivery";
              statusObj = { value: "out_for_delivery", label: "Out for Delivery" }
            }



            let priority;
            let priorityObj;
            if (item.su_orders[0].request_priority.toLowerCase() === "critical") {
              priority = "critical";
              priorityObj = { value: 'critical', label: 'Critical' }
            }
          
            else if (item.su_orders[0].request_priority.toLowerCase() === "high") {
              priority = "high";
              priorityObj = { value: 'high', label: 'High' }
            }

            else if (item.su_orders[0].request_priority.toLowerCase() === "medium") {
              priority = "medium";
              priorityObj = { value: 'medium', label: 'Medium' }
            }
            else if (item.su_orders[0].request_priority.toLowerCase() === "low") {
              priority = "low";
              priorityObj = { value: 'low', label: 'Low' }
            }
           
            return ({
              paid_status:item.payment_details.paid_status,
              payment_mode:item.payment_details.payment_mode,
              order_type:item.order_type,
              su_orders:item.su_orders,
              name: item.patient_name,
              date: item.su_orders[0].request_due_date,
              order: item.order_type==="master_medicine"?"Medicines":item.order_type==="master_labtest"?"Lab tests":item.su_orders[0].item_service_name,
              order_id:item.order_id,
              id: item.id,
              priority: priority,
              priorityObj: priorityObj,
              ref: item.service_request_order,
              contact_number: item.contact_number,
            statusold:item.order_delivery_status,
              statusObj: statusObj,
              patient_id: item.patient_id,
              consent_level: parseInt(item.su_orders[0].consent_level_id),
             // address: item.address,
             // details: item.details,
             required_date:
             newDate,
             
              assigned_name:
                item.order_assigned_to !== null ? item.order_assigned_to : null,

             quantity:item.order_type==="master_medicine"||item.order_type==="master_labtest"?item.su_orders.length:item.su_orders[0].request_quantity,

              remarks: item.remarks ? item.remarks : null,
              remark_list: item.remarks_list,
            }
            )
          })

          console.log('THE UPDATED LISt', updatedList)
          
          tableData2 = updatedList.filter(function(o1){
            // filter out (!) items in result2
            return !tableData.some(function(o2){
                return o1.order_id === o2.order_id;          // assumes unique id
            });
        })
        console.log("tableData2",tableData2)
        setTableData([...tableData, ...tableData2]);
        }

        else {
          setLoading(false);

          setGetError(res.data.message);
        }



      })
      .catch(err => {
        setLoading(false);

        setGetError("Error in fetching data");
      })
  };

  useEffect(() => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')



    axios.get(`${APIURL}/api/v1/patient/assigned-sr-view/?q=${search}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {
        const assignee_List = []
        if (res.data.status === "success") {

          const assigneeList = res.data.data
          assigneeList.map((person, index) => (assignee_List.push(person["id"])))

          setOptions(assigneeList);
          setOptionsDisplay(assignee_List)


        }


      })
      .catch(err => {

      })


  }, [search]);

  useEffect(() => {
    setTableData(tableData)
  }, [render, tableData]);
  const handlePageChange = page => {
    getServices(page+1, perPage);
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage);
    
  }
  const statusOptions = [
    { value: 'assigned', label: 'Assigned' },
    { value: 'accepted', label: 'Accepted' },
    { value: 'delivered', label: 'Delivered' },

    { value: 'rejected', label: 'Rejected' },
    { value: 'out_for_delivery', label: 'Out For Delivery' },

  ];



  const SuccessPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>{props.msg} </h5>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    );
  }
  const SubmitErrorPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {errorMsg !== '' ? errorMsg : "Error in Data Submission. Please try again!"} </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>




        </Modal.Footer>
      </Modal>
    );
  }


  const UpdatePopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>Please save to complete changes </h5>




        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>






        </Modal.Footer>
      </Modal>
    );
  }

  const renderAssigned2 = (cellInfo) => {
    const { ref } = cellInfo.original;
    const {
      assigned_name,
      assigned_to,
      consent_level,
      patient_id,
      assigned_by_name,
    } = tableData.find(data => data.ref === ref);

    return (
      <>
        <div style={{ display: "flex" }}>
          <Typeahead
            id={"assigned_name_" + ref}
            key={"assigned_name_" + ref}
            placeholder="Search..."
            onFocus={() => {
              const tokenString = sessionStorage.getItem("usertoken");

              let str = tokenString.replace(/["]+/g, "");

              axios
                .get(
                  `${APIURL}/api/v1/service-provider/sr-consent-users/?consent_level_id=${consent_level}&patient_id=${patient_id}`,
                  {
                    headers: {
                      Authorization: "Token " + str,
                    },
                  }
                )
                .then((res) => {
                  const assignee_List = [];
                  if (res.data.status === "success") {
                    const assigneeList = res.data.message;
                    assigneeList.map((person, index) =>
                      assignee_List.push(person["assigned_by_name"])
                    );

                    setOptions(assigneeList);

                    setOptionsDisplay(assignee_List);

                    setCurrentIndex(cellInfo.index);
                    setCurrentColumn(cellInfo.column.id);

                    setDisplay(true);

                    //setSearch(e.target.value);
                  }
                })
                .catch((err) => { });
            }}
            options={options.map((option) => {
              return {
                label: option["name"],
                id: option["id"],
                user: option["user_type"],
              };
            })}
            defaultInputValue={
              assigned_to && assigned_name ? assigned_name : ""
            }
            onChange={(data) => {
              setChanged(cellInfo.index)
              if (data.length) {
                tableData[cellInfo.index].assigned_to = data[0].id;
                setTableData([...tableData]);
                setSearch(data[0].label);
              }
            }}
          />
          {assigned_to && (
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              overlay={
                <Tooltip className="my-tooltip" id="button-tooltip">
                  ReAssign To:{" "}
                  {assigned_by_name !== null
                    ? assigned_by_name
                    : "Not Available"}{" "}
                  <br />
                </Tooltip>
              }
            >
              <i
                style={{
                  color: "#75759c",
                  paddingLeft: "5%",
                  paddingTop: "10%",
                }}
                className="fas fa-info-circle"
              ></i>
            </OverlayTrigger>
          )}
        </div>
      </>
    );
  };



  var sendData = null

  function handleSend(childData) {
    sendData = childData
  }


  const AddPopup = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} trackId={trackId} id={id} patient={patient} order={order} />

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>


        </Modal.Footer>
      </Modal>
    );
  }


  const EditPopup = (props) => {

    return (
      <Modal
        {...props}

        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={remarkData} list={remarkList} update={handleSend} />


        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Add</Button>

        </Modal.Footer>
      </Modal>
    );
  }

  const handleAdd = (index, id, trackId, name, order) => {
    setCell(index);
    setId(id);
    setTrackingId(trackId)
    setpatient(name)
    setOrder(order)
    setModalShow(true);
  };

 
  const renderRemarks = (cellInfo) => {
    return (
      <div style={{textAlign:"center"}}>
      <i
      style={{ color: "rgb(91 138 218)" }}
        onClick={() => handleAdd(cellInfo.index, tableData[cellInfo.index].ref, tableData[cellInfo.index].id, tableData[cellInfo.index].name, tableData[cellInfo.index].order)}
        className="fas fa-comment"
      ></i></div>
    );


  }




  const renderTable = (cellInfo) => {

    return (
<>
      <div style={{textAlign:"center"}}> {tableData[cellInfo.index][cellInfo.column.id]}</div>
      
</>
    );
  }
  const renderName = (cellInfo) => {

    return (
<>
      <div style={{textTransform: "capitalize"}} > {tableData[cellInfo.index][cellInfo.column.id]}</div>
      
</>
    );
  }


  const renderId = (cellInfo) => {
    if (tableData[cellInfo.index][cellInfo.column.id]) {
      return (

        <div className=""> {tableData[cellInfo.index][cellInfo.column.id]}</div>

      );
    }
    else {
      return null
    }
  }
  const renderService = (cellInfo) => {

    return (

      <div > <span style={{textTransform: "capitalize"  }}>
        {tableData[cellInfo.index][cellInfo.column.id]}
      </span>
      {tableData[cellInfo.index].order_type==="master_medicine"||tableData[cellInfo.index].order_type==="master_labtest" ?<i
              style={{ color: "#75759c", paddingLeft: "5%" }}
              className="fas fa-info-circle fa-sm"
              onClick={() => {
               handleInfoPopUp(tableData[cellInfo.index].su_orders,tableData[cellInfo.index].order_type);
              }}
            ></i>:""}{" "}
      </div>

    );
  }


  const renderContact = (cellInfo) => {

    return (

      <div > <span style={{ margin: "10px" }}>
        {tableData[cellInfo.index][cellInfo.column.id]}
      </span>

      </div>

    );
  }

  const renderStatus2 = (cellInfo) => {

    return (
      <>
        <Select
          value={tableData[cellInfo.index].statusObj}
          onChange={(data) => {
            const List = tableData;
            setChanged(cellInfo.index)

            List[cellInfo.index].statusObj = data;
            List[cellInfo.index].status = data.value;
            setTableData(List);
            setRender(!render);

          }}
          options={statusOptions}
          className={`status-select ${tableData[cellInfo.index].status === "accepted" ? "accepted-status" :
              tableData[cellInfo.index].status === "rejected" ? "rejected-status" :
                tableData[cellInfo.index].status === "delivered" ? "delivered-status" :
                  ""}`}
        />
      </>
    );
  }




  const renderPriority = (cellInfo) => {

    if (tableData[cellInfo.index].priority === "critical") {
      return (

        <div > <div className="priority critical-priority-2 caps">{tableData[cellInfo.index][cellInfo.column.id].value}</div></div>

      );
    }
   
    else if (tableData[cellInfo.index].priority === "high") {
      return (

        <div ><div className="priority high-priority-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value}</div></div>

      );
    }
    if (tableData[cellInfo.index].priority === "medium") {
      return (

        <div ><div className="priority medium-priority-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value}</div></div>

      );
    }
    if (tableData[cellInfo.index].priority === "low") {
      return (

        <div ><div className="priority low-priority-2 caps"> {tableData[cellInfo.index][cellInfo.column.id].value}</div></div>

      );
    }


  }


  const renderDate = (cellInfo) => {

    let dateString = tableData[cellInfo.index][cellInfo.column.id];

    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (<div >
      <h6> {updatedDate.slice(4, 10)}  </h6>
      {/* <h6> {updatedDate.slice(16, 24)}</h6> */}
    </div>)

  }

  const renderDueDate = (cellInfo) => {
    if (tableData[cellInfo.index][cellInfo.column.id]) {
      let dateString = tableData[cellInfo.index][cellInfo.column.id];
      let dateObject = new Date(dateString);

      const updatedDate = dateObject.toString();
      return (<div >
        <h6> {updatedDate.slice(4, 10)}  </h6>

      </div>)
    } else {
      return null;
    }

  }

  const saveChanges = (index) => {
    const rowToUpdate = tableData.filter((elt, i) => {
      return i === index
    })

    callSave(rowToUpdate[0],index);

  }

 
  const callSave = (rowToUpdate,index) => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
    const order_id = rowToUpdate.id;
    const assigned_to = rowToUpdate.assigned_to;
    const status = rowToUpdate.status;
    const order_comments = rowToUpdate.remarks;
  
    const dataToUpdate = {}
    if (status) {
      if (rowToUpdate.payment_mode==="cod"&&rowToUpdate.paid_status==="not_paid"&&status==="delivered"){
        tableData.order_delivery_status=dataToUpdate.statusold
       }
      else{
        dataToUpdate.order_delivery_status = status;
      }
    }
    if (assigned_to) {
      dataToUpdate.order_assigned_to = assigned_to;
     tableData.order_delivery_status = "assigned"
    }
    if (order_comments) {
      dataToUpdate.order_comments = order_comments
    }

    console.log("THE DATA", rowToUpdate);
    if (rowToUpdate.payment_mode==="cod"&&rowToUpdate.paid_status==="not_paid"&&status==="delivered"){
      alert.error("payment not done");
      setTableData([]);
      setRerender(!reRender);
     }
    else{
    axios.put(`${APIURL}/api/v1/patient/sr-track-order-update/${order_id}/`, dataToUpdate, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
        
          alert.success("updated successfully");
          setTableData([]);
          setRerender(!reRender);
          // setSubmitMsg(res.data.message ? res.data.message : "Updated successfully!");

          // setSuccessSubmit(true);
          setBackup('')
        }
        else {


          setErrorMsg(res.data.message ? res.data.message : "Error in submission");
          setErrorSubmit(true);

        }



      })
      .catch(err => {

        setErrorMsg("Error in submission");
        setErrorSubmit(true);

      })
    }
  }

  const columns = [
    {
      Header: "Id",
      accessor: "order_id",
      Cell: renderId,
      sortable: true,
      filterable: true,

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      width: 100

    },

    {
      Header: "Service Request",
      accessor: "order",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      width: 140

    },
    {
      Header: "Qty",
      accessor: "quantity",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      width: 50,
      filterable: true,
      sortable: true,
    },

    {
      Header: "Requested On",
      accessor: "date",
      Cell: renderDate,
      filterable: true,
      sortable: true,
      style: { backgroundColor: "rgba(0,0,0,0.1)" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        let dateString = String(row[id]);
        let dateObject = new Date(dateString);
        const updatedDate = dateObject.toString();
        return row[id] !== undefined ? updatedDate.toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      width: 90,


    },
    {
      Header: "Delivery Date",
      accessor: "required_date",
      Cell: renderDueDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },

      width: 90,
      filterable: true,
      sortable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        let dateString = String(row[id]);
        let dateObject = new Date(dateString);
        const updatedDate = dateObject.toString();
        return row[id] !== undefined ? updatedDate.toLowerCase().includes(filter.value.toLowerCase()) : true
      },

    },
    {
      Header: "Name",
      accessor: "name",
      Cell: renderName,
      style: { whiteSpace: "unset" },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      width: 120

    },
    {
      Header: "Contact",
      accessor: "contact_number",
      Cell: renderContact,
      sortable: false,
      filterable: true,
      width: 100

    },
    {
      Header: "Priority",
      accessor: "priorityObj",
      Cell: renderPriority,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
       
        if (filter.value === "high") {
          return row[filter.id].value === "high";
        }

        if (filter.value === "critical") {
          return row[filter.id].value === "critical";
        }
        if (filter.value === "medium") {
          return row[filter.id].value === "medium";
        }
        if (filter.value === "low") {
          return row[filter.id].value === "low";
        }
      },
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="critical">Critical</option>
       
          <option value="high">High</option>
          <option value="medium">Medium</option>
          <option value="low">Low</option>
        </select>,


      width: 100

    },


    {
      Header: "Status",
      accessor: "statusObj",
      Cell: renderStatus2,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row) => {

        if (filter.value === "all") {
          return true;
        }

        if (filter.value.toLowerCase() === "accepted") {
          return row[filter.id].value === "accepted";
        }

        if (filter.value.toLowerCase() === "delivered") {
          return row[filter.id].value === "delivered";
        }

        if (filter.value.toLowerCase() === "rejected") {
          return row[filter.id].value === "rejected";
        }
        if (filter.value.toLowerCase() === "assigned") {
          return row[filter.id].value === "assigned";
        }
        if (filter.value.toLowerCase() === "out_for_delivery") {
          return row[filter.id].value === "out_for_delivery";
        }



      },
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>

          <option value="assigned">Assigned</option>

          <option value="accepted">Accepted</option>

          <option value="delivered">Delivered</option>

          <option value="rejected">Rejected</option>
          <option value="out_for_delivery">Out For Delivery</option>


        </select>,

      width: 150

    },
    {
      Header: "Reassign to ",
      accessor: "assign_to",
      Cell: renderAssigned2,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)"
      },

      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      width: 150

    },

    {
      Header: "",
      accessor: "remarks",
      Cell: renderRemarks,
      sortable: false,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      width: 70

    },

    {
      Header: "",
      Cell: props => {
        return (
          changed === props.index ? <button className="btn btn-primary btn-col btn-square btn-smallest"
            onClick={() => { saveChanges(props.index) }} >Save </button> : ""
        )
      },
      sortable: false,
      filterable: false,
      minWidth: 50,
      width: 50
    }



  ]

  const displayTable = <ReactTable
    columns={columns}
    data={tableData}
    defaultPageSize={perPage-3}
    className="service-req-table"
    resizable
    style={{ overflow: "wrap",textAlign:"left",width:"1230px" }}
    pagination
    paginationServer
    paginationTotalRows={totalRows}
   
    onPageChange={(pageIndex) => {handlePageChange(pageIndex);setPage(pageIndex+1)}}
    onPageSizeChange={handlePerRowsChange}
  >

  </ReactTable>

 
  return (<>


    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "rgba(0, 0, 255, 0.5)",
          },
        }),
        overlay: {},
      }}
    >
      <div className="">

        <div className="text-center">
        <h5 className=""><b>
          Service Fulfillment
          </b></h5>

          <div className="service-table-holder padding1">
            <div className="" style={{ backgroundColor: "#efff001a", height: "34px" }}>

              <div class="row" style={{ textAlign: "left", paddingTop: "4px" }}>
                <div class="col-6">
                  {/* <h5 className="" style={{ color: "var(--theme-blue)" }}><b> <i class="fa fa-th-large" aria-hidden="true"></i>{" "}Service Fulfillment</b></h5> */}
                </div>

                <div class="col">

                </div>
                <div class="col">

                </div>

                <div class="col">
                  <button disabled type="button" style={{ border: "none", textDecoration: "none" }} class=" btn-link"> Save All</button>
                </div>
                <div class="col-2">
                  <button onClick={() => navigate("/service_history_doc")} type="button" style={{ border: "none", textDecoration: "none", marginRight: "10px" }} class=" btn-link"> Service History{" "}<i class="fa fa-angle-right" aria-hidden="true"></i></button>

                </div>

              </div>

            </div>
            {displayTable}
          </div>


        </div>
      </div>
      <br />

      <br />

      {errorMsg !== '' ?
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            setErrorMsg('');
            setRerender(!reRender)
          }}

        /> : ''
      }

      {submitMsg !== '' ?
        <SuccessPopup
          show={successSubmit}
          msg={submitMsg}
          onHide={() => {
            setSuccessSubmit(false);
            setSubmitMsg('');

            setRerender(!reRender)
          }}

        /> : ''}

      {
        modalShow ?
          <AddPopup
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
          /> : null
      }
{infoShow ? (
        <InfoPopup
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}
        />
      ) : (
        ""
      )}
      {
        editShow ?
          <EditPopup
            show={editShow}
            onHide={() => {
              setEditShow(false)
            }}
            onSave={() => {
              if (sendData !== tableData[cell].remarks) {
                const data = tableData
                data[cell].remarks = sendData
                setTableData(data)
                setBackup(sendData)
                setEditShow(false);
                setRender(!render);
                setUpdateSuccess(true)

              }
            }} /> : null
      }

      {
        updateSuccess ?
          <UpdatePopup
            show={updateSuccess}
            onHide={() => {
              setUpdateSuccess(false);
            }}
          /> : null
      }
    </LoadingOverlay>
  </>
  );
}

export default ServiceRequests_doc;
