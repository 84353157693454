import React, { useState } from "react";
import "./bdocard.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

function BdoCard({ data, freeCount, activeCount, handle }) {
  
  const [successShow, setSuccessShow] = useState(false);
  
  const [modalShow, setModalShow] = useState(false);
  const create_link = `/create-bdo/${data.id}`;

 
  const change_password_link = `/changepassword-bdo/${data.id}`;
  const update_link = `/update-bdo/${data.id}`;
 
 

  const [deleteShow, setDeleteShow] = useState(false);
  const [suspendShow, setSuspendShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");
  const [isSuspend, setIsSuspend] = useState(data.is_suspended);



  const SubmitPopUp1 = (props) => {
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-success">Deleted Successfully</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary btn-col" onClick={props.onHide}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      );
    };
    function SubmitPopUp(props) {
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> {isSuspend?"Suspended":"Authorised"} Successfully!!</h4>
           
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>Close</Button>
            
    
           
    
           
          </Modal.Footer>
        </Modal>
      );
    }

 
  const handleDeletePopUp = (id) => {
    setDeleteIndex(id);
    setDeleteShow(true);
  };
  const handleSuspendPopUp = (id) => {
    setDeleteIndex(id);
    setSuspendShow(true);
    setIsSuspend(!isSuspend)
  };

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 className="title-of-page"> Delete User </h4>
          <h5 className="title-of-page">
            {" "}
            Please Confirm to Delete the User ID {deleteIndex}
          </h5>
          <br />

          <div className="form-group " style={{ marginLeft: "120px" }}>
            <button
              type="button"
              className="btn btn-danger btn-cancel"
              onClick={props.onHide}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={() => {
                handleDelete(deleteIndex);
              }}
            >
              Confirm{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const SuspendConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h4 className="title-of-page"> Suspend User </h4>
          <h5 className="title-of-page">
            {" "}
            Please Confirm to{data.is_suspended?"Authorise":"Suspend"} the User ID {deleteIndex}
          </h5>
          <br />

          <div className="form-group " style={{ marginLeft: "120px" }}>
            <button
              type="button"
              className="btn btn-danger btn-cancel"
              onClick={props.onHide}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={() => {
                handleSuspend(deleteIndex);
              }}
            >
              Confirm{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleSuspend = (e) => {
  //	e.preventDefault();

   	const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); 

    
  const data = {
    is_suspended :isSuspend
  }

    axios.put(`${APIURL}/api/v1/service-provider/bdo/${deleteIndex}/`, data, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setModalShow(true);
          
          
        } else {
          // setSubmitMsg("error");
        }
        setDeleteIndex("");
        setSuspendShow(false);
      })
      .catch((err) => {
        // setSubmitMsg("error");
      });

  };
  const handleDelete = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");


    axios
      .post(
        `${APIURL}/api/v1/service-provider/hospital-user-delete/?user_id=${item}&role=bdo`,
        {},
        {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setSuccessShow(true);
          //handle();
        } else {
        }

        setDeleteIndex("");
        setDeleteShow(false);
      })
      .catch((err) => {
      });
  };
  return (
    <>
      <div className="doctor-card">
        <div className="card-header12 bdo-card-header">
          <h6 className="dr-id"> ID : {data.id} </h6>
          <h6> {data.name} </h6>
        </div><br/>

        <div className="card-content26">
          <h6>
            <span className="key-labels"> Username </span><br/> <span>{data.user_name}</span>
          </h6>
          <h6 >  <span className="key-labels"> Qualification </span> <br/>{" "}<span>
            {data.qualification.map((item) => {
              return (
              
                <span>{item},</span>
              
              );
            })}</span></h6>
          <h6 >
            <span className="key-labels"> Specialization </span><br/><span>{" "}
            {data.specializations}</span>
          </h6>
          <h6 >
            <span className="key-labels"> Department </span><br/> <span>{data.department}{" "}</span>
          </h6>
          <br />

          <h6>
            {data.is_app_user ? (
              <div className="test3">
                <span style={{ color: "var(--green)" }} className="bold-class">
                  {" "}
                  Valid User Credentials{" "}
                </span>
              </div>
            ) : (
              <div  className="test3">
                <span style={{ color: "var(--red)" }} className="bold-class">
                  {" "}
                  No User Credentials{" "}
                </span>
              </div>
            )}
          </h6>

          <h6>
            {" "}
            <div  className="test3">
            <span className="key-labels"> Status </span>:{" "}
            {data.is_suspended ? (
              <span style={{ color: "var(--red)" }}> Suspended </span>
            ) : (
              <span style={{ color: "var(--green)" }}> Authorized </span>
            )}{" "}
         </div> </h6>

          <div className="contact-div">
            <i className="fa fa-phone key-labels" aria-hidden="true"></i>
            {data.phone}
            <br />
            <i
              className="fa fa-envelope key-labels"
              aria-hidden="true"
            ></i>{" "}
            {data.email}
            <br/>
            <br/>

          
          </div>
          <br />
       

          <div className="card-content-divider"></div>
          <div className="bottom-links">
            {data.is_suspended ? (
              <Link to="#"  
              onClick={() => {
                handleSuspendPopUp(data.id);
              }}
              
              > Authorize |</Link>
            ) : (
              <Link to =""
              onClick={() => {
                handleSuspendPopUp(data.id);
              }}
              className="delete-warning"> Suspend |</Link>
            )}

            {data.is_app_user ? (
              <Link to={change_password_link}>Change Password |</Link>
            ) : (
              <span className="temp-disabled"> Change Password| </span>
            )}
                     {data.is_app_user ?
              <span className="temp-disabled"> Generate User Credentials|</span>
              :

              <Link to={create_link} state={{name: data.name}}  > Generate User Credentials |</Link>}

            <Link to={update_link}> Edit Details |</Link>

             <Link to =""
              onClick={() => {
                handleDeletePopUp(data.id);
              }}
              className="delete-warning"
            >
              Delete User{" "}
            </Link> 
          </div>
        
        </div>
      </div>

     
      {successShow ? (
        <SubmitPopUp1
          show={successShow}
          onHide={() => {
            setSuccessShow(false);
            handle();
          }}
        />
      ) : null}
      {deleteShow ? (
        <DeleteConfirmPopup
          show={deleteShow}
          onHide={() => {
            setDeleteShow(false);
            setDeleteIndex("");
            handle();
          }}
        />
      ) : (
        ""
      )}
       {suspendShow ? (
        <SuspendConfirmPopup
          show={suspendShow}
          onHide={() => {
            setSuspendShow(false);
            setDeleteIndex("");
            handle();
          }}
        />
      ) : (
        ""
      )}
   
   {modalShow ? (
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            handle();
          }}
        />
      ) : null}

     
      
    
    </>
  );
}

export default BdoCard;
