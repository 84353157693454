import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../Global";
import Pagination from "react-js-pagination";
import "./pagination.css";
import './servicecenter.css'
import './payments.css'
import AddCart from "./AddCart";
import { Modal, Button } from 'react-bootstrap';
import SubServiceData from "./SubServiceData";
import fav from "../../assets/images/fav.png";
import clean from "../../assets/images/clean.png";
import LoadingOverlay from 'react-loading-overlay';

function MasterServiceShow({ patient_id, name1, details, setshow, refreshCart }) {
  // " ";
  let shipCharge=0
  let navigate = useNavigate()
  const pat_id = patient_id;
  const name2 = name1;
  const patient = details;
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState()
  const [subID, setSubID] = useState([])
  const [serviceitem, setServiceItem] = useState([])
  const [provider, setProvider] = useState([])
  const [splist, setSPList] = useState([])
  const [service, setService] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [dataval, setdataval] = useState([]);
  const [dataval2, setdataval2] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage,] = useState(5);
  const [cartShow1, setCartShow1] = useState(false)
  const [display1, setDisplay1] = useState(false)
  const [display3, setDisplay3] = useState(false)
  const [addServiceShow, setAddServiceShow] = useState(false)
  const [deleteShow, setDeleteShow] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState('');
  const [editShow, setEditShow] = useState(false)
  const [editIndex, setEditIndex] = useState('');
  const [serviceName, setServiceName] = useState("");
  const [consentLevel, setConsentLevel] = useState("");
  const [cartdata1, setCartdata1] = useState("");
  const [expand, setExpand] = useState()
  const[shipid,setShipid]=useState("")
  const initialRender = useRef(true)
  const [errormsg, setErrorMsg] = useState("");
  const [errorShow, setErrorShow] = useState(false);

  const aggr_type=sessionStorage.getItem("aggr_type")

  const handleDisplay = () => {
    setDisplay1(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    document.addEventListener('click', handleDisplay)

    return () => {
      document.removeEventListener('click', handleDisplay)
    }
  }, [])

  useEffect(() => {
    // " ";
    console.log('the term', searchTerm)
    if (initialRender.current) {
      initialRender.current = false
    }
    else if (searchTerm === "") {
      setService([])
      setDisplay1(false);
    }
    else {

      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      );


      axios
        .get(`${APIURL}/api/v1/connect/common-search/?aggregation_type=${aggr_type}&search=${searchTerm}`, {





          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            // " ";
            //  const data=res.data.result[1].services
            let data = [];
          
            let serv = [];
            res.data.result.forEach(item => {

              if (item && item.services) {
                data = item.services;
               
                console.log("services",item.services)
            
              }
            });
            //let arr =[]
            for (let index = 0; index < data.length; index++) {


              serv.push(data[index])

            }
            setService(serv);
            // " ";

            //setDisplay3(true);
            console.log("data", service)
            setDisplay1(true)


          }
          else {

          }
        }).catch((error) => {

        })

    }
  }, [searchTerm])

  const dis=()=>
  {
    setDisplay3(false)
  }


  const loadData = (id1) => {
    
;
    const id2 = id1;
    if(id2>0){
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    axios
      .get(`${APIURL}/api/v1/patient/service-providers-list/?sub_service=${id2}`, {
        headers: { Authorization: "Token " + v2 },
      })




      .then((res) => {
                                            
        if (res.status === 200) {
          
          const data1 = res.data.service_providers
          let splist = []
          for (let index = 0; index < data1.length; index++) {
            splist.push(data1[index]);
          }
         setSPList(data1);
//splist.push(data1)
          console.log("splist", splist)
          setDisplay1(false)
          setDisplay3(true)




        }


     
      })
      .catch((err) => {

      });
    }
      else{
        setErrorMsg("no service-providers for this service");
        setErrorShow(true);
      }
  }
  const loadCart = (cart) => {
    

    const id3 = cart;
  
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    axios
      .get(`${APIURL}/api/v1/patient/hospital-services-detail/${id3}`, {
        headers: { Authorization: "Token " + v2 },
      })




      .then((res) => {
                                            
        if (res.status === 200) {
          const data1 = res.data.data
         setCartdata1(data1);
         setCartShow1(true);
         
//splist.push(data1)
        
      



        }


     
      })
      .catch((err) => {

      });
   
      // else{
      //   setErrorMsg("no service-providers for this service");
      //   setErrorShow(true);
      // }
  }




  const setRender = (data) => {
    setCartShow1(false);
    refreshCart(data);
    shipCharge=data.sCharge
    console.log('the data received', data)
   setDisplay3(false)
   setSearchTerm("")
   
  }
  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "green" }}>
            {errormsg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const CartPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        // dialogClassName="modal-90w"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{alignSelf: 'center'}}>

          <AddCart data2={props.data10} patient_ID={pat_id} patient_name={name2} ser={setshow} patient={patient} render={setRender} shipID={shipid} dis1={dis} />

        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const handleSearchChange = (e) => {
    // " ";
    e.preventDefault();
    setCurrentPage(1)
    setSearchTerm(e.target.value)

    setDisplay1(false)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }


  // let data5 = splist ? splist.sort((a, b) => {
  //     if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  //     if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  //     return 0;
  // })
  //     .filter((value) => {
  //         if (searchTerm === '') {
  //             return value
  //         } else if (value.name.toLowerCase().includes(searchTerm.toLowerCase())) {
  //             return value
  //         }
  //     }) : [];

  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index)
    }
    else {
      setExpand()
    }
  };

  const showItem = () => {
    console.log('THE ITEM')
    setTimeout(() => {
      setDisplay1(true);
    }, 200);

  }
  // const indexLast = currentPage * perPage;
  // const indexFirst = indexLast - perPage;
  // const current = data5.slice(indexFirst, indexLast);
  // const total = data5.length;

  // const pageNumbers = []
  // for (let i = 0; i <= Math.ceil(total / perPage); i++) {
  //     pageNumbers.push(i)
  // }
  const handleCart = (data) => {
    ;
console.log("data",data)
let cart = data.id
setShipid(data.shipping_charge_id)
loadCart(cart)
    // " ";
    // let arr1 = []
    // arr1.push(dataset)
    // dataval.push(data)
    // //dataval.push(dataset);
    // console.log("dataval",dataval[0])
    //setCartShow1(true);
  }

  const handleClick = (item) => {
  
  initialRender.current = true
  setSearchTerm(item.name)
     setServiceItem(item)
  //let provider1 =[];
  
  
    // subID.push(item.id)
    //  setSubID(item.id)      
 
   // if(item.service_providers.length>0){
   // provider1.push(item.service_providers)
   // setProvider(item.service_providers)
   // console.log("providers",provider1[0][0].id)
    loadData(item.id);
  // loadData(provider1[0][0].id);
   // }
    // else{
    //   loadData('0')
    // }
    // setFlag1(true);
    setDisplay1(false)
    setDisplay3(false)
    // setAllData([])
    // setRefresh(!refresh)
  }
  //  const totalPages = pageNumbers.length;

  let display = splist.length>0 ? splist.map((data, index) => {

    // <h6>select service from given service provdiers!!!</h6>
    // " ";
    return (
      <>
        <div className='list-item8'>

          {" "}
          <h4 className="each-list-link flex-cart-icon">
          { aggr_type==="aggregator" ?
            <Link to="#" style={{ textDecoration: 'none' }} className={expand === index ? 'feature-data mt-3 feature-servicelist' : 'feature-data feature-servicelist'} onClick={() => handleDetail(index)}>
              <i className={expand === index ? "fa fa-caret-up fa-fw" : "fa fa-caret-down fa-fw"} style={{ color: "#000000", fontSize: 30, paddingBottom: 8 }} ></i> <h4 style={{ fontSize: 20, paddingLeft: 20, fontWeight: 600 }}> {data.hospital_name}{"  "}</h4> <br />
            </Link>
            :     <h4 style={{ fontSize: 20, paddingLeft: 20, fontWeight: 600 }}>{data.sub_service_name}</h4> }<span>
              {/* <h6> Add to Cart</h6>  */} <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <i class="fa fa-shopping-cart cart-icon" onClick={() => handleCart(data)} style={{ cursor: "pointer" }} aria-hidden="true"></i></span>
          </h4>


        </div>
        {
          expand === index ? <>
            <div className='expand-cart-list'>
              <h6 className="each-list-link">
                <div style={{ textDecoration: 'none' }} onClick={() => handleDetail(index)}>
                  <h4 style={{ fontSize: 20, paddingLeft: 20, fontWeight: 600 }}>{data.sub_service_name}</h4>
                  <h4 style={{ fontSize: 20, paddingLeft: 20, fontWeight: 600 }}><i class="fa fa-inr" aria-hidden="true"></i> {data.amount}{"  "}</h4>
                  {/* <h4 style={{ fontSize: 20, paddingLeft: 20, fontWeight: 600 }}><i class="fa fa-inr" aria-hidden="true"></i> {data.service_type}{"  "}</h4> */}
                 {/* // <h4 style={{ fontSize: 20, paddingLeft: 20, fontWeight: 600 }}><i class="fa fa-inr" aria-hidden="true"></i> {data.amount}{"  "}</h4> */}
                </div>
              </h6>


            </div>

            {/* SubServices!!
                  <SubServiceData id={data.id} servicetype={data.service_type} patId={pat_id} name3={name2} /> */}

          </>
            : null
        }
      </>

    )
  }) : 
  (
    <div style={{ color: "white", fontSize: "15px", height: "100px", padding: "20px", textAlign: 'center' }}>
      <h4>No Service Providers found for selected Service!!</h4>
    </div>
  )
  return (
    <>

      <div>

        <>

          <div>

            <div className="master-serviceshow">


              <br />
              <br />
              <div className="search-section2">
                {/* <div style={{ flex: 1 }}>
                  {pat_id ?
                    <div className="fav-layout" disabled={true}>
                      <img src={fav} className="message-button" width="50px" />
                      <span className="fav-text">Favourites</span>
                    </div>
                    : null}
                </div> */}


                <div className="search-input1">
                  <div className='flex-row1'>
                    {pat_id ?
                      <div className='search-form'>
                        <input
                          className="search-input"
                          type="text"
                          // maxLength="100"
                          placeholder="Search a Service"
                          value={searchTerm}
                          maxLength="150"
                          disabled={!pat_id}
                          onChange={handleSearchChange}
                        />
                        <i class="fa fa-search" aria-hidden="true" style = {{marginTop:"20px",marginLeft:"122px", color:"rgb(70 137 139)"}}></i>
                        {/* <button disabled={searchTerm === ""} className="btn btn-primary search-icon" onClick={() => showItem()}>
                          {" "}
                          <i className="fas fa-search"></i>{" "}
                        </button> */}
                      </div>
                      : null}
                  </div>
                  {
                    display1 ?
                      <div className='suggest-display adjust-dimensions' style={{ width: '23.5%', position: 'absolute' }}>
                        {
                          service.length > 0 ? service.map((item) => {

                            return (
                              <p onClick={() => handleClick(item)} className='suggest-item text-secondary'>{item.name}</p>
                            )
                          }) : <h6 className='text-muted'>No Services found !!</h6>
                        }

                      </div>
                      : null
                  }
                </div>
              </div>
              <br />
              <div className="list-section">
                <div >

                  {display3 ? (
                    display
                  ) : (
                    <div style={{ color: "white", fontSize: "15px", height: "100px", padding: "20px", textAlign: 'center' }}>
                      {!loading ? pat_id ? <h4>Please search for a Service!!</h4> : <h4>Please select a patient!!</h4> : ""}
                    </div>
                  )}
                  <br /><br />
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{flex: 1}}></div>
                <div className="cleaner-layout">
                <img src={clean} width="50px" style={{
                  // cursor: 'pointer',
                  padding: 10}} />
                </div>
                
              </div>
            </div>
          </div>
        </>
        {errormsg !== "" ? (
        <SubmitErrorPopup
          show={errorShow}
          onHide={() => setErrorShow(false)}
        />
      ) : (
        ""
      )}
        {cartShow1 ?
          <CartPopUp
            show={cartShow1}
            data10={cartdata1}

            onHide={() => {
              setCartShow1(false);
              //setTableData([]);
              //setRerender(!reRender)
            }}

          /> : ''
        }

      </div>





    </>
  )

}

export default MasterServiceShow
