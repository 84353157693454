import { useState, useEffect } from "react"
import axios from "axios";
import { APIURL } from "../../../Global";
import { Button, Modal } from 'react-bootstrap';
import { faL } from "@fortawesome/free-solid-svg-icons";
import ShipDistance from "./ShipDistance";
import ShipEdit from "./ShipEdit";
import ShipSize from "./ShipSize";
import ShipQuantity from "./ShipQuantity";
import QuantityPop from "./QuantityPop";
import "./shipcharge.css"
function ShipCharge({ onSuccess, shipdata, props, ID, shipid, data1, modalClose }) {
  const [flag, setFlag] = useState(true)
  const [id, setId] = useState(shipdata.id)
  let shipid1 = 0
  let shipid2 = 0
  if (shipid.length === 1) {
    if (shipid[0].type === "distance") {
      shipid1 = shipid[0].id
    }
    else {
      shipid2 = shipid[0].id
    }
  } else if (shipid.length === 2) {
    if (shipid[0].type === "distance") {
      shipid1 = shipid[0].id
      shipid2 = shipid[1].id
    }
    else {
      shipid1 = shipid[1].id
      shipid2 = shipid[0].id
    }
  }
  const [shipeditshow, setShipeditShow] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [distance, setDistance] = useState("")
  const [size, setSize] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [charge, setCharge] = useState("")
  const [distance1, setDistance1] = useState("")
  const [size1, setSize1] = useState(0)
  const [quantity1, setQuantity1] = useState(0)
  const [charge1, setCharge1] = useState("")
  const [error, setError] = useState("")
  const [completeShow, setCompleteShow] = useState(false);
  const [distanceShow, setDistanceShow] = useState(false);
  const [sizeShow, setSizeShow] = useState(false);
  const [quantityShow, setQuantityShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);

  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");
    const id1 = id;
    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */
    axios
      .get(`${APIURL}/api/v1/service-provider/shipping-charge-detail/${shipid1}/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {

        if (res.status == 200) {
          const data1 = res.data;


          setCharge1(data1.charge);
          setDistance1(data1.threshold_distance);
          // setSize1(data1.threshold_size);
          // setQuantity1(data1.threshold_quantity);
          // setId(data1.id)
        } else {
          setError(res.data.message)
        }
      })
      .catch((err) => {
        //setErrorShow(true)
      });
  }, [refresh])

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === "charge") {
      setCharge(val);
    }


    if (key === "distance") {
      setDistance(val);
    }
    if (key === "size") {
      setSize(val)
    }
    if (key === "quantity") {
      setQuantity(val)
    }

  };


  const CompletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'> Shipping Charge Successfully Saved</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }
  const handleShipEdit = () => {
    setShipeditShow(true);

  }

  const handleDistanceEdit = () => {
    setDistanceShow(true);
  }



  const submitshipedithandle = () => {
    setShipeditShow(false)
    setRefresh(!refresh)
  }

  const submitDistanceEditHandle = () => {
    setDistanceShow(false);
    setRefresh(!refresh)
  }
  const submitSizeEditHandle = () => {
    setSizeShow(false);
    setRefresh(!refresh)
  }
  const submitQuantityEditHandle = () => {
    setQuantityShow(false);
    setRefresh(!refresh)
  }


  const ShipeditPopup = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShipEdit
            id={shipid1}
            submiteditship={submitshipedithandle}
            seteditship={charge1}
          //onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };





  const DistancePopup = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShipDistance
            id={shipid1}
            setDistanceHandle={submitDistanceEditHandle}
            setdistanceship={distance1}
          //onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const SizePopup = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShipSize
            id={shipid}
            setSizeHandle={submitSizeEditHandle}
            setsizevalue={size1}
          //onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const QuantityPopup = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShipQuantity
            id={shipid}
            setQuantityHandle={submitQuantityEditHandle}
            setquantitydata={quantity1}
          //onHide={PatientDetails}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {errorMsg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const addship = (e) => {


    if (charge == "" && distance == "") {
      setErrorMsg("Please enter ShipCharge and Distance");
      setErrorSubmit(true);

    }
    if (charge < 0 || distance < 0) {
      setErrorMsg("negative values are not allowed");
      setErrorSubmit(true);

    }
    if (charge == "0" || distance == "0") {
      setErrorMsg("zero values are not allowed");
      setErrorSubmit(true);

    }

    else if (charge !== "" && distance == "" || distance !== "" && charge == "") {
      setErrorMsg("Please fill all fields");
      setErrorSubmit(true);
    }
    else {
      e.preventDefault();


      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      const dataToSend = {
        charge: charge,
        item_service: id,

        threshold_distance: distance,
        threshold_size: size,
        // threshold_quantity:quantity,


      }

      axios
        .post(`${APIURL}/api/v1/service-provider/shipping-charge/`, dataToSend, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {

            // onSuccess();
            setRefresh(!refresh)
            setCompleteShow(true)
            // shipid1=

            //setSuccessShow(false)
          } else {
            setError(res.data.message)
          }
        })
        .catch((err) => {
          //setErrorShow(true)
        });
      // setSuccessShow(false)



    }

  }

  return (
    <div className="appFormShip" >

      {shipdata.service_type !== "general_services" ?
        <div className="title-of-tasks" style={{}}><h5>consumable service</h5>
          <button onClick={() => setFlag(true)} className="btn btn-outline-primary toggle-btn-1">Distance</button>
          <button onClick={() => setFlag(false)} className="btn btn-outline-primary toggle-btn-end">Quantity</button></div> :
        <div className="title-of-tasks" ><h5> General Service</h5></div>
      } <br />

      {flag || shipdata.service_type === "general_services" ? <div className="formFields" >
        <div className="title-of-tasks"><h5>Add Shipping Charge Based on Distance</h5> </div>
        <div className="formField" style={{ display: "flex" }}>
          <label className="col-sm-5">*Ship Charge   : </label> {charge1}
          {charge1 != "" ?
            <>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <i
                style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                onClick={handleShipEdit}
                className="fas fa-edit"
                aria-hidden="true"
              ></i>    </>
            :
            <>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <input
                type="number"
                name="charge"
                min="0"
                value={charge}
                onChange={handleChange}
                required
              ></input>
            </>
          }
          {charge1 == 0 && charge1 !== "" ? <><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <i
            style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
            onClick={handleShipEdit}
            className="fas fa-edit"
            aria-hidden="true"
          ></i> </> : " "}

        </div>
        <div className="formField" style={{ display: "flex" }}>
          <label className="col-sm-5">*Distance in km   : </label> {distance1}
          {distance1 != "" ?
            <>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <i
                style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
                onClick={handleDistanceEdit}
                className="fas fa-edit"
                aria-hidden="true"
              ></i>    </>
            :
            <>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <input
                type="number"
                name="distance"
                min="0"
                value={distance}
                onChange={handleChange}
                required
              ></input>
            </>
          }
          {distance1 == 0 && distance1 !== "" ? <><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <i
            style={{ justifySelf: "start", color: "#3468c2", cursor: "pointer" }}
            onClick={handleDistanceEdit}
            className="fas fa-edit"
            aria-hidden="true"
          ></i> </> : " "}
        </div>

        {charge1 === "" ? <div className="form-heading title-of-tasks" >
          <button className=" btn formFieldButton" onClick={addship} disabled={charge1 > 0}>Save Changes</button>
          {/* <Button variant= "danger" onClick={() => { handleDelete(id) }} >Delete</Button> */}
          {error ? (
            <div className="error-validation-msg">
              {error}
            </div>
          ) : null}
        </div> : ""}

      </div> : shipdata.service_type !== "general_services" && <QuantityPop
        id={id}
        shipid={shipid2}
        modalclose={modalClose}
        data={shipdata}
      // shiplclose = {props.closefn}
      //onHide={PatientDetails}
      />}


      {completeShow ?

        <CompletePopUp
          show={completeShow}
          onHide={() => {
            setCompleteShow(false); setRefresh(!refresh)
            modalClose();
          }}
        />
        : null}

      {shipeditshow ?

        <ShipeditPopup
          show={shipeditshow}
          seteditship={charge1}
          onHide={() => {
            setShipeditShow(false)
            modalClose();
          }}
        />
        : null}
      {distanceShow ?

        <DistancePopup
          show={distanceShow}
          setdistanceship={distance1}
          onHide={() => {
            setDistanceShow(false)
            modalClose();
          }}
        />
        : null}

      {sizeShow ?

        <SizePopup
          show={sizeShow}
          setsizevalue={size1}
          onHide={() => {
            setSizeShow(false)
            modalClose();
          }}
        />
        : null}

      {quantityShow ?

        <QuantityPopup
          show={quantityShow}
          setquantitydata={quantity1}
          onHide={() => {
            setQuantityShow(false)
            modalClose();
          }}
        />
        : null}

      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}
        />
      ) : (
        ""
      )}

    </div>


  )
}
export default ShipCharge