import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditMedicine(props) {
  const[completeShow,setCompleteShow]=useState(false)
  const [selectedImage, setSelectedImage] = useState(null);
    const[data, setData]= useState(props.data)
    const value = props.data.dosage_form ? props.data.dosage_form.toLowerCase() : '';
    const label = props.data.dosage_form ? props.data.dosage_form : '';
    const [dosage_type, Setdosage_type]= useState({value:value, label: label});
    const value2 = props.data.med_type ? props.data.med_type.toLowerCase() : '';
    const label2 = props.data.med_type ? props.data.med_type : '';
    const [med_type, setMedicinetype]= useState({value:value2, label:label2});
    const value3 = props.data.strength_unit ? props.data.strength_unit.toLowerCase() : '';
    const label3 = props.data.strength_unit ? props.data.strength_unit : '';
    const [strengthunit, setStrengthUnit]= useState({value:value3, label:label3});
    const value1 = props.data.route ? props.data.route.toLowerCase() : '';
    const label1 = props.data.route ? props.data.route : '';
    const [routine, setRoutine]= useState({value:value1, label: label1})
    const[flag,setFlag]=useState(false)
  //  const [dosage_type, Setdosage_type]= useState({value:props.data.DosageOptions, label: props.data.DosageOptions})
  //  const [routine, setRoutine]= useState({value:props.data.RouteOptions, label: props.data.RouteOptions}
    

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

    const [error,setError]=useState({
      medicinename :'',
      genericname:'',
      strength:'',
      medicinetype:'',
      dosage_type:'',
      medicinecode:'',
      routine:'',
      strengthunit:'',
      composition:''
        
       });
       
    const DosageOptions = [{ value:'tablets', label:'TABLETS' },                       
    { value:'capsules', label:'CAPSULES' },
    { value:'chewable tablets', label:'CHEWABLE TABLETS' },
    { value:'powders', label:'POWDERS' },
    { value:'solutions', label:'SOLUTIONS' },
    { value:'emulsions', label:'EMULSIONS' },
    { value:'suspensions', label:'SUSPENSIONS' },
    { value:'lotions', label:'LOTIONS' },
    { value:'creams', label:'CREAMS' },
    { value:'ointments', label:'OINTMENTS' },
    { value:'effervescent granules', label:'EFFERVESCENT GRANULES' },
    { value:'aerosols', label:'AEROSOLS' },
    { value:'gases', label:'GASES' },
    { value:'suppositories', label:'SUPPOSITORIES' },
    { value:'injections', label:'INJECTIONS' }
]

const RouteOptions = [{ value:'oral', label:'ORAL' },
{ value:'topical_skin', label:'TOPICAL_SKIN' },
{ value:'topical-eyes', label:'TOPICAL_EYE' },
{ value:'topical_ears', label:'TOPICAL_EARS' },
{ value:'inhalation', label:'INHALATION' },
{ value:'injections', label:'INJECTIONS' },
{ value:'vaginal', label:'VAGINAL' },
{ value:'anal', label:'ANAL' },
{ value:'sub_lingual', label:'SUB_LINGUAL' }

]
const Strengthoptions = [{ value:'mg', label:'mg' },                       
{ value:'g', label:'g' },
{ value:'ml', label:'mL' },
{ value:'mcg', label:'mcg' },
{ value:'mEq', label:'mEq' },
{ value:'iu', label:'IU' },
]

const medicinclasses = [{ value:'painkillers', label:'painkillers' },                       
{ value:'Antipyretics', label:'Antipyretics' },
{ value:'AntiMalarial', label:'AntiMalarial' },
{ value:'Antibiotics', label:'Antibiotics' },
{ value:'Antiseptics', label:'Antiseptics' },
{ value:'Mood Stabilizers', label:'Mood Stabilizers' },
{ value:'Premarin', label:'Premarin' },
{ value:'Enovid', label:'Enovid' },
{ value:'Stimulants', label:'Stimulants' },
{ value:'Tranquilizer', label:'Tranquilizer' },
{ value:'statins', label:'statins' },
{ value:'Anesthetics', label:'Anesthetics' },
{ value:'Blood glucose regulators', label:'Blood glucose regulators' },
{ value:'Contraceptives', label:'Contraceptives' },
{ value:'Dermatological agents', label:'Dermatological agents' },
{ value:'	Hormonal agents', label:'	Hormonal agents' },
{ value:'Respiratory tract agents', label:'Respiratory tract agents' },
{ value:'Skeletal muscle relaxants', label:'Skeletal muscle relaxants' },
{ value:'Blood products', label:'Blood products' },
{ value:'Antivirals', label:'Antivirals' },
{ value:'Antifungals', label:'Antifungals' },
{ value:'Ophthalmic agents', label:'Ophthalmic agents' },
{ value:'other', label:'other' }
]

const handleServiceChange= (data) => {
  Setdosage_type(data)
}
const strengthChange = (data)=>
{
 setStrengthUnit(data)
}

const handleUnitChange= (data) => {

  setRoutine(data)
}
const handleMedicineType= (data) => {
setFlag(true)
setMedicinetype(data);
 
}
const customStyles = {
  control: base => ({
    ...base,
    width: 150,
    minHeight: 35
  })
};
const validate=()=>{
    
  let input = data;
  let errors = {};
  let isValid = true;
setError({});


  if (!input["med_name"] || input["med_name"]==="" ) {
    isValid = false;
    errors["medicinename"] = "Please enter Medicine Name";
  }
  if (!input["generic_name"] || input["generic_name"]==="" ) {
    isValid = false;
    errors["genericname"] = "Please enter generic name of medicine";
  }
  if (!input["strength"] || input["strength"]==="" ) {
    isValid = false;
    errors["strength"] = "Please enter strength of medicine";
  }
  if (!input["med_type"] || input["med_type"]==="") {
    isValid = false;
    errors["medicinetype"] = "Please enter medtype";
  }
  if (!input["dosage_form"] || input["dosage_form"]==="" ) {
    isValid = false;
    errors["dosage_type"] = "Please enter dosage type";
  }
  if (!input["med_code"] || input["med_code"]==="" ) {
    isValid = false;
    errors["medicinecode"] = "Please enter medicine code";
  }
  if (!input["route"] || input["route"]==="" ) {
    isValid = false;
    errors["routine"] = "Please enter route of medicine";
  }
  if (!input["strength_unit"] || input["strength_unit"]==="" ) {
    isValid = false;
    errors["strengthunit"] = "Please enter strength unit of medicine";
  }
  if (!input["composition"] || input["composition"]==="" ) {
    isValid = false;
    errors["composition"] = "Please enter composition of medicine";
  }

  
  

   setError(currentstate=>({
         ...currentstate,
        ...errors
      }));
  

  return isValid;
}
const CompletePopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-success'> Successfully Saved</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
       <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
       
       
      </Modal.Footer>
    </Modal>
  );
}

    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }



const saveChanges = (e)=> {
    e.preventDefault();
   
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
     let dataToSend = new FormData();
     if (props.data.med_name === data.med_name && flag === false) {
       dataToSend.append('generic_name', data.generic_name);
       dataToSend.append('strength', data.strength);
       dataToSend.append('composition', data.composition);
       dataToSend.append('dosage_form', dosage_type.value);
       dataToSend.append('strength_unit', strengthunit.value);
       dataToSend.append('med_code', data.med_code);
       dataToSend.append('route', routine.value);
       dataToSend.append('description', data.description);
       dataToSend.append('manufacturer_name', data.manufacturer_name);
       if (selectedImage) {
       dataToSend.append('image', selectedImage);}
     } else if (props.data.med_name === data.med_name) {
       dataToSend.append('generic_name', data.generic_name);
       dataToSend.append('strength', data.strength);
       dataToSend.append('composition', data.composition);
       dataToSend.append('med_type', med_type.value);
       dataToSend.append('dosage_form', dosage_type.value);
       dataToSend.append('med_code', data.med_code);
       dataToSend.append('route', routine.value);
       dataToSend.append('strength_unit', strengthunit.value);
       dataToSend.append('description', data.description);
       dataToSend.append('manufacturer_name', data.manufacturer_name);
       if (selectedImage) {
        dataToSend.append('image', selectedImage);}
     } else {
       dataToSend.append('med_name', data.med_name);
       dataToSend.append('generic_name', data.generic_name);
       dataToSend.append('strength', data.strength);
       dataToSend.append('composition', data.composition);
       dataToSend.append('strength_unit', strengthunit.value);
       dataToSend.append('med_type', med_type.value);
       dataToSend.append('dosage_form', dosage_type.value);
       dataToSend.append('med_code', data.med_code);
       dataToSend.append('route', routine.value);
       dataToSend.append('description', data.description);
       dataToSend.append('manufacturer_name', data.manufacturer_name);
     
       if (selectedImage) {
        dataToSend.append('image', selectedImage);}
    
      // dataToSend.append('image',  data.image);
     }
     
     axios
       .put(`${APIURL}/api/v1/staff/master-medicine-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
        if (res.data.status === "success") {
          setCompleteShow(true);
         // setSuccessShow(true);
         setFlag(false)
          setData({
            medicinename :'',
            genericname:'',
            strength:'',
            composition:'',
            medicinetype:{ value:'', label:'' },
            dosage_type:{ value:'', label:'' },
            strengthunit:{ value:'', label:'' },
            medicinecode:'',
            routine: { value:'', label:'' },
            description:'',
            manufacturername:''
         });}
          else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }
}
const handleImageUpload = (event) => {
  const image = event.target.files[0];
  setData(prevData => ({...prevData, image: URL.createObjectURL(image)}));
  setSelectedImage(image);
};




    return (
        <>
     <h4 style={{marginTop:'0'}} className="title-of-page">Edit Medicine</h4>
     <div style={{display:"flex" ,marginLeft:"180px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {data.image && (
         <div style={{ display: 'flex' }}>
          <p>Selected Image:</p>
          <img src={data.image} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
     <div className='fit-content'>

            
            <div className='feature-data pad-features'>
                <div className="flex-col justify-feature" >
                  
                    <span className='align-rental'>*Medicine Name: </span>
                    <input type='text' className='form-control title-flex' name='med_name' value={data.med_name} onChange={handleTitle} />
                    {error.medicinename ? <div className="error-validation-msg error-feature-inventory">{error.medicinename}</div> : null}
                </div>

                <div className="flex-col justify-feature">
                    <label className='align-rental'>*Generic Name : </label>
                    <input type='text' className='form-control title-flex' name='generic_name' value={data.generic_name} onChange={handleTitle} />
                    {error.genericname ? <div className="error-validation-msg error-feature-inventory">{error.genericname}</div> : null}
                </div>
                <div className="flex-col justify-feature" >
                    <span className='align-rental'>*Strength: </span>
                    <input type='text' className='form-control title-flex' name='strength' value={data.strength} onChange={handleTitle} />
                    {error.strength ? <div className="error-validation-msg error-feature-inventory">{error.strength}</div> : null}
                </div>
                <div className='flex-row name-content' style={{marginLeft:"-1%", width:"40%"}} >
                <span className='align-rental'>*Strength Unit: </span>
                <Select
                           defaultValue={strengthunit}
                            onChange={(data,e)=>{strengthChange(data,e)
                            }}
                            options={Strengthoptions}
                            name='strengthunit'
                            className="select-currency select-flex"
                        />
                   {error.strengthunit ? <div className="error-validation-msg error-feature-inventory">{error.strengthunit}</div> : null} 
                </div>
                <div className="flex-col justify-feature" >
                <span className='align-rental'>*Medicine Type: </span>
                <Select
                            defaultValue={med_type}
                            onChange={(data,e)=>{handleMedicineType(data,e)
                            }}
                            options={medicinclasses}
                            name='medicinetype'
                            className="select-currency select-flex"
                            styles={customStyles}
                        /><br/>
                    {error.medicinetype ? <div className="error-validation-msg error-feature-inventory">{error.medicinetype}</div> : null}
                </div>
                
                <div className="flex-col justify-feature" >
                    <span className='align-rental'>* Medicine Code: </span>
                    <input type='text' className='form-control title-flex' name='med_code' value={data.med_code} onChange={handleTitle} />
                    {error.medicinecode ? <div className="error-validation-msg error-feature-inventory">{error.medicinecode}</div> : null}
                </div>
               
                <div className="flex-col justify-feature" >
                    <span className='align-rental'> Description: </span>
                    <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
                    {/* {error.description ? <div className="error-validation-msg error-feature-inventory">{error.description}</div> : null} */}
                </div>
                <div className="flex-col justify-feature" >
                    <span className='align-rental'> Manufacturer Name: </span>
                    <input type='text' className='form-control title-flex' name='manufacturer_name' value={data.manufacturer_name} onChange={handleTitle} />
                    {/* {error.manufacturername ? <div className="error-validation-msg error-feature-inventory">{error.manufacturername}</div> : null} */}
                </div>
                <div className='flex-col justify-feature' >
                    <span className='align-rental'>*Composition: </span>
                    <input type='text' className='form-control title-flex' name='composition' value={data.composition} onChange={handleTitle} />
                    {error.composition ? <div className="error-validation-msg error-feature-inventory">{error.composition}</div> : null}
                </div>
                <div className="flex-col justify-feature">
                <span className='align-rental'>Dosage: </span>
                <Select
                            defaultValue={dosage_type}
                            onChange={(data,e)=>{handleServiceChange(data,e)
                            }}
                            options={DosageOptions}
                            name='dosage_type'
                            className="select-currency select-flex"
                            styles={customStyles}
                        />
                    {error.dosage_type ? <div className="error-validation-msg error-feature-inventory">{error.dosage_type}</div> : null}
                </div>

                <div className="flex-col justify-feature" >
                <span className='align-rental'>Route: </span>
                <Select
                            // value={data.routine}
                            defaultValue={routine}
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={RouteOptions}
                            name='routine'
                            className="select-currency select-flex"
                            styles={customStyles}
                        />
                    {error.routine ? <div className="error-validation-msg error-feature-inventory">{error.routine}</div> : null}
                </div>
                

                </div>

            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
            {successShow ? 
<SuccessPopUp
show={successShow}
onProceed={() =>  {setSuccessShow(false); props.modalClose();}}
onHide={() =>  {setSuccessShow(false); props.notProceedClose(); 
  props.modalClose();}}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
{completeShow ? 

<CompletePopUp
show={completeShow}
onHide={() =>  {setCompleteShow(false)
props.modalClose()}}
/> 
: null}            
        </div>
        </>
    )
}

export default EditMedicine
