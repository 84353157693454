import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import axios from "axios";
import { APIURL } from "../../Global";

function InputOtp(props) {
  const [otpValue, setOtpValue] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const user = location.state.username;
  const msg = location.state.responseMessage;


  useEffect(() => {
    console.log('tHE USER PARAMs', user, msg);
  }, []);


  const ErrorPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {errorMsg} </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleValidateOTP = (e) => {
    e.preventDefault();
    const dataToSend = {
      username: user,
      otp: otpValue
    }
    axios.post(`${APIURL}/api/v1/account/forgot-password/`, dataToSend, {
      headers: {
        'Content-Type': 'application/json'
      }
    })

      .then(res => {
        if (res.data.status === "success") {
          if (res.data.is_valid === true) {

            navigate('/reset-password', {
              replace: false,
              state: {
                username: user,
                otp: otpValue
              }
            });
          }
          else {
            setErrorMsg("Invalid OTP");
            setErrorSubmit(true);
          }
        }
        else {

          setErrorMsg(res.data.message ? res.data.message : "Error in OTP validation");
          setErrorSubmit(true);

        }

      })
      .catch(err => {
        setErrorMsg("Error in OTP validation");
        setErrorSubmit(true);

      })


  }




  return (<>
    <div className="wrapper-content">
      <div className="forgot-password-page">

        <form className="login-form forgot-password-form" onSubmit={handleValidateOTP}>

          <h4 className="form-heading title-of-tasks"> Enter OTP </h4>
          <h6 style={{ color: "grey", textAlign: 'center', marginTop: 20 }}> {msg} </h6><br />
          <input type="text" maxLength='30' className="form-control" placeholder="Enter OTP here" value={otpValue.toString()} onChange={(e) => {
            e.preventDefault();
            setOtpValue(e.target.value);
          }} required />



          <br />
          <div style={{ textAlign: 'center' }}>


            <button type="submit" className="btn btn-primary btn-col"  >Validate OTP</button>
          </div>
        </form>











        {errorMsg !== null ?
          <ErrorPopup
            show={errorSubmit}
            onHide={() => setErrorSubmit(false)}

          /> : null}


      </div>
    </div>

  </>


  )


}
export default InputOtp;