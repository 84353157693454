import React, { useState, createContext } from "react";

export const HospLoginContext = createContext();

export const HospLoginProvider = (props) => {
  const [hospData,setHospData] = useState();
   
  return (
    <HospLoginContext.Provider value={[hospData,setHospData]}>
      {props.children}
    </HospLoginContext.Provider>
  );
};
