import React, { useEffect, useState,useContext } from "react";
import axios from "axios";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { APIURL } from "../../Global";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Modal, Button } from "react-bootstrap";
import { Carousel } from 'react-bootstrap';
import AddToCart from './AddToCart';
import { CartContext } from "./AddToCartContext";
import { Badge } from 'react-bootstrap';
import AddToCartBestSell from './AddtoCartBestSell';
import { useAlert } from "react-alert";
import QuickView from "./QuickView";
function NewArrivals({ bannerUrl, title, description, price, data, key, discount, updateCartData }) {
  const [quickViewDetails, setQuickViewDetails] = useState({})
  const [selectedItem, setSelectedItem] = useState(null);
  const [cartdata, setCartdata] = useState("");
  const { handleAddToCart } = useContext(CartContext);
  const alert = useAlert();
  const [distance, setDistance] = useState(0.0);
  const [cartShow, setCartShow] = useState(false)
  const [selecteddata, setSelecteddata] = useState([]);
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );

  const [amountdata, setAmountData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [promotions, setPromotions] = React.useState([]);

  useEffect(() => {

    // const tokenString = sessionStorage.getItem("usertoken");

    // let v2 = tokenString.replace(
    //   /["]+/g,
    //   ""
    // ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/patient/list-promotions/?promotion=${data.promotion_id}`, 
      // {
      //   headers: { Authorization: "Token " + v2 },
      // }
      )
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {

          const datas = res.data.items;
          console.log(datas);
          setPromotions(datas);
          //console.log(featureList)
        } else {
          //setLoading(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  }, []);

  const handleQuickView = (item) => {
    // const tokenString = sessionStorage.getItem("usertoken");
    let image = ""
    let category=""
    // let v2 = tokenString.replace(
    //   /["]+/g,
    //   ""
    // ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/patient/list-promotions/?promotion=${item}`, 
      // {
      //   headers: { Authorization: "Token " + v2 },
      // }
      )
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {

          const datas = res.data.items;
          console.log("datas", datas)
          image = datas[0].image
          category= datas[0].service_category
          //setSelecteddata1(datas)
          // setAmountData(datas[0].amount)
          console.log("selecteddata", selecteddata)
          axios
            .get(`${APIURL}/api/v1/patient/hospital-services-detail/${datas[0].item_service}`,
            //  {
            //   headers: { Authorization: "Token " + v2 },
            // }
            )

            .then((res) => {
              //console.log(res);
              if (res.status == 200) {

                const datas = res.data.data;
                console.log(datas);
                setSelectedItem(datas);
                setShowDetails(true);
                //setCartShow(true);
                setQuickViewDetails({
                  amount: datas.amount,
                  discount: datas.discount,
                  hospital: datas.hospital,
                  main_service: datas.main_service_name,
                  name: datas.hospital_name,
                  service: datas.service_name,
                  service_category: category,
                  image: image,
                  id: datas.id
                })
                //console.log(featureList)
              } else {
                //setLoading(false);
              }
            })
            .catch((err) => {
              // setLoading(false);
            });

        }

        else {
          //setLoading(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });

  }

  //setSelectedItem(item);





  const addToCart = (item) => {

debugger;


    // const tokenString = sessionStorage.getItem("usertoken");

    // let v2 = tokenString.replace(
    //   /["]+/g,
    //   ""
    // ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/patient/list-promotions/?promotion=${item}`,
      
      // {
      //   headers: { Authorization: "Token " + v2 },
      // }
      
      )
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {

          const datas = res.data.items;
          console.log("datas", datas)
          setSelecteddata(datas)
          // setAmountData(datas[0].amount)
          console.log("selecteddata", selecteddata)
          axios
            .get(`${APIURL}/api/v1/patient/hospital-services-detail/${datas[0].item_service}`, 
            // {
            //   headers: { Authorization: "Token " + v2 },
            // }
            )

            .then((res) => {
              //console.log(res);
              if (res.status == 200) {

                const image = datas[0].image;

                handleAddToCart({ ...res.data.data, image }); 
                // console.log(datas);
                // setCartdata(datas);
                // setShowDetails(false);
                // setCartShow(true);

                //console.log(featureList)
              } else {
                //setLoading(false);
              }
            })
            .catch((err) => {
              // setLoading(false);
            });

        }

        else {
          //setLoading(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });

  }



  const calculateDistance = (latt1, latt2, long1, long2) => {

    var R = 6371; // Radius of the earth in km
    const lat1 = parseFloat(latt1)
    const lat2 = parseFloat(latt2)
    const lon1 = parseFloat(long1)
    const lon2 = parseFloat(long2)
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    // return d;
    setDistance(parseFloat(d).toFixed(4));
    console.log('THE DISTANCE', d)

  };
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
  }
  const HandleClose = () => {
    setCartShow(false)
  }
  const DetailPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ width: "96%" }}>
          <QuickView item={quickViewDetails} handleRefreshCart={HandleClose} from="wo" />
        </Modal.Body>
      </Modal>

    )
  }
  const AddtoCartPopUp = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddToCartBestSell

            datas={props.data}
            handle={HandleClose}


          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };





  return (
    <>


      <div className="single-banner-item" >
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-12">
                  <div className="banner-content">
                    {/* <span className="sub-title">{title}</span> */}
                    <h5 style={{ color: "#F0A639" }}><b>New Arrivals!!</b></h5>
                    <h1>{description}</h1>
                    <div className="btn-box">
                      <div className="d-flex align-items-center">
                        <a href="#" className="default-btn" style={{ backgroundColor: "#F0A639", color: "white" }} onClick={() => addToCart(data.promotion_id)}><i className="flaticon-trolley"></i>Add to Cart</a>
                        {price !== (price * (100 - discount) / 100) ?
                          <span className="price">
                            <span className="old-price" style={{ textDecoration: "line-through", color: "grey", fontWeight: "normal" }}>
                              <i class="fa fa-usd" aria-hidden="true"></i>
                              {price}
                            </span>
                            <span>&nbsp;</span>
                            <span className="discounted-price">
                              {(price * (100 - discount) / 100).toFixed(2)}
                            </span>
                          </span>
                          :
                          <>
                            <span className="price"> <i class="fa fa-usd" aria-hidden="true"></i> {price} </span>
                          </>
                        }


                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-12">
                  <div className="banner-image text-center">
                    <img src={bannerUrl} className="main-image" alt="image" onClick={() => handleQuickView(data.promotion_id)} style={{ cursor: "pointer" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        {cartShow ? (
          <AddtoCartPopUp
            show={cartShow}
            data={cartdata}
            onHide={() => {
              setCartShow(false);

            }}
          />
        ) : (
          ""
        )}

        {showDetails ? (
          <DetailPopup
            show={showDetails}
            data={selectedItem}
            onHide={() => {
              setShowDetails(false);

            }}
          />
        ) : (
          ""
        )}
      </div>


    </>






  )







}
export default NewArrivals