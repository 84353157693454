import { useState, useEffect } from 'react'
    
const Message = ({ variant, children }) => {
  const [show, setShow] = useState(true)

  //On componentDidMount set the timer
  // useEffect(() => {
  //   setShow(true)
  //   const timeId =() => {
      
  //     setShow(false)
  //   }

  //   return () => {
  //     if(timeId) {setShow(true); clearTimeout(timeId)}
  //   }
  // }, []);

  // If show is false the component will return null and stop here
  if (!show) {
    return null;
  }

  // If show is true this will be returned
  return (
    <div className={`alert alert-${variant}`}>
      {children}
    </div>
  )
}

Message.defaultPros = {
  variant: 'info',
}

export default Message;