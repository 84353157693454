import { useState, useEffect } from "react"
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

function AddService({ onSuccess }) {
    const [consent, setConsent] = useState(0)
    const [serviceName, setServiceName] = useState("")
    const [consentLevel, setConsentLevel] = useState(0)
    const[servicetype,setServiceType]= useState({ value:'', label:'' });
    const [error, setError] = useState("")
    const [consentUser,setConscentUser]=useState("")
    const [successShow, setSuccessShow] = useState(false);
    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );

        axios.get(`${APIURL}/api/v1/staff/consent-level/`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.status === 200) {
                    const datas = res.data
                    getConsentLevel(datas);
                   
                    //setConsent(datas.consent_level)
                }
                else {

                }
            })
            .catch((err) => {

            })

    }, [])

    const serviceoptions = [{ value:'general_services', label:'General Services' },
    { value:'consumables', label:'Consumable' },  
   
 ]
    const getConsentLevel = (datas) => {
        let consentarr = datas.map(item =>{
            return {label: item.consent_level, value: item.id, users :item.level_users}
        });
        setConsent(consentarr);
    }

    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        const user= e.target.user;
        const label= e.target.label;
        if (key === "Servicename") {
            setServiceName(val);
        }
        if (key === "Consentlevel") {
            setConsentLevel(val);
            setConscentUser(user)
           
        }
        if (key === "servicetype") {
            setServiceType(val);
           
        }
       
    };
    const addService = (e) => {
        
        e.preventDefault();


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */
       
        const dataToSend = {
            service_name: serviceName.slice(0,1).toUpperCase() +serviceName.slice(1,serviceName.length),
            consent_level: consentLevel,
            service_type:servicetype,
           
        }

        axios
            .post(`${APIURL}/api/v1/staff/service-list/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    setSuccessShow(true);
                   // onSuccess();
                    //setRefresh(true)
                    //setCompleteShow(true)
                    //setSuccessShow(false)
                } else {
                    setError(res.data.message)
                }
            })
            .catch((err) => {
                setError("Please fill all mandatory fields!")
            });
        // setSuccessShow(false)
  }

  const SubmitPopUp1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="" >Successfully Added to Master Service List</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };



    return (<>
        <div className="appForm" >
            <div className="">

                <div className="title-of-tasks" style={{}}><h5>Add Service</h5></div><br/>
                <form className="formFields" onSubmit={addService}>

                    <div className="formField">

                        <input
                            type="text"
                            className="formFieldInput"
                            name="Servicename"
                            placeholder={"*Service Name"}
                            value={serviceName}
                            onChange={handleChange}
                         
                        />
                    </div>
                    <div className="formField">
                     
                            
                            {consent && <select  className="formFieldInput"
                                                name="Consentlevel" 
                                                value={consentLevel} 
                                                defaultValue="null"
                                                onChange={handleChange}>
                                 <option selected hidden style={{color:"#495057cf"}} >*Consent Level</option>
                                {consent.map(item => (
                                    <><option value={item.value} user={item.users}>
                                        {item.label}
                                    </option><p>{item.users}</p></>
                                ))}
                               
                            </select>
                           
                           
                           
                            }
                           
                            
                      

                    </div>
                    <div className="formField">
                    <select  className="formFieldInput"
                                                name="servicetype" 
                                                value={servicetype} 
                                               onChange={handleChange}
                                              
                                                >
                                <option  value="" selected  hidden style={{color:"#495057cf"}} >*Service Type</option>
                                <option value="general_services">General Services</option>
                                 <option value="consumables">Consumable</option>
                               
                            </select>
                        </div>
                      

                    <div className="form-heading title-of-tasks"style={{}}>
                        <button  className="btn formFieldButton">Save</button>
                        {error ? (
                            <div className="error-validation-msg">
                                {error}
                            </div>
                        ) : null}




                    </div>


                </form>

            </div>


        </div>
         {successShow ? (
            <SubmitPopUp1
              show={successShow}
              onHide={() => {
                setSuccessShow(false);
                onSuccess();
              }}
            />
          ) : null}</>
    )
}
export default AddService