import React,{useState, useEffect, useRef, useContext } from 'react'
// import HospBdoNavbar from "../HospBdoNavbar/HospBdoNavbar";
import axios from "axios";
import { APIURL } from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import './servicecenter.css'
import './payments.css'
import { Link, Navigate,useNavigate } from "react-router-dom";
// import PaymentConfig from './PaymentConfig';
// import PaymentInvoice from './PaymentInvoice';
// import PastPayments from './PastPayments';
import moment from "moment";
import AppointmentService from './AppointmentService';
import ViewBooking from './ViewBooking';
import ServiceBooking from './ServiceBooking';
import CartViewPage from '../CartViewPage';
import MasterServiceShow from '../MasterServiceShow';
import EquipmentBooking from './EquipmentBooking';
import HospitalRegisterPatient from '../../BDO/HospRegisterPatient';
import HospPatienUpdate from '../../BDO/HospPatienUpdate';
//import { BookingContext } from '../../contexts/BookingContext';
// import BackofficeNavBar from "../BackoffcieNav/BackofficeNavBar";
import { useHistory } from 'react-router';
//import PastPayments from '../Payments/PastPayments';

function ServiceCenter() {
  
     let navigate = useNavigate();
    const [searchTerm, setSearchTerm]= useState('')
    const [errorMsg,setErrorMsg]=useState("");
    const [patient,setPatient]= useState([])
    const [errorShow, setErrorShow]= useState(false)
    const [totalShipCharge,setTotalShipCharge]=useState(0)
    const [isChanged, setIsChanged] = useState(false);
    const [login_datas, setLogin_datas] = useState(
      JSON.parse(sessionStorage.getItem("logindatas"))
        ? JSON.parse(sessionStorage.getItem("logindatas"))
        : null
    );
    console.log("logindatas",login_datas)
    // const [totalPages, setTotalPages]= useState(0)
    // const [perPage, setPerPage]= useState(2)
    // const [currentPage, setCurrentPage]= useState(1)
    // const [totalRecords, setTotalRecords]= useState(0)
    const [pastShow, setPastShow]= useState(false)
    const [editShow, setEditShow]= useState(false)
    const [validateErrorShow, setValidateErrorShow]= useState(false)
    const [display, setDisplay]= useState(false)
    const [allData, setAllData]= useState([])
    const [patientDetails, setPatientDetails]= useState({
      id:"",
      name:"",
      mobile_number:"",
      dob:"",
      address:"",
      next_visit:"",
      cpr:"",
      file_number:""
    })
    const [registerShow, setRegisterShow]= useState(false)
    const [calShow, setCalShow]= useState(true)
    const [detailShow, setDetailShow]= useState(false)
    const [serviceShow, setServiceShow]= useState(false)
    const [rentalShow, setRentalShow]= useState(false)
    const [refresh,setRefresh]= useState()

  
   // let navigate = useNavigate();

    const handleDisplay = () => {
      setDisplay(false)
    }

    useEffect(()=> {
        window.scrollTo(0,0)

        document.addEventListener('click', handleDisplay)

        return () => {
          document.removeEventListener('click',handleDisplay)
        }
    },[])

    // const handleBack=(e)=>{
    //     history.goBack();
    //   }

  const initialRender = useRef(true)

    useEffect(() =>{

      if( initialRender.current ){
        initialRender.current = false
      }
      else if(searchTerm === ""){
        setPatient([])
      }
      else {

          const tokenString = sessionStorage.getItem("usertoken");
  
          let v2 = tokenString.replace(
            /["]+/g,
            ""
          ); /* REGEX To remove double quotes from tokenstring */
    
    
            axios
            .get(`${APIURL}/api/v1/staff/patient-list/?per_page=1000&page=10&q=${searchTerm}`, {
              headers: { Authorization: "Token "+ v2 },
            })
            .then((res) => {
              if (res.data.status === "success") {  
                setPatient(res.data.patient)
                setCalShow(true)
                setServiceShow(false)
                setDisplay(true)
                // setTotalPages(res.data.total_pages)
                // setCurrentPage(res.data.page)
                // setTotalRecords(res.data.total_records)
            }
            else{
    
            }
        }).catch((error)=>{
    
        })

    }

    },[searchTerm,])

    
  const refreshCart = (data) => {
    setTotalShipCharge(data.sCharge)
    setIsChanged(data);
    
    console.log('THE DATA in ser', data);
  }


      const handleSearch =() => {

        const tokenString = sessionStorage.getItem("usertoken");
  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */


        axios
        .get(`${APIURL}/api/v1/hospital/hospital-all-payment/search/?q=${searchTerm}&per_page=10`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {  
            setPatient(res.data.results.data)
            setDisplay(true)
        }
        else{

        }
    }).catch((error)=>{

    })

      }

    // const PastPaymentPopup=(props)=>{
    

    //   return (
    //     <Modal
    //       {...props}
    //       aria-labelledby="contained-modal-title-vcenter"
    //       centered
    //        backdrop="static"
    //         keyboard={false}
    //         dialogClassName="modal-80w"
    //     >
    //       <Modal.Header closeButton>
    //         <Modal.Title id="contained-modal-title-vcenter">
             
    //         </Modal.Title>
    //       </Modal.Header>
    //       <Modal.Body className="text-center">
    //          {/* <PastPayments id={patientDetails.id} name={patientDetails.name}/>  */}
    
    //       </Modal.Body>
    //       <Modal.Footer>
             
    //         <Button variant="secondary" onClick={props.onHide}>Close</Button>
           
    
    //       </Modal.Footer>
    //     </Modal>
    //   );
    // }

    // const ErrorPopup =(props)=>{
    
    //   return (
    //     <Modal
    //       {...props}
    //       size="lg"
    //       aria-labelledby="contained-modal-title-vcenter"
    //       centered
    //        backdrop="static"
    //         keyboard={false}
    //     >
    //       <Modal.Header closeButton>
    //         <Modal.Title id="contained-modal-title-vcenter">
             
    //         </Modal.Title>
    //       </Modal.Header>
    //       <Modal.Body>
    //         <h5 style={{color: "red"}}>Error in Data Submission. Please try again!</h5>
           
    //       </Modal.Body>
    //       <Modal.Footer>
           
    //          <Button variant="secondary" onClick={props.onHide}>Ok</Button>
    
           
           
    
    //       </Modal.Footer>
    //     </Modal>
    //   );
    // }
 const ErrorPopup =(props)=> {
      return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
             <h5 className="text-danger">{errorMsg!==''?errorMsg : <h5 className="text-danger">Error in Data Submission. Please try again!</h5>}</h5>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
    }
    const ValidateErrorPopup =(props)=>{
          
      
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 style={{color: "red"}}>Please Select a Patient !!</h5>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>
    
           
           
    
          </Modal.Footer>
        </Modal>
      );
    }

    const handleSearchChange =(e)=> {
      setSearchTerm(e.target.value)
    }

        const handleClick = (item) => {
          initialRender.current = true
          setSearchTerm(item.full_name)         
          setPatientDetails(item)
          setDisplay(false)
          setAllData([])
          setRefresh(!refresh)


        }

        const handleService3 =(e)=>
{
 
    
   
    setEditShow(true)
    setCalShow(false)
    setRegisterShow(false)
    setDetailShow(false)
    setServiceShow(false)
    setRentalShow(false)
    setPastShow(false)

}
const handleService2 =(e)=>
{
  const name = e.target.name
  if(patientDetails.id ===""){      
  if(name === 'register'){
    setCalShow(false)
    setRegisterShow(true)
    setDetailShow(false)
    setServiceShow(false)
    setRentalShow(false)
    setPastShow(false)
  }
}
else{
  setErrorMsg(" This Patient is Already Registered");
  setErrorShow(true);
}
}
        const handleService = (e) => {
            const name = e.target.name
           
              // if(name === 'register'){
              //   setCalShow(false)
              //   setRegisterShow(true)
              //   setDetailShow(false)
              //   setServiceShow(false)
              //   setRentalShow(false)
              //   setPastShow(false)
              // }
 
              if(patientDetails.id !==""){
              if(name === 'appointment'){
                setCalShow(true)
                setRegisterShow(false)
                setDetailShow(false)
                setServiceShow(false)
                setRentalShow(false)
                setPastShow(false)
              }

              if(name === 'booking'){
                setCalShow(false)
                setRegisterShow(false)
                setDetailShow(true)
                setServiceShow(false)
                setRentalShow(false)
                setPastShow(false)
              }

              if(name === 'cart'){
                setCalShow(false)
                setRegisterShow(false)
                setDetailShow(false)
                setServiceShow(true)
                setRentalShow(false)
                setPastShow(false)
              }

              if(name === 'rental'){
                setCalShow(false)
                setRegisterShow(false)
                setDetailShow(false)
                setServiceShow(false)
                setRentalShow(true)
                setPastShow(false)
              }

              if(name === 'past'){
                setCalShow(false)
                setRegisterShow(false)
                setDetailShow(false)
                setServiceShow(false)
                setRentalShow(false)
                setPastShow(true)
              }
            }
       

            else{
              setIsChanged(!isChanged);
              setValidateErrorShow(true)
            }
        }

    return (<>
        {/* <HospBdoNavbar /> */}
          {/* <div className="flex-head">       
            <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
            <h2 className=" title-of-tasks title-mg"> Payments </h2><br/>
        </div> 

        <br/> */}
<div className='pad-payment'>
<div className='payment-page'>

      <div className='payment-display'>
      {/* {
  patientName ?
  <h5 className='selected-display-payment'>Selected Patient : {patientName}</h5> : null
} */}
        <div className='payment-search-grid'>
            <div className='flex-col justify-content-start'>
                <h5 className='title-of-tasks'>Search Patient</h5>
                <div style={{width:"100%"}} className='flex-row'>

                <input
                style={{width:'70%'}}
                className="form-control"
                type="text"
                maxLength="100"
                placeholder="Enter Name"
                value={searchTerm}
                onChange={handleSearchChange}
              />

              <button disabled={searchTerm===""} onClick={handleSearch}  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>

                </div>
                {
                        display ?
                        <div className='suggest-display adjust-dimensions' style={{width:"87%"}}>
                            {
                              patient.length > 0 ?  patient.map((item)=> {
                                    return (
                                        <p onClick={() => handleClick(item)} className='suggest-item text-secondary'>{item.full_name}</p>
                                    )
                                }) : <h6 className='text-muted'>No Patients found !!</h6>
                            }
                        </div>

                        : null
                    }

            </div>

            <div className="detail-input-grid">

              <input placeholder="Name" type='text' value={patientDetails.full_name} className="form-control" />
              <input placeholder="Mobile Number" type='text' value={patientDetails.mobile_number} className="form-control" />
              {/* <input placeholder="State" type='text' value={patientDetails.state} className="form-control" /> */}
              <input placeholder="Gender" type='text' value={patientDetails.gender} className="form-control" />
              <input placeholder="Email" type='text' value={patientDetails.email} className="form-control" style={{width:"101%"}} />
              {login_datas.role==="bdo" && patientDetails.id !==""?<i className="fa fa-pencil fa-lg"  onClick={handleService3}
              
                        style={{ height: "30px", width: "60px", color: "#5a9569", cursor: "pointer", paddingTop:"6%", marginLeft:"3%" }}
                       
                    >Edit</i>:null}
              {/* <input placeholder="Next Visit" type='text' value={patientDetails.next_visit ? moment(patientDetails.next_visit).format("DD-MM-YYYY") : ""} className="form-control" /> */}
            </div>
      
            {/* <div>
                {patient.length > 0 ?
                    patientDisplay 
                    : <h6 style={{color:"red", alignSelf:"end"}}>No Patients Found</h6> }
<br/>
<br/>
   {totalPages > 0 && totalRecords > 0 && searchTerm !== "" ?
    <div className="pagn">

            <Pagination
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={totalRecords}
          pageRangeDisplayed={totalPages}
          onChange={handlePageChange}
          disabledClass = "disabled-class" handleService
          />
    </div>
    : null} 
            </div> */}

            <div className="services-btn-grid" style={{marginTop:"-7%"}}>
               {login_datas.role==="bdo"? <button  name="register" onClick={handleService2} className='btn btn-primary btn-col btn-small'>Registration</button>: <button onClick={() => navigate('/register-patient')} name="register" disabled className='btn btn-primary btn-col btn-small'>Registration</button>}
                <button onClick={handleService} name="appointment" className='btn btn-primary btn-col btn-small'> + Appointment</button>
                <button onClick={handleService} name="booking" className='btn btn-primary btn-col btn-small'>View Bookings</button>
                {login_datas.user_type==="staff"?<button onClick={handleService} name="cart"  className='btn btn-primary btn-col btn-small'>+ Cart</button>:<button onClick={handleService} name="cart" disabled className='btn btn-primary btn-col btn-small'>+ Cart</button>}
                {/* <button onClick={handleService} name="rental" disabled={true} className='btn btn-primary btn-col btn-small'>+ Equipment</button> */}
                <button onClick={handleService} name="past"  disabled={true} className='btn btn-primary btn-col btn-small'>Past Payments</button> 
                {/* <button onClick={() => navigate('/careplans-db')} name="past" className='btn btn-primary btn-col btn-small'>Care Packages</button> */}
            </div>
           

        </div>
      </div>

      {
        calShow ?
        <AppointmentService id={patientDetails.id} /> : 
        detailShow ?
        <ViewBooking id={patientDetails.id} name={patientDetails.name}/> :
        serviceShow ?
        <>
       <MasterServiceShow patient_id={patientDetails.id} name1={patientDetails.first_name} details={patientDetails} setshow={handleService} refreshCart={refreshCart} /> 
       <CartViewPage patient_id={patientDetails.id} name={patientDetails.full_name} sCharge={totalShipCharge} reRender={isChanged} /> </> :
        rentalShow ?
        <EquipmentBooking /> :
        registerShow ?
        <HospitalRegisterPatient id={patientDetails.id}/> :
        editShow ?
        <HospPatienUpdate data={patientDetails}/>:
        null}
        {/* //pastShow ?
        //<PastPayments id={patientDetails.id} name={patientDetails.name}/>  */}
        
  
      

                    {/* <div className='flex-row'>

            <ReactTable 
         columns={columnsPayment}
         data={config}
         defaultPageSize = {5}
         
         resizable
         className="scan-react-table"
                           
                 
         >

         </ReactTable> 

            </div> */}

<br/>
{/* <button disabled={config.length < 1} onClick={handleGenerate} className='btn btn-primary btn-col'>Generate Bill</button> */}
<br/>
<br/>
<br/>
</div>
</div>

{/* {
      pastShow ? 
      <PastPaymentPopup
        show={pastShow}
        onHide= {() => { setPastShow(false);
                         //setRerender(!reRender)     
        }}

        
      /> : null
    } */}


{errorShow ?
    <ErrorPopup
        show={errorShow}
        onHide={() =>  {setErrorShow(false);
                        //setTableData([]);
                       //setRerender(!reRender)
                      }}
       
      />: ''
    }

{validateErrorShow ?
    <ValidateErrorPopup
        show={validateErrorShow}
        onHide={() =>  setValidateErrorShow(false)}
       
      />: ''
    }

        </>
    )
}

export default ServiceCenter
