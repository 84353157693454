import { APIURL } from "./Global";
import axios from "axios";
import img from "./assets/images/felixa_logo.png";
import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./modules/login/LoginPage";
import ForgotPassword from "./modules/login/ForgotPassword";
import InputOtp from "./modules/login/InputOtp";
import LogoutPage from "./modules/login/LogoutPage";
import HomePage from "./modules/home/HomePage";
import StaffDashboard from "./modules/staff/StaffDashboard";
import Navbar from "./modules/common/Navbar"
import Footer from "./modules/common/Footer";
import RequireAuth from "./modules/registration/RequireAuth";
import ServiceProviderRegister from "./modules/registration/ServiceProviderRegister";
import { LoginProvider } from "./modules/contexts/LoginContext";
import { LoginStatusProvider } from "./modules/contexts/LoginStatusContext";
import { HospLoginProvider } from "./modules/contexts/HospitalLoginContext";
import { RegProvider } from "./modules/contexts/RegistrationContext";
import HospitalPending from "./modules/Hospital/HospitalPending";
import HospitalDashboard from "./modules/Hospital/HospitalDashboard";
import PageNotFound from "./modules/errorPage/PageNotFound";
import AddBdo from "./modules/BDO/addbdo";
import BdoList from "./modules/BDO/BdoList";
import AddSda from "./modules/Sda/AddSda";
import SdaList from "./modules/Sda/SdaList";
import PatientDashboard from "./modules/patient/PatientDashboard";
import RegisteredHospitals from "./modules/staff/RegisteredHospitals";
import DeregisteredHospitals from "./modules/staff/DeregisteredHospitals";
import HospitalDetails from "./modules/staff/HospitalDetails";
import ServiceDetails from "./modules/staff/service/ServiceDetails"
import CreateAppUserDoctor from "./modules/doctor/CreateAppUserDoctor";
import CreateAppUserBdo from "./modules/BDO/CreateAppUserBdo/CreateAppUserBdo";
import CreateAppUserSda from "./modules/Sda/CreateAppUserSda/CreateAppUserSda";
import "react-datepicker/dist/react-datepicker.css";
import { ProfileProvider } from "./modules/contexts/ProfileContext";
import HospProfile from "./modules/HospProfile/HospProfile";
import AddDoctor from "./modules/doctor/AddDoctor";
import DoctorsList from "./modules/doctorsList/DoctorsList"
import EditDoctor from "./modules/doctor/EditDoctor";
import DeleteDoctor from "./modules/doctor/DeleteDoctor";
import SuspendDoctor from "./modules/doctor/SuspendDoctor";
import ChangePasswordDoctor from "./modules/doctor/ChangePasswordDoctor";
import DoctorDashboard from "./modules/doctor/DoctorDashboard";
import BDODashboard from "./modules/BDO/BDODashboard";
import BdoFeedback from "./modules/BDO/BdoFeedback";
import AddLabUser from "./modules/LaboratoryUser/AddLabUser";
import LabUserList from "./modules/LaboratoryUser/LabUserList";
import UpdateLabUser from "./modules/LaboratoryUser/UpdateLabUser";
import CreateLabUser from "./modules/LaboratoryUser/CreateLabUser"
import AddPharmacyUser from "./modules/PharmacyUser/AddPharmacyUser";
import PharmacyUserList from "./modules/PharmacyUser/PharmacyUserList";
import CreatePharmacyUser from "./modules/PharmacyUser/CreatePharmacyUser"
import UpdatePharmacyUser from "./modules/PharmacyUser/UpdatePharmacyUser"
import HospitalUpdateBdo from "./modules/BDO/HospitalUpdateBdo";
import HospitalUpdateSda from "./modules/Sda/HospitalUpdateSda";
import AddProf from "./modules/Professionals/addprof";
import ProfList from "./modules/Professionals/ProfList";
import HospitalUpdateProf from "./modules/Professionals/HospitalUpdateProf";
import CreateAppUserProf from "./modules/Professionals/CreateAppUserProf/CreateAppUserProf";
import AddNurse from "./modules/Nurses/addnurse";
import NurseList from "./modules/Nurses/NurseList";
import HospitalUpdateNurse from "./modules/Nurses/HospitalUpdateNurse";
import CreateAppUserNurse from "./modules/Nurses/CreateAppUserNurse/CreateAppUserNurse";
import MainServices from "./modules/staff/service/MainServices";
import Approvalpage from "./modules/staff/ApprovalImage/Approvalpage";
import MasterServices from "./modules/BDO/SPServices/MasterServices";
import SelectedServices from "./modules/BDO/SPServices/SelectedServices";
import Scan from "./modules/staff/AddScanTest/Scan";
import Test from "./modules/staff/AddLabTest/Test";
import ServiceOrderList from "./modules/staff/service/ServiceOrdersList";
import ServiceOrderReqDetails from "./modules/staff/service/ServiceOrderReqDetails";
import Configuration from "./modules/staff/Configuration/Configuration";
import ConfigDash from "./modules/ConfigDashboard/Config";
import AdminResetPwd from "./modules/ConfigDashboard/AdminResetPwd";
// import ForgotPassword from "./modules/Forgot_password/ForgotPassword";
import UserListDr from "./modules/HospSearchPatient/UserListingDr";
import UserListAllied from "./modules/AlliedProfessionals/UserListAllied";
import PatientRecord from "./modules/HospSearchPatient/PatientRecord";
import ResetPassword from "./modules/login/ResetPassword";
import EmailConfirmation from "./modules/registration/EmailConfirmation";
import ChangePasswordHospitalBdo from "./modules/BDO/ChangePasswordHospitalBdo";
import ChangePasswordHospitalSda from "./modules/Sda/ChangePasswordHospitalSda";
import ChangePasswordHospitalNurse from "./modules/Nurses/ChangePasswordHospitalNurse";
import ChangePasswordHospitalProf from "./modules/Professionals/ChangePasswordHospitalProf";
import ChangePasswordHospitalPharm from "./modules/PharmacyUser/ChangePasswordHospitalPharm";
import ChangePasswordHospitalLab from "./modules/LaboratoryUser/ChangePasswordHospitalLab";
import ObservationSymptomsBO from "./modules/staff/ObservationAndSymptoms/ObservationSympotmsBO";
import Allergy from "./modules/staff/AddAllergy/Allergy";
import Misc from "./modules/staff/AddMisc/Misc";
import Medicine from "./modules/staff/AddMedicines/Medicine";
import PrimaryDiscease from "./modules/staff/AddPrimaryDiscease/PrimaryDiscease";
import MedicineApprove from "./modules/staff/MedicineApproval/MedicineApprove";
import Comorbidites from "./modules/staff/AddComobridities/Comorbidites"
import ProcedureList from "./modules/staff/ProcedureList/ProcedureList";
import ProcedureRequests from "./modules/ProcedureFulfillment/ProcedureRequests";
import ProcedureNamesBdo from "./modules/ProcedureFulfillment/ProcedureNamesBdo";
import ProcedureRequestsBdo from "./modules/ProcedureFulfillment/ProcedureRequestsBdo";
import LabDashboard from "./modules/LabSP/LabDashboard";
import PharmDashboard from "./modules/PharmacySP/PharmDashboard";
import PharmacyDashboard from "./modules/Pharmacy/PharmacyDashboard";
import LabUserDashboard from "./modules/Laboratory/LabUserDashboard";
import ErrorNotification from "./modules/registration/ErrorNotification";
import ServiceRequests_doc from "./modules/ServiceRequests/ServiceRequests_doc";
import ServiceHistoryLab from "./modules/Laboratory/ServiceHistoryLab";
import ServiceHistoryAllied from "./modules/AlliedProfessionals/ServiceHistoryAllied";
import ServiceHistoryNurse from "./modules/Nurse/ServiceHistoryNurse";
import ServiceHistoryPharm from "./modules/Pharmacy/ServiceHistoryPharm";
import ServiceRequests from "./modules/BDO/ServiceFulfillment/ServiceRequests";
import ServiceHistory from "./modules/BDO/ServiceFulfillment/ServiceHistory";
import WebNotifications from "./modules/WebNotifications/WebNotifications";
import NotificationAll from "./modules/WebNotifications/NotificationAll";
// import NotificationsGeneral from "./modules/WebNotifications/NotificationsGeneral";
import WebNotificationsBdo from "./modules/WebNotifications/WebNotifications_Bdo";
import WebNotificationsDr from "./modules/WebNotifications/WebNotifications_Dr";
import Profile from "./modules/common/Profile/Profile"
import HospRegisterPatient from "./modules/BDO/HospRegisterPatient";
import PaymentRegistration from "./modules/BDO/PaymentRegistration";
import Payment from "./modules/BDO/Payments/Payments";
import Reports from "./modules/BDO/Reports/Reports";
// import InputOtp from "./modules/login/InputOtp";
import PatientsMessage from "./modules/staff/PatientsMessage";
import ServiceCenter from "./modules/staff/Service Center/ServiceCenter";
//import ServiceCenter from "./modules/staff/ServiceCenter";
import CartViewPage from "./modules/staff/CartViewPage";
import ConsumableList from "./modules/staff/Consumable/ConsumableList";
import PharmacyServiceFulfillment from "./modules/Pharmacy/PharmacyServiceFulfillment";
import AlliedServiceFulfillment from "./modules/AlliedProfessionals/AlliedServiceFulfillment";
import NurseServiceFulfillment from "./modules/Nurse/NurseServiceFulfillment";
import ScheduleList from "./modules/doctor/Schedule/ScheduleList"
// import Patientordering from "./modules/patient/Patientordering";
import WebOrdering from "./modules/patient/WebOrdeing";
import RazorpayPage from "./modules/patient/RazorpayPage";
import ServicesNew from "./modules/patient/ServicesNew";
import Contact from "./modules/patient/Contact";
import Categories from "./modules/patient/Categories";
import RegisterPatient from "./modules/patient/RegisterPatient";
import Checklist from "./modules/patient/Checklist";
import LabServiceFulfillment from "./modules/Laboratory/LabServiceFulfillment";
import AlliedDashboard from "./modules/AlliedProfessionals/AlliedDashboard";
import NurseDashboard from "./modules/Nurse/NurseDashboard";
import ServiceHistoryDoc from "./modules/ServiceRequests/ServiceHistoryDoc";
import SdaDashboard from "./modules/Sda/SdaDashboard";
import VideoCall from "./modules/videocall/VideoCall";
import LoginPagePatient from "./modules/patient/LoginPagePatient";
import FollowUp from "./modules/patient/FollowUp";
import AppUserDash from "./modules/ElderCare/AppUserDash";
import CartPage from "./modules/patient/CartPage";
import DealsPage from "./modules/patient/DealsPage";
import OTPpage from "./modules/patient/OTPpage";
import OTPpageMobile from "./modules/patient/OTPpageMobile";
import OTPpageuser from "./modules/patient/OTPpageuser";
import NewArrivalDeals from "./modules/patient/NewArrivalDeals";
import Promotions from "./modules/BDO/SPServices/webordering/Promotions";
import SearchResult from "./modules/patient/SearchResult";
import CreateAcconuntPatient from "./modules/patient/CreateAcconuntPatient";
import CreateAccountMobile from "./modules/patient/CreateAccountMobile";
import CreateAccountUser from "./modules/patient/CreateAccountUser";
import EmailOTPLogin from "./modules/patient/EmailOTPLogin";
import MobileOTPLogin from "./modules/patient/MobileOTPLogin";
import SkipPage1 from "./modules/patient/SkipPage1";
import SkipPage2 from "./modules/patient/SkipPage2";
import SkipPage3 from "./modules/patient/SkipPage3";
function App() {
  const [showNavbarAndFooter, setShowNavbarAndFooter] = useState(true);
  const [cartData, setCartData] = useState([]);
  const updateCartData = () => {
    const tokenString = sessionStorage.getItem("usertoken");


    let v2 = tokenString.replace(
        /["]+/g,
        ""
    );
    // Make API call to retrieve updated cart data
    axios.get(`${APIURL}/api/v1/patient/cart/`, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        setCartData(res.data.cart_items);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {

    axios
      .get(`${APIURL}/api/v1/account/customer-info/`, {
        headers: {},
      })

      .then((res) => {

        if (res.data.status === "success") {
        
            document.getElementById("favicon").setAttribute("href", res.data.app_logo)
            document.getElementById('title').innerHTML = res.data.message.app_name ? res.data.message.app_name : "Felixa Care";
        }
        else {
          document.getElementById("favicon").setAttribute("href", img)
        }
      })
  }, []);

  return (
    <>

      <BrowserRouter>
        <RegProvider>
          <LoginStatusProvider>
            <HospLoginProvider>
              <ProfileProvider>
                <LoginProvider>
                 {showNavbarAndFooter &&  <Navbar />}
                  <div className="test">


                    <Routes >
                      
                      <Route exact path="/" element={<RequireAuth><HomePage /></RequireAuth>} />
                      <Route exact path="/Hospital" element={<RequireAuth type="hospital"><HospitalDashboard /></RequireAuth>} />
                      <Route exact path="/AppUser"  element={<RequireAuth type="appuser"><AppUserDash/></RequireAuth>} />
                      <Route exact path="/hospitallist" element={<RequireAuth type="staff"><RegisteredHospitals /></RequireAuth>} />
                      <Route exact path="/Deregistered_splist" element={<RequireAuth type="staff"><DeregisteredHospitals /></RequireAuth>} />
                      <Route exact path="/servicelist" element={<RequireAuth type="staff"><MainServices /></RequireAuth>} />
                      <Route exact path="/approvalpage" element={<RequireAuth type="staff"><Approvalpage /></RequireAuth>} />

                      <Route exact path="/hospital-details/:id" element={<RequireAuth type="staff"><HospitalDetails /></RequireAuth>} />
                      <Route exact path="/service-details/:id" element={<RequireAuth type="staff"><ServiceDetails /></RequireAuth>} />
                      <Route exact path="/login" element={<LoginPage />} />
                      <Route exact path="/forgot-password" element={<ForgotPassword />} />
                      <Route exact path="/input-otp" element={<InputOtp />} />
                      <Route exact path="/reset-password" element={<ResetPassword />} />
                      <Route exact path="/confirm-email" element={<EmailConfirmation />} />
                      <Route exact path="/signup" element={<ServiceProviderRegister />} />
                      <Route exact path="/skippage1" element={<SkipPage1 setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/skippage2" element={<SkipPage2 setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/skippage3" element={<SkipPage3 setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/loginpatient" element={<LoginPagePatient setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/registerpat" element={<RegisterPatient setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/createaccountpat" element={<CreateAcconuntPatient setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/createccountmobile" element={<CreateAccountMobile setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/createaccountuser" element={<CreateAccountUser setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/otppagepat" element={<OTPpage setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/otppagemob" element={<OTPpageMobile setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/otppageuser" element={<OTPpageuser setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/emailotplogin" element={<EmailOTPLogin setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/phoneotplogin" element={<MobileOTPLogin setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> } />
                      <Route exact path="/ErrorNotification" element={<ErrorNotification />} />
                      <Route exact path="/logout" element={<LogoutPage />} />
                      <Route exact path="/patient" element={<RequireAuth type="patient"><WebOrdering setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}   /> </RequireAuth>} />
                      <Route exact path="/config_admin" element={<RequireAuth type="config_admin"><ConfigDash /></RequireAuth>} />
                      <Route exact path="/admin_reset_pwd" element={<RequireAuth type="config_admin"><AdminResetPwd /></RequireAuth>} />
                      <Route exact path="/hospital_pending" element={<RequireAuth type="hospital_pending"><HospitalPending /></RequireAuth>} />
                      {/* <Route exact path="/LaboratoryPending" element={<RequireAuth type="LaboratoryPending"><LaboratoryPending /></RequireAuth>} /> */}
                      <Route exact path="/staff" element={<RequireAuth type="staff"><StaffDashboard /></RequireAuth>} />
                      <Route exact path="/404" element={<PageNotFound />} />
                      <Route exact path="/add-bdo" element={<RequireAuth type="hospital"><AddBdo /></RequireAuth>} />
                      <Route exact path="/bdo-list" element={<RequireAuth type="hospital"><BdoList /></RequireAuth>} />
                      <Route exact path="/add-sda" element={<RequireAuth type="hospital"><AddSda /></RequireAuth>} />
                      <Route exact path="/sda-list" element={<RequireAuth type="hospital"><SdaList /></RequireAuth>} />
                      <Route exact path="/create-doctor/:id" element={<RequireAuth type="hospital"><CreateAppUserDoctor /></RequireAuth>} />
                      <Route exact path="/create-bdo/:id" element={<RequireAuth type="hospital"><CreateAppUserBdo /></RequireAuth>} />
                      <Route exact path="/update-bdo/:id" element={<RequireAuth type="hospital"><HospitalUpdateBdo /></RequireAuth>} />
                      <Route exact path="/cartview" element={<CartViewPage />} />
                      <Route exact path="/changepassword-bdo/:id" element={<RequireAuth type="hospital"><ChangePasswordHospitalBdo /></RequireAuth>} />
                      <Route exact path="/changepassword-sda/:id" element={<RequireAuth type="hospital"><ChangePasswordHospitalSda /></RequireAuth>} />
                      <Route exact path="/changepassword-prof/:id" element={<RequireAuth type="hospital"><ChangePasswordHospitalProf /></RequireAuth>} />
                      <Route exact path="/changepassword-nurse/:id" element={<RequireAuth type="hospital"><ChangePasswordHospitalNurse /></RequireAuth>} />
                      <Route exact path="/changepassword-lab/:id" element={<RequireAuth type="hospital"><ChangePasswordHospitalLab /></RequireAuth>} />
                      <Route exact path="/changepassword-pharm/:id" element={<RequireAuth type="hospital"><ChangePasswordHospitalPharm /></RequireAuth>} />
                      <Route exact path="/create-prof/:id" element={<RequireAuth type="hospital"><CreateAppUserProf /></RequireAuth>} />
                      <Route exact path="/update-prof/:id" element={<RequireAuth type="hospital"><HospitalUpdateProf /></RequireAuth>} />
                      <Route exact path="/update-sda/:id" element={<RequireAuth type="hospital"><HospitalUpdateSda /></RequireAuth>} />
                      <Route exact path="/create-sda/:id" element={<RequireAuth type="hospital"><CreateAppUserSda /></RequireAuth>} />
                      <Route exact path="/profile" element={<HospProfile />} />
                      {/* <Route exact path="/patientordering" element={<Patientordering />} /> */}
                      <Route exact path="/webordering" element={<WebOrdering  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  />
                      <Route exact path="/contact" element={<Contact  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  />
                      <Route exact path="/categories" element={<Categories setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)} updateCartData={updateCartData} />}  />
                      <Route exact path="/servicespatient" element={<ServicesNew setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)} updateCartData={updateCartData} />} />
                      <Route exact path="/cartpage" element={<CartPage  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  />
                      <Route exact path="/dealspage" element={<DealsPage setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)} updateCartData={updateCartData} />} />
                      <Route exact path="/newarrivaldeals" element={<NewArrivalDeals  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  />
                      <Route exact path="/checklist" element={<Checklist  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  />
                      <Route exact path="/razor" element={<RazorpayPage  setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>}  />
                      <Route exact path="/followup" element={<FollowUp/>} />
                      <Route exact path="/adddoctor" element={<RequireAuth type="hospital"><AddDoctor /></RequireAuth>} />
                      <Route exact path="/feedback" element={<RequireAuth type="bdo"><BdoFeedback /></RequireAuth>} />
                      <Route exact path="/doctorslist" element={<RequireAuth type="hospital"><DoctorsList /></RequireAuth>} />
                      <Route exact path="/editdoctor/:id" element={<RequireAuth type="hospital"><EditDoctor /></RequireAuth>} />
                      <Route exact path="/deletedoctor/:id" element={<RequireAuth type="hospital"><DeleteDoctor /></RequireAuth>} />
                      <Route exact path="/suspenddoctor/:id" element={<RequireAuth type="hospital"><SuspendDoctor /></RequireAuth>} />
                      <Route exact path="/changepassword/:id" element={<RequireAuth type="hospital"><ChangePasswordDoctor /></RequireAuth>} />
                      <Route exact path="/doctor/profile" element={<Profile />} />
                      <Route exact path="/Laboratory" element={<RequireAuth type="lab"><LabDashboard /></RequireAuth>} />
                      <Route exact path="/Pharmacy" element={<RequireAuth type="pharmacy"><PharmDashboard /></RequireAuth>} />
                      <Route exact path="/hospital_doctor" element={<RequireAuth type="doctor"><DoctorDashboard /></RequireAuth>} />
                      <Route exact path="/doctor_schedule" element={<RequireAuth type="doctor"><ScheduleList /></RequireAuth>} />
                      <Route exact path="/hospital_pharmacist" element={<RequireAuth type="pharmacy"><PharmacyDashboard /></RequireAuth>} />
                      <Route exact path="/hospital_labuser" element={<RequireAuth type="lab"><LabUserDashboard /></RequireAuth>} />
                      <Route exact path="/hospital_allied_prof" element={<RequireAuth type="allied"><AlliedDashboard /></RequireAuth>} />
                      <Route exact path="/hospital_nurse" element={<RequireAuth type="nurse"><NurseDashboard /></RequireAuth>} />
                      <Route exact path="/hospital_sda" element={<RequireAuth type="sda"><SdaDashboard /></RequireAuth>} />
                      <Route exact path="/hospital_BDO" element={<RequireAuth type="bdo"><BDODashboard /></RequireAuth>} />
                      <Route exact path="/add-labuser" element={<RequireAuth type="hospital"><AddLabUser /></RequireAuth>} />
                      <Route exact path="/labusers-list" element={<RequireAuth type="hospital"><LabUserList /></RequireAuth>} />
                      <Route exact path="/updateLabUser/:id" element={<RequireAuth type="hospital"><UpdateLabUser /></RequireAuth>} />
                      <Route exact path="/create-lab-user/:id" element={<RequireAuth type="hospital"><CreateLabUser /></RequireAuth>} />
                      <Route exact path="/add-Pharm-user" element={<RequireAuth type="hospital"><AddPharmacyUser /></RequireAuth>} />
                      <Route exact path="/pharmacyusers-list" element={<RequireAuth type="hospital"><PharmacyUserList /></RequireAuth>} />
                      <Route exact path="/create-pharmacy-user/:id" element={<RequireAuth type="hospital"><CreatePharmacyUser /></RequireAuth>} />
                      <Route exact path="/updatePharmacyUser/:id" element={<RequireAuth type="hospital"><UpdatePharmacyUser /></RequireAuth>} />
                      <Route exact path="/configuration" element={<RequireAuth type="staff"><Configuration /></RequireAuth>} />
                      <Route exact path="/patient-record/:id" element={<PatientRecord />} />

                      <Route exact path="/updatePharmacyUser/:id" element={<RequireAuth type="hospital"><UpdateLabUser /></RequireAuth>} />
                      <Route exact path="/add-professional" element={<RequireAuth type="hospital"><AddProf /></RequireAuth>} />
                      <Route exact path="/allied-professional-list" element={<RequireAuth type="hospital"><ProfList /></RequireAuth>} />
                      <Route exact path="/add-nurse" element={<RequireAuth type="hospital"><AddNurse /></RequireAuth>} />
                      <Route exact path="/nurse-list" element={<RequireAuth type="hospital"><NurseList /></RequireAuth>} />
                      <Route exact path="/update-nurse/:id" element={<RequireAuth type="hospital"><HospitalUpdateNurse /></RequireAuth>} />
                      <Route exact path="/create-nurse/:id" element={<RequireAuth type="hospital"><CreateAppUserNurse /></RequireAuth>} />
                      <Route exact path="/forgot-password" element={<ForgotPassword />} />
                      <Route exact path="/patientlist" element={<UserListDr />} />
                      <Route exact path="/allied-patient-list" element={<UserListAllied />} />

                      <Route exact path="/master-services" element={<RequireAuth type="bdo"><MasterServices /></RequireAuth>} />
                      <Route exact path="/selected-services" element={<RequireAuth type="bdo"><SelectedServices /></RequireAuth>} />
                      <Route exact path="/Scantest" element={<RequireAuth type="staff"><Scan /></RequireAuth>} />
                      <Route exact path="/Labtest" element={<RequireAuth type="staff"><Test /></RequireAuth>} />
                      <Route exact path="/observation-symptoms" element={<RequireAuth type="staff"><ObservationSymptomsBO /></RequireAuth>} />
                      <Route exact path="/allergy" element={<RequireAuth type="staff"><Allergy /></RequireAuth>} />
                      <Route exact path="/misc" element={<RequireAuth type="staff"><Misc /></RequireAuth>} />
                      <Route exact path="/medicine" element={<RequireAuth type="staff"><Medicine /></RequireAuth>} />
                      <Route exact path="/primary-disease" element={<RequireAuth type="staff"><PrimaryDiscease /></RequireAuth>} />
                      <Route exact path="/comorbidities" element={<RequireAuth type="staff"><Comorbidites /></RequireAuth>} />
                      <Route exact path="/procedures" element={<RequireAuth type="staff"><ProcedureList /></RequireAuth>} />
                      <Route exact path="/consumablelist" element={<RequireAuth type="staff"><ConsumableList /></RequireAuth>} />
                      <Route exact path="/procedure-request" element={<ProcedureRequests />} />
                      <Route exact path="/procedure-names-bdo" element={<ProcedureNamesBdo />} />
                      <Route exact path="/procedure-requests-bdo" element={<ProcedureRequestsBdo />} />
                      <Route exact path="/service_request" element={<RequireAuth type="bdo"><ServiceRequests /></RequireAuth>} />
                      <Route exact path="/promotions" element={<RequireAuth type="bdo"><Promotions /></RequireAuth>} />
                      <Route exact path="/service_history" element={<RequireAuth type="bdo"><ServiceHistory /></RequireAuth>} />
                      <Route exact path="/register-patient" element={<RequireAuth type="bdo"><HospRegisterPatient/></RequireAuth>} />
                      <Route exact path="/paymentreg" element={<RequireAuth type="bdo"><PaymentRegistration/></RequireAuth>} />
                      <Route exact path="/service_history_doc" element={<ServiceHistoryDoc />} />
                      <Route exact path="/service_history_lab" element={<ServiceHistoryLab />} />
                      <Route exact path="/medlist" element={<MedicineApprove />} />
                      <Route exact path="/service_history_allied" element={<ServiceHistoryAllied />} />
                      <Route exact path="/service_history_pharm" element={<ServiceHistoryPharm />} />
                      <Route exact path="/service_history_nurse" element={<ServiceHistoryNurse />} />

                      <Route exact path="/serviceorderlist" element={<RequireAuth type="staff"><ServiceOrderList /></RequireAuth>} />
                      <Route exact path="/serviceorderdetails/:id" element={<RequireAuth type="staff"><ServiceOrderReqDetails /></RequireAuth>} />
                      <Route exact path="/notifications" element={<NotificationAll />} />
                      {/* <Route exact path="/notifications_general" element={<NotificationsGeneral />} /> */}
                      <Route exact path="/service-request" element={<ServiceRequests_doc/>} />
                       <Route exact path="/search" element={<SearchResult setShowNavbarAndFooter={(value) => setShowNavbarAndFooter(value)}/>} />
                      <Route exact path="/service-request-pharm" element={<PharmacyServiceFulfillment />} />
                      <Route exact path="/service-request-allied" element={<AlliedServiceFulfillment />} />
                      <Route exact path="/service-request-nurse" element={<NurseServiceFulfillment />} />
                      <Route exact path="/payment" element={<Payment />} />
                      <Route exact path="/reports" element={<Reports />} />
                      {/* <Route exact path="/service-requestnurse" element={<PharmacyServiceFulfillment/>} />   */}

                      <Route exact path="/videocall" element={<VideoCall />} />
                      <Route exact path="/service-request-lab" element={<LabServiceFulfillment />} />

                      <Route exact path="/customercare" element={<ServiceCenter /> }/>
                    </Routes>
                  </div>
                 {showNavbarAndFooter &&  <Footer />}
                </LoginProvider>
              </ProfileProvider>
            </HospLoginProvider>

          </LoginStatusProvider>
        </RegProvider>

      </BrowserRouter>


    </>
  );
}

export default App;
