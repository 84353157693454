import React, {useState, useCallback,useEffect} from 'react'
//import {useHistory} from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-date-picker';
import axios from "axios";
import Select from 'react-select';
import { Link, Navigate,useNavigate } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
import { useAlert } from 'react-alert'
import VerifyOTPPatient from "../BDO/VerifyOTPPatient";
import PaymentRegistration from "../BDO/PaymentRegistration";
//import HospBdoNavbar from '../HospBdoNavbar/HospBdoNavbar';
import './hospregisterpatient.css'
import 'react-table-v6/react-table.css'
//import GeneratePatientInvoice from './GeneratePatientInvoice';
import debounce from 'lodash.debounce';

function HospRegisterPatient() {
  let navigate = useNavigate();
    const [userDatas,setUserDatas]= useState({
        firstname: "",
        lastname:'',
        email: "",
        
        consent:"",
        gender: "",
        dob: "",
        country: "India",
        state: "",
        phone: {
          dialcode: "",
          rawphone: "",
          mobile_number: "",
        },
        identitycard: { value: '', label: '' },
        pincode:"",
        identitynumber:"",
        province:"",
        city:"",
        location:""

       
        // complaint:"",
        // medHistory:"",
        // famHistory:"",
      
       
       
    });
let ItemID =[];
    const [patientId, setPatientId]= useState('')
    const [invalid ,setInvalid] =useState(false)
    const [isValidUsername,setIsValidUsername]=useState(true);
    const [isValidEmail,setIsValidEmail]=useState(true);
    const [isValidPhone,setIsValidPhone]=useState(true);
    const [invoiceData , setInvoiceData]= useState([]);
    const alert = useAlert()
    const [errorMsg,setErrorMsg]=useState("");
    const [errorShow,setErrorShow]=useState(false);
    const [successShow,setSuccessShow]=useState(null);
    const [successMsg,setSuccessMsg]=useState("");
    const [verifyOTP, setVerifyOTP] = useState(false)
    const[otpval,setotpval]=useState("")
    const[signmethod,setSignMethod]=useState("")
    const [modalShow, setModalShow] = useState(false)
    const [loginEnable, setLoginEnabled] = useState(false)
    const [consentlog, setConsent] = useState(false)
    const [dob, setDob] = useState();
    const [,setFormattedDob] = useState("");
    const [invoiceShow, setInvoiceShow]= useState(false)
    const [login_datas, setLogin_datas] = useState(
      JSON.parse(sessionStorage.getItem("logindatas"))
          ? JSON.parse(sessionStorage.getItem("logindatas"))
          : null
  );
  console.log("login_datas",login_datas);

   // const history =useHistory();

    const [error,setError]=useState({
        username:"",
        //email: '',
        phone:"",
      
       });

       const identityoptions = [{ value:'pan', label:'PAN' },                       
       { value:'passport', label:'PASSPORT' },
       { value:'aadhar', label:'AADHAR' },
       { value:'driving_license', label:'DRIVING LICENCE' },
      
       ]
    const handleChange=e=>{
        
        e.preventDefault();
        const val=e.target.value;
        const key=e.target.name;
        setUserDatas(currentstate=>({
          ...currentstate,
          [key]: val
        }));  
    
           
        
  }
  const handleChangeLoc=e=>{
        
    e.preventDefault();
    const val=e.target.value;
    const key=e.target.name;
    setUserDatas(currentstate=>({
      ...currentstate,
   location: val
    }));  

       
    
}


useEffect(()=> {
  debugger;

  const tokenString = sessionStorage.getItem("usertoken");

  let v2 = tokenString.replace(
    /["]+/g,
    ""
  ); /* REGEX To remove double quotes from tokenstring */


    axios
    .get(`${APIURL}/api/v1/service-provider/hospital-services/?type=misc`, {
      headers: { Authorization: "Token "+ v2 },
    })
    .then((res) => {
      if (res.status ===200) { 
          debugger;
       
        let dataList = (res.data.filter(item => {
          
        
          return item.service_name === 'Registration' || item.service_name === 'registration'
        
        }))
        //(res.data.orders)
        setInvoiceData(dataList[0]);
       
    
        console.log(invoiceData)
    }
    else{

    }
}).catch((error)=>{

})





},[successShow])

  const  refreshPage =() =>
  {
    window. location. reload(false); 
  }

  const onSuccess = (data) => {
    
    setotpval(data.otp)
    setSignMethod(data)
    console.log(signmethod,otpval)
    setVerifyOTP(false);
   setModalShow(true);
    setLoginEnabled(true);
    setConsent(true);
    }
const onCancel = () => {

    setVerifyOTP(false);
    setUserDatas(currentstate=>({
      ...currentstate,
      consent: ""

    }))

};
const SubmitPopUp = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Verified Successfully!!</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-primary btn-col" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
   </Modal>
  );
};

const InvoicePopup=(props)=>{

  // const consultData = config.find(item => { return item.name === 'Consultation' })
   

   return (
     <Modal
       {...props}
       aria-labelledby="contained-modal-title-vcenter"
       centered
        backdrop="static"
         keyboard={false}
         dialogClassName="modal-80w"
     >
       <Modal.Header closeButton >
         <Modal.Title id="contained-modal-title-vcenter">
          
         </Modal.Title>
       </Modal.Header >
       <Modal.Body className="text-center">
 
           <PaymentRegistration patientID={patientId} name={userDatas.firstname} mobile={userDatas.phone.rawphone} refresh={handleInvoiceShow} />  
 
       </Modal.Body>
       <Modal.Footer>
          
      
       {/* <Button className="btn btn-primary btn-col" onClick={refreshPage}>
          Close
        </Button> */}

       </Modal.Footer>
     </Modal>
   );
 }

  const handleDOB=(date)=>{

    setDob(date);
  
  let dateUpdated= date;
    if(date!==null){
  const offset = date.getTimezoneOffset();
  
  dateUpdated = new Date(date.getTime() - (offset*60*1000))
  
  let DateExtracted = dateUpdated.toISOString().split('T')[0];
  
  /*let dateString= DateExtracted.toString(); */
  setFormattedDob(DateExtracted);
  
   setUserDatas(currentstate=>({
              ...currentstate,
              dob: DateExtracted
            }));
  }
  }

//   const selectCountry =(val)=> {
//     setUserDatas(currentstate=>({
//        ...currentstate,
//           country: val
//         }));
    
   
//  }

 const selectRegion= (val)=> {
 // let value1=e.target.value;
   setUserDatas(currentstate=>({
       ...currentstate,
          state: val
        }));
 }

 const handleGenderRadioButton=(e)=>{
    let genderOpted = e.target.value;
    setUserDatas(currentstate=>({
      ...currentstate,
      gender: genderOpted

    }))
   }

   const handleInvoiceShow =() =>
   {
    setInvoiceShow(false)
    setSuccessShow(false)
   
    
   }

   const handleConsentRadioButton=(e)=>{
    
    if(userDatas.phone !==""){
    let consentOpted = e.target.value;
  
    setUserDatas(currentstate=>({
      ...currentstate,
      consent: consentOpted

    }))
if(consentOpted==="yes"){
  setVerifyOTP(true)
}
else{
  //setLoginEnabled(false)
  //setConsent(false)
}
    }
    else{

      setErrorMsg("Please enter mobile number or email for verification");
      setErrorShow(true);

    }
    // setUserDatas(currentstate=>({
    //   ...currentstate,
    //   consent: consentOpted

    // }))
   }


   function OtpPopUp(props) {
    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body >
                <VerifyOTPPatient onSuccess={onSuccess} onCancel={onCancel} email={userDatas.email} phone={userDatas.phone} />
            </Modal.Body>

        </Modal>
    );
}

 const handlePhoneInput= (value, data, event, formattedValue)=>{
  
  
    const rawPhone = value.slice(data.dialCode.length) ;
    
    const dialcode = value.slice(0,data.dialCode.length) ;
     setUserDatas(currentstate=>({
          ...currentstate,
             phone: {
              dialcode: dialcode,
                rawphone: rawPhone,
                mobile_number: value
              }
  
                         }));
    
    
  
   }
    
    const handleCheckExistingPhone= (e)=>{
   setIsValidPhone(true);
  if(e.target.value !==""){
    
    const rawPhone =  userDatas.phone.rawphone ;
    
    const dialcode =  userDatas.phone.dialcode;
     setError(currentstate=>({
                ...currentstate,
              phone: ''}));
  
    
  
      const data = {
              code: dialcode,
              mobile_number: rawPhone
          }
         
            axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
       'Content-Type': 'application/json'}})
      .then(res =>{
           
              if(res.data.status === "success"){
                if(res.data.is_existing_user === true){
                 
                    setError(currentstate=>({
                ...currentstate,
              phone: "Phone Number already registered!"}));
                      setIsValidPhone(false);
                   }
                    else{
                      setIsValidPhone(true);
  
                    }
                     
                      
                  }
              else{
               
                setIsValidPhone(true);
                    
              }
                  
              })
      .catch(err =>{
  
      
    })
  
  }
    
  
   }



  
  const handleCheckExisting=e=>{
    debugger;
    setIsValidEmail(true);
  if(e.target.value!== ""){
         setError(currentstate=>({
                ...currentstate,
              email:  ''}));
    e.preventDefault();
    let key=e.target.name;
  
  
      const data = {
              email: e.target.value
          }
       
            axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
       'Content-Type': 'application/json'}})
      .then(res =>{
            
              if(res.data.status === "success"){
                if(res.data.is_existing_user === true){
               
                      setError(currentstate=>({
                ...currentstate,
              email: `This ${key} is already registered!`}));
                   setIsValidEmail(false);
                   }
                    else{
                      setIsValidEmail(true);
                    }
                     
                      
                  }
              else{
          
                setIsValidEmail(true);
                    
              }
                  
              })
      .catch(err =>{
     
      
    })
  }
  
  }

  const handleChangecard=e=>{
        
    e.preventDefault();
    const val=e.target.value;
    const key=e.target.name;

    setError(currentstate=>({
          ...currentstate,
          [key]: ""
        }));

      setUserDatas(currentstate=>({
        ...currentstate,
        [key]: val
      }));   

     // debouncedCheckCpr(key,val)
    
}


// const debouncedCheckCpr = useCallback(
//   debounce((key,val) => handleCheckExistingCpr(key,val),1000 ),
//   [],
// )





  const IdentityChange = (data) => {
    

    setUserDatas(current => ({
      ...current, identitycard: data
    }))
  }






  const validate= async()=>{
    let input = userDatas;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["firstname"] || input["firstname"]==="" ) {
      isValid = false;
      errors["firstname"] = "Please enter first name";
    }
    // if (!input["identitycard"].value || input["identitycard"].value==="" ) {
    //   isValid = false;
    //   errors["identitycard"] = "Please select identity card";
    // }
    if (!input["pincode"] || input["pincode"]==="" ) {
      isValid = false;
      errors["pincode"] = "Please enter pincode";
    }
    // if (!input["email"] || input["email"]==="" ) {
    //   isValid = false;
    //   errors["email"] = "Please enter email address";
    // }
    // if (!input["identitynumber"] || input["identitynumber"]==="" ) {
    //   isValid = false;
    //   errors["identitynumber"] = "Please enter identity card number";
    // }

    if (!input["lastname"] || input["lastname"]==="" ) {
      isValid = false;
      errors["lastname"] = "Please enter last name";
    }
    





  
    if (!input["state"] || input["state"]==="") {
      isValid = false;
      errors["state"] = "Please select a region";
    }





    if(isValidPhone===false){
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }
    if(isValidEmail===false){
      isValid = false;
      errors["email"] = "Email already registered!";
    }





  


    if (input["email"] !== "") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
      }
    }


    if (typeof input["firstname"] !== "undefined") {
        
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern2.test(input["firstname"])) {
        isValid = false;
        errors["firstname"] = "Special Characters are not allowed";
      }
    }

    if (typeof input["lastname"] !== "undefined") {
        
      var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern3.test(input["lastname"])) {
        isValid = false;
        errors["lastname"] = "Special Characters are not allowed";
      }
    }


    if (!userDatas.phone.dialcode || userDatas.phone.dialcode==="" || !userDatas.phone.rawphone || userDatas.phone.rawphone==="" ) {
      isValid = false;
      errors["phone"] = "Please enter phone number";
    }
    
    if(typeof dob=="undefined" || dob==="" || dob=== null){
      isValid = false;
      errors["dob"] = "Please enter your date of birth";
    }
     if(typeof userDatas.gender=="undefined" ||  userDatas.gender==="" ||  userDatas.gender=== null){
      isValid = false;
      errors["gender"] = "Please select gender";
    }




     setError(currentstate=>({
          ...currentstate,
          ...errors
        }));
    

    return isValid;
}


  const handleContinue= async (e)=>{
    debugger;

    e.preventDefault();

     if(await validate() && isValidEmail && isValidPhone){

       handleRegister();
     }
    //  else{
    //   setErrorMsg("please cehck email already registered or not")
    //   setErrorShow(true)
    //  }
}

const  handleRegister=()=>{
  debugger;

  if(userDatas.consent===""){
   setErrorMsg("Please Select App consent yes or no")
   setErrorShow(true)
  }
  else{
  

  const tokenString= sessionStorage.getItem('usertoken');
   
  let str= tokenString.replace(/["]+/g, '');
     
                  let user =  {
          
                    first_name : userDatas.firstname,
                    last_name : userDatas.lastname,
                    signup_method:signmethod.mobile_number ? "mobile" : signmethod.email ? "email" : "mobile",
                  // email:userDatas.email,
                  // phone : userDatas.phone.rawphone,
                 gender : userDatas.gender,
                 //country_code:userDatas.phone.dialcode,
                 dob:userDatas.dob,
                 province:userDatas.province ? userDatas.province :"-",
                 city:userDatas.city ? userDatas.city :"-",
                 country:"India",
                 location:userDatas.location ? userDatas.location :"-",
                 state: userDatas.state,
                 pincode:userDatas.pincode,
                 code : userDatas.phone.dialcode,
                 mobile_number: userDatas.phone.rawphone,
                 idcard_type  :userDatas.identitycard.value ? userDatas.identitycard.value :"-",
                 idcard_no:userDatas.identitynumber ? userDatas.identitynumber : "-",
                 src:"web",
                 login_enabled:loginEnable !== true ? "False" : true,
                 login_consent:consentlog  !== true ? "False" : true, 
                 hospital_id:login_datas.hospital_id,
                 otp: otpval!==""? otpval : "1234",
                 latitude:"10.12345",
                 longitude:"10.12345"
            
                     
                }

      if(userDatas.email){
         user = { ...user,  email:userDatas.email,}
       }

      
  
       axios.post(`${APIURL}/api/v1/account/signup/`, user,{ headers: {
     'Content-Type': 'application/json',
      "Authorization": "Token "+str
      }})
    .then(res =>{
      
            
            if(res.data.status === "success"){
         
                
          setSuccessMsg(res.data.message);
          setPatientId(res.data.patient_id)
          setSuccessShow(true);


            }        
            else {

             setErrorMsg(res.data.message);
             setErrorShow(true);

            }
                
            })
     .catch(err =>{
      setErrorMsg("Error in registration");
              setErrorShow(true);
    
  })
}
 
}

let data={}

const handleOk = () =>
{
  data=userDatas
    setUserDatas(  {firstname: "",
    lastname:'',
    email: "",
    
    consent:"",
    gender: "",
    dob: "",
    country: "India",
    state: "",
    phone: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
    identitycard: { value: '', label: '' },
    pincode:"",
    identitynumber:"",
    province:"",
    city:"",
    location:""}
)
setDob("")
  setInvoiceShow(true)
}
const handleCancel =() =>
{
  debugger;
  const tokenString= sessionStorage.getItem('usertoken');
      
  let str= tokenString.replace(/["]+/g, '');

 

  ItemID=invoiceData.id

  //const consult = props.consultData
  const dataToUpdate = {
    payment_mode : "cod",
      paid_amount: 0.0,
      patient_id: patientId,
      paid_for:"registration",
      paid_status:"not_paid",
      items :  [ItemID]

  }
 
   axios.post(`${APIURL}/api/v1/service-provider/hospital-payments/`, dataToUpdate ,
   { headers: {
     'Content-Type': 'application/json',
     "Authorization" : 'Token '+str  }})
      .then(res=>{
 
     if(res.data.status==="success"){
      data=userDatas
      setUserDatas(  {
      firstname: "",
      lastname:'',
      email: "",
      
      consent:"",
      gender: "",
      dob: "",
      country: "India",
      state: "",
      phone: {
        dialcode: "",
        rawphone: "",
        mobile_number: "",
      },
      identitycard: { value: '', label: '' },
      pincode:"",
      identitynumber:"",
      province:"",
      city:"",
      location:""})
     setDob("")
 
  
    alert.show("Payment Canceled successfully")
    
    setSuccessShow(false)
    

        
   


     // props.dataHandle1();
 
     }else{
      setErrorMsg(res.data.message)
      setErrorShow(true)
     }
 
 
   })
   .catch(err=>{
      setErrorMsg('Error in Data Submission.Please try again')
      setErrorShow(true)
 })

}

const handleSuccess = ()=> {

  setSuccessShow(false)

}

const handleInvoice = () => {
  setInvoiceShow(true)
}


const SuccessPopup =(props)=> {
  debugger;
  return (
   
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">Patient Registered Successfully!!</h4> <br/><br/> { invoiceData!==undefined ?  <h6 style={{color:"black"}}>Please click <b>"Goto Payment"</b> for registartion !! else click <b>"Cancel"</b> for later payment</h6> :  <h6 className="text-center" style={{color:"black"}}>No Registration Fee for this Hospital</h6> }
      </Modal.Body>
      <Modal.Footer>

      { invoiceData!==undefined ?
      <>
        <Button className="btn btn-primary btn-col" onClick={handleCancel}>
         Cancel
        </Button>

        <Button className="btn btn-primary btn-col" onClick={handleOk}>
        Goto Payment
        </Button>

       </> :     <Button className="btn btn-primary btn-col" onClick={handleSuccess}>
         Cancel
        </Button>}

        {/* <Link to="/paymentreg" patientID={patientId} name={userDatas.firstname} mobile={userDatas.phone.rawphone}>
            <Button className="btn btn-primary btn-col">Goto Payment for registration</Button>
          </Link> */}
      </Modal.Footer>
    </Modal>
  );
}


const ErrorPopup =(props)=> {
  return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
         <h5 className="text-danger">{errorMsg!==''?errorMsg : <h5 className="text-danger">Error in Data Submission. Please try again!</h5>}</h5>
           
          </Modal.Body>
          <Modal.Footer>
           
             <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
    
          </Modal.Footer>
        </Modal>
      )
}
const handleBack = (e) => {
  navigate(-1);
};
    return (
        <div>
              {/* <div className=" text-left" style={{marginTop:"2%",marginLeft:"1%"}}>
      
          <button className="btn btn-info back-btn" onClick={handleBack}> <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i><span>&nbsp;</span>Back to CSC</button> 
    </div>  */}
            <form className="">
                                     {/* <h3 className="title-of-page">Patient Registration </h3>  <br/> */}
                                     {/* <div className='progress-bar'>
                                            <div className='step'>
                                                <p>Register</p>
                                                <div className='bullet'>
                                                    <span>1</span>
                                                </div> 
                                                <i className="fas fa-check"></i>
                                            </div>
                                            <div className='step'>
                                                <p>Details</p>
                                                <div className='bullet'>
                                                    <span>2</span>
                                                </div> 
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>  */}
                                     <div className="hosp-patient-form" style={{marginTop:"1%" ,width:"80%"}}>

                                             <h4 className='title-of-tasks'>Patient Details</h4>

                                     <br/>
                                        <div className='form-align'>
                                            
                                            <div className="item item-left-row"> 
                                        <label className="">* First Name: </label>
                                        <input value={userDatas.firstname} className="form-control" type="text" name="firstname"  onChange={handleChange} maxLength='30'
                                        />  

                                         { error.firstname ? <div className="error-validation-msg">{error.firstname}</div> : null}
                                         </div>
                                          <div className="item item-left-row"> 
                                        <label className="">* Last Name: </label> <input className="form-control" value={userDatas.lastname} type="text" name="lastname"  onChange={handleChange} maxLength='30'
                                        />  
                                          { error.lastname ? <div className="error-validation-msg">{error.lastname}</div> : null}
                                            </div>
                                            <div className="item item-left-row">
                                              
                                           <label className="phone-hov">* Phone: </label>
                                        <PhoneInput
                                            inputProps={{
                                                  
                                                  
                                                  
                                                }}
                                            country={'in'}
                                           // onlyCountries={['in']}
                                           // countryCodeEditable={false}
                                            value={userDatas.phone.mobile_number?userDatas.phone.mobile_number:""}
                                            onChange={handlePhoneInput}
                                            onBlur={handleCheckExistingPhone}

                                          />
                                          {error.phone ? <div className="error-validation-msg"> {error.phone}</div>  : null}

                                          </div>

                                        <div className="item item-left-row"> 
                                        <label className=""> Email Address: </label><input className="form-control" type="email" value={userDatas.email} name="email" onChange={handleChange} onBlur={handleCheckExisting} maxLength='50' />
                                        
                                        {error.email ? <div className="error-validation-msg"> {error.email}</div> : null}
                                        </div>
                                        
                                        {/*  <input className="form-control" type="text" name="dob" placeholder="Date of Birth* : YYYY-MM-DD" required />
                                          */}

                                     
                                         
                                        <div style={{alignSelf:"end"}} className="form-control gender-div">
                                       
                                       
                 <span>* Date of Birth:</span> 
                    <DatePicker
                          onChange={date=>{handleDOB(date)}}

                          maxDate = {new Date()}
                          value={dob}
                          
                     /> 
                       {error.dob?  <div className="error-validation-msg"> {error.dob}</div> : null}
         
         </div>  
         <div className="item item-left-row"> 
              <label className=""> Identity card: </label>
              <Select
                           value={userDatas.identitycard}
                            onChange={(data,e)=>{IdentityChange(data,e)
                            }}
                            options={identityoptions}
                            name='identitycard'
                            className="select-currency select-flex"
                        /> 
            {/* {error.identitycard ? <div className="error-validation-msg"> {error.identitycard}</div> : null} */}
        </div>
           

          <div className="form-control gender-div" style={{alignSelf:"end"}} >
                                     
                                        <span>* Gender:</span> 
                                          <input 
                                             type="radio" 
                                             id="male" 
                                             name="gender" 
                                             value="male"
                                             checked={userDatas.gender === "male"}
                                             onClick={handleGenderRadioButton}
                                            
                                                 />
                                          <label htmlFor="male">Male</label>

                                          <input  
                                            type="radio"
                                            id="female"
                                             name="gender" 
                                             value="female"
                                             checked={userDatas.gender === "female"}
                                            onClick={handleGenderRadioButton} />

                                          <label htmlFor="female">Female</label>

                                          <input 
                                              type="radio"
                                              id="others" 
                                              name="gender"
                                              value="others"
                                              checked={userDatas.gender === "others"}
                                            onClick={handleGenderRadioButton} 
                                                 />
                                          <label htmlFor="others">Others</label>

                                         {error.gender ?  <div className="error-validation-msg"> {error.gender}</div> : null}
                                        </div>

                       {userDatas.identitycard.value!=="" ?    <div className="item item-left-row"> 
                                     
                                     <span> Identity Card Number:</span> 
                                     <input className="form-control" value={userDatas.identitynumber} type="text" name="identitynumber" placeholder="" onChange={handleChange} maxLength='20'
                                        />  
                                           {/* {error.identitynumber ?  <div className="error-validation-msg"> {error.identitynumber}</div> : null} */}
                                     </div> : null}

                                     <div className="item item-left-row"> 
                                        <label className=""> Address line1: </label>
                                        <input className="form-control" type="text"value={userDatas.province} name="province" placeholder="province" onChange={handleChange} 
                                        />  
                                        {/* {error.cpr ? <div className="error-validation-msg"> {error.cpr}</div>  : null} */}
                                         </div>

                                         <div className="item item-left-row"> 
                                        <label className=""> Address line2: </label>
                                        <input className="form-control" type="text"value={userDatas.city} name="city" placeholder="city" onChange={handleChange} 
                                        />  
                                        {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
                                         </div>
                                         <div className="item item-left-row"> 
                                        <label className=""> Address line3: </label>
                                        <input className="form-control" value={userDatas.location} type="text" name="location" placeholder="district" onChange={handleChangeLoc} 
                                        />  
                                        {/* {error.file ? <div className="error-validation-msg"> {error.file}</div>  : null} */}
                                         </div>
                                        <div className="country-selector item-left-row">
                                         <label className=""> Country: </label>
                                            <input  className="form-control"
                                              value={"India"}
                                               disabled
                                              //onChange={(val) => selectCountry(val)}
                                               />
                                           
                                        </div>
                                        <div className="region-selector item-left-row">
                                         <label className="">* Region:  </label>
                                            <RegionDropdown
                                              country={"India"}
                                              value={userDatas.state}
                                              onChange={(val) => selectRegion(val)} />
                                          {error.state ? <div style={{zIndex:'auto'}} className="error-validation-msg"> {error.state}</div> : null}
                                           
                                           </div>

                                          {/* <div className="item item-left-row"><label className="">* Zipcode: </label><input className="form-control" type="text" name="zipcode" placeholder="" onChange={handleChange} maxLength='50'
                                        /> 
                                        {error.zipcode ? <div className="error-validation-msg"> {error.zipcode}</div> :null}
                                           </div> */}
                                         
                                       

                                         <div className="item item-left-row"> 
                                        <label className="">* Pincode: </label>
                                        <input className="form-control"value={userDatas.pincode}type="text" name="pincode"  onChange={handleChange} maxLength='10'
                                        />  
                                        {error.pincode ? <div className="error-validation-msg"> {error.pincode}</div>  : null}
                                         </div>

                                         <div className="form-control gender-div consent-div">
                                     
                                     <span>* App login  :</span> 
                                       <input 
                                          type="radio" 
                                          id="yes" 
                                          name="consent" 
                                          value="yes"
                                    
                                        
                                          checked={userDatas.consent === "yes"}
                                          onClick={handleConsentRadioButton}
                                         
                                              />
                                       <label htmlFor="yes">Yes</label>

                                       <input  
                                         type="radio"
                                         id="no"
                                          name="consent" 
                                          value="no"
                                          checked={userDatas.consent === "no"}
                                         onClick={handleConsentRadioButton} />

                                       <label htmlFor="no">No</label>

                                     </div>

                            
                                        </div>
                                        <br/>
                                        <div className="form-button">
                                            <button onClick={handleContinue} className="btn btn-primary btn-col">Register</button>
</div>
                                    </div>
                                </form>


      {
        successShow ? 
        <SuccessPopup
        show={successShow}
        onHide={handleSuccess} /> : null
      } 
         {verifyOTP ? (
                <OtpPopUp
                    show={verifyOTP}
                    onHide={() => {
                        setVerifyOTP(false);
                    }}
                />
            ) : null}
            

{
        errorShow ? 
        <ErrorPopup
        show={errorShow}
        onHide={()=> setErrorShow(false)} /> : null
      } 
      {
      invoiceShow ? 
      <InvoicePopup
        show={invoiceShow}
        onHide= {() => { setInvoiceShow(false);
                         //setRerender(!reRender)     
        }}

        
      /> : null
    }

{modalShow ? (
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
           // data.modalClose();
          
           // setSubmitMsg("");
          }}
        />
      ) : (
        ""
      )}
        
        </div>
    )
}

export default HospRegisterPatient
