import React,{useState} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import './profile.css'
import {Button ,Modal } from 'react-bootstrap';

function MailUpdate(props) {
    const [email ,setEmail] =useState();
    const [isValidEmail,setIsValidEmail]=useState(true);
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);

    const [error,setError]=useState({
        username:"",
        email: '',
        phone:""
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
          if(key==='email'){
            setEmail(val)
          } 
        }

        const handleCheckExisting=e=>{

            setIsValidEmail(true);
          if(e.target.value!== ""){
               setError(currentstate=>({
                      ...currentstate,
                    email:  ''}));
          e.preventDefault();
          let key=e.target.name;
          
          
            const data = {
                    [e.target.name]: e.target.value
                }
             
                  axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
             'Content-Type': 'application/json'}})
            .then(res =>{
                  
                    if(res.data.status === "success"){
                      if(res.data.is_existing_user === true){
                     
                            setError(currentstate=>({
                      ...currentstate,
                    [key]: `This ${key} is already registered!`}));
                          setIsValidEmail(false);
                         }
                           else{
                
                      setIsValidEmail(true);
                          
                    }
                          
                            
                        }
                    else{
                
                      setIsValidEmail(true);
                          
                    }
                        
                    })
            .catch(err =>{
          
            
          })
        }
          
    }

  const  validate = () => {
      //let input = email;
      let errors = {};
      let isValid = true;
  
  
      if (!email) {
        isValid = false;
        errors["email"] = "Please enter  email Address.";
      }
  
      if (email !== "undefined") {
          
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(email)) {
          isValid = false;
          errors["email"] = "Please enter valid email address.";
        }
      }
  
      setError(currentstate=>({
        ...currentstate,...errors
      }))
  
      return isValid;
  }

  const handleContinue =async(e) => {
    e.preventDefault();

    if( validate() && isValidEmail){
      submitMail()
    }
  }


    const submitMail = (e) =>{
        const tokenString= sessionStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '') 
      
         const data = {
           email : email
         }
      
         axios.put(`${APIURL}/api/v1/account/profile/` ,data, { headers: {
      
          "Authorization" : 'Token '+str  }})
        .then(res=>{
        
              
          if(res.data.status==="success"){

            setSubmitMsg("success");
            setShow(true)
           setEmail()
           
        
          }else{
            setErrorMsg(res.data.message);
            setErrorSubmit(true);
          
           
          }
        
        })
        .catch(err=>{
          setErrorMsg("error");
  
          setErrorSubmit(true);

        
        })
      
      }


      const ConfirmPopUp = (props) => {
        return(
        <Modal
        {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            //  backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 class="updated-message">Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }
      
      
      const SubmitErrorPopup =(props)=>{
          
      
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            //  backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 class="updated-message" style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }




    return (
        <div>
            <input className="form-control" type="email" name="email" placeholder="E-mail Address*" value={email} onChange={handleChange} onBlur={handleCheckExisting} />
                                        
            {error.email ? <div className="error-validation-msg"> {error.email}</div>: null}
        <br/>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update Email</button>
            </div>

            {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              props.handle();
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default MailUpdate
