import React , {useState ,useEffect ,useContext} from 'react'
import { Link } from "react-router-dom";
import axios from "axios";
import {APIURL} from "../../Global";
//import {useHistory} from 'react-router-dom'
import {Button ,Modal ,Card ,ListGroup ,ListGroupItem} from 'react-bootstrap';
import '../../modules/css/bootstrap.min.css';
import '../../modules/css/iofrm-style.css';
import '../../modules/css/iofrm-theme4.css';
import "./popup.css";
import "./country_region_selector.css";
import "./dob.css";
import './hospprofile.css'
import EditHospProfile from './EditHospProfile';
import {ProfileContext} from '../contexts/ProfileContext'
//import VerifyOtp from '../VerifyOtp/VerifyOtp';
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';
import BackButton from '../common/BackButton';
function HospProfile() {
  const [isDeregister, setIsDeregister] = useState(false)
  const [deleteShow, setDeleteShow] = useState(false)
  const [successShow, setSuccessShow] = useState(false); 
  const [img, setImg] =useState('');
    const [practise, setPractise]=useState();
    const [location,setLocation]=useState();
    const [state, setState] = useState('')
    const [street, setStreet] = useState('');
    const [country, setCountry] = useState('');
    const [email,setEmail]=useState(null);
    const [name,setName] = useState('');
    const [spec,setSpec] = useState([]);
    const [mobile, setMobile]= useState();
    const [editShow, setEditShow]= useState(false);
    const [website, setWebsite]= useState(false)
    const [facility, setFacility]= useState([])
    const [desc, setDesc]= useState('')
    const [verifyShow ,setVerifyShow] =useState(false)
    const [verified,] =useState()
    const [render, setRender]= useContext(ProfileContext)
    const [refresh ,setRefresh] =useState(false)
    const [user,setUser]= useState('')
    const [otpShow, setOtpShow]= useState(false)
    const [otpFail, setOtpFail]= useState(false)
    const [disable, setDisable]= useState(false)
    const [emergency, setemergency] = useState('');
    const [year, setyear] = useState('');
    const [healthcare, setHealthcare] = useState('');
    const [delivery, setDelivery] = useState('');
    const [regno, setRegNo] = useState('');

  const[flag,setflag]=useState(false)
   // const history =useHistory();

//const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});


// useEffect(()=>{

// window.onkeydown =(e) => {
 
//  keys[e.key]=true

//  if(e.altKey && keys['d']){
//    history.push('/doctors-list')
//  }
//  if(e.altKey && keys['a']){
//    history.push('/hospital/add')
//  }
//  if(e.altKey && keys['b']){
//    history.push('/bdo-list')
//  }
//  if(e.altKey && keys['o']){
//    history.push('/hospital/add-bdo')
//  }
//  if(e.altKey && keys['n']){
//    history.push('/emergency-number')
//  }
//  if(e.altKey && keys['s']){
//    history.push('/service-request-list')
//  }
//  if(e.altKey && keys['l']){
//    history.push('/license-details')
//  }
//  if(e.altKey && keys['p']){
//    history.push('/license-payment')
//  }
//  if(e.altKey && keys['c']){
//    history.push('/hosp-calllog')
//  }
//  if(e.altKey && keys['h']){
//    history.push('/payment-history')
//  }

// }
// window.onkeyup=(ev)=> {
 
//  keys[ev.key]= false
// }

// return()=>{
//  window.onkeydown=null
//  window.onkeyup=null
// }
// },[]);

    const handleEditPopUp =(e) => {
      
        e.preventDefault();
        setEditShow(true)
    }

    useEffect(()=>{
        const tokenString= sessionStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '')                           
          
         
          axios.get(`${APIURL}/api/v1/service-provider/hospital-profile/`, { headers: {
            
            "Authorization" : 'Token '+str  }})
          .then(res=>{
              
            if(res.data.status==="success"){
              const hospData= res.data.hospital_profile
             // setFacility(hospData.facilities.join())       
            //setSpec(hospData.specializations.join())
            setIsDeregister(hospData.is_deregister)
            setPractise(hospData.practise_type)
            setImg(hospData.photo)
            setName(hospData.name)
            setLocation(hospData.location)
            setState(hospData.state)
            setCountry(hospData.country)
            setStreet(hospData.street)
            setMobile(hospData.mobile_number)                
            setEmail(hospData.email)
            setWebsite(hospData.website)
            setDesc(hospData.description)
            setUser(hospData.username)
            setemergency(hospData.emergency_number)
            setyear(hospData.established_year)
            setHealthcare(hospData.health_care_type)
            setDelivery(hospData.delivery_radius)
            setRegNo(hospData.reg_number)
      
            }
      
      
          })
          .catch(err=>{
            
      
      })
      
      
      },[refresh]);


      const verifyMail = () => {
        // const tokenString= localStorage.getItem('usertoken');
             
        //      let str= tokenString.replace(/["]+/g, '')
    
        //   axios.get(`${APIURL}/api/v1/account/email-verification/`,{headers : {
        //     "Authorization" : 'Token '+str  }})
        //     .then(res => {
    
        //       if(res.data.status==="success"){
    
        //         setVerifyShow(true)
        //       }
        //     })
      }

      const handleReset = (e) => {
        e.preventDefault();
        setDisable(true)
        const pw = sessionStorage.getItem('pw')

        const data = null

        const tokenString= sessionStorage.getItem('usertoken');
        let str= tokenString.replace(/["]+/g, '');        

       axios.post(`${APIURL}/api/v1/account/reset-password/?username=${user}&password=${pw}`, data , { headers: {
         
        "Authorization" : 'Token '+str,
        'Content-Type': 'application/json' }
   
   
       })
   
       .then(res=>{
        
         if(res.data.status=== "success"){
            setDisable(false)
            setOtpShow(true)
         }
         else{
            setDisable(false)
            setOtpFail(true)
               }
                   
               })
        .catch(err =>{
          setDisable(false)
          setOtpFail(true)
     })
      }


      const VerifyPopUp = (props) => {
        return(
        <Modal
        {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 id="confirm-reject">Verification Link sent to Email</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }

      const modalHandle = () => {
        setOtpShow(false)
      }
      const OtpPopUp = (props) => {
        return(
        <Modal
        {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <VerifyOtp username= {user} mail = {email} handle={modalHandle} /> */}
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }

      const OtpFailPopUp = (props) => {
        return(
        <Modal
        {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-danger'>Error in Data Submission.Please Try Again.</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }

      const EditPopUp = (props) => {
        
          return(
            <Modal
            {...props}
            aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog1"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body1">

       <EditHospProfile specs={spec} show={editShow} image={img} mail={email} location={location} street={street} state={state} country={country} emergency={emergency} year={year} healthcare={healthcare} regno={regno} delivery={delivery} />
       
      </Modal.Body>
      <Modal.Footer>
            
         <Button className='btn btn-primary btn-col' onClick={props.onHide}> Close</Button>   

      </Modal.Footer>
    </Modal>
          )
      }
      const DeleteConfirmPopup = (props) => {
        
        
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
           
          >
            
            <Modal.Body >
            {!isDeregister?<>
              <h4 className="title-of-page"> Deregister Account  </h4>
           <h5 className="title-of-page" > Please Confirm to Deregister</h5><br/></>:
           <><h4 className="title-of-page"> Cancel Request  </h4>
           <h5 className="title-of-page" > Please Confirm to Cancel Request</h5><br/></>
           }
           
      <div className="form-group " style={{marginLeft:"120px"}}>
             <button type="button" className="btn btn-danger btn-cancel"onClick={props.onHide}> Cancel </button> 
              <button type="submit" className="btn btn-primary btn-col" onClick={() => { handleDelete() }} >Confirm </button>
              </div>
            </Modal.Body>
            
          </Modal>
        );
      }
      const handleDelete = () => {
      
        
        const tokenString = sessionStorage.getItem('usertoken');
        let dataToSend={}
        let str = tokenString.replace(/["]+/g, '');
      if(flag===true){
         dataToSend = {
          is_deregister: true

      }}
      else{ dataToSend = {
        is_deregister: false

    }}

                            
        axios.put(`${APIURL}/api/v1/service-provider/de-register/`, dataToSend,
        {
          headers: {
      
            "Authorization": 'Token ' + str,
            'Content-Type': 'application/json'
          }   
         })
          .then(res => {
      
      
            if (res.data.status === "success") {
              //setModalShow(true);
              setSuccessShow(true);
              // handle();
              // setRefresh(!refresh)
      
      
      
            }
            else {
              console.log('else called ');
             
              //  setErrorMsg(res.data.message);
              //             setErrorSubmit(true);
            }
      
      
         
            setDeleteShow(false);
      
          })
          .catch(err => {
            console.log("catch called");
            // setSubmitMsg("error");
            // setErrorSubmit(true);
          })
      
      }
      const SubmitPopUp1 = (props) => {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!isDeregister?
              <h4 className="" >successfully requested for deregistration</h4>:
              <h4 className="" >successfully Cancelled deregistration</h4>}
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn btn-primary btn-col" onClick={props.onHide}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        );
      };


    return (
      <>

    <div style={{marginLeft: '23%', display:'flex', marginRight:'21%', width:'auto'}}>
        <Card className="mb-2" bg={'light'} border='light' style={{ width: '50rem' ,height: 'auto' }}>
    <div >
        <div >
            <Card.Img style={{width: 200,
                height: 200,
                maxWidth: '100%',
                borderRadius: '50%'}} variant="top" src={img} alt="Profile Photo"  />
            
        </div>
        <hr/>
    </div>
  <Card.Body>
    <Card.Title style={{fontSize:'200%'}}>Profile</Card.Title>
    <ListGroup className="list-group-flush">
    <ListGroupItem>Hospital Name : {name}</ListGroupItem>
    <ListGroupItem>Email : {email}<label onClick={verifyMail} style={{color:'blue', cursor: 'pointer'}}>{ verified===false && email !=='' ? <p>(verify)</p> :'' }</label></ListGroupItem>
    <ListGroupItem>Address : {street}</ListGroupItem>
    <ListGroupItem>Mobile Number : {mobile}</ListGroupItem>
    <ListGroupItem>Emergency Number : {emergency}</ListGroupItem>
    <ListGroupItem>Established Year : {year}</ListGroupItem>
    <ListGroupItem>Health Care Type : {healthcare}</ListGroupItem>
    <ListGroupItem>Delivery Radius : {delivery}</ListGroupItem>
    <ListGroupItem>Registration Number : {regno}</ListGroupItem>
   
    {/* <ListGroupItem>Specialization : {spec}</ListGroupItem>
    <ListGroupItem>Facilities : {facility}</ListGroupItem> */}
   
    <ListGroupItem>Website : {website}</ListGroupItem>
    <ListGroupItem>Description : {desc}</ListGroupItem>
  </ListGroup>
     <Button style={{margin:'1rem'}} onClick={handleEditPopUp}className='btn btn-primary btn-col'>Edit Profile</Button>
    {/* <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button>  */}
    
                         {!isDeregister?
                          <Button variant="danger" 
                         onClick={() => { setflag(true);setDeleteShow(true);}} 
                            >Deregister Account </Button>:
                             <Button  variant="info"
                            onClick={() => {setflag(false); setDeleteShow(true);}} 
                               >Cancel Deregistration </Button>}
  </Card.Body>
</Card>
            
{
        deleteShow ?
          <DeleteConfirmPopup
            show={deleteShow}
            onHide={() => {
              setDeleteShow(false);
            
            }}

          /> : ''
      }
         {successShow ? (
        <SubmitPopUp1
          show={successShow}
          onHide={() => {
            setSuccessShow(false);
           setRefresh(!refresh)
            
          }}
        />
      ) : null}

        {
            editShow ? 
        <EditPopUp
            show={editShow}
            onHide= { ()=> {setEditShow(false)
                            setRefresh(!refresh)
                            setRender(!render)}} />
            : null
        }

        {
            verifyShow ? 
        <VerifyPopUp
            show={verifyShow}
            onHide= { ()=> {setVerifyShow(false)
                           }} />
            : null
        } 

        {
            otpShow ? 
        <OtpPopUp
            show={otpShow}
            onHide= { ()=> {setOtpShow(false)
                           }} />
            : null
        }   

{
            otpFail ? 
        <OtpFailPopUp
            show={otpFail}
            onHide= { ()=> {setOtpFail(false)
                           }} />
            : null
        }  
    </div>
    </>

    
    )
}

export default HospProfile
