import React ,{useState} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import './hospprofile.css'
import {Button ,Modal } from 'react-bootstrap';

function WebsiteUpdate(props) {

    const[website ,setWebsite] =useState(props.wb)
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);


    const [error,setError]=useState({
        website:"",
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='website'){
            setWebsite(val)
          }  
        }
      
        

        const  validate = () => {
          
           // let input = website;
            let errors = {};
            let isValid = true;
      
            if (website !== "" && website!== null
        ) { 
          var regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
          var pattern = new RegExp(regex);
          if (!pattern.test(website)) {
            isValid = false;
            errors["website"] = "Please enter a valid url.";
           
            }
            else{
              submitWebsite();
            }
          }
           
        
            setError(currentstate=>({
              ...currentstate,...errors
            }))
        
            return isValid;
        }

        const handleContinue =async(e) => {
          
            e.preventDefault();
   
           
          validate()
         
          }

        const submitWebsite = (e) =>{
         

            const tokenString= sessionStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '') 
          
             const data = {
               website : website
             }
          
             axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/` ,data, { headers: {
          
              "Authorization" : 'Token '+str  }})
            .then(res=>{
            
                  
              if(res.data.status==="success"){
                
                setSubmitMsg("success");
                setShow(true)
               
            
              }else{
                setErrorMsg(res.data.message);
                setErrorSubmit(true);
              
               
              }
            
            })
            .catch(err=>{
              setErrorMsg("error");
  
              setErrorSubmit(true);
            
            })
          
          }


          const ConfirmPopUp = (props) => {
            return(
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                //  backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4 class="updated-message">Updated Successfully</h4>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
          
                </Modal.Footer>
              </Modal>
            )
          }
          
          
          const SubmitErrorPopup =(props)=>{
              
          
            return (
              <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                //  backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 class="updated-message" style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>
          
                 
                 
          
                </Modal.Footer>
              </Modal>
            );
          }



    return (
        <div>
            <div className="item"><input className="form-control" type="url" required name="website"   value={website}  placeholder="https/http://www.example.com" onChange={handleChange}/>

                {error.website ? <div className="error-validation-msg"> {error.website}</div> : null}

            </div>
            <br/>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update Website</button>
            </div>

            {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              props.handle();
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default WebsiteUpdate
