import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import LabUser from './LabUser';
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';
import "./labuserlist.css";
import axios from 'axios';
import { APIURL } from "../../Global";
import { Fade } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import BackButton from '../common/BackButton';

function LabUserList() {
  const [loading,setLoading]=useState(false);
  const [labUsersList, setLabUserList] = useState([]);
  const [freeCount, setFreeCount] = useState();
  const [activeCount, setActiveCount] = useState()
  const [errorMsg, setErrorMsg] = useState("");
  const [refresh, setRefresh] = useState(false)





  useEffect(() => {
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");


    axios
      .get(`${APIURL}/api/v1/service-provider/lab-user/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Token " + v2
        },
      })
      .then((res) => {

        const datas = res.data;

        setLoading(false)
        setLabUserList(datas);

      })
      .catch((err) => {
        setErrorMsg("error");
        setLoading(false)
      });
  }, [refresh]);

  const refreshHandle = () => {
    setRefresh(!refresh)
  }

  return (
 
  <div className="lablist-page">

<LoadingOverlay
    active={loading}
    spinner
    styles={{
      spinner: (base) => ({
        ...base,
        width: '50px',
        '& svg circle': {
          stroke: 'rgba(0, 0, 255, 0.5)'
          
        }
      }),
      overlay: {},
    }}
   
>
    <h2 className="title-of-page"> Lab Users List </h2>
    <div className="quick-links">
      <Link to="/add-labuser"><button type="button" className="btn btn-primary btn-col lab-btn"> + Add Lab User to List </button></Link>

    </div>

    {labUsersList && labUsersList.length !== 0 ? (<div>
      <div className="drs-container">
        {labUsersList.map((data, index) => {
          return <LabUser key={index} data={data}  handle={refreshHandle} />
        })}
      </div>

      <div className="quick-links">


      </div>

    </div>)
      :
      <h5 style={{ color: "red", margin: "20px" }}>Lab Users List is empty </h5>
    }

    {errorMsg === "error" ?
      <h5 style={{ color: "red" }}>

        Error in data fetching.Please try again!
      </h5>
      :
      null}
 
  </LoadingOverlay>
  </div>
  );
}

export default LabUserList;