import React, {useState,useEffect} from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
function AddRemark(props) {
    const [remarkData, setRemarkData]= useState('')
    const [comments, setComments] = useState([])
    const[refresh,setRefresh]=useState(false)
    let data = remarkData
       props.update(data)
    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");
    
        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
    
        axios.get(`${APIURL}/api/v1/patient/sr-assignee-track-order-comments/?order_id=${props.id}`                                                                                                                                                                                                                                                                                                                                                                                                                                      , {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    setComments(res.data.comments)
                   
                }
                else {
    
                }
            })
            .catch((err) => {
    
            })
    
    }, [refresh])
    const callSave = (rowToUpdate) => {
       
          const tokenString = sessionStorage.getItem("usertoken");
    
          let str = tokenString.replace(/["]+/g, "");
         
          let remarks=remarkData
          
           
          const dataToUpdate = {
            remarks,
          
          };
    
          axios
            .put(
              `${APIURL}/api/v1/service-provider/service-request-order-update/${props.id}/`,
              dataToUpdate,
              {
                headers: {
                  Authorization: "Token " + str,
                },
              }
            )
            .then((res) => {
              if (res.data.status === "success") {
              setRefresh(!refresh)
               setRemarkData("")
              } else {
                
              }
            })
            .catch((err) => {
            
            });
        
      };

    return (
        <div className='flex-col'>  
        <h4>{props.order}</h4>    
          <small >Patient Name :{props.patient}</small>
                <textarea 
                type="text" 
                className="form-group text-area remark-add"
                value= {remarkData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setRemarkData(e.target.value)
          } }
            autoComplete="off"
                />
            <button disabled={remarkData===''} onClick={callSave} className='btn btn-primary btn-col'>Save</button>
            <div className='seperate-content' />
            {comments.map(({name, comment}) => {
                return <>
                <div className='list-item ' style={{display:"flex" ,padding:"5px",marginBottom:"5px",marginTop:"5px"}}>
                    <label  style={{color:"#E985E3"}}className=''>{name}:</label>
                    <p>{comment}</p></div>
                    </>
            })
            }
        </div>
        
    )
}

export default AddRemark
