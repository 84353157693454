
import React,{useState,useEffect} from "react";
import "./createappuser.css";
import axios from 'axios';

import { Link, useParams,useLocation} from "react-router-dom";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
//import HospitalNavbar  from "../HospitalNavbar/HospitalNavbar";
import {APIURL} from "../../../Global";
import BackButton from "../../common/BackButton";
function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>User credentials created successfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        

        <Link to="/nurse-list"><Button className='btn btn-primary btn-col' > Goto Nurse List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

function CreateAppUserNurse(props) {
  
  const location = useLocation()
  const {id} =useParams();
 //alert(location.state)
 const { state } = useLocation()
   const name = state.name;
 //const {hospital_username} =useParams();
  
     const [hospData,] =useState(JSON.parse(sessionStorage.getItem('logindatas'))? JSON.parse(sessionStorage.getItem('logindatas')) : null);
    const [modalShow, setModalShow] = useState(false);
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const [passwordType, setPasswordType] = useState("password");
   const [passwordType1, setPasswordType1] = useState("password");
    const [confirmPassword, setConfirmPassword] = useState(null);
   
    const [error,setError]=useState({
    username:"",
    password: '',
    confirm_password:"",
   
   });
   const [submitMsg, setSubmitMsg]= useState("");
  // const id = props.match.params.id;
  //const name = props.data.name;
 
const hospital_username =  hospData.hospital_username;

 // const history =useHistory();

// const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});


// useEffect(()=>{

// window.onkeydown =(e) => {
 
//  keys[e.key]=true

//  if(e.altKey && keys['d']){
//    history.push('/doctors-list')
//  }
//  if(e.altKey && keys['a']){
//    history.push('/hospital/add')
//  }
//  if(e.altKey && keys['b']){
//    history.push('/bdo-list')
//  }
//  if(e.altKey && keys['o']){
//    history.push('/hospital/add-bdo')
//  }
//  if(e.altKey && keys['n']){
//    history.push('/emergency-number')
//  }
//  if(e.altKey && keys['s']){
//    history.push('/service-request-list')
//  }
//  if(e.altKey && keys['l']){
//    history.push('/license-details')
//  }
//  if(e.altKey && keys['p']){
//    history.push('/license-payment')
//  }
//  if(e.altKey && keys['c']){
//    history.push('/hosp-calllog')
//  }
//  if(e.altKey && keys['h']){
//    history.push('/payment-history')
//  }

// }
// window.onkeyup=(ev)=> {
 
//  keys[ev.key]= false
// }

// return()=>{
//  window.onkeydown=null
//  window.onkeyup=null
// }
// },[]);

   const handleUserNameChange =(e)=>{
   
   	setUsername(e.target.value);
   }
   const togglePasswordVisiblity = (e) =>
   {
   
     if(passwordType==="password")
     {
      setPasswordType("text")
      return;
     }
     setPasswordType("password")
   }
   const togglePasswordVisiblity1 = (e) =>
   {
   
     if(passwordType1==="password")
     {
      setPasswordType1("text")
      return;
     }
     setPasswordType1("password")
   }
    const handlePasswordChange =(e)=>{

   	setPassword(e.target.value);
   }
   const handleConfirmPassword =(e)=>{

    setConfirmPassword(e.target.value);
   }
   const handleSubmit=async (e)=>{
    e.preventDefault();
    if(await validate()){
    callSubmit();

  }
}

const validate=async ()=>{
   
      let errors = {};
      let isValid = true;
    setError({});

 if (!username || username==="" || username===null) {
        isValid = false;
        errors["username"] = "Please enter username prefix";
      }
  

      if (username !== "undefined") {
        var pattern1 = new RegExp(/^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){0,18}[a-zA-Z0-9]$/);
        if (!pattern1.test(username) || username.length < 6) {
          isValid = false;
          errors["username"] = "Special Characters and space are not allowed in username and Characters limit is 6-20";
        }
      }


 if (typeof password !== "undefined") {
          

      let pattern = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!#^%*?&])([a-zA-Z0-9@$#^!%*?&]{8,})$/);
      if (!pattern.test(password)) {
        isValid = false;
        errors["password"] = "Password must contain at least 1 UPPERCASE, 1 lower case, 1 number, 1 special character(@$!#^%*?&) and don't use space";
      }
    }
      if (!password || password==="" || password===null) {
        isValid = false;
        errors["password"] = "Please enter password";
      }
  
      if (!confirmPassword || confirmPassword==="" || confirmPassword=== null || typeof confirmPassword === "undefined") {
        isValid = false;
        errors["confirm_password"] = "Please enter confirm password";
      }
  
      if (password && typeof password !== "undefined") {
        if(password.length >0 && password.length < 8){
            isValid = false;
            errors["password"] = "Password must contain atleast 8 characters";
        }
      }
  
      if (typeof password !== "undefined" && password!=="" && password!==null && typeof confirmPassword !== "undefined" && confirmPassword!=="" && confirmPassword!== null) {
       if (password !== confirmPassword) {
          isValid = false;
          errors["confirm_password"] = "Passwords don't match";
        }
      }
     
        


    
       await setError(currentstate=>({
            ...currentstate,
            ...errors
          }));
      

      return isValid;
  }


   const callSubmit=()=>{

   
   	const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); 

    
  const data = {
  	username: username+"@"+hospital_username,
  	password : password
  }

    axios.post(`${APIURL}/api/v1/service-provider/nurse-detail/${id}/`, data, {
        headers: { 'Content-Type': 'application/json',
                     Authorization:  "Token "+v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setModalShow(true);
        

          
        } else {
        
          setSubmitMsg("Username already exists!");
        }
      })
      .catch((err) => {
       
         setSubmitMsg("error");
      });
  }

   
 return( 
 <>


 <div className="liner">

  </div>
    <div className="container">
       
    <h4 className="title-of-page">Nurse User Credentials </h4>
       <form className="create-app-user-form col-lg-6" onSubmit={handleSubmit}>
       
         <h5 className="dr-details title-of-tasks"> Name : {name} </h5>
          <h5 className="dr-details title-of-tasks"> Nurse ID : {id} </h5> <br/>
       <div className="input-group mb-3">
          <input className="form-control" type="text" maxLength='30' placeholder="*Username" value={username} onChange={handleUserNameChange} />
          <div className="input-group-append">
    <span className="input-group-text postfix-username" id="basic-addon2">@{hospital_username}</span>
  </div>
   {error.username ?<div className="error-validation-msg"> {error.username}</div>: null} 
         </div>
         <div className="form-group ">
           <div className="form-control" style={{backgroundColor:"rgb(232, 240, 254)"}}>
            
           <input className="box1"  type={passwordType} maxLength='30' placeholder="*Password" value={password} onChange={handlePasswordChange}  />
           
           <span style={{float:"right"}}>
 
                     { passwordType==="password"? <i onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> :<i onClick={togglePasswordVisiblity} className="fa fa-eye"></i> }
                    </span>
          
            </div>
            {error.password ?<div className="error-validation-msg"> {error.password}</div>: null} 
         </div>

          <div className="form-group ">
            <div className="form-control "style={{backgroundColor:"rgb(232, 240, 254)"}}>
           <input className="box1"  type={passwordType1} maxLength='30' placeholder="*Confirm Password" value={confirmPassword} onChange={handleConfirmPassword} />
           <span style={{ float: "right" }}>

{passwordType1 === "password" ? <i onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity1} className="fa fa-eye"></i>}
</span>

       
        </div>       
        {error.confirm_password ?<div className="error-validation-msg"> {error.confirm_password}</div>: null}                     
         </div>
         {submitMsg!== "" ?
       <h5 style={{ color: "red" }}>
            
         {submitMsg}
          </h5>
      :
      null}

  <div className="form-button ">
          <button type="submit" className="btn btn-primary btn-col">Generate User Credentials </button>
  </div>







       </form>

        <SubmitPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    

    </div>
 </>
);
}
export default CreateAppUserNurse;