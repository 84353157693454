import React ,{Component,useEffect} from 'react';
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import "../staff/pagination.css" ;
import './feedback.css'
import axios from "axios";
import { Tooltip , OverlayTrigger } from "react-bootstrap";
import {APIURL} from '../../Global'
import {Link as Scroll} from 'react-scroll'
//import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";

// let prev =['']
// let j=0

// const menu = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z'.split(',')

class BdoFeedback extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             patientdata : [] ,
             searchTerm : '',
             currentPage : 1,
             perPage : 10,
             expand:"" ,
             keys : {
              'a': false,
              'n': false,
              'm': false,
              'l': false,
              's': false
             } 
        }
    }

    
  handleBack=(e)=>{
    this.props.history.goBack();
}
    componentDidMount(){
        window.scrollTo(0, 0);    
        const tokenString = sessionStorage.getItem("usertoken");


  
      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */


        axios
        .get(`${APIURL}/api/v1/service-provider/sr-feedbacks/`, {
          headers: { Authorization: "Token "+ v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            const data = res.data.message
            this.setState({patientdata : data})
            
        }
        else{

        }
    }).catch((error)=>{

    })

    
    //////* Shortcuts ********/ 

    window.onkeydown =(e) => {
    
      this.state.keys[e.key]=true

      if(e.altKey && this.state.keys['a']){
        this.props.history.push('/appointment')
      }
      if(e.altKey && this.state.keys['n']){
        this.props.history.push('/doctor/notifications')
      }
      if(e.altKey && this.state.keys['m']){
        this.props.history.push('/message')
      }
      if(e.altKey && this.state.keys['l']){
        this.props.history.push('/doctor/analytics')
      }
      if(e.altKey && this.state.keys['s']){
        this.props.history.push('/service-requestlist')
      }
  
  
    }
    window.onkeyup=(ev)=> {
      
      this.state.keys[ev.key]= false
    }
}

componentWillUnmount(){ 
  window.onkeydown=null
  window.onkeyup=null
}


    handleSearchChange = (event)=> {
      this.setState({currentPage:1})
        this.setState({searchTerm: event.target.value})
    }

    handlePageChange = (pageNumber)=> {
      this.setState({currentPage:pageNumber})
    }

    render() {
        const{patientdata ,searchTerm, currentPage, perPage }=this.state

        let data =   patientdata.sort((a,b)=>{
          if(a.patient_name.toLowerCase() < b.patient_name.toLowerCase()) return -1;
          if(a.patient_name.toLowerCase() > b.patient_name.toLowerCase()) return 1;
          return 0;
      })
      .filter((value)=>{
        if(this.state.searchTerm===''){
            return value
        } else if (value.patient_name.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
            return value
        }
    })

    const handleDetail = (index) => {
      " ";
      if (this.state.expand !== index) {
        this.setState({expand:index})
      } else {
        this.setState({expand:""})
      }
    };
 
    // const renderTooltip2 = (props) => (
    //   <Tooltip className="my-tooltip" id="button-tooltip" {...props}>
    //   this.setState({flag:true})
    //   </Tooltip>
    // );


        const indexLast= currentPage * perPage;
        const indexFirst= indexLast - perPage;
        const current = data.slice(indexFirst ,indexLast);            
        const total = patientdata.length;

        const pageNumbers = []
        for(let i=0 ;i<= Math.ceil(total/perPage) ;i++){
          pageNumbers.push(i)
        }
        const totalPages=pageNumbers.length;

        const display = current.map((val,index)=> ({
          name: val.patient_name.charAt(0).toLowerCase(),
          id: val.id
        })                   
      )

      const unique = []
      display.map(x => unique.filter(a => a.patient_name == x.patient_name).length > 0 ? null : unique.push(x));

        return (<>
        
          <div className=" text-left">
        <br/>
          {/* <button className="btn btn-info back-btn" onClick={this.handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button>  */}
    </div> 
          
            <div className="search-patient-page directory-page">

                <h2 className=" title-of-tasks"> Customer Feedback Centre </h2><br/>
        <div className="search-section">
  
       
            <div className="search-div">
            <form style = {{display:"flex"}}>
              <input
                className="form-control search-input"
                type="text"
                placeholder="Enter Name"
                value={searchTerm}
                maxLength="150"
                onChange={this.handleSearchChange}
              />
              <button  className="btn btn-primary btn-col search-btn">
                {" "}
                <i className="fas fa-search"></i>{" "}
              </button>     </form>   </div>
          <div className="container search-results">  
          {
          current.length>0?
            current.map((value,index)=>{

              return(
              // <Link to={{pathname:`/patient-record/${value.id}`, hospId:value.hospital_patient_id}}>
                  <div key ={index} className='list-item'  onClick={() => handleDetail(index)}>               
                      <h5 className="each-list-link"  id={value.id} key={value.id}>{(currentPage-1)*10 + index+1}.{value.patient_name}.{ <OverlayTrigger
              trigger="hover"
              rootClose
              placement="top"
              overlay={
                <Tooltip className="my-tooltip" id="button-tooltip">
                 Feedback : {""}
                 {value.feedback}
                </Tooltip>
              }
            >
              <i
                style={{
                  color: "#75759c",
                  paddingLeft: "85%",
                  paddingTop: "1%",
                }}
                className="fas fa-commenting"
              ></i>
            </OverlayTrigger>}</h5>  
                      <div className="details">
                      
                      <span style={{color:"white"}}>Service: {value.service_name}  </span> 
 <b style={{float:"right"}}>Feedback: {value.feedback} </b>

               {this.state.expand ===index ? (
                <>


<span style={{width:"50%" , marginTop:"5%"}}>
                        <p> OrderID: {value.sr_order}   </p>
                        <p> Status : {value.request_status} </p></span>
</>
             ) : null}
                   
                  
 
                      </div>      
                                              
                  </div>

                 
                // </Link>
              )          
            }) : <h6 style={{color:"red"}}>No Patients Found</h6>
          }
         <span className="scroll-class">

         {
                unique.map(val => {
                  return (
                    <div >
                              {searchTerm==="" ? 

                  <Scroll  activeClass="active" to={val.id} spy={true} offset={-80} smooth={true} duration={750}> <button class ='btn btn-small btn-secondary btn-square btn-letter' style={{position:"relative",bottom:"150px",right:"500px"}}>{val.name}</button></Scroll>

                              : null

                              }
                              </div>
                  )
                })
              }

          {/* {
          current.length > 0 ? 
            current.map((val)=>{       

              prev[j] = val.name
              j++
            
              for(let i=0 ; i<menu.length;i++){
                  if(j>1){
                    if(prev[j-2].charAt(0).toLowerCase()===val.name.charAt(0).toLowerCase()){
                      if(current[0].name.toLowerCase()===val.name.toLowerCase()){
                        if(val.name.charAt(0).toLowerCase()===prev[j-2].charAt(0).toLowerCase()){
                          if(menu[i] === val.name.charAt(0).toLowerCase()){                           
                            
                          return(
                                               
                              <div >
                              {searchTerm==="" ? 
                               <Scroll activeClass="active" to={val.id} spy={true} offset={-80} smooth={true} duration={450}> <button class ='btn btn-small btn-secondary btn-square btn-letter' style={{position:"relative",bottom:"150px",right:"500px"}}>{menu[i]}</button></Scroll>
                              : null

                              }
                              </div>
                                                  
                                )
                            }
                      }
                        }
                        
                      else{
                        continue
                      }
                    }
                  }                                       
                  if(menu[i] !== val.name.charAt(0).toLowerCase()){
                      continue                                                          
                  }
                  
                  else if(menu[i] === val.name.charAt(0).toLowerCase()){
                      
                          return(
                                               
                              <div >
                              {searchTerm==="" ? 
                               <Scroll  activeClass="active" to={val.id} spy={true} offset={-80} smooth={true} duration={750}> <button class ='btn btn-small btn-secondary btn-square btn-letter' style={{position:"relative",bottom:"150px",right:"500px"}}>{menu[i]}</button></Scroll>
                              : null

                              }
                              </div>
                                                  
                                )  
                      } 
                  }                  
              }
          ) : null
        } */}
        </span>
          </div>
              
            </div>
            <br/>
            <br/>
            {totalPages > 0 ?
    <div className="pagn">

            <Pagination
          activePage={currentPage}
          itemsCountPerPage={perPage}
          totalItemsCount={total}
          pageRangeDisplayed={totalPages}
          onChange={this.handlePageChange}
          disabledClass = "disabled-class" 
          />
    </div>
    : null}
        </div>
        </>
        )
    }
}

export default BdoFeedback;