import React, { useState } from 'react'
import Select from 'react-select';
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import "./featurelist1.css";
function Addmedicine(props) {

  let formData =new FormData();
  const [image, setImage] = useState(null);

  const [data, setData] = useState({

    medicinename: '',
    genericname: '',
    strength: '',
    composition:'',
    medicinetype: { value: '', label: '' },
    dosage_type: { value: '', label: '' },
    medicinecode: '',
    routine: { value: '', label: '' },
    description: '',
    manufacturername: '',
    strength_unit:{ value: '', label: '' }

  })

  const [error, setError] = useState({
    medicinename: '',
    genericname: '',
    strength: '',
    medicinetype: '',
    dosage_type: '',
    strength_unit:'',
    medicinecode: '',
    routine: '',
    composition:''


  });

  const [successShow, setSuccessShow] = useState(false)
  const [errorShow, setErrorShow] = useState(false)
  const [completeShow, setCompleteShow] = useState(false)
  const [errorValidate, setErrorValidate] = useState(false)
  const DosageOptions = [{ value: 'tablets', label: 'TABLETS' },
  { value: 'capsules', label: 'CAPSULES' },
  { value: 'chewable tablets', label: 'CHEWABLE TABLETS' },
  { value: 'powders', label: 'POWDERS' },
  { value: 'solutions', label: 'SOLUTIONS' },
  { value: 'emulsions', label: 'EMULSIONS' },
  { value: 'suspensions', label: 'SUSPENSIONS' },
  { value: 'lotions', label: 'LOTIONS' },
  { value: 'creams', label: 'CREAMS' },
  { value: 'ointments', label: 'OINTMENTS' },
  { value: 'effervescent granules', label: 'EFFERVESCENT GRANULES' },
  { value: 'aerosols', label: 'AEROSOLS' },
  { value: 'gases', label: 'GASES' },
  { value: 'suppositories', label: 'SUPPOSITORIES' },
  { value: 'injections', label: 'INJECTIONS' }
  ]

  const RouteOptions = [{ value: 'oral', label: 'ORAL' },
  { value: 'topical_skin', label: 'TOPICAL_SKIN' },
  { value: 'topical-eyes', label: 'TOPICAL_EYE' },
  { value: 'topical_ears', label: 'TOPICAL_EARS' },
  { value: 'inhalation', label: 'INHALATION' },
  { value: 'injections', label: 'INJECTIONS' },
  { value: 'vaginal', label: 'VAGINAL' },
  { value: 'anal', label: 'ANAL' },
  { value: 'sub_lingual', label: 'SUB_LINGUAL' }

  ]

  const Strengthoptions = [{ value:'mg', label:'mg' },                       
{ value:'g', label:'g' },
{ value:'ml', label:'mL' },
{ value:'mcg', label:'mcg' },
{ value:'mEq', label:'mEq' },
{ value:'iu', label:'IU' },
]

  const medicinclasses = [{ value: 'painkillers', label: 'painkillers' },
  { value: 'Antipyretics', label: 'Antipyretics' },
  { value: 'AntiMalarial', label: 'AntiMalarial' },
  { value: 'Antibiotics', label: 'Antibiotics' },
  { value: 'Antiseptics', label: 'Antiseptics' },
  { value: 'Mood Stabilizers', label: 'Mood Stabilizers' },
  { value: 'Premarin', label: 'Premarin' },
  { value: 'Enovid', label: 'Enovid' },
  { value: 'Stimulants', label: 'Stimulants' },
  { value: 'Tranquilizer', label: 'Tranquilizer' },
  { value: 'statins', label: 'statins' },
  { value: 'Anesthetics', label: 'Anesthetics' },
  { value: 'Blood glucose regulators', label: 'Blood glucose regulators' },
  { value: 'Contraceptives', label: 'Contraceptives' },
  { value: 'Dermatological agents', label: 'Dermatological agents' },
  { value: '	Hormonal agents', label: '	Hormonal agents' },
  { value: 'Respiratory tract agents', label: 'Respiratory tract agents' },
  { value: 'Skeletal muscle relaxants', label: 'Skeletal muscle relaxants' },
  { value: 'Blood products', label: 'Blood products' },
  { value: 'Antivirals', label: 'Antivirals' },
  { value: 'Antifungals', label: 'Antifungals' },
  { value: 'Ophthalmic agents', label: 'Ophthalmic agents' },
  { value: 'other', label: 'other' }
  ]

  const customStyles = {
    control: base => ({
      ...base,
      width: 150,
      minHeight: 35
    })
  };
  const CompletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-success'> Successfully Saved</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-secondary' onClick={props.onHide}>Cancel</Button>


        </Modal.Footer>
      </Modal>
    );
  }



  const handleError = () => {
    setErrorValidate(false);
    setSuccessShow(false)
  }

  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>check data duplication or not</h5>

        </Modal.Body>
        <Modal.Footer>


          <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }

  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setData(current => ({
      ...current, [key]: val
    }))
  }



  const handleServiceChange = (data) => {

    setData(current => ({
      ...current, dosage_type: data
    }))
  }

  const strengthChange = (data) => {

    setData(current => ({
      ...current, strength_unit: data
    }))
  }

  const handleMedicineType = (data) => {

    setData(current => ({
      ...current, medicinetype: data
    }))
  }

  const handleUnitChange = (data) => {

    setData(current => ({
      ...current, routine: data
    }))
  }


  const validate = () => {

    let input = data;
    let errors = {};
    let isValid = true;
    setError({});


    if (!input["medicinename"] || input["medicinename"] === "") {
      isValid = false;
      errors["medicinename"] = "Please enter Medicine Name";
    }
    if (!input["genericname"] || input["genericname"] === "") {
      isValid = false;
      errors["genericname"] = "Please enter generic name of medicine";
    }
    if (!input["strength"] || input["strength"] === "") {
      isValid = false;
      errors["strength"] = "Please enter strength of medicine";
    }
    if (!input["medicinetype"].value || input["medicinetype"].value === "") {
      isValid = false;
      errors["medicinetype"] = "Please select medicine type";
    }
    if (!input["dosage_type"].value || input["dosage_type"].value === "") {
      isValid = false;
      errors["dosage_type"] = "Please enter dosage type";
    }
    if (!input["strength_unit"].value || input["strength_unit"].value === "") {
      isValid = false;
      errors["strength_unit"] = "Please enter strength unit";
    }
    if (!input["medicinecode"] || input["medicinecode"] === "") {
      isValid = false;
      errors["medicinecode"] = "Please enter medicine code";
    }
    if (!input["routine"].value || input["routine"].value === "") {
      isValid = false;
      errors["routine"] = "Please enter route of medicine";
    }
    if (!input["composition"]|| input["composition"] === "") {
      isValid = false;
      errors["composition"] = "Please enter composition of medicine";
    }



   

    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));


    return isValid;
  }

  const saveChanges = (e) => {
    debugger;
    e.preventDefault();
    
   
    if (validate()) {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      const dataToSend = {
        med_name: data.medicinename.slice(0,1).toUpperCase() +data.medicinename.slice(1, data.medicinename.length),
        generic_name: data.genericname,
        strength: data.strength,
        strength_unit:data.strength_unit.value,
        med_type: data.medicinetype.value,
        dosage_form: data.dosage_type.value,
        med_code: data.medicinecode,
        route: data.routine.value,
        description: data.description,
        composition:data.composition,
        manufacturer_name: data.manufacturername


      }
      const formData = new FormData();
      if (image) {
        formData.append('image', image);
      }
    Object.keys(dataToSend).forEach(key => formData.append(key, dataToSend[key]));

      axios
        .post(`${APIURL}/api/v1/staff/master-medicines/`, formData, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setCompleteShow(true);

            setData({
              medicinename: '',
              genericname: '',
              composition: '',
              strength: '',
              medicinetype: { value: '', label: '' },
              dosage_type: { value: '', label: '' },
              strength_unit:{ value: '', label: '' },
              medicinecode: '',
              routine: { value: '', label: '' },
              description: '',
              manufacturername: ''
            });
          } else {
            setErrorShow(true)
          }
        })
        .catch((err) => {
          setErrorShow(true)
        });
      setSuccessShow(false)
    }

    else {
      setErrorValidate(true)
    }

  }

  const handleImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };


  return (
    <>
      <h4 style={{ marginTop: '0' }} className="title-of-page">Add Medicine</h4>
      <div style={{display:"flex" ,marginLeft:"380px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {image && (
         <div style={{ display: 'flex' }}>
          <p>Selected Image:</p>
          <img src={URL.createObjectURL(image)} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
      <div className='fit-content'>
        <div className='feature-data pad-features'>
          <div className='flex-col justify-feature'>
            <span className='align-rental'>*Medicine Name : </span>
            <input type='text' className='form-control title-flex' name='medicinename' value={data.medicinename} onChange={handleTitle} />
            {error.medicinename ? <div className="error-validation-msg error-feature-inventory">{error.medicinename}</div> : null}
          </div>

          <div className='flex-col justify-feature'>
            <span className='align-rental'>*Generic Name : </span>
            <input type='text' className='form-control title-flex' name='genericname' value={data.genericname} onChange={handleTitle} />
            {error.genericname ? <div className="error-validation-msg error-feature-inventory">{error.genericname}</div> : null}
          </div>
          <div className='flex-col justify-feature'>
            <span className='align-rental'>*Strength: </span>
            <input type='text' className='form-control title-flex' name='strength' value={data.strength} onChange={handleTitle} />
            {error.strength ? <div className="error-validation-msg error-feature-inventory">{error.strength}</div> : null}
          </div>
          <div className='flex-row name-content' style={{marginLeft:"-1%", width:"47%"}} >
                <span className='align-rental'>*Strength Unit: </span>
                <Select
                           value={data.strength_unit}
                            onChange={(data,e)=>{strengthChange(data,e)
                            }}
                            options={Strengthoptions}
                            name='strength_unit'
                            className="select-currency select-flex"
                        />
                      {error.strength_unit ? <div className="error-validation-msg error-feature-inventory">{error.strength_unit}</div> : null}
                </div>

          <div className='flex-col justify-feature'>
            <span className='align-rental'> *Medicine Code : </span>
            <input type='text' className='form-control title-flex' name='medicinecode' value={data.medicinecode} onChange={handleTitle} />
            {error.medicinecode ? <div className="error-validation-msg error-feature-inventory">{error.medicinecode}</div> : null}
          </div>

          <div className='flex-col justify-feature'>
            <span className='align-rental'> Description : </span>
            <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
            {/* {error.description ? <div className="error-validation-msg error-feature-inventory">{error.description}</div> : null} */}
          </div>
          <div className='flex-col justify-feature'>
            <span className='align-rental'> Manufacturer Name : </span>
            <input type='text' className='form-control title-flex' name='manufacturername' value={data.manufacturername} onChange={handleTitle} />
            {/* {error.manufacturername ? <div className="error-validation-msg error-feature-inventory">{error.manufacturername}</div> : null} */}
          </div>
          <div className='flex-col justify-feature' >
                    <span className='align-rental'>*Composition: </span>
                    <input type='text' className='form-control title-flex' name='composition' value={data.composition} onChange={handleTitle} />
                    {error.composition? <div className="error-validation-msg error-feature-inventory">{error.composition}</div> : null}
                </div>
          <div className='flex-col justify-feature'>
            <span className='align-rental'>*Medicine Type : </span>
            <Select
              value={data.medicinetype}
              onChange={(data, e) => {
                handleMedicineType(data, e)
              }}
              options={medicinclasses}
              name='medicinetype'
              className="select-currency select-flex1"
              styles={customStyles}
            />
            {error.medicinetype ? <div className="error-validation-msg error-feature-inventory">{error.medicinetype}</div> : null}
          </div>
          <div className='flex-row name-content' style={{marginLeft:"-1%"}}>
            <span className='align-rental'>*Dosage: </span>
            <Select
              value={data.dosage_type}
              onChange={(data, e) => {
                handleServiceChange(data, e)
              }}
              options={DosageOptions}
              name='dosage_type'
              className="select-currency select-flex"
            />
            {error.dosage_type ? <div className="error-validation-msg error-feature-inventory">{error.dosage_type}</div> : null}
          </div>

          <div className='flex-row name-content' style={{marginLeft:"18%"}}>
            <span className='align-rental'>*Route: </span>
            <Select
              value={data.routine}
              onChange={(data, e) => {
                handleUnitChange(data, e)
              }}
              options={RouteOptions}
              name='routine'
              className="select-currency select-flex"
            />
            {error.routine ? <div className="error-validation-msg error-feature-inventory">{error.routine}</div> : null}
          </div>


        </div>


      <div className='text-center'>
        <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>

      </div>


      {errorShow ?

        <ErrorPopUp
          show={errorShow}
          onHide={() => { setErrorShow(false) }}
        />
        : null}


      {completeShow ?

        <CompletePopUp
          show={completeShow}
          onHide={() => {
            setCompleteShow(false)
            props.modalClose()
          }}
        />
        : null}

    </div>
    </>
  )
}

export default Addmedicine
