import React, { useEffect, useState } from "react";
import axios from "axios";
import "./addcart.css";
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../Global";
import Pagination from "react-js-pagination";
import "./pagination.css";
import { Modal } from "react-bootstrap";
import SubServiceData from "./SubServiceData";
import PreviewPage from "./PreviewPage";
import del from "../../assets/images/del.png";
import delivery from "../../assets/images/delivery.png";
import LoadingOverlay from "react-loading-overlay";
import { Button } from "react-bootstrap";
import cart from "../../assets/images/icons/cart.png";

function CartViewPage({ patient_id, name, sCharge, reRender,dis1 }) {
  ;

  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [repeat, setRepeat] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  // const[checkboxlist,setcheckboxlist] = useState
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const patient_name = name;
  const id = patient_id;
  const render = reRender;
  const [serviceName, setServiceName] = useState("");
  const [cartlength, setCartLength] = useState();
  const [shipcharege, setShipCharge] = useState();
  const [expand, setExpand] = useState();
  const [successShow, setSuccessShow] = useState(false);
  const [previewCart, setPreviewCart] = useState(false);

  const [flag, setFlag] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [checkShow, setCheckShow] = useState(false);
  const [showExistModal, setShowExistModal] = useState(false);
  const [showNoItem, setShowNoItem] = useState(false);
  const [deletedItems, setDeletedItems] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);

  const addCart = () => {
    ;

    let repeat;
    setRepeat(null);
    serviceList.forEach((service, index) => {
      serviceList.forEach((item, ind) => {
        if (index !== ind) {
          if (item.item_service_name.toLowerCase() === service.item_service_name.toLowerCase() && item.net_total_item_amount ===service.net_total_item_amount
          &&
                    //  item.item_quantity.toString() == service.item_quantity.toString() && 
                     item.item_due_date.toString() === service.item_due_date.toString() && !item.id && !repeat)
                     {

          
            repeat = item;
            setRepeat(item);
          }
        }
      });
    });
    // console.log("THE Repeat item", repeat);
    if (!repeat) {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(/["]+/g, "");

      const dataTosend = [];

      serviceList.forEach((item) => {

        console.log("THE ITEM", item);
        if (!item.id) {
          let data = {
            item_service: item.item_service,
            item_due_date: item.item_due_date,
            //item_quantity: item.item_quantity
          };
          if (item.geo_distance) {
            data.geo_distance = item.geo_distance;
          }
          if (item.item_quantity !== "") {
            data.item_quantity = parseInt(item.item_quantity);
          }
          dataTosend.push(data);
        }
      });
      // render(dataTosend);

      axios
        .post(
          `${APIURL}/api/v1/staff/add-to-cart/?patient_id=${patient_id}`,
          dataTosend,
          {
            headers: { Authorization: "Token " + v2 },
          }
        )
        .then((res) => {


          if (res.status === 200) {


            setSuccessShow(true);

            //setFlag(true);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setShowExistModal(true);
    }
  };

  useEffect(() => {

    loadData();

  }, [])
  const loadData = () => {

    //   setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/staff/add-to-cart/?patient_id=${id}`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        // " " ;
        if (res.data.status === "success") {
          // setLoading(false);

          const datas = res.data.cart_items;
          setCartLength(res.data.cart_items.length)
          setServiceList(datas);
          console.log("datas", serviceList);
          setDisplay(true);
          setShowSubmit(false);
        } else {
          //  setLoading(false);
          setShowSubmit(false);
          setDisplay(false);
        }
      })
      .catch((err) => {
        // setLoading(false);
        setShowSubmit(false);
      });
  };

  useEffect(() => {
;
    // let "general_services"
    if (render && render.item_service) {

      let list = serviceList;
      let data = {
        item_due_date: render.item_due_date,
        item_quantity: render.item_quantity,
        item_servicetype: render.item_service.service_type,
        item_service_name: render.item_service.service_name,
        item_service: render.item_service.id,

        net_total_item_amount:
          render.item_service.service_type === "consumables"
            ? render.item_service.amount * render.item_quantity
            : render.item_service.amount,
        shipping_charge: sCharge,
        isChecked: false,
      };
      if (render.geo_distance) {
        data.geo_distance = render.geo_distance;
      }
      list.push(data);
      setShipCharge(data.shipping_charge)
      setServiceList(list);

      //setDisplay(true);
      setShowSubmit(true);
      setCheckShow(true);
    } else {
      loadData();
    }
  }, [render]);

  let sum = serviceList.reduce(function (prev, current) {
    console.log(prev, current.net_total_item_amount, current.shipping_charge);
    return parseFloat(
      parseFloat(prev) + current.net_total_item_amount + current.shipping_charge
    ).toFixed(2);
  }, 0);
  console.log(sum);

  // const handleRemoveItem = (index) => {
  //   // assigning the list to temp variable
  //   const temp = [...serviceList];

  //   // removing the element using splice
  //   temp.splice(index, 1);

  //   // updating the list
  //   setServiceList(temp);
  // };
  const PreviewPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-preview"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ alignSelf: "center", height: "20%",marginLeft:"5%" }}>
          <PreviewPage
            patient_id1={patient_id}
            name1={name}
            data={serviceList}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitPopUp1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success">Cart Submitted Successfully</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-success">
            Are you sure want to delete these items?
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary btn-col"
            onClick={() => {
              setDeleteShow(false);
              deleteSelected();
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ExistModal = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ color: "red" }}>Service: {repeat.item_service_name}</h4>

          <h4 style={{ color: "red" }}>Already exist</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "green" }}>{deleteMsg}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const NoItems = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ color: "red" }}>
            Please select items to delete using checkbox
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
    } else {
      setExpand();
    }
  };

  const handleOnChange = (index) => {
    let data = serviceList;
    console.log("THE INDEX", data[index]);
    data[index].isChecked = !data[index].isChecked;
    setServiceList(data);
  };

  const deleteSelected = () => {
    let isChecked = false;
    let list = serviceList;
    list.forEach((item) => {
      if (item.isChecked) {
        isChecked = true;
      }
    });
    if (isChecked) {
      let newList = [];
      list.forEach((item) => {
        if (item.isChecked) {
          list.pop();
        }
      });
      console.log("THE ITEMS, newList", list);
      setServiceList([]);
      setTimeout(() => {
        setServiceList(list);
      }, 300);
      setDisplay(true)
     // loadData();
      // setDeleteMsg("Deleted Successfully")
      //setDeleteShow1(true)
    } else {
      setShowNoItem(true);
    }
  };


  let display1 =
    serviceList.length > 0
      ? serviceList.map((data, index) => {

        return (
          <>
            <div className='container' style={{ marginBottom: "3px", marginTop: "3px", boxShadow: "none", paddingLeft: "1px",}}>
            
              <div class="row" style={{ display:"flex",backgroundColor:"#e5eed5",width:"95%", marginLeft:"8px",borderRadius: "10px"}} >
                <div class="col-1" style={{ color: "#E985E3", textAlign: "left", }}>
                  {index>=cartlength ? <>

                    <input
                      type="checkbox"
                      style={{ marginLeft:-2,cursor:"pointer"}}
                      onClick={() => handleOnChange(index)}
                      value={data.isChecked}
                    /> </> : null}
                </div>
                <div class="col1-3" style={{maxWidth:"50%"}}>
                  {data.item_service_name}
                </div> 
                <div class="col-2">
                  {data.service_type === "consumables" ? <div >
                    {data.item_quantity}
                  </div> : null}
                </div>
                {/* <div class="col-4">
                  <i class="fa fa-inr" ></i>
                  {parseFloat(
                    data.net_total_item_amount + data.shipping_charge
                  ).toFixed(2)}
                </div> */}
                <small 
              style={{paddingBottom:"10px", float:"right", textAlign:"centre",paddingRight:"15px" , color :"black",marginLeft:"-30px", display:"flex",marginTop:"1%"}}
                >
                  <span style={{ flex: -2, marginLeft: 51 }}>
                  {" "}<i class="fa fa-inr" aria-hidden="true"     style={{ cursor: "pointer", color: "blue", marginLeft: "25px" }} ></i>{Math.round(data.net_total_item_amount)}</span>
              <span style={{ flex: 1, marginLeft: 10 }}>
                  <i class="fa fa-truck" aria-hidden="true"     style={{ cursor: "pointer", color: "blue", marginLeft: "25px" }}></i>:{data.shipping_charge}</span>
                </small>
              </div><br/>
           
            </div>
           
          </>
              )
              }

        )
      : null;
return (
  <>
    <div className="cartpage">
  
      <h4 className="title-of-page23">   <img src={cart} className="message-button" width="50px" />Cart </h4>
      <div className="cart-inner-container">
        <div className="">
          <div className="test-reports-display-list">
            {display ? (
              display1
            ) : (
              <div
                style={{
                  color: "red",
                  fontSize: "25px",
                  height: "200px",
                  padding: "40px",
                }}
              >
                <h4>Cart is Empty!!</h4> 
              </div>
            )}
          </div>
        </div>
      </div>
      {display ? (
        <div className="best">
          {/* <i class="fa fa-refresh" aria-hidden="true" style={{width:"10%"}} onClick={handleRefresh}> </i> */}
          {/* { showSubmit ? 
                            <div style={{ flex: 1 }}>
                                <img src={del} width="50px"
                                    onClick={() => deleteSelected()}
                                    style={{
                                        cursor: 'pointer',
                                        padding: 10,
                                        // opacity: '40%'
                                    }} />
                            </div>
                        : */}
          <div style={{ flex: 1 }}>

            {serviceList.length > cartlength ? <img
              src={del}
              width="50px"
              onClick={() => setDeleteShow(true)}
              style={{
                cursor: "pointer",
                padding: 10,
                opacity: "70%",
              }}
            /> : null}
          </div>
          {/* } */}
          <h5>Total :</h5>{" "}
          <h5>
            <i class="fa fa-inr" aria-hidden="true"></i>
            {display ? sum : null}{" "}
          </h5>
        </div>
      ) : null}
    </div>
    {display ? (
      <div style ={{marginLeft:"91%"}}>
      
        {showSubmit ? (
          <>
            {serviceList.length === cartlength ?
              <div className="cart-submit" onClick={() => setPreviewCart(true)}>
                <span style={{ color: "white", paddingTop: 5 }}>preview</span>
                <img
                  src={delivery}
                  width="40px"
                  style={{
                    // cursor: 'pointer',
                    padding: 10,
                  }}
                />
              </div>
              : <div
              className="cart-submit" onClick={() => addCart()}
              
            >
              <span style={{ color: "white", paddingTop: 5 }}>submit</span>
              <img
                src={delivery}
                width="40px"
                style={{
                  // cursor: 'pointer',
                  padding: 10,
                }}
              />
            </div>} </>
        ) : ( <div className="cart-submit" onClick={() => setPreviewCart(true)}>
        <span style={{ color: "white", paddingTop: 5 }}>preview</span>
        <img
          src={delivery}
          width="40px"
          style={{
            // cursor: 'pointer',
            padding: 10,
          }}
        />
      </div>
          
        )}
      </div>
    ) : null}

    {successShow ? (
      <SubmitPopUp1
        show={successShow}
        onHide={() => {
          setSuccessShow(false);
          loadData();
        }}
      />
    ) : null}

    {showExistModal ? (
      <ExistModal
        show={showExistModal}
        onHide={() => {
          setShowExistModal(false);
          setRepeat(null);
        }}
      />
    ) : null}
    {/* 
                {deletedItems ? (
                <DeletedPopUp
                    show={deletedItems}
                    onHide={() => {
                     setDeletedItems(false);
                    }}
                />
                ) : null} */}

    {showNoItem ? (
      <NoItems
        show={showNoItem}
        onHide={() => {
          setShowNoItem(false);
        }}
      />
    ) : null}

    {deleteShow ? (
      <DeleteConfirmPopup
        show={deleteShow}
        onHide={() => {
          setDeleteShow(false);
        }}
      />
    ) : null}
    {previewCart ? (
      <PreviewPopUp
        show={previewCart}
        onHide={() => {
          setPreviewCart(false);
        }}
      />
    ) : null}
    {/* {deleteMsg !== "" ? (
        <SubmitErrorPopup
          show={deleteShow}
          onHide={() => setDeleteShow(false)}
        />
      ) : (
        ""
      )} */}
  </>
);
}

export default CartViewPage;
