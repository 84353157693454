import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditDisease(props) {

    const[data, setData]= useState(props.data)
    const [error,setError]=useState({
      disease_name:''
        
       });

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const[completeShow,setCompleteShow]=useState(false)
    const[errorValidate,setErrorValidate]=useState(false)

   




const CompletePopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-success'> Successfully Saved</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
       <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
       
       
      </Modal.Footer>
    </Modal>
  );
}

   

      const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>check data duplication or not</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
     
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


      const handleSave=() =>
{
  setSuccessShow(true);
 
}
 

  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["disease_name"] || input["disease_name"]==="" ) {
      isValid = false;
      errors["disease_name"] = "Please enter disease Name";
    }
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();
 
 console.log(validate())
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 let  dataToSend={}
  if(props.data.disease_name===data.disease_name&&props.data.disease_type===data.disease_type){
    dataToSend = {
     
      disease_stage:data.disease_stage,
      generic_name:data.generic_name,
      icd_code:data.icd_code
  }
  }
  else if(props.data.disease_name===data.disease_name){
  dataToSend = {
    disease_type:data.disease_type,
    disease_stage:data.disease_stage,
    generic_name:data.generic_name,
    icd_code:data.icd_code
}
 }else{
   dataToSend = {
        disease_name: data.disease_name,
        disease_type:data.disease_type,
        disease_stage:data.disease_stage,
        generic_name:data.generic_name,
        icd_code:data.icd_code
  }
}
     axios
       .put(`${APIURL}/api/v1/staff/master-primary-disease-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          setCompleteShow(true);
            
          setData({
            disease_name : '',
            disease_type : '',
            disease_stage : '',
            generic_name : '',
            icd_code : ''
         });
         } else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }

    else {
      setErrorValidate(true)
   }

}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Edit Disease</h4>
        <div style={{maxHeight:'1000px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Disease Name: </label>
                    <input type='text' className='form-control title-flex' name='disease_name' value={data.disease_name} onChange={handleTitle} /></div>
                    {error.disease_name ? <div className="error-validation-msg error-feature-inventory">{error.disease_name}</div> : null}
                </div>

                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Disease Type: </label>
                    <input type='text' className='form-control title-flex' name='disease_type' value={data.disease_type} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Disease Stage: </label>
                    <input type='text' className='form-control title-flex' name='disease_stage' value={data.disease_stage} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Disease Generic Name: </label>
                    <input type='text' className='form-control title-flex' name='generic_name' value={data.generic_name} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'> ICD Code: </label>
                    <input type='text' className='form-control title-flex' name='icd_code' value={data.icd_code} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>

            </div>

            

            


            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
          

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}


{completeShow ? 

<CompletePopUp
show={completeShow}
onHide={() =>  {setCompleteShow(false)
props.modalClose()}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditDisease;
