import React, {useState, useEffect} from 'react'

function ImageScale(props) {
    const [,setRotation]=useState(0);
    const [rotationArray, setRotationArray]= useState([])
    const [scaleValue ,setScaleValue]= useState([]);
    const [imgLength, setImgLength]= useState(0)

    useEffect(()=> {
      let imgLength= props.img.length
      setImgLength(imgLength)
    let rotationArr=new Array(imgLength).fill(null).map(()=>(0));
    setRotationArray(rotationArr)

    let scaleArr=new Array(imgLength).fill(null).map(()=>(1));
    setScaleValue(scaleArr)
    },[])

      const handleRotateLeft2=(i)=>{
        setRotation([])
        let newRotation = rotationArray[i] - 90;
        
        if(newRotation >= 360){
          newRotation =0;
        }if(newRotation === -360){
          newRotation =0;
        }
        let newArray=rotationArray;
        newArray[i]=newRotation;
        setRotationArray(newArray);
      }

      const handleScale=(i)=>{ 
        setRotation([])
          if(scaleValue[i]<2.3){
            let scale = scaleValue[i] + 0.3
            let newArray= scaleValue
            newArray[i]= scale
            setScaleValue(newArray)
          }
      }
      
      const handleScaleOut=(i)=> {
        setRotation([])
        if(scaleValue[i]>1){
          let scale = scaleValue[i] - 0.3
            let newArray= scaleValue
            newArray[i]= scale
            setScaleValue(newArray)
        }
      }

    return (
        <div className={imgLength>1?'flex-column presc-handle img-display':'flex-column img-display'}>
          <h3 className='title-of-tasks'>{props.presc===true? 'Prescription':'Lab Report'}</h3>
          {
            props.img.map((item,index)=> { 
              return(
                <div key={index} className='flex-row'>
                <div className={scaleValue[index]===1.3?'overflow-handle1': scaleValue[index]===1.6?'overflow-handle2' : scaleValue[index]===1.9000000000000001? 'overflow-handle3': scaleValue[index]===2.2? 'overflow-handle4': scaleValue[index]===2.5 ? 'overflow-handle5': '' }>
                  <p style={{textAlign:'center'}}>
                  <img src={item} className={index>0&&scaleValue[index-1]>1.4? 'img-popup margin-inc':'img-popup margin-dec'} width='300' height='300' alt="Scanned Image"  style={{transform:`rotate(${rotationArray[index]}deg) scale(${scaleValue[index]}`}}/>
                  </p>
                  
                  
                  <button className={scaleValue[index]<2?"btn btn-primary btn-col btn-pos0":scaleValue[index]===2.2?"btn btn-primary btn-col btn-pos1":scaleValue[index]===2.5?"btn btn-primary btn-col btn-pos2":null}  onClick={()=>handleRotateLeft2(index)}><i className="fa fa-undo" aria-hidden="true"></i> </button><br/><br/>
                  <button  className={scaleValue[index]<2?"btn btn-primary btn-col btn-pos0":scaleValue[index]===2.2?"btn btn-primary btn-col btn-pos1":scaleValue[index]===2.5?"btn btn-primary btn-col btn-pos2":null} onClick={()=>handleScale(index)}><i className="fas fa-search-plus"></i> </button><br/><br/>
                  <button  className={scaleValue[index]<2?"btn btn-primary btn-col btn-pos0":scaleValue[index]===2.2?"btn btn-primary btn-col btn-pos1":scaleValue[index]===2.5?"btn btn-primary btn-col btn-pos2":null} onClick={()=>handleScaleOut(index)}><i className="fas fa-search-minus"></i> </button>
                  <br/>
                </div>
                </div>
              )
              
            })
          }
            
         
        </div>
    )
}

export default ImageScale
