import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { APIURL } from "../../Global";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button, Dropdown, ToggleButton, Tab, Tabs, ToggleButtonGroup, ButtonGroup } from "react-bootstrap";
import "./webnotifications.css";
import NotificationsGeneral from "./NotificationsGeneral";
import Notification from "./Notification";
import moment from 'moment';
function NotificationAll() {

    return (
        <>
           


                <div style={{ backgroundColor: "rgba(0, 101, 124, 0.33)"  }} >
                    
                    <Tabs
                        defaultActiveKey="home"

                        className="general"
                        
                        style={{ backgroundColor: "#E7F2E7",marginLeft:"15.1%" }}>
                           
                          
                        <Tab eventKey="home" title="Health" style={{ color: "#4B83EF", fontWeight: "bold", MarginLeft:"20%" }}>
                            <Notification />
                        </Tab>
                        <Tab eventKey="profile" title="General" style={{ color: "#4B83EF" }}>
                            <NotificationsGeneral />
                        </Tab>

                    </Tabs>

                </div>

        

        </>
    );
}
export default NotificationAll;
