import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./addmedi.css";
import { Link } from "react-router-dom";
import AddMedicinePtient from "./AddMedicinePtient";
import AddRemark from "./AddRemark";
import Select from "react-select";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import { APIURL } from "../../Global";
import { useNavigate, Navigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import EditRemark from "./EditRemark";

function AddMedicinesPat({ patient, closeMed }) {
  const [temppushitem, setTempPushItem] = useState([]);
  const [medList1, setMedList1] = useState([]);
  const [newarray, setNewarray] = useState([]);
  const [medCounter, setmedCounter] = useState(0);
  const [render, setRender] = useState(true);
  const [reRender, setRerender] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [medId, setMedID] = useState("");
  const [price, setPrice] = useState("");
  const [addShow, setAddShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [addRentalShow, setAddRentalShow] = useState(false)
  const [vat, setVat] = useState("");
  const [index, setIndex] = useState();
  const [meddata, setMedData] = useState("");
  const [medErr, setMedErr] = useState("");
  const [deletesuccess, setDeleteSuccess] = useState("");
  const [deletesucc, setDeleteSucc] = useState(false);
  const [poppShow, setPopupShow] = useState(false);
  const patientid = patient;
  const [IndexToDelete, setIndexToDelete] = useState();
  const [deleteTestConfirmMsg, setDeleteTestConfirmMsg] = useState("");
  // const [item1,setItem]=useState([]);
  const [remarkData, setRemarkData] = useState("");
  const [dateShow, setDateShow] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [load, setLoad] = useState(false)
  const [drvalue, setDrvalue] = useState(0)
  const [medName, setMedname] = useState();
  const [strength1, setStrength] = useState("");
  const [strengthunit, setStrengthUnit] = useState("");
  const [dosgetype, setDosageForm] = useState("");
  const [routinee, setRoutie] = useState("");
  const [item2, setItem2] = useState([
    // {
    //   med_name: "",
    //   strength: "",
    //   dosage_form: "",
    //   route: "",
    //   dosage: "",
    //   durationvalue:"",
    //   freequency: "",
    //   intake_count: [],
    //   intake_time: "",
    //   dosage_count: "",
    //   remarks: "",
    //  },
  ]);

  const [item1, setItem] = useState([
    //  {
    //   med_name: "",
    //   strength: "",
    //   dosage_form: "",
    //   route: "",
    //   dosage: "",
    //   durationvalue:"",
    //   freequency: "",
    //   intake_count: [],
    //   intake_time: "",
    //   dosage_count: "",
    //   remarks: "",
    //     created_at: "2022-06-03T00:31:42.484212Z"
    // created_name: "backoffice"
    // description: ""
    // dosage_form: "suspensions"
    // generic_name: "Prilosec"
    // id: 8
    // manufacturer_name: ""
    // med_code: "dummy"
    // med_name: "Omeprazole"
    // med_type: "dummy"
    // modified_at: "2022-06-03T00:31:42.484253Z"
    // route: "oral"
    // strength: "dummy"
    //  },
  ]);

  const [tableData, setTableData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [display, setDisplay] = useState(false);

  const [search, setSearch] = useState("med_name");
  const [options, setOptions] = useState([]);
  const [, setOptionsDisplay] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  const [submitMsg, setSubmitMsg] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteConfirmMsg, setDeleteConfirmMsg] = useState("");
  const [deleteConfirmModalShow, setDeleteConfirmModalShow] = useState(false);
  const [remarkList, setRemarkList] = useState([]);
  const [cell, setCell] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [backup, setBackup] = useState("");
  const [procedureData, setProcedureData] = useState([]);
  const [procedureShow, setProcedureShow] = useState(false);
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);

  const [period, setPeriod] = useState("");
  const [count, setCount] = useState("");

  // const currencyOptions = [
  //   defaultCurrency,
  //   { value: "usd", label: "USD" },
  //   { value: "aed", label: "AED" },
  // ];

  const [searchTerm, setSearchTerm] = useState("");
  const [medicine, setMedicine] = useState([]);
  const [fetchTableData, setFetchTableData] = useState(true);

  const handleDisplay = () => {
    setDisplay(false);

  }

  useEffect(() => {
    document.addEventListener('click', handleDisplay)

    return () => {
      document.removeEventListener('click', handleDisplay)
    }
  }, [])


  console.log("medname,", medName)

  useEffect(() => {



    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');




    axios.get(`${APIURL}/api/v1/doctor/update-current-medication/?medicines_list=current_medicines&patient_id=${patientid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": 'Token ' + str
        }
      })
      .then(res => {




        if (res.data.status === "success") {

          let doctorname = "";

          let data = []
          if (res.data.doctor.length === 0) {
            data = []
          }

          if (res.data.doctor.length !== 0) {
            res.data.doctor.map((item) => {
              data = data.concat(item.medicines)
              doctorname = data.doctor_name;
            })
          }
          if (res.data.patient.length !== 0) {
            res.data.patient.map((item) => {
              data = data.concat(item.medicine)
              //patient_name=data.patient_name;
            })
          }
          setMedList1(data)
          console.log("medList1", medList1)
          // setDoctorname(doctorname)

          //  if (res.data && res.data.doctor && res.data.doctor.length > 0) {
          //     const data = res.data.doctor.medicines;
          //     setMedList(data);



        } else {

          // setErrorMsg(res.data.message? res.data.message : "Error in fetching data");
          // setErrorSubmitModalShow(true);
          // setMedList([]);
        }


      })
      .catch(err => {

        // setErrorMsg("Error in fetching data");
        // setErrorSubmitModalShow(true);
      })



  }, [])

  //let navigate = useNavigate();

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setMedicine([]);
      setDisplay(false);
    } else {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      axios
        .get(
          `${APIURL}/api/v1/doctor/search/?search=${search}:${searchTerm}`,
          {
            headers: { Authorization: "Token " + v2 },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setMedicine(res.data);

            setDisplay(true);
            // setTotalPages(res.data.total_pages)
            // setCurrentPage(res.data.page)
            // setTotalRecords(res.data.total_records)
          } else {
          }
        })
        .catch((error) => { });
    }
  }, [searchTerm]);

  useEffect(() => {
    // setItem([
    //   {
    //     med_name: "",
    //     strength: "",
    //     dosage_form: "",
    //     route: "",

    //     dosage: "",
    //     freequency: "",
    //     intake_count: [],
    //     intake_time: "",
    //     dosage_count: "",
    //     remarks: "",
    //   },
    // ]);

    setmedCounter(0);

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/doctor/online-prescription/?patient_id=${patientid}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.message.length > 0) {
            const data = res.data.message;
            const filteredData = data.filter((item) => {
              return item.id !== "";
            });


            if (filteredData.length < 1) {
              setItem([
                {
                  generic_name:"",
                  composition: "",
                  med_name: "",
                  strength: "",
                  dosage_form: "",
                  route: "",
                  durationvalue: "",
                  dosage: "",
                  freequency: "",
                  intake_count: [],
                  intake_time: "",
                  dosage_count: "",
                  remarks: "",
                  intake_unit: ""
                },
              ]);
            } else {
              // setItem(filteredData);
            }

            // setmedCounter(filteredData.length);
          }
        }
      })
      .catch((err) => { });
  }, [refresh]);

  // const bodyClickHandler = (e) => {
  //   if (e.target.parentNode.dataset.name !== "procedure-suggestions") {
  //     document.body.removeEventListener("click", bodyClickHandler);
  //     setDisplay(false);
  //   }
  // }
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setMedID("");
    if (e.target.value === '') {
      setDisplay(false);
    }
    // document.body.addEventListener("click", bodyClickHandler);
  };

  //   window.onscroll = () => {
  //     setDisplay(false);
  // };

  const handleClick = (e, item) => {
    e.preventDefault();
    initialRender.current = true;
    setSearchTerm(item.med_name);
    setMedID(item.id);
    setMedData(item);
    // setPatientDetails(item)

    setDisplay(false);

    // setAllData([])
    // setRefresh(!refresh)
  };

  useEffect(() => {
    setTableData(tableData);
  }, [render, tableData]);
  const intakecountoptions = [
    { value: 1, label: "Daily" },
    { value: 2, label: "Weekly" },
    { value: 3, label: "Monthly" },
    { value: 4, label: "Alternate days" },
    { value: 5, label: "Weekly twice" },
    { value: 6, label: "Weekly Thrice" },
  ];

  const freqOptions = [
    { value: "0-0-0-1", label: "0-0-0-1" },
    { value: "0-0-1-0", label: "0-0-1-0" },
    { value: "0-0-1-1", label: "0-0-1-1" },
    { value: "0-1-0-0", label: "0-1-0-0" },
    { value: "0-1-0-1", label: "0-1-0-1" },
    { value: "0-1-1-0", label: "0-1-1-0" },
    { value: "0-1-1-1", label: "0-1-1-1" },
    { value: "1-0-0-0", label: "1-0-0-0" },
    { value: "1-0-0-1", label: "1-0-0-1" },
    { value: "1-0-1-0", label: "1-0-1-0" },
    { value: "1-0-1-1", label: "1-0-1-1" },
    { value: "1-1-0-0", label: "1-1-0-0" },
    { value: "1-1-0-1", label: "1-1-0-1" },
    { value: "1-1-1-0", label: "1-1-1-0" },
    { value: "1-1-1-1", label: "1-1-1-1" },
  ];
  const durationOptions = [
    { value: 1, label: "Days" },
    { value: 7, label: "Weeks" },
    { value: 30, label: "Months" },
  ];

  const intakeTimeOptions = [

    { value: "before food", label: "Before food" },
    { value: "after food", label: "After food" },
  ];

  const IntakeUnitOptions = [

    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "oz", label: "ounces" },
    { value: "nos", label: "number" },
    { value: "shots", label: "shots" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" },

  ];

  // useEffect(() => {
  //   ;
  //       // let "general_services"

  //  let data1=render1.medname;


  // }, [render1]);

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete medicine</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              setDeleteConfirmModalShow(false);
              handleDeleteMed(props.index, props);
              setIndexToDelete(null);
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handleNew = (item2) => {

    // setMedname(item2.medname);
    // setStrength(item2.strengthval);
    // setStrengthUnit(item2.strengthuni);
    // setDosageForm(item2.dosgetypee);
    // setRoutie(item2.routee);

    console.log("daaaataaaas", medName, dosgetype, routinee, strengthunit, strength1)
    const ddd = {
      created_at: "",
      doctor: 0,
      doctor_name: "",
      dosage_count: "", // needde frm db
      dosage_form: item2.dosgetypee,
      duration: "", // needed frm db
      frequency: "", // needed frm db
      hospital: 0, // needed frm db
      id: "",
      intake: "", // needed frm db
      intake_time: "", // needed frm db
      med_type: "",
      medicine: 0, // needed frm db
      medicine_name: item2.medname,
      modified_at: "",
      patient: 0, // needed frm db
      patient_name: "", // needed frm db
      remarks: "",
      route: item2.routee,
      strength: item2.strengthval,
      strength_unit: item2.strengthuni,
      composition: item2.composition,
      generic_name:item2.generic_name
    };

    temppushitem.push(ddd);
    setItem((oldArray) => oldArray.concat(ddd));
    setSearchTerm('');
    let data = { ...meddata };
    // data.med_code = '';
    // data.generic_name = '';
    // data.strength = '';
    // data.strength_unit = '';
    setMedData(data);
    setMedID("")


    // const [medName, setMedname] = useState();
    // const [strength1, setStrength] = useState("");
    // const [strengthunit, setStrengthUnit] = useState("");
    // const [dosgetype, setDosageForm] = useState("");
    // const [routinee, setRoutie] = useState("");
    setAddRentalShow(false)
    console.log("datanew", data)
  }

  const handleDeleteMed = (index, props) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const ts_id = item1[index].id;

    axios
      .delete(`${APIURL}/api/v1/doctor/prescription-detail/${ts_id}/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.status == 204) {
          setDeleteSuccess("Successfully Deleted");
          setDeleteSucc(true);
          setRefresh(!refresh);
          // setReRender(!reRender);
          //refreshPage();
        } else {
          setSubmitMsg("Please check data");
        }
      })
      .catch((err) => {
        setSubmitMsg("please check data");
      });
  };
  const rentalModalClose = () => {
    setAddRentalShow(false)
    setRefresh(!refresh)
    setFlag(true)
  }
  const handleChange = (e) => {
    const val = e.target.value;
    setSearch(val)
    console.log(val)
  }
  const notProceedClose = () => {
    setAddRentalShow(false)
    setRefresh(!refresh)
  }

  const ErrorProcedurePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {medErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };



  const AddRentalPopUp = (props) => {

    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">


          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <AddMedicinePtient modalClose={rentalModalClose} notProceedClose={notProceedClose} render={handleNew} patientID={patient} />



        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-primary btn-col' onClick={() => { handleClose(); console.log("medName", medName) }}>Close</Button>

        </Modal.Footer>
      </Modal>
    )
  }
  const handleClose = () => {
    // bindMedicine();
    setAddRentalShow(false);
    //  setEditShow(false);
  }
  const DeleteSuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "Green" }}> {deletesuccess}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Data submitted successfully!!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} save={addHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <EditRemark data={remarkData} update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className='btn btn-primary btn-col' onClick={props.onSave}>Update</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const addHandle = () => {
    console.log(
      'THE SEND DATA', sendData
    )
    setAddShow(false);
    let list = [...item1];
    list[cell].remarks = sendData;
    setPopupShow(true);
  };

  const handleMedicine = () => {

    setLoad(true)
    let isValid = true;

    const MedData = item1.filter((row, i) => {
      return row.med_name !== "" || row.id !== "" || row.med_code !== "";
    });

    MedData.map((item) => {
      if (item.medicine_name === "") {

        setMedErr("*Medicine name is mandatory!");
        setErrorShow(true);
        isValid = false;
      } else if (item.strength === "") {
        setMedErr("*Strength is mandatory!");
        setErrorShow(true);
        isValid = false;
      } else if (item.dosage_form < 1) {
        setMedErr("*Dosage form is mandatory!");
        setErrorShow(true);
        isValid = false;
      } else if (item.route === "") {
        setMedErr("*Route is mandatory!");
        setErrorShow(true);
        isValid = false;
      } else if (item.frequency === "") {
        setMedErr("*Frequency is mandatory!");
        setErrorShow(true);
        isValid = false;
      } else if (item.intake_time === "") {
        setMedErr("*Intake Time is mandatory!");
        setErrorShow(true);
        isValid = false;
      } else if (item.intake_unit === "") {
        setMedErr("*Intake Unit is mandatory!");
        setErrorShow(true);
        isValid = false;
      } else if (item.duration < 1) {
        setMedErr("*Duration is mandatory!");
        setErrorShow(true);
        isValid = false;
      } else if (item.intake === "") {
        setMedErr("*Intake Period is mandatory!");
        setErrorShow(true);
        isValid = false;
      } else if (item.dosage_count === "") {
        setMedErr("*Dosage Count is mandatory!");
        setErrorShow(true);
        isValid = false;
      }

      //  else {
      //   ;
      //   for (let i=0;i<=medList1.length;i++){

      //     if(medList1[i]===item.medicine_name&&item.strength){
      //       setMedErr("*This medicine already added!");
      //       setErrorShow(true);
      //       isValid = false;
      //     }

      //   }
      //  }
      // else if(medList1.includes(item.medicine_name&&item.strength)){
      //   setMedErr("This medicine is already added");
      //   setErrorShow(true);
      //   isValid = false;
      // }

      //  else if (item.remarks === "") {
      //   setMedErr("*Remark is mandatory!");
      //   setErrorShow(true);
      //   isValid = false;
      // }
    });

    if (isValid) {
      medicineSubmit(MedData);
    }
    else {
      setLoad(false)
    }
  };

  const medicineSubmit = (MedData) => {
    ;

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    if (MedData.length < 1) {
      return false;
    }

    let medList = [];



    for (let i = 0; i < MedData.length; i++) {
      let durationVal = 0;
      let frequencyValue = 0;
      let intakeValue = 0;
      let intakeTimeValue = 0;
      let intakeUnitValue = 0;
      if (!MedData[i].durationvalue) {
        MedData[i].durationvalue = 1
      }
      if (
        typeof MedData[i].duration === "object" &&
        MedData[i].duration !== null
      ) {
        durationVal = MedData[i].duration.value * MedData[i].durationvalue;
      } else {
        durationVal = MedData[i].duration * MedData[i].durationvalue;
      }

      if (
        typeof MedData[i].frequency === "object" &&
        MedData[i].frequency !== null
      ) {
        frequencyValue = MedData[i].frequency.value;
      } else {
        frequencyValue = MedData[i].frequency;
      }

      if (typeof MedData[i].intake === "object" && MedData[i].intake !== null) {
        intakeValue = MedData[i].intake.label;
      } else {
        intakeValue = MedData[i].intake;
      }
      if (
        typeof MedData[i].intake_time === "object" &&
        MedData[i].intake_time !== null
      ) {
        intakeTimeValue = MedData[i].intake_time.value;
      } else {
        intakeTimeValue = MedData[i].intake_time;
      }
      if (
        typeof MedData[i].intake_unit === "object" &&
        MedData[i].intake_unit !== null
      ) {
        intakeUnitValue = MedData[i].intake_unit.value;
      }




      else {
        intakeUnitValue = MedData[i].intake_unit;
      }

      // for (let i=0;i<=medList1.length;i++){
      //   ;

      //   if((medList1[i].drug===MedData[i].medicine_name)&&(medList1[i].strength===MedData[i].strength)){
      //     setMedErr("*This medicine already added!");
      //     setErrorShow(true);
      //     // isValid = false;
      //   }

      //  else{

      medList.push({

        drug: MedData[i].medicine_name,
        medicine: MedData[i].id,
        med_type: MedData[i].med_type,
        frequency: frequencyValue,
        intake: intakeValue,
        intake_time: intakeTimeValue,
        intake_unit: intakeUnitValue,
        strength: MedData[i].strength,
        form: MedData[i].dosage_form,
        route: MedData[i].route,
        duration: durationVal,
        strength_unit: MedData[i].strength_unit,
        //dosage:"",
        intake_count: MedData[i].dosage_count,
        remarks: MedData[i].remarks,
      });
    }
    const dataToSend = {
      patient_id: patientid,
      medicines: medList,
    };

    axios
      .post(`${APIURL}/api/v1/doctor/patient-prescriptions/`, dataToSend, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setSubmitMsg("success");
          setModalShow(true);
          setLoad(false)
        } else {
          // setErrorMsg("Please check whether already added or not");
          setErrorSubmit(true);
          setLoad(false)
        }
      })
      .catch((err) => {
        setErrorMsg(" Please check whether medicine already added or not");
        setErrorSubmit(true);
        setLoad(false)
      });
    //   }
    // }
  };

  const SuccessPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}> Remarks Added successfully </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleAddProcedure = (e,item) => {
    
    e.preventDefault();
    e.preventDefault();
    initialRender.current = true;
    setSearchTerm(item.med_name);
    setMedID(item.id);
    setMedData(item);
    // setPatientDetails(item)

    setDisplay(false);

    // setAllData([])
    // setRefresh(!refresh)
    // post api for Add procedure
    if (item.id === "") {
      setMedErr("Please Select Medicine name from Dropdown");
      setErrorShow(true);
    }


    const s = medList1.filter(x => x.drug == item.med_name)

    if (s.length > 0) {

      setFlag(true)
      return
    } else {
      setFlag(false)
    }
    if (flag == false) {
      if (item.id !== "") {
        const ddd = {
          created_at: item.created_at,
          doctor: 0,
          doctor_name: "",
          dosage_count: "", // needde frm db
          dosage_form: item.dosage_form,
          duration: "", // needed frm db
          frequency: "", // needed frm db
          hospital: 0, // needed frm db
          id: item.id,
          intake: "", // needed frm db
          intake_time: "", // needed frm db
          med_type: item.med_type,
          medicine: 0, // needed frm db
          medicine_name: item.med_name,
          modified_at: item.modified_at,
          patient: 0, // needed frm db
          patient_name: "", // needed frm db
          remarks: "",
          route: item.route,
          strength: item.strength,
          strength_unit: item.strength_unit,
          composition: item.composition,
          generic_name:item.generic_name
        };

        temppushitem.push(ddd);
        setItem((oldArray) => oldArray.concat(ddd));
        setSearchTerm('');
        let data = { ...meddata };
        data.med_code = '';
        data.generic_name = '';
        data.strength = '';
        data.strength_unit = '';
        setMedData(data);
        setMedID("")
      }

    }







  };

  useEffect(() => {

    if (flag == true) {

      // if (medId === "") {
      //   setMedErr("Please Select Medicine name from Dropdown");
      //   setErrorShow(true);
      // }



      if (flag === true) {

        setMedErr("*This medicine is already added to this patient!");
        setErrorShow(true);
      }
      // isValid = false;

      // else if(medList1.includes(meddata.med_name && meddata.strength)){
      //   setErrorMsg("This medicine is already added");
      //   setErrorSubmit(true);
      //         // isValid = false;
      //       }
      else {
        const ddd = {
          created_at: meddata.created_at,
          doctor: 0,
          doctor_name: "",
          dosage_count: "", // needde frm db
          dosage_form: meddata.dosage_form,
          duration: "", // needed frm db
          frequency: "", // needed frm db
          hospital: 0, // needed frm db
          id: meddata.id,
          intake: "", // needed frm db
          intake_time: "", // needed frm db
          med_type: meddata.med_type,
          medicine: 0, // needed frm db
          medicine_name: meddata.med_name,
          modified_at: meddata.modified_at,
          patient: 0, // needed frm db
          patient_name: "", // needed frm db
          remarks: "",
          route: meddata.route,
          strength: meddata.strength,
          strength_unit: meddata.strength_unit,
          composition: meddata.composition,
          generic_name:meddata.generic_name
        };

        temppushitem.push(ddd);
        setItem((oldArray) => oldArray.concat(ddd));
        setSearchTerm('');
        let data = { ...meddata };
        data.med_code = '';
        data.generic_name = '';
        data.strength = '';
        data.strength_unit = '';
        setMedData(data);
        setMedID("")
      }

    } else {

      if (medId !== "") {
        const ddd = {
          created_at: meddata.created_at,
          doctor: 0,
          doctor_name: "",
          dosage_count: "", // needde frm db
          dosage_form: meddata.dosage_form,
          duration: "", // needed frm db
          frequency: "", // needed frm db
          hospital: 0, // needed frm db
          id: meddata.id,
          intake: "", // needed frm db
          intake_time: "", // needed frm db
          med_type: meddata.med_type,
          medicine: 0, // needed frm db
          medicine_name: meddata.med_name,
          modified_at: meddata.modified_at,
          patient: 0, // needed frm db
          patient_name: "", // needed frm db
          remarks: "",
          route: meddata.route,
          strength: meddata.strength,
          strength_unit: meddata.strength_unit,
          composition: meddata.composition,
          generic_name:meddata.generic_name
        };

        temppushitem.push(ddd);
        setItem((oldArray) => oldArray.concat(ddd));
        setSearchTerm('');
        let data = { ...meddata };
        data.med_code = '';
        data.generic_name = '';
        data.strength = '';
        data.strength_unit = '';
        setMedData(data);
        setMedID("")
      }
    }

  }, [flag])

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>

            Please Check data!!

          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  var sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }

  const ProcedurePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* <ProcedureList data={procedureData} toggle='bdo'/>  */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const deleteRow = (index) => {
    const newList = item1.filter((elt, i) => {
      return i !== index;
    });
    setItem(newList);
    // handleDelete(index, newList);
  };

  const handleDelete = (index, newList) => {
    setDeleteConfirmMsg("success");
    setDeleteConfirmModalShow("true");
    setIndexToDelete(index);
    newarray.push(newList);
    setItem2(newarray);
  };
  const handleView = (data, index) => {
    setRemarkData(data);
    setCell(index);
    setViewShow(true);
  };
  const handleAdd = (index) => {
    setCell(index);
    setAddShow(true);
  };

  const handleNumber = (e, props) => {
    e.preventDefault();
    // 
    const val = e.target.value;


    if (val.length <= 4) {
      setPeriod(e.target.value);
    } else return false;
  };
  const handleCount = (e) => {
    e.preventDefault();

    const val = e.target.value;

    if (val.length <= 4) {
      setCount(e.target.value);
    } else return false;
  };

  const renderMedicineType = (cellInfo) => {

    // if(item1[cellInfo.index].id!==""){
    return <p>{item1[cellInfo.index].medicine_name}</p>;
    // }

  };
  const renderComposition = (cellInfo) => {

    // if(item1[cellInfo.index].id!==""){
    return <p>{item1[cellInfo.index].composition}</p>;
    // }

  };
  const renderGeneric = (cellInfo) => {

    // if(item1[cellInfo.index].id!==""){
    return <p>{item1[cellInfo.index].generic_name}</p>;
    // }

  };
  const renderStr = (cellInfo) => {

    return <p>{item1[cellInfo.index].strength} {item1[cellInfo.index].strength_unit}</p>;

    // if(item1[cellInfo.index].id!==""){
    //   return <p>{item1[cellInfo.index].strength}</p>
    // }
  };
  const renderForm = (cellInfo) => {

    return <p>{item1[cellInfo.index].dosage_form}</p>;

    //
  };
  const renderRoute = (cellInfo) => {

    return <p>{item1[cellInfo.index].route}</p>;

  };

  const renderFreequency = (cellInfo) => {
    setLoad(false)
    return (
      <Select
        value={item1[cellInfo.index].frequency}
        onChange={(data) => {
          const List = item1;
          List[cellInfo.index].frequency = data;
          setItem(List);
          setRender(!render);
        }}
        options={freqOptions}
        className=""
      />
    );

  };

  const renderInTake = (cellInfo) => {
    setLoad(false)
    let option = {};
    intakeTimeOptions.forEach(item => {
      if (item.value === item1[cellInfo.index].intake_time) {
        option = item;
      }
    });
    return (
      <Select
        defaultValue={option}
        onChange={(data) => {
          const List = item1;
          List[cellInfo.index].intake_time = data;

          setItem(List);

          setRender(!render);
        }}
        options={intakeTimeOptions}
        className=""
      />
    );

  };


  const renderIntakeUnit = (cellInfo) => {
    setLoad(false)
    let option = {};
    IntakeUnitOptions.forEach(item => {
      if (item.value === item1[cellInfo.index].intake_unit) {
        option = item;
      }
    });
    return (
      <Select
        defaultValue={option}
        onChange={(data) => {
          const List = item1;
          List[cellInfo.index].intake_unit = data;

          setItem(List);

          setRender(!render);
        }}
        options={IntakeUnitOptions}
        className=""
      />
    );

  };




  const renderIntake1 = (cellInfo) => {
    setLoad(false)
    let option = {};
    intakecountoptions.forEach(item => {
      if (item.value === item1[cellInfo.index].intake) {
        option = item;
      }
    });
    return (
      <>
        <Select
          defaultValue={option}
          onChange={(data) => {
            const List = item1;
            List[cellInfo.index].intake = data;

            setItem(List);
            setRender(!render);
          }}
          options={intakecountoptions}
          className=""
        />
      </>
    );

  };
  const renderRemarks = (cellInfo) => {
    setLoad(false)
    return item1[cellInfo.index].remarks === "" ||
      item1[cellInfo.index].remarks === null ||
      item1[cellInfo.index].remarks === undefined ? (
      <div style={{ textAlign: "center" }}>
        <button
          onClick={() => handleAdd(cellInfo.index)}
          className="btn btn-smallest add-remark-btn"
        >
          <i className="fas fa-plus"></i>
        </button></div>
    ) : (
      <div style={{ textAlign: "center" }}><button
        onClick={() =>
          handleView(
            item1[cellInfo.index][cellInfo.column.id],
            cellInfo.index
          )
        }
        className="btn btn-primary btn-col btn-square btn-smallest"
      >
        <i className="fa fa-comment-o"></i>
      </button></div>
    );

  };
  const renderDosageCount = (cellInfo) => {
    setLoad(false)
    let data = item1[cellInfo.index].dosage_count;
    return (
      <>
        <input
          type="number"
          min="1"
          step="any"
          className=""
          style={{ width: "80px", height: "35px" }}
          defaultValue={data}
          onChange={(e) => {
            e.preventDefault();
            data = e.target.value;
            if (data === "0") {
              setMedErr("Please Enter a Valid Dosage")
              setErrorShow(true);

            }
            else if (data.length < 4) {
              const List = item1;
              List[cellInfo.index].dosage_count = data;
              setItem(List);
            }
          }}
        />
      </>
    );

  };
  const renderDuration = (cellInfo) => {
    // 
    setLoad(false)
    let option = {};
    intakecountoptions.forEach(item => {
      if (item.value === item1[cellInfo.index].duration) {
        option = item;
      }
    });
    return (
      <>
        <form style={{ display: "flex" }}>
          <input
            type="number"
            min="1"
            className=""
            style={{ width: "40px" }}
            defaultValue={item1[cellInfo.index].durationvalue}
            onChange={(e) => {
              e.preventDefault();
              setDrvalue(e.target.value)
              let data = e.target.value;
              if (data === "0") {
                setMedErr("Please Enter a Valid duration")
                setErrorShow(true)
              }
              else if (data.length < 4 || data !== 0 || data !== "") {
                const List = item1;
                List[cellInfo.index].durationvalue = data;
                setItem(List);
              }


            }
            }

          />
          <Select
            style={{ width: "40px" }}
            defaultValue={option}
            onChange={(data) => {
              const List = item1;

              List[cellInfo.index].duration = data;

              setItem(List);

              setRender(!render);

            }}
            options={durationOptions}
            className="select-intake dur-period"
          />
        </form>
      </>
    );

  };

  const columns = [
    {
      Header: "Drug",
      accessor: "medicine_name",
      Cell: renderMedicineType,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",  whiteSpace: "unset" ,
      },

      minWidth: 100,
      sortable: false,
      filterable: false,
      width: 120,
    },
    {
      Header: "Generic Name",
      accessor: "generic_name",
      cell:renderGeneric,
      style: {
       whiteSpace: "unset"
      },

      minWidth: 100,
      sortable: false,
      filterable: false,
      width: 120,
      
    },
    {
      Header: "Composition",
      accessor: "composition",
      Cell: renderComposition,
      style: {
       whiteSpace: "unset"
      },

      minWidth: 100,
      sortable: false,
      filterable: false,
      width: 120,
    },
    {
      Header: (
        <span
          className="table-header-tooltip"
          data-toggle="tooltip"
          data-placement="top"
          title="Strength"
        >
          Strength
        </span>
      ),
      style: {
        backgroundColor: "rgba(0,0,0,0.1)", whiteSpace: "unset"
      },
      width: 120,
      Cell: renderStr,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Dosage Form",
      accessor: "dosage_form",
      width: 80,
      Cell: renderForm,
      sortable: false,
      style: {
        whiteSpace: "unset"
       },
      filterable: false,
    },
    {
      Header: "Route",
      accessor: "route",
      style: {
        whiteSpace: "unset"
       },
      width: 80,
      Cell: renderRoute,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Frequency",
      accessor: "frequency",
      minWidth: 80,
      width: 130,
      style: {
        whiteSpace: "unset"
       },
      Cell: renderFreequency,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Intake Time",
      accessor: "intake_time",
      Cell: renderInTake,
      sortable: false,
      width: 130,
      style: {
        whiteSpace: "unset"
       },
      filterable: false,
    },
    {
      Header: "Duration",
      accessor: "duration",
      Cell: renderDuration,
      sortable: false,
      style: {
        whiteSpace: "unset"
       },
      width: 160,
      filterable: false,
    },
    {
      Header: "Intake Period",
      accessor: "intake",
      Cell: renderIntake1,
      sortable: false,
      style: {
        whiteSpace: "unset"
       },
      width: 150,
      filterable: false,
    },

    {
      Header: (
        <span
          className="table-header-tooltip"
          data-toggle="tooltip"
          data-placement="top"
          title="INTAKE COUNT"
        >
          Intake Count
        </span>
      ),
      accessor: "dosage_count",
      Cell: renderDosageCount,
      sortable: false,
      width: 100,
      style: {
        whiteSpace: "unset"
       },
      filterable: false,
    },
    {
      Header: "Intake Unit",
      accessor: "intake_unit",
      Cell: renderIntakeUnit,
      sortable: false,
      width: 120,
      style: {
        whiteSpace: "unset"
       },
      filterable: false,
    },

    {
      Header: "Remarks",
      accessor: "remarks",
      Cell: renderRemarks,
      sortable: false,
      width: 80,
      style: {
        whiteSpace: "unset"
       },
      filterable: false,
    },

    {
      Header: "",


      Cell: (props) => {

        return (
          <button
            className="delete-btn"
            onClick={() => {
              deleteRow(props.index);
            }}
          >
            {" "}
            <i className="fas fa-trash-alt"></i>{" "}
          </button>
        );

      },
      style: {
        whiteSpace: "unset"
       },
      sortable: false,
      filterable: false,
      minWidth: 50,
      width: 50,
    },

  ];

  const displayTable = (
    <ReactTable
      columns={columns}
      data={item1}
      defaultPageSize={10}
      // filterable
      // resizable
      // noDataText={"No data"}
      className="current-med-table"
      style={{ overflow: "wrap", textAlign: "left" }}
    ></ReactTable>
  );

  return (
    <>
     
     
      



      <div className="procedure-navbar1" style={{ width: "97%", marginLeft: "1%", border: "2%" }}>

        <div className="" >
          <div className="procedure-form">
            <div className="search-section">

              <br />
              <div className="search-div">
                <div style={{ display: "flex" }}>

                  <input
                  
                    type="text"
                    className=""
                    placeholder={search === "composition" ? "Search with medical composition" : search === "med_name" ? "Search with medicine name" : search === "generic_name" ? "Search with generic name" : "Search here"}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: "600px",paddingLeft:"10px" }}
                  />
                  <div className="btn btn-primary btn-col search-btn">
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                  </div>

                </div>
              </div>
            </div>


            {display ? (
              <div className="suggest-display adjust-dimensions procedure-auto-suggest1">
                {medicine.length > 0 ? (
                  medicine.map((item) => {
                    return (
                      <p
                        onClick={(e) => handleAddProcedure(e, item)}
                        className=" text-secondary"
                        style={{padding:"2%"}}
                      >

                        {search === "med_name" ?
                          <>{item.med_name+","+item.strength}<span>&nbsp;&nbsp;</span>   <i style={{ float: "right" }} class={item.is_approved === false ? "fa fa-window-close" : "fa fa-check-square"} aria-hidden="true"></i> </>:
                          search === "generic_name" ?
                          <div className="row"><span className="col-6">{item.med_name+","+item.strength}</span> <span className="col-5"> {item.generic_name}</span> <i style={{ float: "right" }} class={item.is_approved === false ? "fa fa-window-close" : "fa fa-check-square"} aria-hidden="true"></i></div> :
                            <div className="row"> <span className="col-6">{item.med_name+","+item.strength}</span><span className="col-5">{item.composition}</span> <i style={{ float: "right" }} class={item.is_approved === false ? "fa fa-window-close" : "fa fa-check-square"} aria-hidden="true"></i></div>}
                      </p>
                    );
                  })
                ) : (
                  <h6 className="text-muted" style={{ padding: 10 }}>
                    No Medicines found !!
                  </h6>
                )}
              </div>
            ) : null}
            {/* <h5 className="text-type" style={{color:"black"}}> Generic :</h5>
          <input
            type="text"
            className="form-control procedure-form-width"
            // min="0"
            //step='any'
            value={meddata.generic_name}
            //onChange={handlePriceChange}
          ></input>
          <h5 className="text-type" style={{color:"black"}}> Strength :</h5>
          <input
            type="text"
            className="form-control procedure-form-width"
            // min="0"
            //step='any'
            value={meddata.strength}
            // onChange={handleVatChange}
          ></input>
          <h5 className="text-type" style={{color:"black"}}>Code :</h5>
          <input
            type="text"
            className="form-control procedure-form-width"
            // min="0"
            // step='any'
            value={meddata.med_code}
            //onChange={handleVatChange}
          ></input> */}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            {/* <button onClick={handleAddProcedure} style={{ marginTop: "3%",height:"40px",width:"50px", boxShadow: "3px 5px 10px rgba(0,0,0,0.2)",sborderRadious:"2px", color:"white", backgroundColor:"#2b69a3", border:"#2b69a3",fontSize:"large"}} type="submit" className="">
            +
            </button>{" "} */}
            <button className="" onClick={() => setAddRentalShow(true)} style={{ boxShadow: "3px 5px 10px rgba(0,0,0,0.2)", marginLeft: "90%",height:"38px",width:"120px", marginTop: "-5%",fontSize:"small",borderRadious:"2px", color:"white", backgroundColor:"#2b69a3", border:"none" }}>
        Add New  <i class="fa fa-arrow-right" aria-hidden="true"></i>
      </button>
          </div>

          <div style={{marginTop:"0.5%", marginLeft: "1%" }} className="row">
            <div style={{ display: "flex" }}><h6 style={{ marginTop: "1%" }} className="">Search via: <span>&nbsp;&nbsp;</span></h6>

              <div className="radio-options">
                <input
                  style={{ marginTop: "1%"}}
                  type="radio"
                  id="Hospital"
                  name="health"
                  value="med_name"
                  checked={search === "med_name"}
                  onChange={handleChange}
                /><span>&nbsp;</span>
                <small htmlFor="hospital">
                  Medicine Name
                </small>

                <span>&nbsp;&nbsp;</span>
                <input
                  style={{ marginTop: "1%" }}
                  type="radio"
                  id="Hospital"
                  name="health"
                  value="generic_name"
                  checked={search === "generic_name"}
                  onChange={handleChange}
                /><span>&nbsp;</span>
                <small htmlFor="hospital">
                  Generic Name
                </small>
              </div>
              <span>&nbsp;&nbsp;</span>
              <div className="radio-options">
                <input
                  style={{ marginTop: "1%" }}
                  type="radio"
                  id="Pharmacy"
                  name="health"
                  value="composition"
                  checked={search === "composition"}
                  onChange={handleChange}
                /><span>&nbsp;</span>
                <small htmlFor="Pharmacy">Composition</small>
              </div></div>





          </div>

        </div>
      </div>
      <br />
      <div className="">
        <div className="text-center">
          <div className="service-table-holder flex-col"> {displayTable}</div>
          <br />
          <Button variant="info" onClick={handleMedicine} disabled={load}>
            Save
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {/* <Button variant="primary">History</Button> &nbsp;&nbsp;&nbsp;&nbsp; */}
          {/* <Button variant="success">Print</Button> */}
        </div>
      </div>
      <br />
      {/* <br />

      <br />
      <br /> */}

      {errorSubmit ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            //setErrorMsg("");
            // setTableData([]);
            // setRerender(!reRender);
          }}
        />
      ) : (
        ""
      )}

      {submitMsg !== "" ? (
        <SuccessPopup
          show={successSubmit}
          msg={submitMsg}
          onHide={() => {
            setSuccessSubmit(false);
            setSubmitMsg("");
            setTableData([]);
            setRerender(!reRender);
          }}
        />
      ) : (
        ""
      )}

      {addShow ? (
        <AddPopup
          show={addShow}
          onHide={() => {
            setAddShow(false);
          }}
        />
      ) : null}
      {viewShow ? (
        <ViewPopup
          show={viewShow}
          onHide={() => {
            setViewShow(false);
          }}
          onSave={() => {
            if (sendData !== item1[cell].remarks) {
              setViewShow(false);
              // setEditSuccess(true);
              const list = item1;
              list[cell].remarks = sendData;
              setItem(list);
            }
          }}
        />
      ) : null}
      {poppShow ? (
        <SuccessPopup show={poppShow} onHide={() => setPopupShow(false)} />
      ) : null}
      {medErr !== "" ? (
        <ErrorProcedurePopup
          show={errorShow}
          onHide={() => setErrorShow(false)}
        />
      ) : null}


      {
        addRentalShow ?
          <AddRentalPopUp
            show={addRentalShow}
            onHide={() => setAddRentalShow(false)}
          /> : null
      }

      {submitMsg === "success" ? (
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setRefresh(!refresh);
            closeMed();
            setSubmitMsg("");
          }}
        />
      ) : (
        ""
      )}
      {deleteConfirmMsg === "success" ? (
        <DeleteConfirmPopup
          show={deleteConfirmModalShow}
          onHide={() => setDeleteConfirmModalShow(false)}
          index={IndexToDelete}
          newList={item2}
        />
      ) : (
        ""
      )}
      {deletesuccess !== "" ? (
        <DeleteSuccessPopup
          show={deletesucc}
          onHide={() => setDeleteSucc(false)}
        />
      ) : null}
    </>
  );
}

export default AddMedicinesPat;
