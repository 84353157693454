import React, { useState, } from "react";
import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";


function EditMedicine(data) {
  const [period, setPeriod] = useState(1)
  const [frequency, setFrequency] = useState(data.data.frequency)
  const [intakeTime, setIntakeTime] = useState(data.data.intake_time)
  const [duration, setDuration] = useState(data.data.duration)
  const [durationValue, setDurationValue] = useState(1)
  const [intakeCount, setIntakeCount] = useState(data.data.intake)
  const [intakeunit, setIntakeUnit] = useState(data.data.intake_unit)
  const [dosage, setDosage] = useState(data.data.intake_count)
  const [remark, setRemark] = useState(data.data.remarks)
  const [errorMsg, setErrorMsg] = useState("");
  const [submitMsg, setSubmitMsg] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [medErr, setMedErr] = useState("");
  const [errorShow, setErrorShow] = useState(false);

  // let intakecount_label = "";
  // if (intakeCount === "1") {
  //   intakecount_label = "Daily";
  // }
  // else if (intakeCount === "2") {
  //   intakecount_label = "Weekly";
  // }
  // else if (intakeCount === "3") {
  //   intakecount_label = "Monthly";
  // }
  // else if (intakeCount === "4") {
  //   intakecount_label = "Alternate days";
  // }
  // else if (intakeCount === "5") {
  //   intakecount_label = "weekly twice";
  // }
  // else if (intakeCount === "6") {
  //   intakecount_label = "weekly Thrice";
  // }


  const intakecountoptions = [
    { value: 1, label: "Daily" },
    { value: 2, label: "Weekly" },
    { value: 3, label: "Monthly" },
    { value: 4, label: "Alternate days" },
    { value: 5, label: "Weekly twice" },
    { value: 6, label: "Weekly Thrice" },
  ];

  const freqOptions = [
    { value: "0-0-0-1", label: "0-0-0-1" },
    { value: "0-0-1-0", label: "0-0-1-0" },
    { value: "0-0-1-1", label: "0-0-1-1" },
    { value: "0-1-0-0", label: "0-1-0-0" },
    { value: "0-1-0-1", label: "0-1-0-1" },
    { value: "0-1-1-0", label: "0-1-1-0" },
    { value: "0-1-1-1", label: "0-1-1-1" },
    { value: "1-0-0-0", label: "1-0-0-0" },
    { value: "1-0-0-1", label: "1-0-0-1" },
    { value: "1-0-1-0", label: "1-0-1-0" },
    { value: "1-0-1-1", label: "1-0-1-1" },
    { value: "1-1-0-0", label: "1-1-0-0" },
    { value: "1-1-0-1", label: "1-1-0-1" },
    { value: "1-1-1-0", label: "1-1-1-0" },
    { value: "1-1-1-1", label: "1-1-1-1" },
  ];
  const durationOptions = [
    { value: 1, label: "Days" },
    { value: 7, label: "Weeks" },
    { value: 30, label: "Months" },
  ];

  const IntakeUnitOptions = [
   
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "oz", label: "ounces" },
    { value: "nos", label: "number" },
    { value: "shots", label: "shots" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" },
   
  ];

  const intakeTimeOptions = [

    { value: "before food", label: "Before food" },
    { value: "after food", label: "After food" },
  ];


  const handleSearchChange = (e) => {
    const val = e.target.value;
    const key = e.target.name;
    if (key === "frequency") {
      setFrequency(val)
    }
    if (key === "intakeTime") {
      setIntakeTime(val)
    }
    if (key === "period") {
      if(period===""){
        setPeriod(1)
        setDuration(durationValue)
      }
      setDuration(Number(durationValue)*Number(val))
      setPeriod(val)
      
    }
    if (key === "durationValue") {
     
        setDurationValue(val)
      setDuration(Number(val)*Number(period))
       
    }
    if (key === "intakeUnitValue") {
     
      setIntakeUnit(val)
 
     
  }
    if (key === "intakeCount") {
      setIntakeCount(val)
    }
    if (key === "dosage") {
      setDosage(val)
    }
    if (key === "remark") {
      setRemark(val)
    }
  }
  const SubmitPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Data submitted successfully!!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
 

  const medicineSubmit = (MedData) => {  
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
   
    // let intakecount_label = "";
    // if (intakecount_label === "Daily") {
    //   setIntakeCount(1);
    // }
    // else if (intakecount_label === "Weekly") {
    //   setIntakeCount(2);
    // }
    // else if (intakecount_label === "Monthly") {
    //   setIntakeCount(3);
    // }
    // else if (intakecount_label === "Alternate days") {
    //   setIntakeCount(4);
    // }
    // else if (intakecount_label === "weekly twice") {
    //   setIntakeCount(5);
    // }
    // else if (intakecount_label === "weekly Thrice") {
    //   setIntakeCount(6);
    // }
   if (dosage<1) {
      setMedErr("*Intake Count is mandatory!");
      setErrorShow(true);
    }
    if (duration===0) {
      setMedErr("*Please choose a valid duration!");
      setErrorShow(true);
    }
    
 else{
    const dataToSend = {
      frequency: frequency,
      intake: intakeCount,
      intake_time: intakeTime,
      duration:  duration,
      intake_count: dosage,
      remarks: remark,
      intake_unit:intakeunit
    };

    axios
      .put(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/${data.data.id}/`, dataToSend, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        
        if (res.data.status === "success") {

          setModalShow(true);

        } else {
          setErrorMsg("error");
          setErrorSubmit(true);
        }
      })
      .catch((err) => {
        setErrorMsg("error");
        setErrorSubmit(true);
      });
    }
  };

  const SuccessPopup = (props) => {
    
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}> Remarks Added successfully </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const ErrorProcedurePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {medErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMsg !== ""
              ? errorMsg
              : "Error in Data Submission. Please try again!"}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };






  return (
    <>

      <div className="flex-head">
        <h4 className="title-of-tasks title-mg"> Edit Medicines</h4>
        <h5 className="title-of-tasks"> {data.data.medicine_name}</h5>
      </div>
      <br />

      <div className=" ">
        <div className="" >
          <div style={{ display: "flex" }}>

            <label className="col-4">*Frequency :</label>
            <select
              name="frequency"
              type="text"
              className=" col-7"
              value={frequency}
              defaultInputValue={frequency}
              onChange={handleSearchChange}
              options={freqOptions}
            >
              {freqOptions.map(item => (
                <option value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

          </div><br />
          <div style={{ display: "flex" }}>

            <label className="col-4">*Intake Time :</label>
            <select

              name="intakeTime"
              type="text"
              className=" col-7"
              value={intakeTime}
              defaultInputValue={intakeTime}
              onChange={handleSearchChange}
              options={intakeTimeOptions}
            >
              {intakeTimeOptions.map(item => (
                <option value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

          </div><br />
          <div className="" style={{ display: "flex" }}>

            <label className="col-4">*Duration :</label>
            <div className="col-7"  >
              <label className="title-of-tasks title-mg">{duration}Days</label>
              <div style={{ display: "flex", marginLeft: "-10px" }}>
                <input
                  name="period"
                  type="number"
                  min="1"
                  value={period}
                  className=" col-3"
                  onChange={handleSearchChange}
                /><span>&nbsp;&nbsp;</span>
                <select
                  name="durationValue"
                  type="text"
                  className=" col-9"
                  value={durationValue}
                  onChange={handleSearchChange}
                  options={durationOptions}
                > <option></option>
                  {durationOptions.map(item => (
                    <option value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>

              </div>
            </div></div><br />
          <div style={{ display: "flex" }}>

            <label className="col-4">*Intake Period :</label>
            <select
              name="intakeCount"
              type="text"
              className=" col-7"
              value={intakeCount}
             // defaultInputValue={intakecount_label}
              onChange={handleSearchChange}
              options={intakecountoptions}
            >
              {intakecountoptions.map(item => (
                <option value={item.label}>
                  {item.label}
                </option>
              ))}
            </select>

          </div><br />
          <div style={{ display: "flex", paddingRight: "3%" }}>

            <label className="col-4">*Intake Count:</label>
            <input
              name="dosage"
              style={{ marginLeft: "3%" }}
              type="number"
              min="1"
              max="200"
              className=" col-7"
              value={dosage}
              defaultInputValue={dosage}
              onChange={handleSearchChange}

            />

          </div><br />
          <div style={{ display: "flex" }}>

<label className="col-4">*Intake Unit :</label>
<select

  name="intakeUnitValue"
  type="text"
  className=" col-7"
  value={intakeunit}
  defaultInputValue={intakeunit}
  onChange={handleSearchChange}
  options={IntakeUnitOptions}
>
  {IntakeUnitOptions.map(item => (
    <option value={item.value}>
      {item.label}
    </option>
  ))}
</select>

</div><br />
          <div style={{ display: "flex", paddingRight: "3%" }}>

            <label className="col-4">Remark :</label>
            <textarea
              name="remark"
              style={{ marginLeft: "3%" }}
              type="text"
              className=" col-7"
              value={remark}
              defaultInputValue={remark}
              onChange={handleSearchChange}

            />

          </div><br />

          <button    onClick={() =>{medicineSubmit() }} type="submit" className="btn btn-primary btn-col">
            {" "}
            Save
          </button>{" "}

        </div>
      </div>


      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            setErrorMsg("");
            // setTableData([]);
            // setRerender(!reRender);
          }}
        />
      ) : (
        ""
      )}

      {submitMsg !== "" ? (
        <SuccessPopup
          show={successSubmit}
         
          onHide={() => {
            setSuccessSubmit(false);
            setSubmitMsg("");

          }}
        />
      ) : (
        ""
      )}
         {medErr !== "" ? (
        <ErrorProcedurePopup
          show={errorShow}
          onHide={() => setErrorShow(false)}
        />
      ) : null}




      {modalShow ? (
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            data.modalClose();
          
           // setSubmitMsg("");
          }}
        />
      ) : (
        ""
      )}


    </>
  );
}

export default EditMedicine;
