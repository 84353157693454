import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditAllergy(props) {
  const [error,setError]=useState({
    allergyname:''
      
     });
    const[data, setData]= useState(props.data)
    const[completeShow,setCompleteShow]=useState(false)
    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

   

    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


      const CompletePopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'> Successfully Saved</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
             <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
             
             
            </Modal.Footer>
          </Modal>
        );
      }
      

const saveChanges = (e)=> {
    e.preventDefault();
 ;
  if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 let dataToSend={}
 if(props.data.allergy_name===data.allergy_name&&props.data.allergy_type===data.allergy_type){
  dataToSend = {                           
    allergy_stage: data.allergy_stage,                     
    description: data.description
  }
 }else if(props.data.allergy_name===data.allergy_name){
  dataToSend = {                           
    allergy_type : data.allergy_type,  
    allergy_stage: data.allergy_stage,                     
    description: data.description
  }
 }
 
 else{
   dataToSend = {
    allergy_name: data.allergy_name,                               
    allergy_type : data.allergy_type,
    allergy_stage: data.allergy_stage,                     
    description: data.description
  }
}
     axios
       .put(`${APIURL}/api/v1/staff/master-allergy-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status=="success") {
         setCompleteShow(true)
          setData({
            allergy_name : '',
            allergy_type : '',
            allergy_stage : '',
            description : ''
          });
        }
          else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
        } //  setSuccessShow(false)
    }

    const validate=()=>{
    
      let input = data;
      let errors = {};
      let isValid = true;
    setError({});
  
  
      if (!input["allergy_name"] || input["allergy_name"]==="" ) {
        isValid = false;
        errors["allergyname"] = "Please enter allergy Name";
      }
      
      
  
       setError(currentstate=>({
             ...currentstate,
            ...errors
          }));
      
  
      return isValid;
  }
  
    

    return (
        <>
     <h4 style={{marginTop:'0'}} className="title-of-page">Edit Allergy</h4>
        <div style={{maxHeight:'1000px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                <div style={{display:"flex"}}>
                    <label className='align-rental'>*Allergy Name: </label>
                    <input type='text' className='form-control title-flex' name='allergy_name' value={data.allergy_name} onChange={handleTitle} /></div>
                    {error.allergyname ? <div className="error-validation-msg error-feature-inventory">{error.allergyname}</div> : null}
                </div>

                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Allergy Type: </label>
                    <input type='text' className='form-control title-flex' name='allergy_type' value={data.allergy_type} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Allergy Stage: </label>
                    <input type='text' className='form-control title-flex' name='allergy_stage' value={data.allergy_stage} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Description: </label>
                    <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                </div>

            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
{completeShow ? 

<CompletePopUp
show={completeShow}
onHide={() =>  {setCompleteShow(false)
props.modalClose()}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditAllergy
