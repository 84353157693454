import React,{useState,useEffect,useContext} from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./presc.css";
import "./presctable.css";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
import EditMedicine from './EditMedicine'
//import {MedContext} from "../contexts/MedContext";
import "./currentmedications.css";
import DatePopUp from './DatePopUp';
//import ImageScale from '../ImageScale/ImageScale';
import ViewRemark from './ViewRemark';

function CurrentMedications(props){
const [img ,setImg] = useState([]);
const [index ,setIndex]= useState();
//const [medRender,setMedRender]=useContext(MedContext);
const [dateShow ,setDateShow] =useState(false)
const [prescData ,setPrescData]= useState([]);
  const [medList,setMedList]=useState([    { drug: "",
                 
  from: "",
  to:"",
 
  dosage: "",

  intake_count: [],
  intake_time: '',
  remarks: ""
   }]);

  const [editShow, setEditShow]= useState(false)      
  const [render,]=useState(true);
  const [errorMsg,setErrorMsg]=useState('');
  const [doctor1,setDoctorname]=useState('');
  const [submitMsg,setSubmitMsg]=useState("");
  const [modalShow,setModalShow] =useState(false);
  const [imageShow, setImageShow]= useState(false)
  //const [clickShow ,setClickShow]= useState(false)
  const [remarkData, setRemarkData]= useState('')
  const [viewShow, setViewShow]= useState(false)
const [errorSubmitModalShow,setErrorSubmitModalShow]=useState(false);
const [deleteMedConfirmMsg,setDeleteMedConfirmMsg]=useState("");
const [deleteMedConfirmModalShow,setDeleteMedConfirmModalShow]=useState(false);
const [refresh, setRefresh]=useState(false);
const [medIndexToDelete,setMedIndexToDelete] = useState();
const [medDelete, setMedDelete] = useState(false);
const [medRefresh, setMedRefresh] = useState(false);
const[dataList,setDataList]=useState([])
 const id = props.id;
 const refrsh = props.refresh;

 const [keys,] = useState({'z' :false });


           useEffect(()=>{
            const handleKeyDown =(e)=>
            {      
              keys[e.key]=true
          
              if(e.altKey && keys['z']){
                setErrorSubmitModalShow(false)
                setErrorMsg('')
                setModalShow(false)
               // setMedRender(!medRender)
                setSubmitMsg('')
              }
          
            }
            window.addEventListener('keydown', handleKeyDown)
            
            
            const handleKeyUp =(ev)=>
          {
              
              keys[ev.key]= false
            }
          
          window.addEventListener('keyup', handleKeyUp)
          
          
          
            return()=>{
              window.removeEventListener('keydown', handleKeyDown)
              window.removeEventListener('keyup', handleKeyUp)
              
            }
          },[submitMsg, errorMsg]);


 
  useEffect(()=>{

    setMedList(medList)
   
  },[render, medList]);
  

  useEffect(()=>{

  

    const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

   
    
   
    axios.get(`${APIURL}/api/v1/doctor/update-current-medication/?medicines_list=current_medicines&patient_id=${id}`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){

        let doctorname="";

        let data = []
        if(res.data.doctor.length ===0 && res.data.patient.length ===0){
          data=[]
        }

        if(res.data.doctor.length !==0){
          res.data.doctor.map((item)=> {
            data = data.concat(item.medicines)
            // doctorname=data.doctor_name;
          })
        }
        if(res.data.patient.length !==0){
          res.data.patient.map((item)=> {
            data = data.concat(item.medicine)
            //patient_name=data.patient_name;
          })
        }


        // if(res.data.prescription.length !==0){
        //   res.data.prescription.map((item)=> {
        //     data = data.concat(item.medicines)
        //   })
        // }
        setMedList(data)
        //setDoctorname(doctorname)
       
      //  if (res.data && res.data.doctor && res.data.doctor.length > 0) {
      //     const data = res.data.doctor.medicines;
      //     setMedList(data);
      


      }else{

        setErrorMsg(res.data.message? res.data.message : "Error in fetching data");
        setErrorSubmitModalShow(true);
       // setMedList([]);
      }


    })
    .catch(err=>{
  
  setErrorMsg("Error in fetching data");
  setErrorSubmitModalShow(true);
})
 

 
},[id, refresh, refrsh])


/*const AddRow=()=>{
  const list = medList;
  list.push({drug:"", strength: "",form:"",duration: "",route:"", dosage:"",frequency:"",intake_count:[]});
  
  setMedList(list);
  setRender(!render);
  
}*/




/*const handleNewRow=(e)=>{
  e.preventDefault();
     const newMed = medList;
    newMed.push({drug:""});
    setMedList(newMed);

}*/

let newDate= ''
function DateHandler(childData){
  newDate=childData
 
}

const callDelete=()=>{
  
  if(newDate !== ''){

   const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');

   
    const dataToUpdate={
      med_id: index,
      stop_medicine:true,
      stop_medicine_date:newDate
    }
   
    axios.put(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/?patient_id=${id}/`,dataToUpdate, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   
    
      if(res.data.status==="success"){
        setSubmitMsg(res.data.message? res.data.message : "Successfully stopped medicine");
        setModalShow(true);

  
   
      

      }else{
        setErrorMsg(res.data.message? res.data.message: "Error in removing medicine");
        setErrorSubmitModalShow(true);
      }


    })
    .catch(err=>{
       setErrorMsg("Error in removing medicine");
        setErrorSubmitModalShow(true);
  
 
    })
  }

}
const modalClose = () => {
  setEditShow(false)    
  setRefresh(!refresh);
};

const EditPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
      <EditMedicine data={dataList} modalClose={modalClose}/>

      </Modal.Body>
      <Modal.Footer>
         
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
       

      </Modal.Footer>
    </Modal>
  );
}


const handleShow=(index)=> {
  const newarr=medList;
  const newList = newarr.filter((elt,i)=>{
    return i=== index
  })

  let data=[]
  for(let i=0; i<prescData.length; i++) {
    if(prescData[i].prescription_id===newList[0].prescription_id){
      prescData[i].image.map(item=>{
            data= data.concat(item)
        })
      setImg(data)
      
      break;
    }
    else {
      setImg([])
    }
  }

  
  setImageShow(true)
  
  
}

const ViewPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <ViewRemark data={remarkData}/>

      </Modal.Body>
      <Modal.Footer>
         
        <Button variant="secondary" onClick={props.onHide}>Close</Button>
       

      </Modal.Footer>
    </Modal>
  );
}

const handleView =(data,index) => {
  setRemarkData(data)
  setViewShow(true)
}

const renderRemarks= (cellInfo) => {
  if(medList[cellInfo.index][cellInfo.column.id]==="" || medList[cellInfo.index][cellInfo.column.id]===null){
    return null
   }
   else{
   
    return (
      <button onClick={()=> handleView(medList[cellInfo.index][cellInfo.column.id], cellInfo.index)} className='btn btn-primary btn-col btn-square btn-small'>View</button>
      )
   }
}


const renderEditable=(cellInfo)=>{

console.log("cellinfodata",cellInfo)
  return(
    <div 
        className="form-control"
      > {medList[cellInfo.index][cellInfo.column.id]}</div> )
}

const renderEditableStrength=(cellInfo)=>{

  return(
    <div 
        className="form-control"
      > {medList[cellInfo.index].strength}{medList[cellInfo.index].strength_unit}</div> )
}
const renderEditableCount=(cellInfo)=>{

  return(
    <div 
        className="form-control"
      > {medList[cellInfo.index].intake_count}{medList[cellInfo.index].intake_unit}</div> )
}



const renderEditableDuration=(cellInfo)=>{
let day = 'day';
if (medList[cellInfo.index][cellInfo.column.id] && parseInt(medList[cellInfo.index][cellInfo.column.id]) > 1) {
  day = 'days'
}
  return(
    <div 
        className="form-control"
      > {medList[cellInfo.index][cellInfo.column.id]} {day}</div> )
}

const renderDr=(cellInfo)=>{
const creator =medList[cellInfo.index]
const type =creator.creator_type
  return(

    type==='doctor'?

    <div 
        className="form-control"
      ><h6> {medList[cellInfo.index].doctor}</h6>
       <h6> {medList[cellInfo.index].hospital} </h6> </div> :

       type==='self'?

       <div 
        className="form-control"
      ><h6>Added by Patient</h6>
       </div> : 

       type=== undefined ?
       <div 
        className="form-control"
      ><h6>Added as a Prescription</h6>
      <button className="presc-btn" 
       onClick={()=>{handleShow(cellInfo.index);}}>Click to View</button>
       </div> : null

        )
}
const renderIntakeTime=(cellInfo)=>{
  const food_time=medList[cellInfo.index][cellInfo.column.id];
  // let food_label="";
 
  // if(food_time=== "1"){
  //   food_label= "Before food";
  // }
  // else if(food_time=== "2"){
  //   food_label= "After food";
  // } 
   return(

    <div 
        className="form-control"
      > {food_time}</div> 
    

)
}
const renderIntakeCount=(cellInfo)=>{
  const intakeval=medList[cellInfo.index][cellInfo.column.id];
  let intakecount_label="";
  if(intakeval=== "1"){
    intakecount_label= "Daily";
  }
  else if(intakeval=== "2"){
    intakecount_label= "Weekly";
  }
  else if(intakeval=== "3"){
    intakecount_label= "Monthly";
  } 
  else if(intakeval=== "4"){
    intakecount_label= "Alternate days";
  } 
  else if(intakeval=== "5"){
    intakecount_label= "weekly twice";
  } 
  else if(intakeval=== "6"){
    intakecount_label= "weekly Thrice";
  } 
 
   return(

    <div 
        className="form-control"
      > {intakeval}</div> 
    

)
}




  const dateSelect =(index)=> {
    const newarr=medList;
  const newList = newarr.filter((elt,i)=>{
    return i=== index
  })
    setIndex(newList[0].id)
    setDateShow(true)
  }

  const deleteMedicineRow = (index)=>{

    setDeleteMedConfirmMsg('success');
    setDeleteMedConfirmModalShow(true);
    setMedIndexToDelete(index);
     
  }
  const handleEdit = (data)=>{
  setDataList(data)
  setEditShow(true)
  }
  const renderEdit= (cellInfo) => {
    if(medList[cellInfo.index][cellInfo.column.id]==="" || medList[cellInfo.index][cellInfo.column.id]===null){
      return null
     }
     else{
     
      return (
        <i style={{color:" var(--theme-blue)"}}  onClick={()=>handleEdit(medList[cellInfo.index]) } class="fas fa-pencil-alt" aria-hidden="true"></i>
        )
     }
  }



const columns = [

  {
    Header: <i className="fas fa-trash-alt"></i>,
    Cell: props=>{
      return (
        <i className="fas fa-trash-alt trash-btn" style={{marginLeft:"20%"}}  onClick={() => { deleteMedicineRow(props.index) }} ></i>
      )
    },
    sortable:false,
    filterable:false,
    minWidth:50
  
  },
{
  Header: "Drug Name",
  accessor: "drug",
  Cell: renderEditable,
  
  minWidth:100,
   sortable:false,
   filterable:false,
   width:200

},

{
  Header: "Strength",
  accessor: "strength",
  minWidth:80,
  width:110,
  Cell: renderEditableStrength,
  sortable:false,
  filterable:false

},
{
  // Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
  //              data-placement = "top" title = "INTAKE COUNT">COURSE</span>,
  Header: "Intake Time",
  accessor: "intake_time",
  Cell: renderEditable,
  sortable:false,
  width:180,
  minWidth:100,
  filterable:false

},
{
  // Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
  //              data-placement = "top" title = "INTAKE COUNT">COURSE</span>,
  Header: "Intake Period",
  accessor: "intake",
  Cell: renderEditable,
  sortable:false,
  width:180,
  minWidth:100,
  filterable:false

},
{
  Header:"Intake Count",
  accessor: "intake_count",
  minWidth:100,
  width:180,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header:"Intake Unit",
  accessor: "intake_unit",
  minWidth:100,
  width:180,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: "Dosage Form",
  accessor: "form",
  minWidth:120,
  width:150,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
// {
  // Header: "Intake Time",
  // accessor: "intake_time",
  // minWidth:120,
  // width:150,
  // Cell: renderIntakeCount,
  // sortable:false,
  // filterable:false

// },
// {
//   Header: "TO",
//   accessor: "to",
//   minWidth:80,
//   width:120,
//   Cell: renderEditable,
//   sortable:false,
//   filterable:false

// },
{
  Header: "Frequency",
  accessor: "frequency",
  minWidth:140,
  width:140,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: "Route",
  accessor: "route",
  minWidth:80,
  width:130,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: "Duration",
  accessor: "duration",
  minWidth:80,
  width:130,
  Cell: renderEditableDuration,
  sortable:false,
  filterable:false

},
{
  Header: "Prescribed by",
  accessor: "doctor",
  Cell: renderDr,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:100,
   sortable:false,
   filterable:false,
   width:200

},
{
  Header: "Remarks",
  accessor: "remarks",
  Cell: renderRemarks,
  sortable:false,
  width:120,
  minWidth:70,
  filterable:false

},
{
  Header: "",
  accessor: "edit",
  Cell: renderEdit,
  sortable:false,
  width:50,
  minWidth:100,
  filterable:false

},
]


const ErrorSubmitPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg} </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}


const DeletePopUp = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-success">Deleted Successfully</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-primary btn-col" onClick={props.onHide}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const SubmitPopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{submitMsg}!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button> 
       
      
      </Modal.Footer>
    </Modal>
  );
}

const ImagePopUp=(props)=> {
  return (
    <Modal
      {...props}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
              {/* <ImageScale img={img} presc={true} /> */}
          
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button> 
       
      
      </Modal.Footer>
    </Modal>
  );
}

const handleDeleteMed=()=>{


  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
    

const dataToSend={
  is_deleted:"True"
}
  
    axios.put(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/${medList[medIndexToDelete].id}/`, dataToSend,{ headers: {
      "Content-Type": "application/json",
      
      Authorization : 'Token '+str,
  }


    })



    .then(res=>{

       if(res.status==200){
   
    
     setMedDelete(true);
    setMedIndexToDelete(null)
     //setRefresh(!refresh)
   }
 else{
     setSubmitMsg("error");
    
   
   }

    })
    .catch(err=>{
  
  setSubmitMsg("error");

})


 }

const DeleteMedConfirmPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4  className='title-of-tasks' id="confirm-reject">Please confirm to delete Current Medicine</h4><br/><br/>
        <div className='title-of-tasks' >
         <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
         <span>&nbsp;&nbsp;</span>
        <Button variant="danger" onClick={()=>{
     
          setDeleteMedConfirmModalShow(false);
            handleDeleteMed();
            }}> Confirm</Button>

       
</div>
      </Modal.Body>
     
    </Modal>
  );
}

const DateChange=(props)=> {
  return (
    <Modal
      {...props}
      dialogClassName="modal-90h"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        <DatePopUp date={DateHandler} />        
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button className='btn btn-primary btn-col' onClick={props.onHide}>Close</Button>
       <Button variant='danger' onClick={props.confirm}>Confirm</Button>  
       
      
      </Modal.Footer>
    </Modal>
  );
}

  return(
    <div className="overlay-body">


    <div className="add-presc-page"><br/>
   
   
    {/*<h4> Patient : ID {id} </h4><br/> */}

<div className="current-table-holder">
    <ReactTable 
         columns={columns}
         data={medList}
         defaultPageSize = {5}
         filterable
         resizable
         noDataText={"No data"}
         className="observation-table"
         >

         </ReactTable>
         <br/>
  </div>
       
          
        
        
      </div>
     







  {errorMsg !=="" ?
    <ErrorSubmitPopup
        show={errorSubmitModalShow}
        onHide={() =>  {setErrorSubmitModalShow(false);
                        setErrorMsg('')}}
        
      />   : null } 



 {submitMsg!=="" ? 

          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {setModalShow(false);
                        // setMedRender(!medRender);
                        setSubmitMsg('')}}
      /> 
      : ""}

      
  {medDelete ? 

    <DeletePopUp
    show={medDelete}
    onHide={() =>  {setMedDelete(false);setRefresh(!refresh)
    }}
    /> 
    : ""}

{imageShow ? 

<ImagePopUp
show={imageShow}
image={img}
onHide={() =>  {setImageShow(false)}}
/> 
: null}

{dateShow ? 

<DateChange
show={dateShow}
onHide={() =>  {setDateShow(false)}}
confirm={()=>{ callDelete(); if(newDate!==''){
                                 setDateShow(false)
                                }
                             }}
/> 
: null}

{deleteMedConfirmMsg==="success" ? 

<DeleteMedConfirmPopup
show={deleteMedConfirmModalShow}
onHide={() =>  setDeleteMedConfirmModalShow(false)}
index= {medIndexToDelete}
/>

 : 
   '' } 
{
    editShow ? 
      <EditPopup
        show={editShow}
        onHide= {() => { setEditShow(false)     
        }}
      /> : null
    }
{
      viewShow ? 
      <ViewPopup
        show={viewShow}
        onHide= {() => { setViewShow(false)     
        }}
      /> : null
    }
  
</div>    
)
}

export default CurrentMedications;