import React ,{useState, useContext} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import './hospprofile.css'
import {Button ,Modal } from 'react-bootstrap';

function DeliveryUpdate(props) {

    const[delivery ,setDelivery] =useState('')
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);

    const [error,setError]=useState({
        delivery:"",
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='delivery'){
            setDelivery(val)
          }  
        }

        const  validate = () => {
          //let input = email;
          let errors = {};
          let isValid = true;
    
          if (delivery==="" ||delivery < 1  ) {
            isValid = false;
            errors["delivery"] = "Please check delivery radius and must be greater than 1.0";
          }
      
          setError(currentstate=>({
            ...currentstate,...errors
          }))
      
          return isValid;
      }

      const handleContinue =async(e) => {
        e.preventDefault();
    
        if( validate() ){
          submitDelivery()
        }
      }

        const submitDelivery = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
            let str= tokenString.replace(/["]+/g, '') 
         
            const data = {
                delivery_radius : delivery
            }
         
            axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/` ,data, { headers: {
         
             "Authorization" : 'Token '+str  }})
           .then(res=>{
           
                 
             if(res.data.status==="success"){
               
               setSubmitMsg("success");
               setShow(true)         
           
             }else{
               setErrorMsg(res.data.message);
               setErrorSubmit(true);
             
              
             }
           
           })
           .catch(err=>{
             setErrorMsg("error");
 
             setErrorSubmit(true);
           
           })
         
          }


          const ConfirmPopUp = (props) => {
            return(
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                //  backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4 class="updated-message">Updated Successfully</h4>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
          
                </Modal.Footer>
              </Modal>
            )
          }
          
          
          const SubmitErrorPopup =(props)=>{
              
          
            return (
              <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                //  backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 class="updated-message" style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>
          
                 
                 
          
                </Modal.Footer>
              </Modal>
            );
          }



    return (
        <div>
            <div className="item">
              
            <div className="row">
              

              


                      <input
                        type="number"
                   
                        name="delivery"
                        value={delivery}
                      
                        onChange={handleChange}
                      />
                     
              

              </div>
              
              
              {/* <input className="form-control" type="text" maxLength="20" name="practise" placeholder="Practise Type" onChange={handleChange}/> */}

                {error.delivery ? <div className="error-validation-msg"> {error.delivery}</div> : null}

            </div>
            <br/>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update delivery radius</button>
            </div>

            {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              props.handle();
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default DeliveryUpdate
