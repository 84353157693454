import React, {useState, useEffect} from 'react'
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import axios from "axios";
import {APIURL} from "../../../Global";
import { useAlert } from "react-alert";
//import ViewRemark from '../HospDr_AddPrescription/ViewRemark';

function PastPayments(props) {


    const [paymentData,setPaymentData]= useState([])
    const [modalShow, setModalShow]= useState(false);
    const [errorMsg,setErrorMsg]=useState("");
    const [submitMsg,setSubmitMsg] = useState("");
     const [successSubmit,setSuccessSubmit]=useState(false);
     const [errorSubmit,setErrorSubmit]=useState(false);
     const [perPage, setPerPage] = useState(10);
     const [totalRows, setTotalRows] = useState(0);
     const [page, setPage] = useState(1)
     const [infoShow, setinfoShow] = useState(false);
     const [infoShow1, setinfoShow1] = useState(false);
     const [infoData, setInfoData] = useState("");
     const alert = useAlert();
     const [showAlert, setShowAlert] = useState(false);
    

     const PaymentPage=(page, per_page) =>{
  
          const tokenString = sessionStorage.getItem("usertoken");
    
          let v2 = tokenString.replace(
            /["]+/g,
            ""
          ); /* REGEX To remove double quotes from tokenstring */
    
    
            axios
            .get(`${APIURL}/api/v1/service-provider/past-payments/?patient_id=${props.id}&page=${page}&per_page=${per_page}`, {
              headers: { Authorization: "Token "+ v2 },
            })
            .then((res) => {
              if (res.data.status === "success") { 
                let data =[];
                data=res.data.orders
                setPaymentData(data)
                //setPaymentData(data.sort((a, b) => new Date(b.payment_date) - new Date(a.payment_date)));
                console.log()
            }
            else{
    
            }
        }).catch((error)=>{
    
        })
  
      }
const handleInfoPopUp = (item,type) => {

   
        const tokenString = sessionStorage.getItem("usertoken");
    
        let v2 = tokenString.replace(/["]+/g, "");
      
         axios.get(
                          `${APIURL}/api/v1/service-provider/liability-detail/${item}/?checkoutcart_id=${type}`,
                          {
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: "Token " + v2,
                            },
                          }
                        )
          
              // Set the expanded card data to be an object containing both the liability and message data
              .then((res) => {
              
                if (res.data.status === "success") {
              
              
                //           //  //setDetailData(res.data.message)
                               
              
                //           //     }
                //             })
    
                let data = res.data.message
    
               if(data.length>0) {
                setInfoData(data)
                setinfoShow(true)
              }
              else{
                setShowAlert(true);
             
                //setShowAlert(true);
                setinfoShow1(true)
            
               
              }
     
            }
            })
            .catch(error => console.error(error));
        
    
        // setExpandedCardId(checkoutcartId);

       // setInfoData(item);
       // setinfoShow(true);
     
      };
      const handleHideAlert = () => {
        setShowAlert(false);
      };

    const renderEditable = (cellInfo) => {
     
        if(paymentData[cellInfo.index][cellInfo.column.id]){
            return (
                <div className="fixed-co"> {paymentData[cellInfo.index][cellInfo.column.id]}
            </div>
            )
        }
        else {
            return <span className='text-danger'>NIL</span>
        }
    }

    const renderEditablePaid = (cellInfo) => {
      if(paymentData[cellInfo.index][cellInfo.column.id]){

          return (
              <div className="fixed-co"> {paymentData[cellInfo.index][cellInfo.column.id]}     <i
              style={{ color: "#75759c", paddingLeft: "5%" }}
              className="fas fa-info-circle fa-sm"
              onClick={() => {
               
               handleInfoPopUp(paymentData[cellInfo.index].master_payment_id,paymentData[cellInfo.index].checkoutcart_id);
              }}
            ></i></div>
          )
      }
      else {
          return <span className='text-danger'>NIL</span>
      }
  }

    const handlePageChange = page => {
      PaymentPage(page + 1, perPage);
    }
    const handlePerRowsChange = async (newPerPage, pageindex) => {
      setPerPage(newPerPage);
  
    }
    useEffect(() =>{
      PaymentPage(page, perPage)
     },[perPage])

    const renderDate=(cellInfo)=> {

        let dateString = paymentData[cellInfo.index][cellInfo.column.id];
    
    let dateObject = new Date(dateString);
    
    const updatedDate = dateObject.toString();
        return (<div >
          <h6> {updatedDate.slice(4,16)}  </h6>
           <h6> {updatedDate.slice(16,24)}</h6>
         </div>)
    
       }


    const SuccessPopup=(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <h5 style={{color:"green"}}>{props.msg} </h5>
              
              
           
             
            </Modal.Body>
            <Modal.Footer>
               
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
               
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }
       
      const InfoPopup = (props) => {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter"><h5>  {infoData[0].paid_for ? infoData[0].paid_for : infoData[0].order_type === "master_scan"
            ? "Scan"
            : infoData[0].order_type === "misc"
            ? "Misc"
            : infoData[0].order_type === "master_medicine"
            ? "Medicine"
            : infoData[0].order_type === "master_procedure"
            ? "Procedure"
            : infoData[0].order_type === "master_consumable"
            ? "Consumables"
            : infoData[0].order_type === "master_labtest"
            ? "Test"
            : infoData[0].order_type === "master_procedure"
            ? "Procedure"
            : infoData[0].order_type === "master_consumable"
            ? "Consumables"
            : infoData[0].order_type === "master_labtest"
            ? "Test"
            : infoData[0].order_type === "registration"
            ? "Registration"
            : ""} 
    </h5>
</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
               
                {/* <h6>infoData.message.item_service_name</h6> */}
            {infoData.map((item,index) => {
              debugger;
                 return (            
                 <div className="row-of-features">
                 
                     <h6><b style={{color:"var(--dark-teal)"}}>{item.item_service_name} (Qty:{item.quantity===null ? "1" :item.quantity})</b>       </h6>
                  
 
                 
    
                  
                  
                 </div>
                 );
              })}
                  
              
              </div>
            </Modal.Body>
          </Modal>
        );
      };

      const InfoPopup1 = (props) => {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              {/* <Modal.Title id="contained-modal-title-vcenter"><h5>{type}</h5></Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div>
               
                <h6 style={{color:"red" ,justify:"center"}}>No Data!!</h6>
          
                 </div>
            
           
                  
              
             
            </Modal.Body>
          </Modal>
        );
      };
      
       const SubmitErrorPopup =(props)=>{
          
      
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorMsg!=='' ? errorMsg : "Error in Data Submission. Please try again!" } </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }    

    const columnsProcedure = [
        
        {
            Header: "Invoice No",
            accessor: "invoice_no",
            Cell: renderEditable,
          //   style:{
          //     backgroundColor:"rgba(0,0,0,0.1)"
          //   },
            width:110,
            minWidth:100,
             sortable:false
          
          },
        {
          Header: "Paid For",
          accessor: "paid_for",
          Cell: renderEditablePaid,
        //   style:{
        //     backgroundColor:"rgba(0,0,0,0.1)"
        //   },
          width:200,
          minWidth:100,
           sortable:false
        
        },
        {
            Header: "Date",
            accessor: "payment_date",
            Cell: renderDate,

            width:180,
            minWidth:100,
             sortable:false
          
          },
        {
          Header: "Method",
          accessor: "payment_mode",
          Cell: renderEditable,

          width:150,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Status",
          accessor: "paid_status",
          Cell: renderEditable,

          width:150,
          minWidth:100,
           sortable:false
        
        },
        {
          Header: "Paid Amount",
          accessor: "paid_amount",
          Cell: renderEditable,

          width:150,
          minWidth:100,
           sortable:false
        
        },
        {
            Header: "Added By",
            accessor: "billed_by",
            Cell: renderEditable,

            width:200,
            minWidth:100,
            sortable:false
          
          },
        
    ]


    const displayTable=<ReactTable 
    columns={columnsProcedure}
    data={paymentData}
    defaultPageSize={perPage - 1}
    className="procedure-table container pr-0 pl-0"
    pageSizeOptions={[perPage - 1,10, 20, 25, 50,100]}
    resizable
    pagination
    paginationServer
    paginationTotalRows={totalRows}

    onPageChange={(pageIndex) => { handlePageChange(pageIndex); setPage(pageIndex + 1) }}
    onPageSizeChange={handlePerRowsChange}
 
           
    >

    </ReactTable>

    return (
        <div id='procedure-table-list'>
            <h3 className='title-of-tasks'>Past Payments of <span className='past-payment-name'>{props.name}</span></h3>
            {/* {showAlert ?
       
         alert.show("no data")
    
      :""} */}
            {displayTable}
        <br/>


 
        {submitMsg!=='' ?
    <SuccessPopup
        show={successSubmit}
        msg={submitMsg}
        onHide={() => { setSuccessSubmit(false);
                        setSubmitMsg('');}}
       
      />: ''}
       {infoShow ? (
        <InfoPopup
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}
        />
      ) : (
        ""
      )}

{infoShow1 ? (
        <InfoPopup1
          show={infoShow1}
          onHide={() => {
            setinfoShow1(false);
          }}
        />
      ) : (
        ""
      )}


{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                        setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default PastPayments
