import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import { Badge } from 'react-bootstrap';
import DatePicker from "react-date-picker";
import { useAlert } from "react-alert";
import service1 from "../../assets/images/1.png";
import service2 from "../../assets/images/2.png";
import PatientNav from "./PatientNav";
import PatientFooter from "./PatientFooter";
import { CartContext } from "./AddToCartContext";
import OwlCarousel from 'react-owl-carousel';
import { Modal } from "react-bootstrap";
import Addresspage from "./Addresspage";
function SearchResult({ setShowNavbarAndFooter }) {
    const { state } = useLocation()
    const [id, setId] = useState(state.details);
    const [item,setItem] = useState({});
    const alert = useAlert();
    const [isEmpty, setIsEmpty] = useState(false)
    const [prod, setProd] = useState("")
    const [selected, setSelected] = useState([])
    const [color, setColor] = useState("")
    const [size, setSize] = useState("")
    const [weight, setWeight] = useState("")
    const { handleAddToCart } = useContext(CartContext);
    const [volume, setVolume] = useState("")
    const [colors, setColors] = useState([])
    const [sizes, setSizes] = useState([])
    const [weights, setWeights] = useState([])
    const [volumes, setVolumes] = useState([])
    const [colors1, setColors1] = useState([])
    const [sizes1, setSizes1] = useState([])
    const [weights1, setWeights1] = useState([])
    const [volumes1, setVolumes1] = useState([])
    const [img, setImg] = useState("")
    const [similarProd, setSimilarProd] = useState([])
    const [similarProdHosp, setSimilarProdHosp] = useState([])
    const [prodDetails, setProdDetails] = useState([])
    const [dateval, setDateVal] = useState("");
    const [dateToday, setDateToday] = useState("");
    const [distance, setDistance] = useState(0.0);
    const [qty, setQty] = useState(1);
    
    const [description, setDescription] = useState(true)
    const [reviews, setReviews] = useState(false)
    const [login_datas, setLogin_datas] = useState(
        JSON.parse(sessionStorage.getItem("logindatas"))
            ? JSON.parse(sessionStorage.getItem("logindatas"))
            : null
    );
    const [refresh,setRefresh]=useState(false)
    const [addressview, setAddressView] = useState(false);
    const [checkId, setCheckId] = useState("");
    const [addressList, setAddressList] = useState([])
    const [addres, setAddress] = useState()
    const [varientDetails, setVarientDeails] = useState()
    useEffect(() => {
        setShowNavbarAndFooter(false);
        return () => setShowNavbarAndFooter(true);
    }, [setShowNavbarAndFooter]);

    useEffect(() => {
        axios
        .get(`${APIURL}/api/v1/patient/hospital-services-detail/${id}`,
       
        )
    
        .then((res) => {
         
            if (res.data.status === "success") {
    
            const datas = res.data.data;
            console.log(datas);
           setItem({
            service:datas.service_name,
            service_category:datas.service_category,
            amount:datas.amount,
            discount:datas.discount,
            main_service:datas.main_service_name,
            hospital:datas.hospital,
            name:datas.hospital_name
           })
           setProd(datas)
          } else {
            //setLoading(false);
          }
        })
        .catch((err) => {
          // setLoading(false);
        });
    }, []);

    useEffect(() => {
        setIsEmpty(false)
       
     
        axios.get(`${APIURL}/api/v1/patient/product-variations/?service_name=${item.service}`, {
           
        })
            .then((res) => {
                if (res.data.status === "success") {
                    setSimilarProd(res.data.variations)

                    const filteredData = res.data.variations.filter(x => {
                        return x.item_service === item.item_service

                    })
                    setProdDetails(filteredData)

                }
            })

        axios.get(`${APIURL}/api/v1/patient/product-variations/?service_name=${item.service}&hospital_id=${item.hospital}`, {
            // headers: {
            //     Authorization: "Token " + str,
            // },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    let data = res.data.variations



                    const tempArray = data.filter(function (x) {
                        return x.item_service === id
                    })
                    if (item.service_category === "master_consumable" || item.service_category === "master_medicine") {
                        setImg(tempArray[0].image)
                    }
                    if (item.service_category === "master_consumable") {
                        setProd(tempArray)
                        setSimilarProdHosp(res.data.variations)
                        setSelected(tempArray)
                        res.data.variations.map((x) => {
                            if (x.color !== "" || x.color !== null) colors.push(x.color);
                        })
                        const colorTempArray = colors.filter(function (elem, pos) {
                            return colors.indexOf(elem) == pos;
                        });
                        const colorTempArray1 = colorTempArray.filter(function (x) {
                            return x !== null && x !== "" && x !== "null"
                        });
                        setColors(colorTempArray1)

                        res.data.variations.map((x) => {
                            if (x.size !== "" || x.size !== null) sizes.push(x.size);
                        })
                        const sizeTempArray = sizes.filter(function (elem, pos) {
                            return sizes.indexOf(elem) == pos;
                        });
                        const sizeTempArray1 = sizeTempArray.filter(function (x) {
                            return x !== null && x !== "" && x !== "null"
                        });
                        setSizes(sizeTempArray1)
                        res.data.variations.map((x) => {
                            if (x.weight !== "" || x.weight !== null) weights.push(x.weight);
                        })
                        const weightTempArray = weights.filter(function (elem, pos) {
                            return weights.indexOf(elem) == pos;
                        });
                        const weightTempArray1 = weightTempArray.filter(function (x) {
                            return x !== null && x !== "" && x !== "null" && x !== 0
                        });
                        setWeights(weightTempArray1)
                        res.data.variations.map((x) => {
                            if (x.volume !== "" || x.volume !== null) volumes.push(x.volume);
                        })
                        const volumeTempArray = volumes.filter(function (elem, pos) {
                            return volumes.indexOf(elem) == pos;
                        });
                        const volumeTempArray1 = volumeTempArray.filter(function (x) {
                            return x !== null && x !== "" && x !== 0 && x !== "null"
                        });
                        setVolumes(volumeTempArray1)
                    }
                }
            })

        setColors1(colors)
        setVolumes1(volumes)
        setWeights1(weights)
        setSizes1(sizes)


    }, [item.service]);
    useEffect(() => {
        if (login_datas) {
            const tokenString = sessionStorage.getItem("usertoken");

            let v2 = tokenString.replace(/["]+/g, "");
            axios
                .get(`${APIURL}/api/v1/patient/shipping-address/ `, {
                    headers: { Authorization: "Token " + v2 },
                })
                .then((res) => {

                    setAddressList(res.data.message);
                    const filteredData = res.data.message.filter(x => {
                        return x.is_default === true
                    })
                    setAddress(filteredData)
                })
                .catch((err) => { });
        }
    }, [refresh]);

    function AddressPopUp(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}
                >
                    <button
                        style={{ backgroundColor: "white", marginLeft: "95%" }}
                        variant="secondary"
                        onClick={(props) => { setAddressView(false);setRefresh(!refresh)}}
                    >
                        <i
                            class="fa fa-times-circle fa-2x"
                            style={{ color: "#F0A639" }}
                            aria-hidden="true"
                        ></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Addresspage addList={addressList} address={addres} />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }

    useEffect(() => {
        if (isEmpty === true)
            alert.error('no product available for given choice')
    }, [item.service_category === "master_consumable" && selected.length === 0]);


    useEffect(() => {
        setImg("")
        if (item.service_category === "master_consumable") {
            const tempArray = similarProdHosp.filter(function (x) {
                return (color ? x.color === color : true)
                    && (weight ? x.weight === weight : true)
                    && (size ? x.size === size : true)
                    && (volume ? x.volume === volume : true);
            });

            setSelected(tempArray)

            if (similarProdHosp.length > 0 && tempArray.length === 0) {
                setIsEmpty(true)
                setId("")
            }
            else if (similarProdHosp.length > 0 && tempArray.length !== 0) {
                setIsEmpty(false)
                setId(tempArray[0].item_service)
            }


            tempArray.map((x) => {
                colors1.push(x.color)
            })
            tempArray.map((x) => {
                sizes1.push(x.size);
            })
            tempArray.map((x) => {
                weights1.push(x.weight);
            })
            tempArray.map((x) => {
                volumes1.push(x.volume);
            })
            if (selected.length > 0) {
                setProd(selected[0])
                setImg(selected[0].image)
            }
            else{
                setProd("")
            }

        }
    }, [color, size, volume, weight])



    const handleChangeRadio = (event) => {
        console.log(selected)
        if (event.target.value == 1) {
            setDateVal('');
            setDateToday(new Date())

        }
        if (event.target.value == 2) {
            setDateVal(event.target.value);
        }


    }
    const handleDateChange = (date) => {
        if (date !== null) {

            setDateVal(date)
        }
    };
    const handleChangeQty = (e) => {
        setQty(e.target.value)
    };

    const addCart = () => {
        if (dateToday === "" && dateval === "") {
            alert.error("Please select date for delivery");

        }
        else {

            let date = dateToday !== "" ? dateToday : dateval
            let localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
            const dataToSend = {
                item_quantity: qty,
                id: id,
                service_category: item.service_category,
                date: localDate.toISOString().split("T")[0],
                item_service_priority: "critical",
                distance: distance,
                hospital: item.hospital,
                item_name: item.service,
                image: img,
                amount: item.amount,
                discount: item.discount,
                vat: item.vat

            };
            handleAddToCart(dataToSend)

        }


    }

    const addToCart = (val) => {
        debugger;


        const dataToSend = {
            item_quantity: qty,
            id: val.item_service,
            service_category: item.service_category,

            item_service_priority: "critical",
            distance: distance,
            hospital: val.hospital_id,
            
            item_name: val.consumable_name,
            image: val.image,
            amount: val.price,
            discount: val.discount,
            vat: val.vat

        }
        handleAddToCart(dataToSend)

    }


    const calculateDistance = (latt1, latt2, long1, long2) => {

        var R = 6371; // Radius of the earth in km
        const lat1 = parseFloat(latt1)
        const lat2 = parseFloat(latt2)
        const lon1 = parseFloat(long1)
        const lon2 = parseFloat(long2)
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        // return d;
        setDistance(parseFloat(d).toFixed(4));


    };
    const deg2rad = (deg) => {
        return deg * (Math.PI / 180)
    }
    return (<>
        <PatientNav />
        <br /><br />
        <br />

        <div className="row align-items-center">
            <div className="col-lg-2 col-md-2"></div>
            <div className="col-lg-4 col-md-4" style={{ marginLeft: "5%", marginTop: "3%" }}>
                <div className="product-search prod-div-search ">

                    <a style={{ marginLeft: "90%" }} href="#" data-toggle="modal" data-target="#shoppingWishlistModal"><i style={{ fontSize: "20px", color: "#C3B7B7" }} className='fa fa-heart fa-lg'></i></a>
                    {img !== "" ? <img style={{ marginTop: "5%", opacity: item.service_category === "master_consumable" && similarProdHosp.length > 0 && isEmpty && "0.2" }} src={img} alt="image" />
                        : <img style={{ borderRadius: "20px", }} src={service2} alt="image" />}

                </div>
                <br />
                <div className="" style={{ display: "flex", paddingLeft: "3%" }}>

                    <button type="submit" style={{ marginTop: "4%", backgroundColor: "#F0A639", fontSize: "12px", paddingLeft: "20px", marginRight: "0px", width: "180px", paddingTop: "3%" }} className="default-btn " onClick={() => { addCart() }}> Buy Now</button>&nbsp;&nbsp;&nbsp;&nbsp;

                    <button type="submit" style={{ marginTop: "4%", fontSize: "12px", paddingLeft: "20px", marginRight: "0px", width: "180px", backgroundColor: "white", color: "red", border: "1px solid #E73C17", paddingTop: "3%" }} className="default-btn default-btn-white" onClick={() => { addCart() }}> Add to Cart</button>
                </div>
            </div>

            <div className="col-lg-5 col-md-5" >
                <div className="" style={{ fontSize: "15px", marginLeft: "5%", marginTop: "3%" }}>
                    <div style={{ fontSize: "12px" }}><Link to="/webordering">Home</Link>/<Link to={item.service_category === "master_scan" ? "/categories?categorynew=Scan" : item.service_category === "misc" ? "/categories?categorynew=Misc" : item.service_category === "master_medicine" ? "/categories?categorynew=Medicine" : item.service_category === "master_procedure" ? "/categories?categorynew=Procedure" : item.service_category === "master_consumable" ? "/categories?categorynew=Consumable" : "/categories?categorynew=Lab"}> {item.service_category === "master_scan"
                        ? "Scan"
                        : item.service_category === "misc"
                            ? "Misc"
                            : item.service_category === "master_medicine"
                                ? "Medicine"
                                : item.service_category === "master_procedure"
                                    ? "Procedure"
                                    : item.service_category === "master_consumable"
                                        ? "Consumables"
                                        : item.service_category === "master_labtest"
                                            ? "Test"
                                            : ""} </Link>/
                        <Link to="#">{item.service}</Link></div>
                    <h6 style={{ marginTop: "1%" }}><a href="#"><b>{item.service}</b></a></h6>
                    <div className="products-details-desc">
                        <div className="price" style={{ fontSize: "12px" }}>
                            {selected.length === 0 ? <> {item.discount !== 0 ? <><span className="old-price">${item.amount}</span>&nbsp;&nbsp;</> : ""}
                                <span className="discounted-price">${(item.amount * (100 - item.discount) / 100).toFixed(2)}</span>
                                <span>&nbsp;&nbsp;</span>
                                {item.discount !== 0 ? <Badge pill variant="success" style={{ fontSize: '12px' }}>
                                    {item.discount}% Off!
                                </Badge> : ""}</> :
                                <> {selected[0].discount !== 0 ? <><span className="old-price">${selected[0].price}</span>&nbsp;&nbsp;</> : ""}
                                    <span className="discounted-price">${(selected[0].price * (100 - selected[0].discount) / 100).toFixed(2)}</span>
                                    <span>&nbsp;&nbsp;</span>
                                    {item.discount !== 0 ? <Badge pill variant="success" style={{ fontSize: '12px' }}>
                                        {selected[0].discount}% Off!
                                    </Badge> : ""}</>}
                        </div>
                    </div>
                    <div style={{ display: "flex", color: "gold", fontSize: "12px" }} className="products-review">
                        <div className="rating">
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>

                        </div>
                        <a href="#" className="rating-count"><small>4 stars</small></a>
                    </div>
                    <ul style={{ fontSize: "12px", marginLeft: "-27px", marginTop: "1%" }} className="products-info">
                        <li><span><b>Vendor:</b></span> <span><b>{item.name}</b></span></li>
                        <li><span><b>Availability:</b></span> <span><b>In stock</b></span></li>
                        <li><span><b>Products Type:</b></span> <span><b>{item.main_service}</b> </span></li>
                    </ul>
                    <div className="">
                        {item.service_category === "master_consumable" || item.service_category === "master_medicine" ?
                            <div className="input-counter" style={{ display: 'flex' }}>
                                <div style={{ paddingTop: "3px", paddingLeft: "1%" }} className="pro-counter">QTY</div>
                                &nbsp;
                                <input style={{ paddingLeft: "1%" }} onChange={handleChangeQty} className="pro-counter" value={qty} type={"number"} />
                            </div>
                            : ""}

                    </div>
                    {colors.length > 0 && <div style={{ display: "flex", marginTop: "1%" }}>
                        {colors.map((x) => {
                            return (
                                <>
                                    <div className="prod-size-onselect" style={{ backgroundColor: color === x && "#439efe7a", border: !colors1.includes(x) && "1px solid #80808017" }}><div onClick={() => {
                                        setColor(color === x ? "" : x);
                                        setColors1([]);
                                        setVolumes1([]);
                                        setWeights1([]);
                                        setSizes1([]);
                                        
                                    }} style={{ backgroundColor: x, opacity: colors1.includes(x) ? "1" : "0.1" }} className="colorattr"></div></div><span>&nbsp;&nbsp;</span>
                                </>
                            )
                        })}</div>}
                    {sizes.length > 0 && <div style={{ display: "flex", marginTop: "1%" }}>
                        {sizes.map((x) => {
                            return (
                                <>
                                    <div style={{ backgroundColor: size === x && "#439efe7a", textDecoration: sizes1.includes(x) ? "none" : "line-through", border: !sizes1.includes(x) && "1px solid #80808017" }} onClick={() => {
                                        setSize(size === x ? "" : x); setColors1([]);
                                        setVolumes1([]);
                                        setWeights1([]);
                                        setSizes1([])
                                        
                                    }} className="prod-size-onselect">{x}</div><span>&nbsp;&nbsp;</span>
                                </>
                            )
                        })}</div>}

                    {volumes.length > 0 && <div style={{ display: "flex", marginTop: "1%" }}>
                        {volumes.map((x) => {
                            return (
                                <>
                                    <div style={{ backgroundColor: volume === x && "#439efe7a", textDecoration: volumes1.includes(x) ? "none" : "line-through", border: !volumes1.includes(x) && "1px solid #80808017" }} onClick={() => {
                                        setVolume(volume === x ? "" : x); setColors1([]);
                                        setVolumes1([]);
                                        setWeights1([]);
                                        setSizes1([])
                                       
                                    }} className="prod-size-onselect">{x}L</div><span>&nbsp;&nbsp;</span>
                                </>
                            )
                        })}</div>}
                    {weights.length > 0 && <div style={{ display: "flex", marginTop: "1%" }}>
                        {weights.map((x) => {
                            return (
                                <>
                                    <div style={{ backgroundColor: weight === x && "#439efe7a", textDecoration: weights1.includes(x) ? "none" : "line-through", border: !weights1.includes(x) && "1px solid #80808017" }} onClick={() => {
                                        setWeight(weight === x ? "" : x); setColors1([]);
                                        setVolumes1([]);
                                        setWeights1([]);
                                        setSizes1([]);
                                      
                                    }} className="prod-size-onselect">{x}Kg</div><span>&nbsp;&nbsp;</span>
                                </>
                            )
                        })}</div>}
                    <div style={{ fontSize: "12px", marginTop: "2%" }} className="">
                        <div>
                            <input type="radio" style={{ width: "9px" }} name="status" onChange={handleChangeRadio} value="1" />
                            {" "}
                            <span style={{ color: 'black' }}>Today</span>
                        </div>

                        <div>
                            <input type="radio" style={{ width: "9px" }} name="status" onChange={handleChangeRadio} value="2" />
                            {" "}
                            {dateval ?

                                <DatePicker
                                    value={new Date(dateval)}
                                    onChange={(date) => {
                                        handleDateChange(date);

                                    }}
                                    minDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    className="custom-datepicker"

                                />

                                :
                                <span style={{ color: 'black' }}>Set Delivery Date</span>}
                        </div>

                    </div><br />
                    <div className="del-date prod-div">
                        <i style={{ color: "#F0A639" }} class="fa fa-truck fa-flip-horizontal" aria-hidden="true"></i>&nbsp;&nbsp;
                        <span style={{ color: "#33A10D" }}>Free Delivery</span></div><br />
                    {(login_datas) ?
                        <div
                            className="shipping"
                            style={{ flex: "2 2 70%" }}
                        >
                            <h6
                                style={{
                                    color: "#000000",
                                    marginLeft: "4%",
                                    textDecoration: "underline",
                                }}
                            >
                                <b>Shipping Address</b>
                            </h6>
                            <h6
                                style={{
                                    fontSize: "15px",
                                    marginLeft: "5%",
                                    color: "#6F6F6F",
                                }}
                            >
                                {addres && addres.length > 0 ? <><span> {addres[0].first_name}{" "}{addres[0].last_name}</span><br />
                                    {addres[0].apt_no !== null && <span>{addres[0].apt_no},</span>}
                                    {addres[0].landmark !== null && <span>{" "}{addres[0].landmark},</span>}
                                    {addres[0].city !== null && <span>{" "}{addres[0].city},</span>}
                                    {addres[0].province !== null && <span>{" "}{addres[0].province},</span>}
                                    {addres[0].pincode !== null && <span>{" "}{addres[0].pincode},</span>}
                                    {addres[0].state !== null && <span>{" "}{addres[0].state}</span>}<br />
                                    {addres[0].mobile_number !== null && <span>Mobile:{addres[0].mobile_number}</span>}
                                </>
                                    : "Please Add Address"}
                                <i
                                    class="fa fa-pencil-square"
                                    onClick={() => {
                                        setAddressView(true);
                                    }}
                                    style={{ color: "#F0A639", marginLeft: "90%" }}
                                    aria-hidden="true"
                                ></i>
                            </h6>

                        </div>
                        : ""}
                </div>

            </div>
        </div><br /><br />
        <div className="prod-div" style={{ display: "flex", paddingLeft: "10%" }}>

            <div className="col-2" onClick={() => {

                setDescription(true);
                setReviews(false);
            }}
                style={{ textDecoration: description ? "underline #E73C17" : "" }}>Description</div>
            <div className="col-2" onClick={() => {
                setDescription(false);
                setReviews(true);
            }} style={{ textDecoration: reviews ? "underline #E73C17" : "" }}>Reviews</div>

        </div>
        <div className="devider"></div>
        {description ? <div style={{ fontSize: "12px", padding: "5%" }} className="prod-div">
<h6><b>Product Details:</b></h6>&nbsp;&nbsp;&nbsp;&nbsp;
{selected.length > 0 &&<ul style={{ fontSize: "12px", marginLeft: "-27px", marginTop: "1%" }} className="products-info">
{selected[0].color&& <li><span><b>Color:</b></span> <span><b>{selected[0].color}</b></span></li>}
{selected[0].weight&&<li><span><b>Weight:</b></span> <span><b>{selected[0].weight}</b></span></li>}
{selected[0].size&& <li><span><b>Size:</b></span> <span><b>{selected[0].size}</b> </span></li>}
{selected[0].volume&& <li><span><b>Size:</b></span> <span><b>{selected[0].volume}</b> </span></li>}
                    </ul>}
            {prodDetails.length > 0 && prodDetails[0].description}


        </div>
            : <div style={{ marginTop: "3%" }} className="prod-div">Reviews</div>}

        <div className="appForm">
            <div className="section-title">
                <h6>Similar Products</h6>
            </div>

            <div className="row">
                <div style={{ display: "contents", width: "200px" }} className="home-slides owl-carousel owl-theme" >
                    <OwlCarousel items={similarProd.length > 3 ? 4 : similarProd.length}
                        className="owl-theme"
                        loop
                        nav
                        dots={false}
                        margin={0}

                    >
                        {similarProd.map((x) => (

                            <div className="single-products-box" style={{ width: "100%" }}>
                                <div className="image prod-div" style={{ backgroundColor: "white" }} >


                                    <a href="#" className="d-block">    <Link
                                    to="/search"
                                    state={{ details: x.item_service}}
                                   
                                    > <img src={item.service_category === "master_medicine" || item.service_category === "master_consumable" ? x.image : service2} alt="image" style={{ height: "150px", width: "175px", backgroundColor: "#f7f8fa" }} onClick={() => ""} /></Link></a>

                                    <div className="buttons-list">
                                        <ul>
                                            <li>
                                                <div onClick={() => addToCart(x)} className="cart-btn">
                                                    <a data-toggle="modal" data-target="#productsQuickView">
                                                        <i class="fa fa-cart-plus" aria-hidden="true"></i>
                                                        <span className="tooltip-label">Add to Cart</span>
                                                    </a>
                                                </div>
                                            </li>
                                            <li>
                                                <div onClick={() => ""} className="quick-view-btn">
                                                    <Link
                                                        to="/search"
                                                        state={{ details: x.item_service}}

                                                    >
                                                        <i className='bx bx-search-alt'></i>
                                                        <span className="tooltip-label">Quick View</span>
                                                    </Link>
                                                </div>
                                            </li>


                                        </ul>
                                    </div>
                                </div>

                                <div className="content" style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                                    <h6 style={{ fontFamily: "Arial, monospace" }}><b>{item.service}</b>  <div style={{ float: "right" }} className="price">
                                        <span className="old-price"><i class="fa fa-usd" aria-hidden="true"></i>{x.price}</span>
                                        <span className="discounted-price">{(x.price * (100 - x.discount) / 100).toFixed(2)}</span>
                                    </div></h6>

                                    <small>{x.hospital_name}</small>
                                </div>
                            </div>

                        ))}
                    </OwlCarousel>
                </div>
            </div>
        </div>
        <PatientFooter />
        {addressview ? (
            <AddressPopUp
                show={addressview}
                onHide={() => {
                    setAddressView(false);
                }}
            />
        ) : null}
    </>
    )
}
export default SearchResult;