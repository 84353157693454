import React, { useState, useEffect } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL } from "../../Global";
import { useNavigate, Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import "../ServiceRequests/service.css";
import { Modal } from "react-bootstrap";
let ServiceHistoryAllied = () => {
  const [ServiceList, setServiceList] = useState([]);
  const [activating, setActivating] = useState(false);
  const [render, setRender] = useState(false);
  const [infoShow, setinfoShow] = useState(false);
  const [infoData, setInfoData] = useState("");
  const [type, setType] = useState("");
  
  let navigate = useNavigate();
  const loadData = () => {
    setActivating(true);
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(
        `${APIURL}/api/v1/patient/assigned-sr-view/`,
        {
          headers: { Authorization: "Token " + v2 },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          let dataList = res.data.orders;
          let filteredList = dataList.filter((item, index) => {
            return (item.order_delivery_status.toLowerCase() === "delivered" || item.order_delivery_status.toLowerCase() === "rejected");
          }).sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
          })
          let updatedList = [];
          updatedList = filteredList.map((item, index) => {

            let status;
            let statusObj;
            if (item.order_delivery_status.toLowerCase() === "delivered") {
              status = "delivered";
              statusObj = { value: "delivered", label: "Delivered" }
            }
            else if (item.order_delivery_status.toLowerCase() === "rejected") {
              status = "rejected";
              statusObj = { value: "rejected", label: "Rejected" }
            }

            return ({
              patient_name: item.patient_name,
              id: item.order_id,
              ref: item.order_id,
              contact_number: item.contact_number,
              item_service:item.order_type==="master_medicine"?"medicines":item.order_type==="master_labtest"?"lab tests":item.su_orders[0].item_service_name,
              status: status,
              statusObj: statusObj,
              order_type:item.order_type,
              su_orders:item.su_orders,
           
            }
            )
          })
          setActivating(false);
          setServiceList(updatedList);
        } else {
          setActivating(false);
          //  setErrorShow(true)
        }
      })
      .catch((err) => {
        setActivating(false);
        // setErrorShow(true)
      });
  };
  useEffect(() => {
    loadData();
  }, []);
  const handleInfoPopUp = (item,type) => {
    setInfoData(item);
    setinfoShow(true);
    console.log(item)
    if(type==="master_medicine"){
      setType("Medicines")
    }else{
      setType("Lab Tests")
    }
  };
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><h5>{type}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
           
            
        {infoData.map((item,index) => {
             return (            
             <div className="row-of-features">
               <div className="col-of-features"><br/>
                 <h6> {index+1}&nbsp;{item.item_service_name}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Quantity:&nbsp;{item.request_quantity}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Requested Due Date:&nbsp;{item.request_due_date}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Priority:&nbsp;{item.request_priority}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Status:&nbsp;{item.request_status}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Amount:&nbsp;{item.order_amount}</h6>
             

              
               </div>
             </div>
             );
          })}
              
          
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const renderService = (cellInfo) => {
   
    return (
      <div style={{ display: "flex" }}>
        {" "}

        <div
         
        >
          {ServiceList[cellInfo.index][cellInfo.column.id]}
        </div>
     {ServiceList[cellInfo.index].order_type==="master_medicine"||ServiceList[cellInfo.index].order_type==="master_labtest" ?<i
              style={{ color: "#75759c", paddingLeft: "5%" }}
              className="fas fa-info-circle fa-sm"
              onClick={() => {
               handleInfoPopUp(ServiceList[cellInfo.index].su_orders,ServiceList[cellInfo.index].order_type);
              }}
            ></i>:""}{" "}
      </div>
    );
  };
  useEffect(() => {
    setServiceList(ServiceList)
  }, [render]);

  const statusOptions = [{ value: 'delivered', label: 'Delivered' },
  { value: 'rejected', label: 'Rejected' }];

  const renderStatus2 = (cellInfo) => {
    let data = ServiceList[cellInfo.index].statusObj;
    let status = '';
    if (data.value.toLowerCase() === 'delivered') {
      status = 'Delivered';
    }else if (data.value.toLowerCase() === 'rejected') {
      status = 'Rejected';
    }
    return (
      <>
      <span>{status}</span>
      </>
    );
  }

  const columns = [
    {
      Header: "ID",
      accessor: "ref",
      sortable: true,
      filterable: true,
      style: { whiteSpace: "unset" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      minWidth: 20,
    },
    {
      Header: "Service",
      accessor: "item_service",
      Cell:renderService,
      sortable: true,
      filterable: true,
      style: { whiteSpace: "unset" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      minWidth: 40,
    },
    {
      Header: "Patient Name",
      accessor: "patient_name",
      sortable: true,
      filterable: true,
      style: { whiteSpace: "unset" },
      filterMethod: (filter, row) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      minWidth: 40,
    },

    {
      Header: "Delivery Status",
      accessor: "statusObj",
      Cell: renderStatus2,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value.toLowerCase() === "delivered") {
          return row[filter.id].value === "delivered";
        }
        if (filter.value.toLowerCase() === "rejected") {
          return row[filter.id].value === "rejected";
        }
      },
      Filter: ({ filter, onChange }) =>
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="delivered">Delivered</option>
          <option value="rejected">Rejected</option>
        </select>,

      width: 200
    },
  ];

  return (
    <>
      <div
        id="patient-presc-page"
        className="appForm "
        style={{ width: "100%" }}
      >
        <LoadingOverlay
          active={activating}
          spinner
          styles={{
            spinner: (base) => ({
              ...base,
              width: "50px",
              "& svg circle": {
                stroke: "rgba(0, 0, 255, 0.5)",
              },
            }),
            overlay: {},
          }}
        >
         
          <div className="patient-presc-sections patient-presc-sections2">
            <div className="table-holder">
            <h5 className="text-center"><b>
          Service History
          </b></h5>
            <div className="service-table-holder" style={{ backgroundColor: "#efff001a", height: "34px" }}>
            <div class="row" style={{ textAlign: "left", paddingTop: "4px" }}>
                  <div class="col-5">
                    {/* <h5 className="" style={{ color: "var(--theme-blue)" }}><b> <i class="fa fa-th-large" aria-hidden="true"></i>{" "}Service History</b></h5> */}
                  </div>


                  <div class="col">

                  </div>
                  <div class="col">
                    <button onClick={() => navigate("/service-request-allied")} type="button" style={{ border: "none", float: "right", textDecoration: "none" }} class=" btn-link"> Service Fulfillment{" "}<i class="fa fa-angle-right" aria-hidden="true"></i></button>

                  </div>

                </div>
              </div>
              <ReactTable
                filterable
                minRows={10}
                data={ServiceList}
                columns={columns}
                defaultPageSize={10}
                className="service_table"
              />
            </div>
          </div>
        </LoadingOverlay>{" "}
      </div>
      {infoShow ? (
        <InfoPopup
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ServiceHistoryAllied;
