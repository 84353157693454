import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function AddProcedure(props) {

  let formData =new FormData();
  const [image, setImage] = useState(null);

    const[data, setData]= useState({
        procedurename:'',
        proceduretype:'',
        

    })

    const [error,setError]=useState({
      procedurename:'',
      proceduretype:'',
       });

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const [refresh, setRefresh]= useState(false)
    //const [addRentalShow, setAddRentalShow]= useState(false)
    const[errorValidate,setErrorValidate]=useState(false)
    const[completeShow,setCompleteShow]=useState(false)

//     const serviceOptions = [{ value:'general', label:'General' },
//     { value:'service_request', label:'Service Request' },
//     { value:'rental', label:'Equipment Request' }
// ]

    const unitOptions = [{ value:'days', label:'Days' },
    { value: 'count', label: 'count' }
]



  const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }

    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Procedure already present.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
             
            
            </Modal.Footer>
          </Modal>
        );
      }

     
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

      const handleImageUpload = (event) => {
        debugger;
        const selectedImage = event.target.files[0];
        setImage(selectedImage);
      };
    

      const CompletePopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'> Successfully Saved</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
             <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
             
             
            </Modal.Footer>
          </Modal>
        );
      }

 
  const validate=()=>{
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["procedurename"] || input["procedurename"]==="" ) {
      isValid = false;
      errors["procedurename"] = "Please enter procedure Name";
    }
    if (!input["proceduretype"] || input["proceduretype"].value==="" ) {
      isValid = false;
      errors["proceduretype"] = "Please enter procedure Type";
    }
    

     setError(currentstate=>({
          ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();

    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
    procedure_name : data.procedurename.slice(0,1).toUpperCase() +data.procedurename.slice(1, data.procedurename.length),
    procedure_type : data.proceduretype,

  }
  const formData = new FormData();
  if (image) {
    debugger;
    formData.append('image', image);
  }
Object.keys(dataToSend).forEach(key => formData.append(key, dataToSend[key]));
 
     axios
       .post(`${APIURL}/api/v1/staff/master-procedure/`, formData , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          setRefresh(true)
           setCompleteShow(true)
           //setSuccessShow(false)
         } else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
   
    }
    else {
       setErrorValidate(true)
    }

    

}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Add procedure</h4>
    <div style={{display:"flex" ,marginLeft:"180px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {image && (
         <div style={{ display: 'flex' }}>
          <p>Selected Image:</p>
          <img src={URL.createObjectURL(image)} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
        <div style={{maxHeight:'1000px'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField">
                  <div  style={{display:"flex"}}>
                    <label className='align-rental'>*Procedure Name: </label>
                    <input type='text' className='form-control title-flex' name='procedurename' value={data.procedurename} onChange={handleTitle} /></div>
                    {error.procedurename ? <div className="error-validation-msg error-feature-inventory">{error.procedurename}</div> : null}
                </div>

                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Procedure Type : </label>
                    <input type='text' className='form-control title-flex' name='proceduretype' value={data.proceduretype} onChange={handleTitle} /></div>
                    {error.proceduretype ? <div className="error-validation-msg error-feature-inventory">{error.proceduretype}</div> : null}
                </div>

            </div>

            

            

           

          

            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>
                
            </div>
            
             {/* {successShow ? 
<SuccessPopUp
show={successShow}
onProceed={() =>  {setSuccessShow(false); props.modalClose();}}
onHide={() =>  {setSuccessShow(false); props.notProceedClose(); }}
/> 
: null} */}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}


{completeShow ? 

<CompletePopUp
show={completeShow}
onHide={() =>  {setCompleteShow(false)
props.modalClose()}}
/> 
: null}
          
            
        </div>
        </>
    )
}

export default AddProcedure
